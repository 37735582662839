import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.css";
// import "./DashboardPage.css";
import axios from 'axios';
import { loginUser, logout } from '../Service/service';

import Bargraph from "../components/Misreports/Bargraph";
import Areagraph from "../components/Misreports/Areagraph";
import maxmize from "../Assets/maxmize.png";
import call from "../Assets/call.png";
import totalvoters from "../Assets/totalvoters.png";
import malevoters from "../Assets/malevoters.png";
import femalevoters from "../Assets/femalevoters.png";
import othersvoters from "../Assets/othersvoters.png";
import Bargraphtwo from "../components/Misreports/Bargraphtwo";
import endfooter from "../Assets/endfooter.png";
import graphclose from "../Assets/closebtngraphs.png";
import totalvotersbg from "../Assets/totalvotersbackground.png";
import malevotersbg from "../Assets/malevotersbg.png";
import femalevotersbg from "../Assets/femalevotersbg.png";
import othervotersbg from "../Assets/othervotersbg.png";

import Filter from "../components/Filters/Filter";
import {
  castePollingVoterFilterData,
  constituencyVoterFilterData,
  getwardissues,
  importantpeople,
} from "../Service/service";
import ToDo from "../components/ToDo/ToDo";
import { RoleDataContext } from "../AppContextComponent";
import { useContext } from "react";
import { ROLES_PAGE_NAME_CONSTANTS } from "../Utils/utils";
import { useNavigate } from "react-router";
import { favourVillageVoterFilterData } from "../Service/service";
import LoaderWrapper from "../components/LoaderWrapper";
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

let pollingvalue = true;
function Dashboard(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [vilagesisues, setWardVillageissues] = useState([]);
  // const vilagesisues = [
  //   {
  //     title: "Power issue",
  //     status: "In Progress",
  //   },
  //   {
  //     title: "Sewerage overflow",
  //     status: "In Progress",
  //   },
  //   {
  //     title: "Bad Road",
  //     status: "In Progress",
  //   },
  //   {
  //     title: "Drinking water Connection",
  //     status: "In Progress",
  //   },
  //   {
  //     title: "Musquito issue",
  //     status: "In Progress",
  //   },
  //   {
  //     title: "Medical fecilities",
  //     status: "In Progress",
  //   },
  //   {
  //     title: "Anganwadi Rural child Care",
  //     status: "In Progress",
  //   },
  //   {
  //     title: "Veterinary hospital",
  //     status: "In Progress",
  //   },
  //   {
  //     title: "Public Transport",
  //     status: "In Progress",
  //   },
  // ];

  const [casteData, setCasteData] = useState();
  const [votersCount, setVotersCount] = useState();
  const [votersFavourTo, setVotersFavourTo] = useState();
  const [importantpeopleData, setImportantpeopleData] = useState([]);
  const [isCheckedcaste, setIsCheckedcaste] = useState(true);
  const [isCheckedvotefavour, setIsCheckedvotefavour] = useState(true);
  const [votersMaxmize, setVotersMaxmize] = useState(false);
  const [setCasteMaxmize, setsetCasteMaxmize] = useState(false);
  const [votefavourMaxmize, setvotefavourMaxmize] = useState(false);
  const selectedPhoneNumberRef = useRef("");
  const { roleDataService } = useContext(RoleDataContext);
  const [casteisLoading, setcasteisLoading] = useState(false);
  const [selectedconstituencies, setSelectedConstituencies] = useState([]);
  const [selectedmandals, setSelectedmandals] = useState([]);
  const [selectedvillages, setSelectedvillages] = useState([]);
  const [selectedpollingstations, setSelectedpollingstations] = useState([]);
  const [filterClear, setFilterclear] = useState(false);
  const [initialrender, seInitialrender] = useState(true);
  const [initialLoader, setInitialLoader] = useState(false);
  const [voterfavourisLoading, setVoterisloading] = useState(false);
  const [voterfilterLoading, setVoterfilterLoading] = useState(false);
  const [castehide, setCastehide] = useState();
  const [votefavourhide, setVotefavourhde] = useState();

  useEffect(() => {
    console.log('Fetching location...');
    let userData = JSON.parse(localStorage.getItem('data'));
    
    let isApiCallInProgress = false; // Flag to track API call status
    let intervalId; // Store the interval ID
  
    const getLocation = async () => {
      // Check if an API call is already in progress or if the component is unmounted
      if (isApiCallInProgress || !userData) {
        console.log('API call already in progress or component is unmounted. Skipping location update.');
        return;
      }
  
      isApiCallInProgress = true; // Set flag to indicate API call start
  
      const watchId = navigator.geolocation.watchPosition(
        async (position) => {
          console.log('Location fetched successfully:', position);
          console.log('position.coords', position.coords);
          console.log('latitude', position.coords.latitude);
          console.log('longitude', position.coords.longitude);
          const locationData = {
            user_id: userData.user_data.user_id,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            user_name: userData.user_data.first_name
          };
          // Make a POST request to the API endpoint
          try {
            await axios.post(
              'https://sxqm562sjc.execute-api.us-east-1.amazonaws.com/dev/updatelocation',
              locationData
            );
            console.log('Location updated successfully.');
          } catch (error) {
            console.error('Error updating location:', error);
          } finally {
            isApiCallInProgress = false; // Reset flag after API call completion
          }
        },
        (error) => {
          console.error('Error getting location:', error.message);
        }
      );
  
      return () => navigator.geolocation.clearWatch(watchId);
    };
  
    // Call getLocation immediately
    getLocation();
  
    // Set interval to call getLocation every 15 seconds
    intervalId = setInterval(getLocation, 15000);
  
    // Clear interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  
  const navigate = useNavigate();

  const clearAppliedFilters = () => {
    setFilterclear(!filterClear);
  };

  useEffect(() => {
    if (pollingvalue) {
      importantpeople().then((people) => {
        setImportantpeopleData(people);
      });
    }
    roleDataService.getPageRole(ROLES_PAGE_NAME_CONSTANTS.Dashboard);
    if (!roleDataService.access.read) {
      navigate("/votersdata");
    }
  }, []);

  useEffect(() => {
    if (!isCheckedcaste) {
      setIsCheckedcaste(true);
    }
  }, []);

  useEffect(() => {
    if (!isCheckedvotefavour) {
      setIsCheckedvotefavour(true);
    }
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setcasteisLoading(true);
  //   }, 2000);
  // }, []);

  const cammaSeperatedNumbers = (num) => {
    const number = num;
    const formattedNumber = number?.toLocaleString("en-IN");
    return formattedNumber;
  };
  const handleMaxmized = () => {
    setVotersMaxmize(true);
  };

  function closemaxmizebtn() {
    setVotersMaxmize(false);
  }

  const handleMaxmizedCaste = () => {
    setsetCasteMaxmize(true);
  };

  function casteMaxmizeClose() {
    setsetCasteMaxmize(false);
  }

  const handlemaxmizedvotfavour = () => {
    setvotefavourMaxmize(true);
  };
  const votefavourMaxmizeClose = () => {
    setvotefavourMaxmize(false);
  };

  const handleMakeCall = () => {
    if (selectedPhoneNumberRef.current) {
      window.location.href = `tel:${selectedPhoneNumberRef.current}`;
    }
  };

  const updateconstituencydropdownvalues = (value) => {
    setSelectedConstituencies(value);
  };
  const updatemandalsdropdown = (value) => {
    setSelectedmandals(value);
  };
  const updatevillagedropdowns = (value) => {
    setSelectedvillages(value);
  };
  const updatepollingstations = (value) => {
    setSelectedpollingstations(value);
  };

  const casteudateshow = (value) => {
    setCastehide(value);
  };
  const votefavourupdate = (value) => {
    setVotefavourhde(value);
  };

  useEffect(() => {
    if (selectedconstituencies.length > 0) {
      setVoterisloading(true);
      favourVillageVoterFilterData(
        selectedconstituencies,
        selectedmandals,
        selectedvillages
      ).then((resp) => {
        if (resp) {
          setVotersFavourTo(resp);
        }
        setVoterisloading(false);
      });
    }
  }, [selectedconstituencies, selectedmandals, selectedvillages]);

  useEffect(() => {
    if (selectedconstituencies.length > 0) {
      setcasteisLoading(true);
      if (initialrender) {
        setInitialLoader(true);
      }
      castePollingVoterFilterData(
        selectedconstituencies,
        selectedmandals,
        selectedvillages,
        selectedpollingstations
      ).then((resp) => {
        if (resp) {
          setCasteData(resp);
        }
        if (initialrender) {
          setInitialLoader(false);
          seInitialrender(false);
        }
        setcasteisLoading(false);
      });
    }
  }, [
    selectedconstituencies,
    selectedmandals,
    selectedvillages,
    selectedpollingstations,
  ]);

  useEffect(() => {
    if (selectedconstituencies.length > 0) {
      setVoterfilterLoading(true);
      constituencyVoterFilterData(
        selectedconstituencies,
        selectedmandals,
        selectedvillages,
        selectedpollingstations
      ).then((resp) => {
        if (resp) {
          setVotersCount(resp);
        }
        setVoterfilterLoading(false);
      });
    }
  }, [
    selectedconstituencies,
    selectedmandals,
    selectedvillages,
    selectedpollingstations,
  ]);

  useEffect(() => {
    if (selectedconstituencies.length > 0) {
      getwardissues(
        selectedconstituencies,
        selectedmandals,
        selectedvillages,
        selectedpollingstations
      ).then((resp) => {
        if (resp) {
          setWardVillageissues(resp.message);
        }
      });
    }
  }, [selectedconstituencies, selectedmandals, selectedvillages]);
  
  const [slideIndex, setSlideIndex] = useState(1);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const slidesArray = document.getElementsByClassName("admin_dashboard_bargraph_main_div_1");
    setSlides(slidesArray);
  }, []); // 

  const plusSlides = (n) => {
    // showSlides(slideIndex + n);
    if (slideIndex + n <= slides.length && slideIndex + n >= 1) {
      showSlides(slideIndex + n);
    }
  };


  const currentSlide = (n) => {
    showSlides(n);
  };

  const showSlides = (n) => {
    const slides = document.getElementsByClassName("admin_dashboard_bargraph_main_div_1");

    if (n > slides.length) {
      setSlideIndex(1);
    }
     else if (n < 1) {
      setSlideIndex(slides.length);
    }
    // else if (slideIndex + n > 2) {
    //   setSlideIndex(1);
    // }
     else {
      setSlideIndex(n);
    }
  };

  useEffect(() => {
    const slides = document.getElementsByClassName("admin_dashboard_bargraph_main_div_1");
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[slideIndex - 1].style.display = "block";
  }, [slideIndex]);

  const logOut = () => {
    logout().then(res => {
      localStorage.removeItem("data");
      navigate("/login");
    }
    );
  }
  const toCamelCase = (str) => {
    if (!str) {
      return ""; // or handle appropriately for your use case
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  return (
    <div className="">

      
      <div>
        {/* <Topnav toggleClicked={toggleClicked} toggleopend={toggleopend} /> */}

        <div className="EMSAdmin_main_div d-flex flex-column">  
          <div className="admin_dashboard_total_votersflex_div d-flex flex-column flex-md-row " id="admin_dashboard_total_voters__">
            <div
              className="admin_dashboards_total_voters_box" id="admin_dashboards_total_voters_box_"
              style={{
                background: "#00AF85",
              }}
            >
              <div className="d-flex flex-column">
                <span className="Admin_dashboard_total_voters_text" id="Admin_dashboard_total_voters_tex_">
                  Total Voters
                </span>
                <span className="admin_total_count_number">
                  {cammaSeperatedNumbers(votersCount?.total_voters)}
                </span>
                <img
                  className="totalvoterslogo_admin_dashboard"
                  src={totalvoters}
                  alt="not_visible"
                />
              </div>
            </div>

            <div
              className="admin_dashboards_total_voters_box admin-bashboard_male_voters_box" id="admin_dashboards_total_voters_box_"
              style={{
                background: "#24469D"
              }}
            >
              <div className="d-flex flex-column dashboard_malevoter">
                <span className="Admin_dashboard_total_voters_text">
                  Male Voters
                </span>
                <span className="admin_total_count_number male_total_count_number">
                  {cammaSeperatedNumbers(votersCount?.male_voters)}
                </span>
              
              </div>
            </div>
         
          </div>



          <div className="admin_dashboard_total_votersflex_div d-flex flex-column flex-md-row " id="admin-dashboard_total_voters_female_others">
        
            <div
              className="admin_dashboards_total_voters_box admin-bashboard_male_voters_box"
              id="admin_dashboards_total_voters_box_"
              style={{
                background: "#BC46B0",
              
              }}
            >
              <div className="d-flex flex-column dashboard_malevoter">
                <span className="Admin_dashboard_total_voters_text">
                  Female Voters
                </span>
                <span className="admin_total_count_number male_total_count_number">
                  {cammaSeperatedNumbers(votersCount?.female_voters)}
                </span>
               
              </div>
            </div>
            <div
              className="admin_dashboards_total_voters_box admin-bashboard_male_voters_box"
              id="admin_dashboards_total_voters_box_"
              style={{
                background: "#DFB23E"
              }}
            >
              <div className="d-flex flex-column dashboard_malevoter">
                <span className="Admin_dashboard_total_voters_text">
                  Others
                </span>
                <span className="admin_total_count_number male_total_count_number">
                  {cammaSeperatedNumbers(votersCount?.other_voters)}
                </span>
              
              </div>
            </div>
          </div>

          <div className="ems_total_voter_boxes_underline_div"></div>
        
          <Filter
            clearfilter={filterClear}
            updateconstituencydropdownvalues={updateconstituencydropdownvalues}
            updatemandalsdropdown={updatemandalsdropdown}
            updatevillagedropdowns={updatevillagedropdowns}
            updatepollingstations={updatepollingstations}
            casteudateshow={casteudateshow}
            votefavourupdate={votefavourupdate}
          />
          <div className="ems_total_dropdown_underline_div"></div>
          <div className="search_clearfilters_parent">
            <span className="search_appears_admin_dashboard">
              Search appears based on filters
            </span>
            <button
              className="clear_filter_admin_dashboard"
              onClick={clearAppliedFilters}
            >
              Clear Filters
            </button>
          </div>

          <div className="boxs_wrap_for_tabs" id="boxs_wrap_for_tabs">
            <div className="d-flex graphs_holder_maindiv_dashboard flex-column flex-md-row ">

              <div className="admin_dashboard_bargraph_main_div_1 ">
                <span
                  className="admin_dashboard_bargraph_main_div"
                >
                  <Bargraph
                    loadingvoter={voterfilterLoading}
                    voters={votersCount}
                    onClick={handleMaxmized}
                  />
                </span>
              </div>
              <div className="admin_dashboard_bargraph_main_div_1 ">
                <span
                  className="admin_dashboard_bargraph_main_div"
                >
                 {isCheckedcaste ? (
                  <Areagraph
                    casteLoad={casteisLoading}
                    caste={casteData}
                    voters={votersCount}
                    maxmizingcasteGraph={handleMaxmizedCaste}
                    popupvalueCaste={setCasteMaxmize}
                    castehideshow={castehide}
                  />
                ) : null}
                </span>
              </div>
              <div className="admin_dashboard_bargraph_main_div_1 ">
                <span
                  className="admin_dashboard_bargraph_main_div"
                >
                 {isCheckedvotefavour ? (
                  <div style={{ height: "100%", width: "100%" }}>
                    {votersFavourTo && (
                      <Bargraphtwo
                        votefavourhideshow={votefavourhide}
                        loadingfavour={voterfavourisLoading}
                        favourto={votersFavourTo}
                        onClick={handlemaxmizedvotfavour}
                      />
                    )}
                  </div>
                ) : null}
                </span>
              </div>

              <div class="btn-container">
                <button
                  className="btn prev"
                  id="prev"
                  onClick={() => plusSlides(-1)}
                >
                  ❮
                </button>
                <button
                  className="btn next"
                  id="next"
                  onClick={() => plusSlides(1)}
                >
                  ❯
                </button>
              </div>

               <div className="admin_dashboard_bargraph_main_div" id="admin_dashboard_bargraph_main_div_charts">
                <Bargraph loadingvoter={voterfilterLoading} voters={votersCount} onClick={handleMaxmized} />
              </div> 

               <div className="admin_dashboard_bargraph_main_div" id="admin_dashboard_bargraph_main_div_charts">
                {isCheckedcaste ? (
                  <Areagraph
                    casteLoad={casteisLoading}
                    caste={casteData}
                    voters={votersCount}
                    maxmizingcasteGraph={handleMaxmizedCaste}
                    popupvalueCaste={setCasteMaxmize}
                    castehideshow={castehide}
                  />
                ) : null}
              </div> 

              
            </div>
            <div className="d-flex">
            <div className="admin_dashboard_bargraph_main_div   admin_dashboard_bargraph_main_div_charts_voters_favoutr_compoent_div_tag"
             id="admin_dashboard_bargraph_main_div_charts">
                {isCheckedvotefavour ? (
                  <div style={{ height: "100%", width: "100%" }}>
                    {votersFavourTo && (
                      <Bargraphtwo
                        votefavourhideshow={votefavourhide}
                        loadingfavour={voterfavourisLoading}
                        favourto={votersFavourTo}
                        onClick={handlemaxmizedvotfavour}
                      />
                    )}
                  </div>
                ) : null}
              </div> 
              <div
                className="admin_dashboard_bargraph_main_div"
                id="admin_imp_people"
              >
                <div className="admindashboard_important_text_div d-flex align-items-center">
                  <div className="d-flex flex-column">
                    <span className="important_people_text_1 ">
                      Important people
                    </span>
                  </div>
                </div>
                <div className="name_designation_mobile_div d-flex align-items-center">
                  <span className="important_people_name_text">Name</span>
                  <span className="important_people_designation_text">
                    Designation
                  </span>
                  <span className="important_people_mobile_text">Mobile</span>
                </div>
                <div className="important_people_info_scrolldiv">
                  {importantpeopleData.length > 0 ? (
                    importantpeopleData?.map((imppeople) => {
                      return (
                        <div
                          className="d-flex admin_dashboard_text_info_detail_border_div"
                          key={imppeople.id}
                        >
                          <div className="importantpeople_info_text_details_div d-flex align-items-center">
                            <span id="imp_poeple_first_name">
                              {/* {imppeople.first_name} */}
                              {toCamelCase(imppeople.first_name)}
                            </span>
                          </div>
                          <div className="importantpeople_info_text_details_div d-flex align-items-center">
                            <span id="imp_poeple_designation">
                              {imppeople.designation}
                            </span>
                          </div>
                          <div className="importantpeople_info_text_details_div d-flex align-items-center">
                            {imppeople.phone_no ? (
                              <>
                                <span id="imp_poeple_mobile_name">
                                  {" "}
                                  {imppeople.phone_no}
                                </span>
                                <img
                                  className="call_logo_admin_dashboard"
                                  src={call}
                                  alt="not_visible"
                                  onClick={() => {
                                    selectedPhoneNumberRef.current =
                                      imppeople.phone_no;
                                    handleMakeCall();
                                  }}
                                />{" "}
                              </>
                            ) : (
                              // "no number"
                              <span className="iphen_dashboard">-</span>
                              // "-"
                              
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="nodata_filters_data">
                      No Data Found</p>
                  )}
                </div>
              </div>
            </div>
           

            <div
              className="d-flex flex-column flex-md-row admin_dashboard_bargraph_main_div_boxs"
              id="admin_dashboard_bargraph_main_div_boxs"
            >
             
              <div
                className="admin_dashboard_bargraph_main_div"
                id="ward_village_dashboard"
              >
                <div className="admindashboard_important_text_div d-flex align-items-center">
                  <div className="d-flex flex-column">
                    <span className="important_people_text_1">
                      Ward/Village issues
                    </span>
                  </div>
                </div>
                <div className="name_designation_mobile_div d-flex align-items-center">
                  <span className="important_people_name_text_title">
                    Title
                  </span>
                  <span className="important_people_designation_text_status">
                    Status
                  </span>
                </div>
                <div className="admin_village_ward_isseues_info_scroll_div">
                  {vilagesisues.length > 0 ? (
                    vilagesisues.map((items) => {
                      return (
                        <div className="admin_dashboard_ward_village_issues_info_main_div d-flex">
                          <div className="admin_dashboard_ward_village_issues_info_sub_div d-flex align-items-center">
                            <span className="admin_dashboard_ward_village_issues_info_text">
                              {items.issue_name}
                            </span>
                          </div>
                          <div className="admin_dashboard_ward_village_issues_info_sub_div d-flex align-items-center">
                            <span
                              className="admin_dashboard_ward_village_issues_info_text"
                              style={{
                                color:
                                  items.issue_status == "In Progress"
                                    ? "#C1A825"
                                    : "#45B733",
                              }}
                            >
                              {items.issue_status}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="nodata_filters_data">No Data Found</p>
                  )}
                </div>
              </div>
              <div
                className="admin_dashboard_bargraph_main_div  admin-todo_block"
                id={`${
                  props.closenav
                    ? "todo_list_for_tab"
                    : "todo_list_for_tab_full"
                }`}
              >
                <ToDo />
              </div>
            </div>
          </div>
        </div>
      </div>
      {votersMaxmize && (
        <div className="bargraph_voters_maxmize_main_div">
          <div className="voters_bargraph_viewreport_parent_div"></div>
          <div className="voters_bargraph_viewreport">
            <button className="close_charts">
              <img
                src={graphclose}
                alt="not_visible"
                onClick={closemaxmizebtn}
              />
            </button>
            <Bargraph voters={votersCount} />
          </div>
        </div>
      )}

      {setCasteMaxmize && (
        <div className="bargraph_voters_maxmize_main_div">
          <div className="voters_bargraph_viewreport_parent_div"></div>
          <div className="voters_Areagraph_viewreport ">
            <img
              className="close_charts"
              src={graphclose}
              alt="not_visible"
              onClick={casteMaxmizeClose}
            />
            <Areagraph
              castehideshow={castehide}
              caste={casteData}
              voters={votersCount}
            />
          </div>
        </div>
      )}
      {votefavourMaxmize && (
        <div className="bargraph_voters_maxmize_main_div">
          <div className="voters_bargraph_viewreport_parent_div"></div>
          <div className="voters_bargraph_viewreport">
            <img
              className="close_charts"
              src={graphclose}
              alt="not_visible"
              onClick={votefavourMaxmizeClose}
            />
            <Bargraphtwo
              favourto={votersFavourTo}
              votefavourhideshow={votefavourhide}
              maxmized={votefavourMaxmize}
            />
          </div>
        </div>
      )}
      {initialLoader ? <LoaderWrapper /> : null}
    </div>
  );
}
export default Dashboard;
