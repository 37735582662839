import { decode, encode } from "base-64";
import { ROLES_PAGE_NAME_CONSTANTS } from "../Utils/utils";

export default class RoleDataService {
  roleData = {};
  access = {};
  allRoles = [];
  constructor() {
    if (localStorage.getItem("data")) {
      try {
        let data = JSON.parse(localStorage.getItem("data"));
        const passwordkey = "asdfwertihvb52803jdn";
        let role_permissions = decode(data.role_data.role_permissions);
        const encodepasswordkey = encode(passwordkey);
        let role_permissions_JSON_STRING = role_permissions.replace(
          encodepasswordkey,
          ""
        );

        let role_permissions_JSON_data =
          role_permissions_JSON_STRING.replaceAll("True", "true");
        let role_permissions_JSON_final = role_permissions_JSON_data.replaceAll(
          "False",
          "false"
        );
        // let role_permissions_JSON = eval(
        //   role_permissions_JSON_STRING.replaceAll("True", "true")
        // );
        this.allRoles = eval(role_permissions_JSON_final);
      } catch (error) {
        console.log("role error", error);
      }
    }
  }

  getPageRole(path) {
    const data = this.allRoles;
    this.roleData = data.filter((x) => x.page_name === path);
    if (this.roleData.length) {
      this.access["read"] = this.roleData[0].read_role;
      this.access["update"] = this.roleData[0].update_role;
      this.access["delete"] = this.roleData[0].delete_role;
      this.access["create"] = this.roleData[0].create_role;
    }
  }
}
