import React, { Suspense, useEffect, useState } from "react";
import { useNavigate, redirect, useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Table from "react-bootstrap/Table";
import "./Reports.scss";
import {
  Button,
  Form,
  Stack,
  Modal,
  Col,
  CloseButton,
  FormCheck,
} from "react-bootstrap";
import { AiOutlineArrowRight } from "@react-icons/all-files/ai/AiOutlineArrowRight";
import { AiOutlinePlus } from "@react-icons/all-files/ai/AiOutlinePlus";
import { FcDownload } from "@react-icons/all-files/fc/FcDownload";
import { ReportsDataService } from "../../Service/service";
import Toast from "react-bootstrap/Toast";

function ReportsPage() {
  const [reportsData, setReportsData] = useState([]);
  const [selectAllCheck, setSelectAllCheck] = useState(false);

  const rolesService = new ReportsDataService();

  useEffect(() => {
    rolesService.getS3Reports().then((result) => {
      if (result.data && result.data.files) {
        let fileInfo = result.data.files;
        let fileObj = [];
        fileInfo.forEach((x) => {
          let obj = {};
          obj["selected"] = false;
          obj["label"] = x.split("/").slice(-1)[0];
          obj["key"] = x;
          fileObj.push(obj);
        });
        setReportsData(fileObj);
      }
    });
  }, []);

  const renderReportsMain = () => {
    return reportsData.map((row, i) => {
      return (
        <tr>
          <td>
            <Stack direction="horizontal" gap={3}>
              <Form.Check
                type="checkbox"
                id={`disabled-default-`}
                onChange={(evt) => onChangeFileSelect(evt, row, i)}
                checked={row.selected}
              />
              {row.label}
            </Stack>
          </td>
          <td>
            <Stack direction="horizontal" gap={3}>
              <FcDownload
                role="button"
                onClick={(evt) => downloadIndividualFile(row)}
              />
            </Stack>
          </td>
        </tr>
      );
    });
  };

  const downloadIndividualFile = (data) => {
    rolesService.getReportsByKey(data.key).then((result) => {
      if (result.data) {
        window.open(result.data, "");
        /* rolesService.getBlobFile(result.data).then(response => {
          debugger
        }) */
      }
    });
  };

  const downloadMultiFile = () => {
    let selectedFiles = reportsData.filter((x) => x.selected);
    selectedFiles.forEach((x) => {
      downloadIndividualFile(x);
    });
  };

  const readFileUrl = (file) => {};

  const onChangeSelectAll = (evt) => {
    let data = JSON.parse(JSON.stringify(reportsData));
    if (evt.target.checked) {
      setSelectAllCheck(true);
      data.forEach((x) => (x["selected"] = true));
      setReportsData(data);
    } else {
      setSelectAllCheck(false);
      data.forEach((x) => (x["selected"] = false));
      setReportsData(data);
    }
  };

  const onChangeFileSelect = (evt, file, index) => {
    let data = JSON.parse(JSON.stringify(reportsData));
    if (evt.target.checked) {
      data[index].selected = true;
      setReportsData(data);
    } else {
      data[index].selected = false;
      setReportsData(data);
    }
    if (data.filter((x) => x.selected).length === data.length) {
      setSelectAllCheck(true);
    } else if (selectAllCheck) {
      setSelectAllCheck(false);
    }
  };

  return (
    <div className="rules-view container-fluid">
      <Row className="title">
        <Stack direction="horizontal" gap={3}>
          <div>
            <div className="reports_title">Reports</div>
          </div>
          <div className="ms-auto create-button" id="reports_download_button">
            <Button
              className={`${
                reportsData.filter((x) => x.selected).length < 2
                  ? "disabled"
                  : ""
              }`}
              onClick={downloadMultiFile}
            >
              Download Selected
            </Button>
          </div>
        </Stack>
        <br />
        <hr />
      </Row>
      {/*   <Row>
          {showSuccessTost.message ? <Toast show={showSuccessTost.status} className="d-inline-block m-1" bg={showSuccessTost.type === 'success'? 'success' : 'danger'}>
              <Toast.Body>
                <Stack className="text-white" direction="horizontal" gap={3}>
                  <div>{showSuccessTost.message}</div>
                  <CloseButton className="btn-close ms-auto text-white" />
                </Stack>
                </Toast.Body>
            </Toast> : '' }
        </Row> */}
      <Row className="m-3 table-main">
        <Table striped bordered hover>
          {/*             <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
              </tr>
            </thead> */}
          <tbody>
            <tr>
              <th>
                <Stack direction="horizontal" gap={3}>
                  <Form.Check
                  className="reports_checkbox"
                    type="checkbox"
                    id={`disabled-default-`}
                    onChange={onChangeSelectAll}
                    checked={selectAllCheck}
                  />
                  Select All
                </Stack>
              </th>
              <td>
                <Stack direction="horizontal" gap={3}></Stack>
              </td>
            </tr>
              {renderReportsMain()}
              {/* {reportsData.map( (row, i) => {
                return <tr>
                  <td>
                    <Stack direction="horizontal" gap={3}>
                      <Form.Check
                        type="checkbox"
                        id={`disabled-default-`}
                        onChange={evt => onChangeFileSelect(evt,row,i)}
                        checked={row.selected}
                      />
                      {row.label}
                    </Stack>
                  </td>
                  <td>
                    <Stack direction="horizontal" gap={3}>
                      <FcDownload role="button" />
                    </Stack>
                  </td>
                </tr>
              }) } */}
          </tbody>
        </Table>
      </Row>
    </div>
  );
}

export default ReportsPage;
