import React, { useEffect, useState } from "react";
import {
  Chart,
  SeriesTemplate,
  CommonSeriesSettings,
  Label,
  Tooltip,
} from "devextreme-react/chart";
import "./Bargraphtwo.css";
import maxmize from "../../Assets/maxmize.png";
import { ValueAxis, Grid } from "devextreme-react/chart";

const formatNumber = (value) => {
  if (typeof value === "string") {
    value = value.replace(/,/g, "");
  }

  if (value >= 10000000) {
    return `${(value / 10000000).toFixed(1)}Cr`;
  } else if (value >= 100000) {
    return `${(value / 100000).toFixed(1)}L`;
  } else if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}K`;
  } else {
    return `${value}`;
  }
};

const Bargraphtwo = (props) => {
  const load = props.loadingfavour;
  const votefavourshow = props.votefavourhideshow
  const [showAll, setShowAll] = useState(false);
  const colors = [
    "#3396EC",
    "#77B8F2",
    "#9C53F2",
    "#52D4CD",
    "#A79797",
    "#B5A7A7",
    "#9E8B8B",
    "#AC9D9D",
    "#8F7E7E",
    "#C0B0B0",
    "#938181",
    "#AEA1A1",
    "#827272",
    "#D1C3C3",
    "#7A6565",
  ];

  const handleButtonmaxmize = () => {
    props.onClick()
  };

  useEffect(()=>{
    if(props.maxmized === true){
      setShowAll(true);
    }
  },[props.maxmized])

 


    // ... (previous code)
  
    const resultArray = [];
    let i = 0;
    for (const key in props.favourto) {
      if (!showAll && i >= 4) {
        break;
      }
  
      const number = votefavourshow ? props.favourto[key] : 0; // Set number to 0 if votefavourshow is false
  
    //   resultArray.push({
    //     party: key,
    //     number,
    //     colors: colors[i % colors.length],
    //   });
    //   i++;
    // }
  
    // ... (rest of the code)
  
  
  
  resultArray.push({
    party: key,
    number: votefavourshow ? props.favourto[key] : 0,
    colors: colors[i % colors.length], // Use modulo to cycle through colors
  });
  i++;
}

  const userData = JSON.parse(localStorage.getItem("data"));
  const chilkaluripetDataSource = [
    {
      party: "YSRCP",
      number: props.favourto["YSR Congress"],
      color: "#3396EC",
    },
    {
      party: "TDP",
      number: props.favourto["Telugu Desam"],
      color: "#77B8F2",
    },
    {
      party: "JSP",
      number: props.favourto["Janasena"],
      color: "#9C53F2",
    },
    {
      party: "INC",
      number: props.favourto["Congress"],
      color: "#52D4CD",
    },
    {
      party: "Others",
      number: 0,
      color: "#A79797",
    },
  ];
  const siddipetSource = [
    {
      party: "YSRCP",
      number: props.favourto["YSR Congress"],
      color: "#3396EC",
    },
    {
      party: "TDP",
      number: props.favourto["Telugu Desam"],
      color: "#77B8F2",
    },
    {
      party: "JSP",
      number: props.favourto["Janasena"],
      color: "#9C53F2",
    },
    {
      party: "BJP",
      number: props.favourto["Bharatiya Janatha Party"],
      color: "#9C53F2",
    },
    {
      party: "INC",
      number: props.favourto["Congress"],
      color: "#52D4CD",
    },
    {
      party: "BRS",
      number: props.favourto["Telangana Rashtra Samiti"],
      color: "#52D4CD",
    },
    {
      party: "Others",
      number: 0,
      color: "#A79797",
    },
  ];

  // const dataSource =
  //   userData.user_data.dist === "Siddipet"
  //     ? siddipetSource
  //     : chilkaluripetDataSource;
  const dataSource = resultArray;

  const labelFont = {
    color: "#263659",
  };

  

  // const datanumbers=formatNumber(dataSource.number)


  return (
    <div className="bargraphtwo_main_div">
     
      <button className="maxmizebtn_charts" onClick={handleButtonmaxmize}>
        <img src={maxmize} alt="not_visible" />
      </button>
      {/* <span className='charts_viewport_text'>View report</span>  */}
      <div className="voters_favourtext_main_div d-flex flex-column justify-content-center">
        <span>Voters favours To</span>
        <div className="ems_admin_dashboara_votersfavoursto_chart_text_underline"></div>
      </div>
     <Chart id="chart" palette={colors} dataSource={load ?null: dataSource}>
        <ValueAxis maxValueMargin={0.25}>
          <Grid visible={true} width={1} color={"#F0F0F0"} />
        </ValueAxis>
        <CommonSeriesSettings
          argumentField="party"
          valueField="number"
          type="bar"
          ignoreEmptyPoints={true}
          minBarSize={10}
          barWidth={30}
        >
          <Label
            visible={true}
            position="outside"
            backgroundColor={"none"}
            font={labelFont}
            format={formatNumber}
          ></Label>
        </CommonSeriesSettings>
        <SeriesTemplate nameField="party" />
        <Tooltip
          enabled={true}
          customizeTooltip={(arg) => {
            const color = arg.point.getColor();
            return {
              text: `<span className="hover_text_graphs" style="color: ${color}"><p class='text_p_tag'>${arg.argumentText}: ${arg.valueText}</p></span>`,
            };
          }}
        />
      </Chart>
      {/* <div className="d-flex flex-column castedetails_main_div "> */}

      <div className="d-flex flex-column  partydetails_main_div">
        {dataSource.map((value) => {
          return (
            <div
              key={value.id}
              className="d-flex align-items-center castecolors_caste_div_main"
            >
              <div
                className="castecolo_div"
                style={{ background: `${value.colors}` }}
              ></div>
              <span className="castecolors_caste_div">{value.party}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Bargraphtwo;
