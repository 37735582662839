import React, { useEffect, useState, useRef } from "react";
import {
  getBirthdayData,
  // getBirthdayDataWithPagination,
  getBirthdayFilters,
} from "../Service/BirthdayService";
import { loginUser, logout } from "../Service/service";
import axios from 'axios';

import callPendingActionIcon from "../Assets/callPendingActionIcon.svg";
import endfooter from "../Assets/endfooter.png";
import arrow from "../Assets/dropdownarrow.png";
import Bluearrow from "../Assets/Bluearrow.png";
// import "./Birthday.css";
import "./BirthdayPage.css";
import { isMobile } from "react-device-detect";
import { MdKeyboardArrowRight } from "@react-icons/all-files/md/MdKeyboardArrowRight";
import { MdKeyboardArrowLeft } from "@react-icons/all-files/md/MdKeyboardArrowLeft";
import viewEyeIcon from "../Assets/viewEyeIcon.svg";
import Modal from "react-bootstrap/Modal";
import BirthdayIndividualModal from "./BirthdayIndividualModal";
import LoaderWrapper from "../components/LoaderWrapper";
import pdf from "../Assets/pdflogo.png";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import excel from "../Assets/excellogo.png";
function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

const handleTitleDiv = (column) => {
  return (
    <div className="titleDiv">
      <span>{column}</span>
    </div>
  );
};

const Birthday = () => {
  // const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0); // State to store the total number of data
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpages, setTotalpages] = useState();
  const pagesCount = Math.ceil(totalpages / 50);
  const [objectsPerPage, setObjectsPerPage] = useState(50);
  const [nextPageUrl, setNextPageUrl] = useState(undefined);
  const [prevPageUrl, setPrevPageUrl] = useState(undefined);
  const [filtersData, setFiltersData] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const [showDropdownward, setShowDropdownward] = useState(false);
  const ref = useRef();
  const [viewdata, setViewdata] = useState(null);
  const [dropdown, setDropdown] = useState(false);
  const [selectedFilteredvalues, setSelectedFilterdvalues] = useState([]);
  const [maleCount, setMaleCount] = useState(0);
  const [femaleCount, setFemaleCount] = useState(0);
  const [show, setShow] = useState(false);
  const [mobileColumn, setMobileColumn] = useState("First Name");
  const tabColumnsArray = [
    // "Action",
    // "Voter ID",
    "First Name",
    // "Last Name",
    // "Age",
    // "Designation",
    "Phone Number",
    "Call Status",
  ];
  const [columnIndex, setColumnIndex] = useState(1);
  const [viewBirthdayItem, setViewBirthdayItem] = useState("");
  const [isLoading, setIsLoading] = useState();

  const handleColumn = (index) => {
    if (index >= 1 && index < tabColumnsArray.length) {
      setMobileColumn(tabColumnsArray[index]);
      setColumnIndex(index);
    }
  };

  useEffect(() => {
    console.log('Fetching location...');
    let userData = JSON.parse(localStorage.getItem('data'));
    
    let isApiCallInProgress = false; // Flag to track API call status
    let intervalId; // Store the interval ID
  
    const getLocation = async () => {
      // Check if an API call is already in progress or if the component is unmounted
      if (isApiCallInProgress || !userData) {
        console.log('API call already in progress or component is unmounted. Skipping location update.');
        return;
      }
  
      isApiCallInProgress = true; // Set flag to indicate API call start
  
      const watchId = navigator.geolocation.watchPosition(
        async (position) => {
          console.log('Location fetched successfully:', position);
          console.log('position.coords', position.coords);
          console.log('latitude', position.coords.latitude);
          console.log('longitude', position.coords.longitude);
          const locationData = {
            user_id: userData.user_data.user_id,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            user_name: userData.user_data.first_name
          };
          // Make a POST request to the API endpoint
          try {
            await axios.post(
              'https://sxqm562sjc.execute-api.us-east-1.amazonaws.com/dev/updatelocation',
              locationData
            );
            console.log('Location updated successfully.');
          } catch (error) {
            console.error('Error updating location:', error);
          } finally {
            isApiCallInProgress = false; // Reset flag after API call completion
          }
        },
        (error) => {
          console.error('Error getting location:', error.message);
        }
      );
  
      return () => navigator.geolocation.clearWatch(watchId);
    };
  
    // Call getLocation immediately
    getLocation();
  
    // Set interval to call getLocation every 15 seconds
    intervalId = setInterval(getLocation, 15000);
  
    // Clear interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  

  useEffect(() => {
    setIsLoading(true);
    fetch(
      "https://api.itoconnect.online/dashboard/birthday_voters/?user_id=799"
    )
      .then((response) => response.json())
      .then((data) => {
        let filteredData = data.voters.filter(
          (x) => x.phone_no !== 0 && x.phone_no !== null
        );
        // console.log("filterdData", filteredData);
        setData(filteredData);
        setTotalData(filteredData.length); // Set the total number of data
        setIsLoading(false);
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, []);

  // Function to calculate counts for male and female records
  const calculateCounts = (data) => {
    let male = 0;
    let female = 0;
    data.forEach((item) => {
      if (item.gender === "M") {
        male++;
      } else if (item.gender === "F") {
        female++;
      }
    });
    setTotalData(data.length);
    setMaleCount(male);
    setFemaleCount(female);
  };
  const getvoterType = () => {
    getBirthdayFilters().then((resp) => {
      setFiltersData(resp);
    });
  };

  const hanleClickview = (item) => {
    setViewdata(item);
  };

  const onClosePopup = () => {
    setViewdata(null);
  };

  const handliFilterdropdownClicked = () => {
    setDropdown(!dropdown);
  };

  const handleClikckFilter = (id) => {
    if (selectedFilteredvalues.includes(id)) {
      setSelectedFilterdvalues(
        selectedFilteredvalues.filter((item) => item !== id)
      );
    } else {
      setSelectedFilterdvalues([...selectedFilteredvalues, id]);
    }
  };

  const handleMakeCall = (mobilenumber) => {
    const telUri = `tel:${mobilenumber}`;
    window.open(telUri);
  };

  const sendSMS = () => {
    // Logic to send SMS to all data here
  };

  const navigate = useNavigate();

  const logOut = () => {
    logout().then((res) => {
      localStorage.removeItem("data");
      navigate("/login");
    });
  };

  const existingArrayString = localStorage.getItem("mobileNumbers");
  // console.log(existingArrayString);

  function handleNextPage() {
    setCurrentPage((prevPage) => prevPage + 1);
  }
  function handlePreviousPage() {
    setCurrentPage((prevPage) => prevPage - 1);
  }
  const toCamelCase = (str) => {
    if (!str) {
      return ""; // or handle appropriately for your use case
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  return (
    <div className="birthday_data_main_div d-flex">
      <Modal
        show={show}
        fullscreen={true}
        onHide={() => setShow(false)}
        className="birthdyas_full_modal"
        id="dd"
      >
        <Modal.Header closeButton>
          <Modal.Title>{viewBirthdayItem.voter_id}</Modal.Title>
        </Modal.Header>
        <BirthdayIndividualModal viewBirthdayItem={viewBirthdayItem} />
      </Modal>

      <div className="top_nav_holder_main">
        <span className="birthday_data_text_ems">Birthdays</span>
        <span className="birthday_data_text_ems_">Total Records: {totalData}</span>
        <div
          // style={{ marginTop: "10vw" }}
          className="itoconnect_birthdays_data_mobile_main_div"
        >
          {/* <div>Total Records: {totalData}</div> */}
          {/* Display male and female records count */}
          <div className="birthdays_title_name">
            <span id="itoconnect_birthdayss_id_title">Today Birthday List</span>
          </div>
          <div className="itoconnect_birthdays_id_child_div_main">
            <div>
              {data.length == 0 ? (
                <div className="nodata_available" id="nodata_available_">
                  NO DATA AVAILABLE
                </div>
              ) : (
                data.map((item) => (
                  <div key={item.id}>
                    <div id="title_div_birthdays_data_first_column">
                      {/* <span className="">{item.tel_name}</span> */}
                      {/* {toCamelCase(item.first_name)} */}
                      <span className="">{toCamelCase(item.english_name)}</span>
                    </div>
                    <div className="birthdays_data_first_name_age_caste_main">
                      <div className="birthdays_data_child_div_first_name_age_caste">
                        <div className="birthdays_display_gender_">
                          <span>{item.phone_no}</span>
                        </div>
                      </div>
                      <div className="call_icon_Data">
                        <img
                          className="call_image_button"
                          src={callPendingActionIcon}
                          alt="not_visible"
                          onClick={() => handleMakeCall(item.phone_no)}
                        />
                      </div>
                    </div>
                  </div>
                ))
              )}
              {/* {data.length > 0 &&
              
                } */}
            </div>
          </div>
        </div>

        <div className="d-flex birthdays_data_next_prev_main_div">
          {/* <div className="total_pages_birthday">{pagesCount}</div>
          <button
            className="prev_button_birthday_data"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            ❮
          </button> */}
          {/* <div className="current_page_number_birthday_data">{currentPage}</div>
          <button
            className="next_button_birthday_data"
            onClick={handleNextPage}
            disabled={currentPage === totalpages}

          >
            ❯
          </button> */}
        </div>

        {/* /// */}
        <div className="parent_horizontal_scroll_birthday_data">
          <div className="horizontal_scroll_birthday_data">
            <div className="d-flex top_headings_in_birthday_data_main_div">
              {tabColumnsArray.map((item) => {
                return (
                  <div>
                    {data.length == 0 ? (
                      <div className="nodata_available">NO DATA AVAILABLE</div>
                    ) : (
                      <div>
                        <div>
                          <div className="top_heading_in_birthday_data">
                            <span>{item}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="birthday_data_holder_main_div">
              {data.length == 0 ? (
                <div className="nodata_available">NO DATA AVAILABLE</div>
              ) : (
                data.map((item) => (
                  <div key={item.id} className="d-flex">
                    {/* <div className="titleDiv">
                      <span onClick={() => handleShow(item)}>View</span>
                    </div> */}

                    {/* <div className="titleDiv">
                      <span className="voter_id_birthdays">
                        {item.voter_id}
                      </span>
                    </div> */}
                    <div className="titleDiv" id="titleDiv_span_title">
                      {/* <span>{item.first_name}</span> */}
                      {/* <span className="titleDiv_span_title_">
                        {toCamelCase(item.first_name)}
                        
                      </span> */}
                      {/* <span className="">{item.english_name}</span> */}
                      <span className="">{toCamelCase(item.english_name)}</span>


                    </div>
                    {/* <div className="titleDiv">
                      <span className="titleDiv_span_title_">
                        {toCamelCase(item.last_name)}
                      </span>
                    </div> */}
                    {/* <div className="titleDiv">
                      <span>{item.age}</span>
                    </div> */}
                    {/* <div className="titleDiv">
                      <span>{item.designation}</span>
                    </div> */}
                    <div className="titleDiv">
                      <span>{item.phone_no}</span>
                    </div>
                    <div className="titleDiv">
                      <img
                        className="call_image_button"
                        src={callPendingActionIcon}
                        alt="not_visible"
                        onClick={() => handleMakeCall(item.phone_no)}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        {/* Show total number of data */}

        {/* Floating button to send SMS to all */}
        <div className="send_sms_button_main_div"
          style={{
            // position: "fixed",
            // bottom: "20px",
            // right: "24px",
            // zIndex: "9999",
          }}
        >
          {/* <button className="btnSaveChanges" onClick={sendSMS}></button> */}
          <button
            className="btnSaveChanges"
            data-toggle="modal"
            data-target="#messagePreview"
            messagePreview
            type="submit"
          >
            Send SMS to All
          </button>
        </div>
      </div>
      {isLoading ? <LoaderWrapper /> : null}
      <div
        style={{ backgroundColor: "rgb(0 0 0 0.7)", opacity: "0.9" }}
        class="modal fade"
        id="messagePreview"
        role="dialog"
      >
        <div
          style={{ top: "10% !important" }}
          class="modal-dialog modal-dialogP"
        >
          <div class="modal-content">
            <div
              class="ecampaign_modal-header"
              style={{
                display: "flex",
                "align-items": "center",
                "justify-content": "space-around",
                padding: "10px",
              }}
            >
              <div></div>
              <span class="modal-title">Message</span>
              <button
                type="button"
                // className="close btn-close"
                className="mes_close_"
                data-dismiss="modal"
                style={{outline: "none",border: "none",background:'none'}}
              >X</button>
            </div>
            <div class="ecampaign_modal-body p-3">
              <div className="row mt">
                <span class="modal-title_title_">Message Preview :</span>
                <div className="row p-2">
                  <span className="message_preview_title_description">
                    ` ప్రియమైన ${data[0]?.first_name} గారు, కోటి కాంతుల
                    చరునవ్వులతో భగవంతుడు మీకు నిండు నూరేళ్ళు ఇవ్వాలని
                    మనస్పూర్తిగా కోరుకుంటూ, పుట్టినరోజు శుభాకాంక్షలు. మీ విడదల
                    రజిని, రాష్ట్ర వైద్య ఆరోగ్య శాఖమాత్యులు, గుంటూరు పశ్చిమ
                    నియోజకవర్గ సమన్వయకర్త`
                  </span>
                </div>
              </div>
              <div className="row mt-3 p-1">
                <span class="modal-title_title_">Message Length : {totalData?.length}</span>
              </div>
              <div className="row mt-3 p-1">
              <span className="message_preview_title_description">
                  This length is for first record only for other records message
                  length depends on used columns of uploaded file.{" "}
                </span>
              </div>
              <div className="row mt-3 p-1">
                <span  class="modal-title_title_">Total Phone Number Selected : {totalData}</span>
              </div>
              <div className="col mt-3 text-center">
                <button
                  onClick={sendSMS}
                  className="submit_messages"
                  data-dismiss="modal"
                  type="submit"
                >
                  Send SMS
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Birthday;
