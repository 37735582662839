import React, { Component } from "react";
import { user } from "../config/roleconfig";
import Loginpage from "../Pages/Loginpage";
import { votersDataConstituency } from "../Service/voterdataservice";

function requireAuth(
  WrappedComponent,
  allowedRoles = [
    user.super_admin,
    user.mp,
    user.mla,
    user.pa,
    user.admin_staff,
    user.volunteer,
  ]
) {
  const user = JSON.parse(localStorage.getItem("data"));
  if (!!user && !!user?.token) {
    return WrappedComponent;
  } else {
    return <Loginpage />;
  }
}

export default requireAuth;
