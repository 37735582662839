import React, { createRef, useContext, Suspense, useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import { Row, Col, Stack, Button } from "react-bootstrap";
import StepProgressComponent from "../StepProgressComponent/StepProgressComponent";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ImportDataContext } from "../../../Pages/ImportData/ImportDataContext";
import Modal from 'react-bootstrap/Modal';
import './MappinfFields.scss';
import { set } from "immutable";

function MappingFieldsComponent() {
  const { importDataService } = useContext(ImportDataContext);
  const [enableNextButton, setEnableNextButton] = useState(false);
  const [mappingFieldData, setMappingFieldData] = useState([]);
  const [showMappingSelection, setShowMappingSelection] = useState(false);
  const [selectedMappingKey, setSelectedMappingKey] = useState();
  const [dbFields, setDbFields] = useState([]);
  const [selectedJsonField,setSelectedJsonField] = useState(null);
  const [errors, setErrors] = useState([]);
  const navigation = useNavigate()

  useEffect( () => {
    setMappingData();
    getDBFields();
  }, [])

  const getDBFields = () => {
    importDataService.getMappingFields().then( result => {
      if( result.data) {
        setDbFields(result.data.fields);
      }
    });
    /* setDbFields([
      {field: 'name', required: true},
      {field: 'voterID', required: true},
      {field: 'fatherName', required: false}
      ]) */
  }

  const setMappingData = () => {
    if ( importDataService.importDataSelected.mappingData.length) {
      setMappingFieldData(importDataService.importDataSelected.mappingData);
    } else {
      if (importDataService.exportedJsonData.voterData.length) {
        let colFromJson = getColumnsFromJson(importDataService.exportedJsonData.voterData[0],importDataService.exportedJsonData.polling_booth_data)
        let _mappingData =  colFromJson.map(x => {
          return { mappedField : '', jsonField : x }
        })
        setMappingFieldData(_mappingData);
      }
    }
  }

  const getColumnsFromJson = (voterData, pollingBoothData) => {
    let _keys = Object.keys(voterData);
    _keys = _keys.concat(Object.keys(pollingBoothData));
    return _keys;
  }

  const getMappingBody = () => {
    return mappingFieldData.map((x,i) => {
      return <tr>
        <td class="map_div"><div role="button" onClick={()=>{showMappingSeleciton(x)}} >{x.mappedField ? 'Change' : 'Map'}</div></td>
        <td class="unmapped_div"><div className={` ${x.mappedField ? '' :'text-danger'}`}>{x.mappedField ? x.mappedField : 'UnMapped' }</div></td>
        <td class="json_data">{x.jsonField}</td>
      </tr>
    })
  }

  const renderMappingFields = () => {
    return dbFields.map( x => {
      return <option>{x.field}</option>
    })
  }

  const showMappingSeleciton = (selectedKey) => {
    setShowMappingSelection(true);
    // if ((x.mappedField) != ""){
    //   document.getElementsByClassName('unmapped_div').style.color="black"
    // }
    if ( selectedKey.mappedField) {
      setSelectedJsonField(selectedKey.mappedField);
      
    } else {
      setSelectedJsonField(null);
    }
    
    setSelectedMappingKey(selectedKey);
  }

  const mappingSelectionClose = (saveFlag) => {
    if(saveFlag) {
      let _mappingFieldData = mappingFieldData;
      let dataIndex = _mappingFieldData.findIndex(x => x.jsonField === selectedMappingKey.jsonField);
      _mappingFieldData[dataIndex].mappedField = selectedMappingKey.mappedField;
      setMappingFieldData(_mappingFieldData);
      
    }
    setShowMappingSelection(false);
  }

  const onChangeMappingField = (evt) => {
    if (evt.target.value) {
      setSelectedJsonField(evt.target.value);
      let _selectedMappingKey = {...selectedMappingKey};
      _selectedMappingKey.mappedField = evt.target.value;
      setSelectedMappingKey(_selectedMappingKey);
    }

  }

  const validateMapping = () => {
    let valid = true;
    let errorMessages =[];
    let mandatoryFields = dbFields.filter(x=> x.required);
    mandatoryFields.forEach( x => {
      let flag = mappingFieldData.some( y => y.mappedField === x.field);
      if (!flag) {
        valid = false;
        errorMessages.push(`${x.field} is a mandatory field`);
      }
    });
    return ({status: valid, errorMessages : errorMessages})
  }

  const cancelUpload = () => {
    navigation('../')
  }

  const previousStep = () => {
    navigation('../uploadData')
  }

  const nextStep = () => {
    let flag = validateMapping();
    if (flag.status) {
      importDataService.importDataSelected.mappingData = mappingFieldData;
      navigation('../viewData');
    } else {
      setErrors( flag.errorMessages);
    }
    
  }
  return (
    <>
      <div className="mapping-field-component">
        <Row xs="12" >
          <StepProgressComponent step={1} />
        </Row>
        <div className="title-section">
          <div className="title">Edit Field Mapping : Voters Data</div>
          <div className="sub-title">Your file has been auto-mapped to existing fields, but you can edit the mappings if you wish. Unmapped fields will not be imported.</div>
        </div>
        
        <Row >
            <Col sm xs="12" md lg xl="8" className="table-main">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Edit</th>
                    <th scope="col">Mapped Object</th>
                    <th scope="col">CSV or XLS Header</th>
                  </tr>
                </thead>
                <tbody>
                  {mappingFieldData.length? getMappingBody() : <tr></tr>}
                </tbody>
              </table>
            </Col>
            <Col sm xs="12" md lg xl="4" className="mb-1 error_messages_main">
            <div className="text-danger">
              {
                errors.map((x, i) => {
                  return <li>{x}</li>
                })
              }
            </div>
            </Col>
        </Row>
        <Row className="navbar-fixed-bottom">
        <Card className="card-navbar-bottom" body>
            <Stack direction="horizontal" gap={3}>
            <Button onClick={cancelUpload} size="sm"  variant="outline-primary" className="ms-auto no-border-radius btn-outline-ems">Cancel</Button>
              <Button onClick={previousStep} size="sm" id="prev_button" style={{background: '#C4D1E0'}} variant="info" className="no-border-radius btn-outline-ems">Previous</Button>
              <Button onClick={nextStep} size="sm" variant="primary"  className="no-border-radius btn-primary-ems">Next</Button>
            </Stack>
          </Card>
          <Stack className="import-footer-main" direction="horizontal" gap={3}>
              <div className="import-footer">
                <span className="import-footer-endfooterimg">Copyright © 2023 iToConnect. All Rights Reserved.</span>
              </div>
          </Stack>
        </Row>
      </div>
      <Modal show={showMappingSelection} onHide={()=>{mappingSelectionClose(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Map your field: {selectedMappingKey?.jsonField} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Map to:</div>
          <Form.Select value={selectedJsonField} onChange={onChangeMappingField} 
            aria-label="mapping field">
            <option value={null}>Select</option>
            {renderMappingFields()}
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{mappingSelectionClose(false)}}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>{mappingSelectionClose(true)}}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MappingFieldsComponent;