import React, { useEffect, useState, useContext } from "react";
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import './StepProgressComponent.scss';


function StepProgressComponent(prop) {

  useEffect(() => {
    

  }, []);

  return (
    <>
    <div className="ems-step-progress xs-12">
    <StepProgressBar
        startingStep={prop.step}
        buttonWrapperClass="step-nav-button-wrap"
        contentClass = "step-nav-content-wrap"
        wrapperClass = "step-nav-main-wrap"
        progressClass = "step-nav-progress-wrap"
        stepClass = "step-nav-step-wrap"
        labelClass = "step-nav-label-wrap"
        steps={[
          {
            label: 'Choose Data',
          },
          {
            label: 'Edit Mapping',
          },
          {
            label: 'Start Import',
          }
        ]}
      />
      <hr/>
    </div>
    </>
  );
}

export default StepProgressComponent;