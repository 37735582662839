import React, { useEffect, useRef, useState } from "react";
import "./Wardvillageissues.css";
import add from "../../Assets/addward.png";
import call from "../../Assets/call.png";
import menu_icon from "../../Assets/menu_icon.png";
import { getissues, importantpeopleApi, wardvillagedelete } from "../../Service/Wardvillageissues.service";
import WardvillageFilters from "./WardvillageFilters";
import AddIssues from "./AddIssues";
import WardvillageTodolist from './WardvillageTodolist';
import { deleteVoter } from "../../Service/voterdataservice";
import AddVoterspopup from "../AddVoterspopup";

function Wardvillageissues() {
  const [importantpeople, setImportantpeople] = useState();
  const [wardvillageData, setwardvillagedata] = useState();
  const [deleteupdate, setDeleteupdate] = useState(false)
  const [dropdownConstituency, setDropdownConstituency] = useState([]);
  const [dropdownMandal, setDropdownMandal] = useState([]);
  const [dropdownvillage, setDropdownvillage] = useState([]);
  const [dropdownPollings, setDropdownPollings] = useState([]);
  const [dropdownvalue, setDropdownvalue] = useState();
  const [addIssues, setAddissues] = useState(false);
  const [editData, setEditdata] = useState({});
  const [modulevalueis, setModulevalueIs] = useState(null);
  const [loadvalue, setLoadvalue] = useState(false)
  const [loadvalueimp, setLoadvalueimp] = useState(false)
  const [addvotersclicked, setAddvotersClicked] = useState(false);
  const [votermoduleType, setVotermoduletype] = useState();
  const [Editvalue, setEditvalue] = useState();
  const [navigate, setNavigate] = useState(null);



  useEffect(() => {
    const constituencydata = dropdownConstituency == '' ? '' : `constituency_id=${dropdownConstituency}`
    const mandaldata = dropdownMandal == '' ? '' : `mandal_id=${dropdownMandal}`
    const villagedata = dropdownvillage == '' ? '' : `ward_village_id=${dropdownvillage}`;
    const pollingdata = dropdownPollings == '' ? '' : `polling_booth=${dropdownPollings}`
    switch (true) {
      case pollingdata.length > 0:
        setDropdownvalue(pollingdata);
        break;
      case villagedata.length > 0:
        setDropdownvalue(villagedata);
        break;
      case mandaldata.length > 0:
        setDropdownvalue(mandaldata);
        break;
      case constituencydata.length > 0:
        setDropdownvalue(constituencydata);
        break;
      default:
        setDropdownvalue('')
    }
  }, [dropdownConstituency, dropdownMandal, dropdownvillage, dropdownPollings])

  useEffect(() => {
    if (dropdownvalue !== undefined) {
      getissues(dropdownvalue).then((resp) => {
        setwardvillagedata(resp.message)
      })
    }
  }, [dropdownvalue, deleteupdate, loadvalue])

  useEffect(() => {
    importantpeopleApi().then((resp) => {
      setImportantpeople(resp)
    })
  }, [loadvalueimp])

  const handleclickedMenuimp = (voterId) => {
    setImportantpeople(
      importantpeople.map((item) => {
        if (item.voter_id === voterId) {
          return { ...item, showDeleteButton: !item.showDeleteButton };
        } else {
          return { ...item, showDeleteButton: false };
        }
      }));
  }

  const handleclickedmenuewardvillage = (issueid) => {
    setwardvillagedata(
      wardvillageData.map((item) => {
        if (item.issue_id === issueid) {
          return { ...item, showEditButton: !item.showEditButton };
        } else {
          return { ...item, showEditButton: false };
        }
      }));
  }

  const handlewardvillagedelete = (issueid) => {
    wardvillagedelete(issueid).then((resp) => {
      if (resp.status == true) {
        setDeleteupdate(!deleteupdate)
      }
    })
  }
  const updateconstituencydropdownvalues = (value) => {
    setDropdownConstituency(value)
  }
  const updatemandalsdropdown = (value) => {
    setDropdownMandal(value)
  }
  const updatevillagedropdowns = (value) => {
    setDropdownvillage(value)
  }
  const updatepollingstations = (value) => {
    setDropdownPollings(value)
  }
  const handleclickedAdissues = () => {
    setAddissues(!addIssues);
    setModulevalueIs('Add')
  }

  const oncancle = () => {
    setAddissues(false)
    setLoadvalue(!loadvalue)
  }

  const handleclickedEdit = (data) => {
    if (data) {
      setAddissues(!addIssues)
    }
    setEditdata({ ...editData, updatedata: data, })
    setModulevalueIs('Edit');
  }

  const handleDeleteimp = (voterid) => {
    deleteVoter(voterid).then((resp) => {
      if (resp.status == true) {
        setLoadvalueimp(!loadvalueimp)
      }
    })
  }
  const handleEditimppeople = (voterid) => {
    setEditvalue(voterid);
    setNavigate('wardvillage')
    setVotermoduletype('Edit')
    setAddvotersClicked(!addvotersclicked)

  }
  const cancleaddvoters = () => {
    setAddvotersClicked(false);
    setVotermoduletype("");
  };
  const handleclickedAddimppeople = () => {
    setAddvotersClicked(!addvotersclicked);
  }
  const handlecallClicked =(number)=>{
    window.location.href = `tel:${number}`;
  }

  return (
    <div className="main_div_wardvillages_issues_page">
      {
        addIssues ? <AddIssues oncancle={oncancle} eddata={editData} modulevar={modulevalueis} /> : null
      }
      <div className="ward_villages__dashboard-main_div d-flex align-items-center">
        <span id="ward_villages_title"> Ward/Village issue</span>
      </div>
      <WardvillageFilters
        updateconstituencydropdownvalues={updateconstituencydropdownvalues}
        updatemandalsdropdown={updatemandalsdropdown}
        updatevillagedropdowns={updatevillagedropdowns}
        updatepollingstations={updatepollingstations} />
      <div className="ward_village_underlines_holder">
        <div className="ward_village_underline_one"></div>
      </div>
      <div className="d-flex flex-column flex-md-row ward_village_note_main_div">
        <div className="ward_village_issues_main_child">
          <div className="ward_village_text_div d-flex align-items-center">
            <div className="d-flex flex-column">
              <span className="important_people_name_text_title_name">
                Important people
              </span>
              <div className="ward_village_importantpeople_underline"></div>
              <img
                className="ward_village_importpeople_add"
                src={add}
                alt="not_visible"
                onClick={handleclickedAddimppeople}
              />
            </div>
          </div>
          <div className="ward_village_name_designation_mobile_div d-flex align-items-center">
            <span className="important_people_name_text_name">Name</span>
            <span className="ward_village_important_people_designation_text">
              Designation
            </span>
            <span className="ward_village_important_people_mobile_text">
              Mobile
            </span>
          </div>
          <div className="ward_village_important_people_info_scrolldiv">
            {
              importantpeople && importantpeople.length > 0 ? (
                importantpeople.map((item) => {
                  return (
                    <div className="d-flex ward_village_text_info_detail_border_div">
                      <div className="ward_village_importantpeople_info_text_details_div d-flex align-items-center">
                        <span id="ward_village_imp_poeple_first_name">{item.first_name}</span>
                      </div>
                      <div className="ward_village_importantpeople_info_text_details_div d-flex align-items-center">
                        <span id="ward_village_imp_poeple_designation">{item.designation}</span>
                      </div>
                      <div className="ward_village_importantpeople_info_text_details_div d-flex align-items-center">
                        <span id="ward_village_imp_poeple_mobile">{item.phone_no}</span>
                        <img
                          className="call_logo_ward_village"
                          src={call}
                          alt="not_visible"
                          style={{ cursor: "pointer" }}
                          onClick={()=>handlecallClicked(item.phone_no)}
                        />
                        <img
                          src={menu_icon}
                          onClick={() => handleclickedMenuimp(item.voter_id)}
                          key={item.id}
                          style={{ cursor: "pointer" }}
                          className="usermanagement_menu_icon"
                          alt="not_visible"
                        />
                        {item.showDeleteButton && (
                          <div className="ward_village_data_view_delete_maindiv  ">
                            <span id="ward_view" className="" onClick={() => handleEditimppeople(item.voter_id)}>
                              Edit
                            </span>
                            <hr className="hrline_view_delete" />

                            <span id="ward_delete" onClick={() => handleDeleteimp(item.voter_id)}>
                              Delete
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })
              ) : <p className="nodata_filters_data">No Data Found</p>
            }
          </div>
        </div>
        <div
          className="ward_village_issues_main_child"
          id="ward_village_ward_modules"
        >
          <div className="ward_village_text_div d-flex align-items-center">
            <div className="d-flex flex-column">
              <span id="ward_villagissue_child_title_sub1">
                Ward/Village issues
              </span>
              <div className="ward_village_importantpeople_underline"></div>
            </div>
            <img
              className="ward_village_importpeople_add"
              src={add}
              alt="not_visible"
              onClick={handleclickedAdissues}
            />
          </div>
          <div className="name_designations_mobile_div d-flex align-items-center">
            <span className="ward_important_people_name_text_title">Title</span>
            <span className="ward_important_people_designation_text_status">
              Status
            </span>
          </div>
          <div className="ward_village_important_people_info_scrolldiv_2">
            {
              wardvillageData && wardvillageData.length > 0 ? (wardvillageData.map((item) => {
                return (
                  <div className="d-flex ward_villageisuuse_text_info_detail_border_div">
                    <div className="ward_village_importantpeople_info_text_details_title_div d-flex align-items-center">
                      <span className="ward_village_imp_poeple_child_title1">
                        {item.issue_name}
                      </span>
                    </div>
                    <div className="ward_village_importantpeople_info_text_details_title_div d-flex align-items-center">
                      <span className="ward_village_imp_poeple_child_title2" style={{ color: item.issue_status == 'In Progress' ? '#C1A825' : '#45B733' }}>
                        {item.issue_status}
                      </span>
                    </div>
                    <img
                      src={menu_icon}
                      onClick={() => handleclickedmenuewardvillage(item.issue_id)}
                      style={{ cursor: "pointer" }}
                      className="ward_village_issues_menu_icon"
                      alt="not_visible"
                    />
                    {item.showEditButton && (
                      <div className="ward_village_data_view_delete_maindiv  ">
                        <span id="ward_view" className="" onClick={() => handleclickedEdit(item)}>
                          Edit
                        </span>
                        <hr className="hrline_view_delete" />

                        <span id="ward_delete" className="" onClick={() => handlewardvillagedelete(item.issue_id)}>
                          Delete
                        </span>
                      </div>
                    )}
                  </div>
                )
              })
              ) : <p className="nodata_filters_data">No Data Found</p>
            }
          </div>
        </div>

        <div
          className="ward_village_issues_main_child "
          id="ward_village_ward_modules"
        >
          <WardvillageTodolist />
        </div>
      </div>
      {addvotersclicked ? (
        <AddVoterspopup
          cancleaddvoters={cancleaddvoters}
          // constituencyvalue={selectedConstituencies}
          oncancleclick={cancleaddvoters}
          // addVoterclosed={handleAddvoterscloseClicked}
          Editvalue={Editvalue}
          votermoduleType={votermoduleType}
          navigateto={navigate}
        />
      ) : null}
    </div>
  );
}

export default Wardvillageissues;
