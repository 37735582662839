import React, { useEffect, useState } from "react";
import "./Areagraph.css";
import maxmize from "../../Assets/maxmize.png";
import PieChart, { Series, Label } from "devextreme-react/pie-chart";
import { parse } from "@fortawesome/fontawesome-svg-core";
import { Tooltip } from "devextreme-react/chart";

function Areagraph(props) {
  const data = props.caste;
  const countvalue = props.castehideshow;
  let count = 0;
  const castecolors = [
    {
      caste: "Reddy",
      percent: props.caste?.Reddy,
      color: "#3396EC",
    },

    {
      caste: "Kapu",
      percent: props.caste?.Kapu,
      color: "#77B8F2",
    },
    {
      caste: "Kamma",
      percent: props.caste?.Kamma,
      color: "#9C53F2",
    },
    {
      caste: "Yadhav",
      percent: props.caste?.Yadava,
      color: "#52D4CD",
    },
    {
      caste: "Anamuk",
      percent: props.caste?.Anamuk,
      color: "#366BA1",
    },
    {
      caste: "Mala",
      percent: props.caste?.Mala,
      color: "#4482C1",
    },
    {
      caste: "Relli",
      percent: props.caste?.Relli,
      color: "#6699CC",
    },
    {
      caste: "Madiga",
      percent: props.caste?.Madiga,
      color: "#ABC7E3",
    },
    {
      caste: "Kuruva",
      percent: props.caste?.Kuruva,
      color: "#CDDEEE",
    },
    {
      caste: "Sugalis",
      percent: props.caste?.Sugalis,
      color: "#C3E0E5",
    },
    {
      caste: "Lambadis",
      percent: props.caste?.Lambadis,
      color: "#5885AF",
    },
    {
      caste: "Banjara",
      percent: props.caste?.Banjara,
      color: "#41729F",
    },
    {
      caste: "Valmiki",
      percent: props.caste?.Valmiki,
      color: "#6699CC",
    },
    {
      caste: "Yerukulas",
      percent: props.caste?.Yerukulas,
      color: "#7AA3CC",
    },
    {
      caste: "Koya",
      percent: props.caste?.Koya,
      color: "#8FADCC",
    },
    {
      caste: "Chanchu",
      percent: props.caste?.Chanchu,
      color: "#A3B8CC",
    },
    {
      caste: "Mudhiraj",
      percent: props.caste?.Mudhiraj,
      color: "#B8C2CC",
    },
    {
      caste: "Nayaks",
      percent: props.caste?.Nayaks,
      color: "#CCCCCC",
    },
    {
      caste: "Mutrasi",
      percent: props.caste?.Mutrasi,
      color: "#6699CC",
    },
    {
      caste: "Rajaka",
      percent: props.caste?.Rajaka,
      color: "#528FCC",
    },
    {
      caste: "Dommara",
      percent: props.caste?.Dommara,
      color: "#3D85CC",
    },
    {
      caste: "Katipapala",
      percent: props.caste?.Katipapala,
      color: "#297ACC",
    },
    {
      caste: "Lambada",
      percent: props.caste?.Lambada,
      color: "#1470CC",
    },
    {
      caste: "Medari",
      percent: props.caste?.Medari,
      color: "#0066CC",
    },
    {
      caste: "Mangali",
      percent: props.caste?.Mangali,
      color: "#5B9FAC",
    },
    {
      caste: "Nakkala",
      percent: props.caste?.Nakkala,
      color: "#6CA2AC",
    },
    {
      caste: "Veeramushti",
      percent: props.caste?.Veeramushti,
      color: "#7DA5AC",
    },
    {
      caste: "Devanga",
      percent: props.caste?.Devanga,
      color: "#8FA7AC",
    },
    {
      caste: "Goud",
      percent: props.caste?.Goud,
      color: "#A0AAAC",
    },
    {
      caste: "Deudekula",
      percent: props.caste?.Deudekula,
      color: "#B1ADAC",
    },
    {
      caste: "Pinjari",
      percent: props.caste?.Pinjari,
      color: "#0090AC",
    },
    {
      caste: "Salivahana",
      percent: props.caste?.Salivahana,
      color: "#0591AC",
    },
    {
      caste: "Kummara",
      percent: props.caste?.Kummara,
      color: "#1694AC",
    },
    {
      caste: "Kuruma",
      percent: props.caste?.Kuruma,
      color: "#2797AC",
    },
    {
      caste: "Nagavaddilu",
      percent: props.caste?.Nagavaddilu,
      color: "#3999AC",
    },
    {
      caste: "Others",
      percent: count,
      color: "#A79797",
    },
  ];

  // Calculate total count for missing castes
  for (var key in data) {
    if (
      key !== "Reddy" &&
      key !== "Kapu" &&
      key !== "Kamma" &&
      key !== "Yadhav"
    ) {
      count = count + data[key];
    }
  }

  // const getCastePercent = (caste) => props.caste?.[caste] || 0;
  const getCastePercent = (caste) => props.caste?.[caste] || "";


  const formatNumber = (value) => {
    // Format large numbers
    if (typeof value === "string") {
      value = value.replace(/,/g, "");
    }

    if (value >= 10000000) {
      return `${(value / 10000000).toFixed(1)}Cr`;
    } else if (value >= 100000) {
      return `${(value / 100000).toFixed(1)}L`;
    } else if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}K`;
    } else {
      return `${value}`;
    }
  };

  // const pieChartFn = () => {
  //   const data =
  //     props.popupvalueCaste === false ? castecolors.slice(0, 5) : castecolors;

  //   // Update caste percentages for the chart
  //   return data.map((casteData) => ({
  //     ...casteData,
  //     percent: getCastePercent(casteData.caste),
  //   }));
  // };

  const pieChartFn = () => {
    const data =
      props.popupvalueCaste === false ? castecolors.slice(0, 5) : castecolors;

    // Update caste percentages for the chart

    const updatedData = data.map((casteData) => ({
      ...casteData,
      percent: countvalue == false ? 0 : getCastePercent(casteData.caste),
    }));

    return updatedData;
  };

  const labelFont = {
    color: "#263659",
  };

  // const customizeLabel = (point) => {
  //   return `${point.valueText}`;
  // };

  const customizeLabel = (point) => {
    return point.value !== 0 ? `${point.valueText}` : '';
  };
  
  const handleButtonmaxmize = () => {
    props.maxmizingcasteGraph();
  };

  useEffect(() => {
    pieChartFn();
  }, [props.popupvalueCaste]);

  return (
    <div className="Areagraph_main">
      <button className="maxmizebtn_charts" onClick={handleButtonmaxmize}>
        <img src={maxmize} alt="not_visible" />
      </button>
      <div className="d-flex flex-column caste_chart_main_div align-items-center">
        <span className="doughnut_cast_chart_text">Caste</span>
        <div className="underline_admin_caste_chart_div"></div>
      </div>

      <div className="dougnut_graph_center_text">
        Total Voters:{formatNumber(props.voters?.total_voters)} voters
      </div>
      {/* <div className="d-flex flex-column castedetails_main_div "> */}

      <div className="d-flex flex-column castedetails_main_div castedetails_main_div_caste">
        {castecolors.map((value, index) => {
          // if (props.popupvalueCaste !== false ? (value.caste > 5): '') {

          if (props.popupvalueCaste !== false) {
            return (
              <div className="d-flex align-items-center castecolors_caste_div_main">
                <div
                  className="castecolo_div"
                  style={{ background: `${value.color}` }}
                ></div>
                <span className="castecolors_caste_div">
                  {value.caste} : {getCastePercent(value.caste)}
                </span>
              </div>
            );
          } else {
            //ui
            if (index < 5 ) {
              return (
                <div className="d-flex align-items-center castecolors_caste_div_main">
                  <div
                    className="castecolo_div"
                    style={{ background: `${value.color}`}}
                  ></div>
                  <span className="castecolors_caste_div">{value.caste}</span>
                </div>
              );
            }
          }
        })}
      </div>
      <PieChart
       className="pie_chart_totalvoters"
        id="pie"
        type="doughnut"
        dataSource={pieChartFn()}
        palette={["#3396EC", "#77B8F2", "#9C53F2", "#52D4CD", "#A79797"]}
        innerRadius={0.63}
        // style={{ width: "40vw", height: "40vw" }}
      >
        <Series argumentField="caste" valueField="percent" minSegmentSize={12}>
          <Label
            visible={true}
            position={"inside"}
            backgroundColor={"none"}
            customizeText={customizeLabel}
            font={labelFont}
            format={formatNumber}
          />
        </Series>
        <Tooltip
          enabled={true}
          customizeTooltip={(arg) => {
            const color = arg.point.getColor();
            return {
              text: `<span className="hover_text_graphs" style="color: ${color}"><p class='text_p_tag'>${arg.argumentText}: ${arg.valueText}</p></span>`,
            };
          }}
        />
      </PieChart>
    </div>
  );
}

export default Areagraph;
