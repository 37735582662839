import React, { Suspense, useContext, useEffect, useState } from "react";
import { useNavigate, redirect, useLocation } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import { ImportDataProvider } from "./ImportDataContext";
import ImportOptions from "../../components/ImportData/ImportOptions/ImportOptions";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ImportDataUploadComponent from "../../components/ImportData/ImportDataUploadComponent/ImportDataUploadComponent";
import MappingFieldsComponent from "../../components/ImportData/MappingFields/MappingFields";
import ViewDataComponent from "../../components/ImportData/ViewDataComponent/ViewDataComponent";
import ImportSummaryComponent from "../../components/ImportData/ImportSummaryComponent/ImportSummaryComponent";
import ImportBatchComponent from "../../components/ImportData/ImportBatchComponent/ImportBatchComponent";
import './ImportDataPage.scss';
import { RoleDataContext } from "../../AppContextComponent";
import { ROLES_PAGE_NAME_CONSTANTS } from "../../Utils/utils";

function ImportDataPage({closenav}) {
  const { roleDataService } = useContext(RoleDataContext);

  useEffect(() => {
    roleDataService.getPageRole(ROLES_PAGE_NAME_CONSTANTS.ImportData);
  }, [])
  
 
  return (
    <ImportDataProvider>
      <div className="import-data container-fluid" >
        <Row className="title">
          <div>
            <div>Imported Data</div>
            <hr />
          </div>
        </Row>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route exact path="/" element={ <ImportOptions />} />
            <Route exact path="uploadData/*" element={<ImportDataUploadComponent />} />
            <Route exact path="mapFields/*" element={<MappingFieldsComponent />} />
            <Route exact path="viewData/*" element={<ViewDataComponent closenav = {closenav} />} />
            <Route exact path="importSummary/*" element={<ImportSummaryComponent />} />
            <Route exact path="importBatch/*" element={<ImportBatchComponent />} />
          </Routes>
        </Suspense>
      </div>
    </ImportDataProvider>
  );
}

export default ImportDataPage;