import "../components/Footer.css";
import { useLocation, NavLink } from "react-router-dom";
import Emslogo from "../../src/Assets/itoclogo.png";
import Dashboard from "../Assets/Dashboard Icon_Mobile.png";
import Voters from "../../src/Assets/voters_mobile.png";
import Birthdays from "../../src/Assets/Birthdays_Mobile.png"
import Calender from "../../src/Assets/calender_Mobile.png"
import ECampaign from "../../src/Assets/E-CampaignIcon_Mobile.png"
import React, { useState,useEffect } from "react";
import DashboardActive from "../../src/Assets/Dashboard_activeMobile.png"
import BirthdaysActive from "../../src/Assets/Birthdays_activeMobile.png"
import EcampaignsActive from "../../src/Assets/E-Campaign_active.png"
import VotersActive from "../../src/Assets/voters_active.png"
import CalenderActive from "../../src/Assets/calender_active.png"
import HumanLogoActive from "../../src/Assets/profilein.png"
import HumanLogoinActive from "../../src/Assets/profileout.png"

const Footer = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  
  useEffect(() => {
    // Retrieve activeTab from localStorage on component mount
    const storedActiveTab = localStorage.getItem("activeTab");
    if (storedActiveTab) {
      setActiveTab(parseInt(storedActiveTab));
    }

    // Update activeTab based on the current location pathname
    const currentTab = tabs.findIndex(tab => `/${tab.link}` === location.pathname);
    if (currentTab !== -1) {
      setActiveTab(currentTab);
    }
  }, [location.pathname]);

  useEffect(() => {
    // Store activeTab in localStorage whenever it changes
    localStorage.setItem("activeTab", activeTab.toString());
  }, [activeTab]);


const handleTabClick = (index) => {
  setActiveTab(index);
  localStorage.setItem("activeTab", index.toString());
};
  const linkStyle = {
    textDecoration: "none", // Remove underline
    color: "black", // Set the desired text color (e.g., black)
  };

  const tabs = [

    {
      label: "Dashboard",
      activeIcon: DashboardActive,
      inactiveIcon:Dashboard,
      link: "" 
    },
    {
      label: "Calendar",
      activeIcon: CalenderActive,
      inactiveIcon:Calender,
      link: "add-calender",
    },
    {
      label: "Voter's Data",
      activeIcon: VotersActive,
      inactiveIcon:Voters,
      link: "votersdata",
    },
    {
      label: "Birthdays",
      activeIcon: BirthdaysActive,
      inactiveIcon:Birthdays,
      link: "birthdays",
    },
    {
      label: "Profile",
      // activeIcon: HumanLogoActive,
      // inactiveIcon:HumanLogoinActive,
      activeIcon: HumanLogoinActive,
      inactiveIcon:HumanLogoActive,
      link: "profile",
    },
  ];
  return (
    <div className="footer_itoconnect">

      <div className="copyright_footer">
        
        <div className="under_line1"></div>
        <span>© 2023 iToconnect - All Rights Reserved</span>
        <div className="under_line1"></div>

      </div>
{/* 
      <div className="bottom-navigation">
      {tabs.map((tab, index) => (
          <NavLink
            to={`/${tab.link}`} 
            key={index}
            className={`nav-item ${isActive(tab.link)}`}
          >
            <div className="nav-icon">{tab.icon}</div>
            <div className="nav-label"
                      style={{ filter: isActive ? 'grayscale(100%)' : 'none' }}

            >{tab.label}</div>
          </NavLink>
        ))}
      
      </div> */}
      <div className="bottom-navigation">
        {tabs.map((tab, index) => (
          <NavLink
            to={`/${tab.link}`} 
            key={index}
            className={`nav-item ${index === activeTab ? "active" : ""}`}
            onClick={() => handleTabClick(index)}
          >
           <div className="nav-icon">
              <img className="nav_icon_footer_images"
                src={index === activeTab ? tab.activeIcon : tab.inactiveIcon}
                alt={tab.label}

                // style={{ width: '30px' }}
              />
            </div>
            <div className="nav-label">{tab.label}</div>
          </NavLink>
        ))}
      </div>
    
    </div>
  );
};

export default Footer;
