import React, { createRef, useContext, Suspense, useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import { Row, Col, Stack, Button, Card } from "react-bootstrap";
import { ImportDataContext } from "../../../Pages/ImportData/ImportDataContext";
import './ImportBatchComponent.scss';
 
function ImportBatchComponent() {
  let _date = new Date().toDateString();
  const { importDataService } = useContext(ImportDataContext);
  const [batchData,setBatchData] = useState([]);
  const [tableData,setTableData] = useState([]);
  const [pagesCount,setPagesCount] = useState(0);
  const [currentPage,setCurrentPage] = useState(1);
  const navigation = useNavigate()
  const count = 10;

  useEffect( () => {
    getBatchData();
  }, [])

  useEffect( () => {
    setPaginationData();
  }, [batchData])

  const getBatchData = () => {
    importDataService.getBatchDetails().then( result => {
      if( result.data) {
        setBatchData(result.data);
        if(result.data.length < count) {
          setTableData(result.data)
        } else {
          setTableData(result.data.slice(0,count))
        }
      }
    })
  }

  const handlePreviousPage = () => {
    if(currentPage > 1) {
      let start;
      let end;
      if ( (currentPage - 1) === 1) {
        start = 0;
        end = (currentPage - 1) * count;
        if ( end > batchData.length)
        end = batchData.length;
      } else {
        start = (currentPage - 2) * count;
        end = (currentPage -1) * count;
        if ( end > batchData.length)
        end = batchData.length;
      }
      setTableData(batchData.slice(start,end));
      setCurrentPage(currentPage - 1);

    }
  }

  const handleNextPage = () => {
    if(currentPage <= pagesCount) {
      let start;
      let end;
      if ( (currentPage + 1) === pagesCount) {
        start = currentPage * count;
        end = (currentPage + 1) * count;
        if ( end > batchData.length)
        end = batchData.length;
      } else {
        start = (currentPage) * count;
        end = (currentPage +1) * count;
        if ( end > batchData.length)
        end = batchData.length;
      }
      setTableData(batchData.slice(start,end));
      setCurrentPage(currentPage + 1);

    }
  }

  const setPaginationData = () => {
    let pages = parseInt(batchData.length / count);
    if ((batchData.length % count) > 0 ) {
      pages = pages + 1;
    }
    setCurrentPage(1);
    setPagesCount(pages);
  }

  const getBatchBody = () => {

    return tableData.map((x,i) => {
      return <tr>
        <td >{x.job_id}</td>
        <td >{x.start_time}</td>
        <td >{x.end_time}</td>
        <td >{x.created_records}</td>
        <td >{x.updated_records}</td>
        <td >{x.failed_records}</td>
        <td >{x.upload_status}</td>
      </tr>
    })
  }

  return (
    <>
      <div className="import-batch-component">
      {/* <StepMenu
        steps={steps}
        activeStepIndex={1}
        onStepClick={()=>{}}
        ></StepMenu> */}
        <Row className="table-main">
            <Col sm xs="12" md lg xl="12">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Batch ID</th>
                    <th scope="col">Start Time</th>
                    <th scope="col">End Time</th>
                    <th scope="col">Total<br/> Processing<br/> Time</th>
                    <th scope="col">Records<br/> Processed</th>
                    <th scope="col">Records<br/> Failed</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {batchData.length? getBatchBody() : <tr></tr>}
                </tbody>
              </table>
            </Col>
        </Row>
        <Row>
        <Stack  direction="horizontal" gap={3}>
              <div className="total_pages">{pagesCount}</div>
              <button
                  className="prev_button_birthday_data ms-auto"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
              >
                  Prev
              </button>
              <div className="current_page_number_birthday_data">{currentPage}</div>
              <button
                  className="next_button_birthday_data me-3"
                  onClick={handleNextPage}
                  disabled={currentPage === pagesCount}
              >
                  Next
              </button>
          </Stack>
        </Row>
        <Row className="navbar-fixed-bottom">
          <Stack className="import-footer-main" direction="horizontal" gap={3}>
              <div className="import-footer">
                <span className="import-footer-endfooterimg">Copyright © 2023 iToConnect. All Rights Reserved.</span>
              </div>
          </Stack>
        </Row>
      </div>
    </>
  );
}

export default ImportBatchComponent;