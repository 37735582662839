import { createContext, useState } from 'react';
import ImportDataService from '../../Service/importDataService';



let importDataService = new ImportDataService();
const ImportDataContext = createContext();
const { Provider } = ImportDataContext;
const ImportDataProvider = ({ children }) => {
  return <ImportDataContext.Provider value={{importDataService}}>{children}</ImportDataContext.Provider>;
};
export { ImportDataContext, ImportDataProvider }