import { useEffect, useState } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "bootstrap/dist/css/bootstrap.min.css";
import { loginUser, logout } from "../Service/service";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  calenderFromToHrs,
  createEventId,
  defTimeFrom,
  defTimeTo,
} from "../Utils/utils";
import { Button, Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import CloseIcon from "../Assets/CloseIcon.png";
import CalendarEditIcon from "../Assets/CalenderEdit.png";
import CalendarDeleteIcon from "../Assets/CalenderDelete.png";
import CalendarClock from "../Assets/Calender-Clock.png";
import CalendarDesc from "../Assets/Calender-desc.png";
import CalendarLocation from "../Assets/Calender-location.png";

import CreateIcon from "../Assets/Create-icon.png";
import NewEventIcon from "../Assets/New Event Icon.png";
import {
  deleteEvent,
  EditEvents,
  eventsData,
  postEvents,
} from "../Service/CalenderService";
import "../Pages/AddCalender.scss";
// import "../Pages/AddCalendarPage.scss";

import moment from "moment-timezone";
import ShowMoreText from "react-show-more-text";
import LoaderWrapper from "../components/LoaderWrapper";

const AddCalender = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [closenav, setClosenav] = useState(false);
  const [respclosenav, setRespClosenav] = useState(false);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [showEventPopup, setShowEventPopup] = useState(false);
  const [meetID, setMeetID] = useState("");
  const [title, setTitle] = useState("");

  const [meetDate, setDate] = useState(moment().format("DD-MM-YYYY"));
  const [meetFrom, setMeetfrom] = useState(
    moment(defTimeFrom, "hh:mm A").format("HH:mm")
  );
  const [meetTo, setMeetto] = useState(
    moment(defTimeTo, "hh:mm A").format("HH:mm")
  );
  const [meetDesc, setMeetDesc] = useState("");
  const [isApiCallActive, setIsApiCallActive] = useState(false);

  const handleMeetDescChange = (value) => {
    
    setMeetDesc(value);

  };
  const [meetSelectInfo, setmeetSelectInfo] = useState({});
  const [events, setEvents] = useState([]);
  const [oneventClkModal, setOneventClkModal] = useState(false);
  const [oneventClkData, setOneventClkData] = useState({});
  const [editBtn, setEditBtn] = useState(false);
  const [createBtn, setCreateBtnn] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log('Fetching location...');
    let userData = JSON.parse(localStorage.getItem('data'));
    
    let isApiCallInProgress = false; // Flag to track API call status
    let intervalId; // Store the interval ID
  
    const getLocation = async () => {
      // Check if an API call is already in progress or if the component is unmounted
      if (isApiCallInProgress || !userData) {
        console.log('API call already in progress or component is unmounted. Skipping location update.');
        return;
      }
  
      isApiCallInProgress = true; // Set flag to indicate API call start
  
      const watchId = navigator.geolocation.watchPosition(
        async (position) => {
          console.log('Location fetched successfully:', position);
          console.log('position.coords', position.coords);
          console.log('latitude', position.coords.latitude);
          console.log('longitude', position.coords.longitude);
          const locationData = {
            user_id: userData.user_data.user_id,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            user_name: userData.user_data.first_name
          };
          // Make a POST request to the API endpoint
          try {
            await axios.post(
              'https://sxqm562sjc.execute-api.us-east-1.amazonaws.com/dev/updatelocation',
              locationData
            );
            console.log('Location updated successfully.');
          } catch (error) {
            console.error('Error updating location:', error);
          } finally {
            isApiCallInProgress = false; // Reset flag after API call completion
          }
        },
        (error) => {
          console.error('Error getting location:', error.message);
        }
      );
  
      return () => navigator.geolocation.clearWatch(watchId);
    };
  
    // Call getLocation immediately
    getLocation();
  
    // Set interval to call getLocation every 15 seconds
    intervalId = setInterval(getLocation, 15000);
  
    // Clear interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  

  const getDefaultEvents = () => {
    eventsData().then((resp) => {
      const newResponse = resp.map((obj) => {
        return {
          id: obj.event_id,
          title: obj.event_title,
          start: obj.event_start.slice(0, -1),
          end: obj.event_end.slice(0, -1),
          description: obj.event_desc,
        };
      });
      setEvents(newResponse);
    });
  };

  useEffect(() => {
    getDefaultEvents();
  }, []);
  const mainContentStyle = {
    width: !closenav ? "100%" : "calc(100vw - 14vw)",
  };
  const handleDateSelect = (selectInfo) => {
    let meetStart = moment(selectInfo.startStr)
      .tz("Asia/Kolkata")
      .format("HH:mm");
    const asiaKolkataTime = moment.tz(selectInfo.endStr, "Asia/Kolkata");
    const meetEnd = asiaKolkataTime.add(30, "minutes").format("HH:mm");
    setMeetfrom(meetStart);
    setMeetto(meetEnd);
    setDate(moment(selectInfo.startStr).format("DD-MM-YYYY"));
    setShowEventPopup(true);
    setmeetSelectInfo(selectInfo);
  };

  const handleEventClick = (clickInfo) => {
    setOneventClkData({
      event_id: clickInfo.event.id,
      title: clickInfo.el.fcSeg.eventRange.def.title,
      date: clickInfo.el.fcSeg.start,
      clkMeetFrom: clickInfo.event.startStr,
      clkMeetTo: clickInfo.event.endStr,
      description: clickInfo.el.fcSeg.eventRange.def.extendedProps.description,
    });
    setOneventClkModal(true);
  };

  const handleEvents = (events) => {
    setCurrentEvents(events);
  };

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isEventContentOpen, setIsEventContentOpen] = useState(false);

  const renderEventContent = (eventInfo) => {
    // setIsModalOpen(true);
    // setIsEventContentOpen(true);
    return (
      <div>
        {/* {isEventContentOpen && ( */}
        <div className="event-content">
          <span>{eventInfo.event.title}</span> &nbsp; &nbsp;
          <span>
            {moment(eventInfo.event.startStr).format("h:mm A")} &nbsp; -  &nbsp;  
            {moment(eventInfo.event.end).format("h:mm A")}
          </span>
        </div>
        {/* )} */}
      </div>
    );
  };

  const handleEventSubmit = (selectInfo) => {
    // Handle submission logic here, e.g. using an API call

    setShowEventPopup(false);
  };

  const toggleClicked = () => {
    setClosenav(true);
    setRespClosenav(true);
    const calendarPageSection = document.getElementsByClassName(
      "fc-view-harness-active"
    )[0];
    // calendarPageSection.style.width=`100vw`;
    calendarPageSection.style.width = `calc(100vw - 14vw)`;
  };

  const toggleopend = () => {
    setClosenav(false);
    setRespClosenav(false);
    const calendarPageSection = document.getElementsByClassName(
      "fc-view-harness-active"
    )[0];
    // calendarPageSection.style.width=`calc(100vw - 14vw)`;
    calendarPageSection.style.width = `100vw`;
    // fc-view-harness fc-view-harness-active
  };

  const handleCloseModal = () => {
    setShowEventPopup(false);
    setTitle("");
    setMeetDesc("");
    setDate("");
    setMeetto(defTimeTo);
    setMeetfrom(defTimeFrom);
  };

  const handleDateFrom = (item) => {
    setMeetfrom(item);
    document.body.click();
  };

  const handleSaveClk = () => {
    setIsApiCallActive(true);
    let calendarApi = meetSelectInfo?.view?.calendar || undefined;

    console.log(calendarApi, "calendarApi");
    let meetFromDateSplit = meetFrom.split(" ")[0];
    let meetToDateSplit = meetTo.split(" ")[0];
    let meetDateFormated = moment(meetDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    if (createBtn === true) {
      postEvents(
        title,
        meetDateFormated,
        meetFromDateSplit,
        meetToDateSplit,
        meetDesc
      ).then((posteddata) => {
        if (posteddata?.event_id) {
          getDefaultEvents();
        }
      });
      setShowEventPopup(false);
    } else if (editBtn === true) {
      EditEvents(
        meetID,
        title,
        meetDateFormated,
        meetFromDateSplit,
        meetToDateSplit,
        meetDesc
      ).then((posteddata) => {
        getDefaultEvents();
      });
      setEditBtn(false);
    } else {
      if (calendarApi) {
        calendarApi.addEvent({
          id: createEventId(),
          title: title,
          start: `${meetDateFormated}T${meetFromDateSplit}+05:30`,
          end: `${meetDateFormated}T${meetToDateSplit}+05:30`,
          description: meetDesc,
        });
      }
      postEvents(
        title,
        meetDateFormated,
        meetFromDateSplit,
        meetToDateSplit,
        meetDesc
      ).then((posteddata) => {
        if (posteddata?.event_id) {
          getDefaultEvents();
        }
      });
    }
    setTitle("");
    setMeetDesc("");
    setShowEventPopup(false);
    setCreateBtnn(false);
  };

  useEffect(() => {
    if (!showEventPopup) {
      setIsApiCallActive(false);
    }
  }, [showEventPopup]);

  const deleteEventClk = (eventID) => {
    deleteEvent(eventID).then((posteddata) => {
      setOneventClkModal(false);
      console.log("statuS", posteddata);
      if (posteddata.status === 204) {
        getDefaultEvents();
      }
    });
  };

  const editEventClk = (event) => {
    setOneventClkModal(false);
    setShowEventPopup(true);
    setMeetID(event.event_id);
    setTitle(event.title);
    setDate(moment(event.date).tz("Asia/Kolkata").format("DD-MM-YYYY"));
    setMeetfrom(moment(event.clkMeetFrom).format("HH:mm"));
    setMeetto(moment(event.clkMeetTo).format("HH:mm"));
    setMeetDesc(event.description);
    setEditBtn(true);
  };

  const getDateWithoutTime = (dt) => {
    dt.setHours(0, 0, 0, 0);
    return dt;
  };
  const navigate = useNavigate();

  const logOut = () => {
    logout().then((res) => {
      localStorage.removeItem("data");
      navigate("/login");
    });
  };
  const isCurrentDate = (date) => {
    const currentDate = new Date();
    return (
      date.getDate() === currentDate.getDate() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    );
  };
  // const dayCellContent = ({ date,view }) => {

   const dayCellContent = ({ date,view  }) => {
    const classNames = [];

    if (isCurrentDate(date)) {
      classNames.push("current-date"); 
    }
    if (view.type === 'day') {
      classNames.push('current-day'); 
    }
    // if (view.type === 'day' || view.type === 'timeGridDay' || view.type === 'timeGridWeek') {
    //   classNames.push('current-day'); 
    // }
    return (
      <div className={classNames.join(" ")}>
        <span className="fc-daygrid-day-number">{date.getDate()}</span>
      </div>
    );
  }



  return (
    <>
      <div className="d-flex calender_container">
        <div className="top_nav_holder_main" id="top_nav_holder_main">
          <span className="calnr-head"> Calendar </span>
          {/* <span  onClick={handleShow} style={{position:'absolute',right:'10px',fontSize:'30px',top:'2px'}}>
        <i class="fa fa-sign-out" aria-hidden="true"></i>
        </span> */}
          {/* <Modal show={show} onHide={handleClose} className="mobile_view_pop">
        <Modal.Body>Are you sure, you want to sign out?</Modal.Body>
        <Modal.Footer style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={logOut}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>   */}
          <div
            className="d-flex flex-column calendar_main_div"
            style={mainContentStyle}
          >
            {" "}
            <div style={{ position: "relative" }}>
              <div>
                {/* <div className="calendar_heading">
                  <Form.Label className="calnr-head">Calendar</Form.Label>
                </div> */}

                <hr class="hr_line_in_responsive" />
              </div>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: `timeGridDay,timeGridWeek,dayGridMonth`,
                  center: "prev,next",
                  right: "title",
                }}
                initialView="timeGridDay"
                handleDateSelect={handleDateSelect}
                handleWindowResize={true}
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                select={handleDateSelect}
                eventContent={renderEventContent}
                eventClick={handleEventClick}
                eventsSet={handleEvents}
                events={events}
                nowIndicator={true}
                selectAllow={(info) =>
                  info.start >= getDateWithoutTime(new Date())
                }
                allDaySlot={false}
                dayHeaderContent={({ date, view }) => (
                  <div class="date-container">
                    <span class="day-of-week">
                      {moment(date).format("ddd")}
                    </span>
                    {/* <span class="day-of-month">{moment(date).format("D")}</span> */}
                    {view.type === "day" ||
                    view.type === "timeGridDay" ||
                    view.type === "timeGridWeek" ? (
                      <span class="day-of-month">
                        {moment(date).format("D")}
                      </span>
                    ) : null}
                  </div>
                )}
                eventOverlap={false}
                eventDisplay="block"
                id="calendar"
                dayCellContent={dayCellContent}
                // customDayCellContent={dayCellContent}
                // customDayCellContent={dayCellContent}
              />{" "}
              <Modal
                show={showEventPopup}
                onHide={handleCloseModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-parent"
                id="modal_parent_caldendar"
              >
                
                {/* <Modal.Header 
                className="modal-close-icon"
                >
                  <img
                    src={CloseIcon}
                    alt="Close-icon"
                    onClick={handleCloseModal}
                  ></img>
                </Modal.Header> */}

                <Modal.Body className="popup_text_container">
                  <form>
                    <input
                      type="text"
                      placeholder="Add Title"
                      value={title}
                      className="add-title"
                      onChange={(event) => setTitle(event.target.value)}
                      
                    />
                    <div className="modal-close-icon">
                      <img
                        src={CloseIcon}
                        alt="Close-icon"
                        onClick={handleCloseModal}
                      ></img>
                    </div>
                    <hr className="addtitle_hrline" />
                    <div className="date_time_select_container">
                      <img
                        className="calendarclock"
                        src={CalendarClock}
                        alt="CalendarClock"
                       
                      ></img>
                      <DatePicker
                        dateFormat="DD/MM/YYYY"                    
                        value={meetDate}
                        // selected={moment(meetDate, "DD-MM-YYYY").toDate()}
                        className="date-picker"
                        placeholderText="Select date"
                        onChange={(date) => {
                          setDate(moment(date).format("DD-MM-YYYY"));
                        }}
                      />
                      <OverlayTrigger
                        trigger="click"
                        key="bottom"
                        placement="bottom"
                        rootClose
                        overlay={
                          <Popover id={`popover-positioned-bottom`}>
                            <Popover.Body
                              className="scrollable-content"
                              style={{
                                display: "grid",
                                maxHeight: "150px",
                                overflowY: "scroll",
                              }}
                            >
                              {calenderFromToHrs.map((item) => {
                                return (
                                  <Form.Label
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleDateFrom(item.value)}
                                  >
                                    {item.key}
                                  </Form.Label>
                                );
                              })}
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <Button
                         id="time_btn_calendat"
                          className="meetBtn"
                          style={{
                            color: "#263659",
                            fontSize: "3.88vw",
                            fontWeight: "600",
                          }}
                        >
                          {moment(meetFrom, "HH:mm").format("hh:mm A")}
                        </Button>
                    
                      </OverlayTrigger>
                      <span className ="to_calendr-btn">To</span>
                      <OverlayTrigger
                        trigger="click"
                        key="bottom"
                        placement="bottom"
                        rootClose
                        overlay={
                          <Popover id={`popover-positioned-bottom`}>
                            <Popover.Body
                              className="scrollable-content"
                              style={{
                                display: "grid",
                                maxHeight: "150px",
                                overflowY: "scroll",
                              }}
                            >
                              {calenderFromToHrs.map((item) => {
                                return (
                                  <Form.Label
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setMeetto(item.value);
                                      document.body.click();
                                    }}
                                  >
                                    {item.key}
                                  </Form.Label>
                                );
                              })}
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <Button
                         id="time_btn_calendat"
                          className="meetBtn"
                          style={{
                             color: "#263659",
                             fontSize: "3.88vw",
                             fontWeight: "600",
                          }}
                        >
                          {moment(meetTo, "HH:mm").format("hh:mm A")}
                        </Button>
                      </OverlayTrigger>
                    </div>
                    <div>
                      {moment.duration(meetFrom, "HH:mm").asMinutes() >=
                      moment.duration(meetTo, "HH:mm").asMinutes() ? (
                        <p className={"error-message_"} id="error_msg_claendar">
                          Please select valid time.
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div style={{ display: "flex" }}>
                      <div>
                      
                        <img
                          className="descriptonimg"
                          src={CalendarDesc}
                          alt="CalendarDesc"
                          style={{
                            height: "10px",
                            marginLeft: "5px",
                          }}
                        ></img>
                      </div>

                      {/* <Form.Control
                          as="textarea"
                          type="text"
                          className="adddescription_text"
                          placeholder="Add Description"
                          rows={4}
                          value={meetDesc}
                          onChange={(event) => setMeetDesc(event.target.value)}
                        /> */}

                      <ReactQuill
                        theme="snow"
                        className="adddescription_text"
                        placeholder="Add Description"
                        value={meetDesc}
                        onChange={handleMeetDescChange}
                        // onChange={(value) => handleMeetDescChange(formatDescription(value))}
                      />
                    </div>
                  </form>
                </Modal.Body>
                <Modal.Footer
                  style={{ border: "none" }}
                  className="calender_footer"
                >
                  {/* <Button
                    className="save_button"
                    variant="primary"
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#1C477A",
                      color: "#FFFFFF",
                      opacity: 1,
                    }}
                    disabled={
                      isApiCallActive ||
                      moment.duration(meetFrom, "HH:mm").asMinutes() >
                        moment.duration(meetTo, "HH:mm").asMinutes()
                    }
                    onClick={handleSaveClk}
                  >
                    Save
                  </Button> */}
                  <button 
                  className="save_button"
                  disabled={
                    isApiCallActive ||
                    moment.duration(meetFrom, "HH:mm").asMinutes() >
                      moment.duration(meetTo, "HH:mm").asMinutes()
                  }
                  onClick={handleSaveClk}
                  >
                    Save
                  </button>
                </Modal.Footer>
              </Modal>
              {/* On click event modal */}
              {/* {isModalOpen && ( */}
              <Modal
                show={oneventClkModal}
                onHide={() => setOneventClkModal(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                className="save-modal"
                // onClick={() => {
                //   setIsModalOpen(true);
                // }}
              >
                <Modal.Header className="modal-title-edit">
                  <Modal.Title 
                  id="example-custom-modal-styling-title"
                  >
                  <span className="event_ttitle_calendar">{oneventClkData.title}</span>
                    <img 
                    className="calendar_icons_mobile"
                      src={CalendarEditIcon}
                      alt="CalendarEditIcon"
                      style={{ marginRight: "20px", cursor: "pointer" }}
                      onClick={() => {
                        editEventClk(oneventClkData);
                      }}
                    ></img>
                    <img
                    className="calendar_icons_mobile"
                      src={CalendarDeleteIcon}
                      alt="CalendarDeleteIcon"
                      style={{ marginRight: "20px", cursor: "pointer" }}
                      onClick={() => {
                        deleteEventClk(oneventClkData.event_id);
                      }}
                    ></img>
                    <img
                    className="calendar_icons_mobile"
                      src={CloseIcon}
                      alt="CloseIcon"
                      style={{ marginRight: "10px",cursor: "pointer" }}
                      onClick={() => {
                        setOneventClkModal(false);
                      }}
                    ></img>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modalBodyStyle">
                  {/* <Form.Label>
                    <b>{oneventClkData.title}</b>
                  </Form.Label> */}
                  <br />
                  <Form.Label style={{ marginRight: "15px" }}>
                    {moment(oneventClkData.clkMeetFrom).format("dddd, MMMM D")}
                  </Form.Label>
                  <div className="add_calendar_from_to_inevents">
                  <Form.Label>
                    {moment(oneventClkData.clkMeetFrom).format("hh:mm A")}
                  </Form.Label>{" "}
                  &nbsp; &nbsp;&nbsp;
                  - {" "}
                  &nbsp; &nbsp;&nbsp;
                  <Form.Label>
                    {moment(oneventClkData.clkMeetTo).format("hh:mm A")}
                  </Form.Label>
                  </div>
                  {/* <Form.Label>
                    {moment(oneventClkData.clkMeetFrom).format("hh:mm A")}
                  </Form.Label>{" "}
                  -{" "}
                  <Form.Label>
                    {moment(oneventClkData.clkMeetTo).format("hh:mm A")}
                  </Form.Label> */}
                  <div
                    style={{
                      border: "1px solid darkgray",
                      borderRadius: "4px",
                      background: "#F2F8FC",
                    }}
                  >
                    <ShowMoreText
                      className="parent_textseemore"
                      lines={3}
                      more={
                        <div
                          style={{ color: "#528BB8", textDecoration: "none" }}
                        >
                          See more
                        </div>
                      }
                      less={
                        <div
                          style={{ color: "#528BB8", textDecoration: "none" }}
                        >
                          See less
                        </div>
                      }
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: oneventClkData.description,
                        }}
                      />
                    </ShowMoreText>
                  </div>
                </Modal.Body>
              </Modal>
              {/* )} */}
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="calendar_note_main_div" id="calendar_note_main_div">
        <img
          src={NewEventIcon}
          onClick={() => {
            setShowEventPopup(true);
            setCreateBtnn(true);
          }}
          className="calender_note_img"
          id="calender_note_img"
        />
      </div>
    </>
  );
};

export default AddCalender;
