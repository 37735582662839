// import React, { useEffect, useState, useRef, useContext } from "react";
// import axios from "axios";
// import WhatsappIcon from "../Assets/whatsapp.png";
// import SMS from "../Assets/sms.png";
// import EcampBtn from "../Assets/ecampbtn.png";
// import ExcelDataUploader from './ExcelDataUploader';
// import { templates } from "../Service/service";
// import refresh from "../Assets/refreshbtn.png";
// import iFrame from "../Assets/iframe.png";
// import {
//   VotersdataEcampaings,
//   getAllNumbersEcampaign,
//   searchvoterEcampaign,
//   sendSMSAPI,
// } from "../Service/Ecampaignservice";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import "./Ecampaign.scss";
// import LoaderWrapper from "../components/LoaderWrapper";
// import General_Filter from "../components/Dropdowns/General Filter/General_Filter";
// import { urlData } from "../config/apiconfig";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { useNavigate, redirect, useLocation } from "react-router-dom";
// import { ROLES_PAGE_NAME_CONSTANTS } from "../Utils/utils";
// import { RoleDataContext } from "../AppContextComponent";
// import Ecampaign_Filter from "../components/ReactDropDown/ReactFilter/EcampaignFilter";

 const Ecampaign = () => {
  return (
    <div className="App" style={{background:'linear-gradient(123deg, #ffffff 50%, rgb(255, 103, 31) 150%)',marginTop:'2rem',height:'45rem'}}>
      <header className="App-header" style={{textAlign:'center',margin:'2rem'}}>
        <h1>Coming Soon</h1>
        <p>We're working on something awesome! Stay tuned.</p>
      </header>
    </div>
  );
 }
 
//   const navigate = useNavigate();
//   const [data, setData] = useState([]);
//   const [TemplateData, setTemplateData] = useState([]);
//   const [VoterData, setVoterData] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalpages, setTotalpages] = useState();
//   const pagesCount = Math.ceil(totalpages / 50);
//   const [objectsPerPage, setObjectsPerPage] = useState(50);
//   const itemsPerPage = 50;
//   const [inputValue, setInputValue] = useState("");
//   const [selectedValue, setSelectedValue] = useState(null);
//   const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
//   const [selectedItems, setSelectedItems] = useState([]);
//   const [selectedOption, setSelectedOption] = useState(null);

//   const [selectedOptionSenderID, setSelectedOptionSenderID] = useState("none");
//   const [next, setNext] = useState();

//   const [inputValueError, setInputValueError] = useState("");

//   const [selectedconstituencies, setSelectedConstituencies] = useState();
//   const [mandals, setMandals] = useState();
//   const [villages, setVillages] = useState();
//   const [pollingstations, setPollingstations] = useState();
//   const [caste, setCaste] = useState();
//   const [gender, setGender] = useState();
//   const [age, setAge] = useState();
//   const [religion, setReligion] = useState();
//   const [selectConstituencyArray, setSelectConstituencyArray] = useState();
//   const [selectMandalArray, setSelectMandalArray] = useState();
//   const [selectVillageArray, setSelectVillageArray] = useState();
//   const [selectPollingArray, setSelectPollingArray] = useState();
//   const [selectAll, setSelectAll] = useState(false);
//   const [sendName, setSendName] = useState();
//   const { roleDataService } = useContext(RoleDataContext);
//   const [var1, setVar1] = useState();
//   const [var2, setVar2] = useState();
//   const [var3, setVar3] = useState();
//   const [replacedstringPreview, setstringPreview] = useState();
//   const [verifyStatus, setVerifyStatus] = useState([]);

//   const ref = useRef();

//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [selectedTime, setSelectedTime] = useState(null);
//   const [showForm, setShowForm] = useState(false);
//   const [headerTemplate, setHeaderTemplate] = useState();
//   const [templateId, setTemplateId] = useState();
//   const [entityId, setEntityId] = useState();

//   const handleStartDateChange = (date) => {
//     setStartDate(date);
//     console.log(date);
//   };

//   const handleEndDateChange = (date) => {
//     setEndDate(date);
//   };

//   const handleTimeChange = (event) => {
//     setSelectedTime(event.target.value);
//   };

//   const handleCheckSchedule = (event) => {
//     setShowForm(event.target.checked);
//   };

//   useEffect(() => {
//     roleDataService.getPageRole(ROLES_PAGE_NAME_CONSTANTS.ECampaigns);
//     templates().then((result) => {
//       setTemplateData(result.response);
//     });
//   }, []);
//   const optionElements = TemplateData.map((data) => {
//     return (
//       <option key={data.template_id} value={data.template_id}>
//         {data.template_name}
//       </option>
//     );
//   });

//   const optionElementsSenderID = data.map((data) => {
//     return (
//       <option key={data.template_header} value={data.template_header}>
//         {data.template_header}
//       </option>
//     );
//   });
//   const [varValue, setvarValue] = useState();

//   const handleSelectChange = (event, param1, param2) => {
//     var filteredData = TemplateData.filter(
//       (v) => v.template_id == event.target.value
//     );
//     setSelectedOption(event.target.value);
//     setHeaderTemplate(filteredData[0].template_header);
//     setTemplateId(filteredData[0].template_id);
//     setEntityId(filteredData[0].entity_id);
//     setInputValue(filteredData[0].content_registered);
//     if (filteredData[0].content_registered.includes("{#var#}")) {
//       console.log(filteredData[0].content_registered.match(/#var#/g).length);
//       setvarValue(filteredData[0].content_registered.match(/#var#/g).length)
//     }

//     //     var inputString = "This is a sample string with two {#var} placeholders. Replace {#var} with new values.";

//     // // Define the new values you want to replace with
//     // var newValue1 = "NewValue1";
//     // var newValue2 = "NewValue2";

//     // // Create a regular expression pattern to match {#var}
//     // var regexPattern = /\{#var\}/g;

//     // // Use the replace method to replace occurrences of {#var} with new values
//   };

//   function handleNextPage() {
//     setCurrentPage((prevPage) => prevPage + 1);
//   }
//   function handlePreviousPage() {
//     setCurrentPage((prevPage) => prevPage - 1);
//   }

//   const handleMessageChange = (event) => {
//     setInputValue(event.target.value);
//     setInputValueError("");
//   };

//   const handleNameChange = (event) => {
//     // setInputValue(event.target.value);
//     setVar1(event.target.value);
//     setInputValueError("");
//   };

//   const handleNameChangeVar2 = (event) => {
//     // setInputValue(event.target.value);
//     setVar2(event.target.value);
//     setInputValueError("");
//   };

//   const handleNameChangeVar3 = (event) => {
//     // setInputValue(event.target.value);
//     setVar3(event.target.value);
//     setInputValueError("");
//   };
//   const handleSubmit = (event) => {
//     event.preventDefault();

//     if (!inputValue) {
//       setInputValueError("Maximum 200 Characters");
//     }
//   };
//   const handleSelectChangeSenderID = (event, param1, param2) => {
//     setSelectedValue(event.target.value);
//   };

//   const sendWhatsApp = async () => {
//     for (let i = 0; i < selectedCheckboxes.length; i++) {
//       var PhoneNumber = selectedCheckboxes[i];
//       var getData = data.filter((x) => x.phone_no == PhoneNumber);
//       var requestOptions = {
//         method: "post",
//         url: "https://api.imiconnect.io/resources/v1/messaging",
//         data: {
//           appid: "a_157354522561438800",
//           deliverychannel: "whatsapp",
//           message: {
//             template: "1272404983119428",
//             parameters: {
//               variable1: `Hello ${
//                 getData[0].first_name + "" + getData[0].last_name
//               }`,
//               variable2:
//                 "ITOCNT77128, Welcome to ItoConnect this is a test message from E-campaign page for more details please visit https://itoconnect.com/",
//             },
//           },
//           destination: [
//             {
//               waid: [`91${getData[0].phone_no}`],
//             },
//           ],
//         },
//         headers: {
//           "Content-Type": "application/json",
//           key: "9570e94a-007a-11ee-bece-06233c583be9",
//         },
//       };
//       try {
//         const response = await axios(requestOptions);
//         if (response.data.response[0].code == "1001") {
//           toast.success("WhatsApp Message Sent Successfully!", {
//             position: "top-center",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: false,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//           });
//         }
//       } catch (error) {
//         var msg = error;
//         console.log("error", error);
//       }
//     }
//   };

//   const makeaPhoneCall = async () => {
//     for (let i = 0; i < selectedCheckboxes.length; i++) {
//       var PhoneNumber = selectedCheckboxes[i];
//       var getData = data.filter((x) => x.phone_no == PhoneNumber);
//       var requestOptions = {
//         method: "post",
//         url: "https://api-smartflo.tatateleservices.com/v1/click_to_call",
//         data: {
//           agent_number: "918069869100",
//           destination_number: getData[0].phone_no,
//         },
//         headers: {
//           "Content-Type": "application/json",
//           authorization:
//             "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjM2MDA5OSwiaXNzIjoiaHR0cHM6XC9cL2Nsb3VkcGhvbmUudGF0YXRlbGVzZXJ2aWNlcy5jb21cL3Rva2VuXC9nZW5lcmF0ZSIsImlhdCI6MTY4OTkyNjcyOSwiZXhwIjoxOTg5OTI2NzI5LCJuYmYiOjE2ODk5MjY3MjksImp0aSI6IkZyTXI4WEs2Mkt0N2ZBNFMifQ.lhRMX2xW6eM8f4cuRS4_rYITPTWXc5fgtjeN-fQ464U",
//         },
//       };
//       try {
//         const response = await axios(requestOptions);
//         if (response.data.success == true) {
//           toast.success("PhoneCall Successfully!", {
//             position: "top-center",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: false,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//           });
//         }
//       } catch (error) {
//         var msg = error;
//         console.log("error", error);
//       }
//     }
//   };

//   const replaceVarPlaceholders = (inputString, replacement1, replacement2) => {
//     let replacedString = inputString;

//     // Replace the first occurrence of {#var#} with replacement1
//     replacedString = replacedString.replace(/\{#var#\}/, replacement1);

//     // Replace the second occurrence of {#var#} with replacement2
//     replacedString = replacedString.replace(/\{#var#\}/, replacement2);

//     return replacedString;
//   };

//   function isEnglishString(inputString) {
//     // Define a regular expression pattern that matches only English alphabet characters
//     const englishDescriptionPattern = /^[a-zA-Z\s!@#$%^&*(),.?":{}|<>]+$/;

//     // Test if the input string matches the pattern
//     return englishDescriptionPattern.test(inputString);
//   }
//   const userData = JSON.parse(localStorage.getItem("data"));
//   const user_Id = userData.user_data.user_id;

//   function getCurrentDateTimeWithoutSeparators() {
//     const now = new Date();

//     // Get date components
//     const year = now.getFullYear().toString();
//     const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
//     const day = now.getDate().toString().padStart(2, "0");

//     // Get time components
//     const hours = now.getHours().toString().padStart(2, "0");
//     const minutes = now.getMinutes().toString().padStart(2, "0");
//     const seconds = now.getSeconds().toString().padStart(2, "0");

//     // Concatenate date and time components
//     const dateTimeWithoutSeparators =
//       year + month + day + hours + minutes + seconds;

//     return dateTimeWithoutSeparators;
//   }

//   const currentDateTimeWithoutSeparators =
//     getCurrentDateTimeWithoutSeparators();
//   const randomTimestamp = getCurrentDateTimeWithoutSeparators();
//   const previewData = () => {
//     var replacedString = "";
//     if (inputValue.includes("{#var#}")) {
//       console.log(inputValue.match(/#var#/g).length);
//     }
//     if (inputValue.includes("{#var#}")) {

//       if(inputValue.match(/#var#/g).length  == 1)

//       {
//         if(var1 == "")
//         {
//           const replacement1 = selectedItems[0].first_name;
//           replacedString = replaceVarPlaceholders(
//             inputValue,
//             replacement1,
//           );
//           setstringPreview(replacedString);
  
//         }
//         else 
//         {
//           const replacement1 =  var1;
//           replacedString = replaceVarPlaceholders(
//             inputValue,
//             replacement1,
//           );   
//           setstringPreview(replacedString);
//          }
  
  
  
//       }

//       else if(inputValue.match(/#var#/g).length  == 2)
//       {
//         if(var1 != "" && (var2 == "" || var2 == undefined))
//         {
//           const replacement1 = var1;
//           const replacement2 = selectedItems[0].first_name
//           replacedString = replaceVarPlaceholders(
//             inputValue,
//             replacement1,
//             replacement2,
//           );
//           setstringPreview(replacedString);
  
//         }
//         else if((var1 == "" || var1 == undefined) && var2 != "")
//         {
//           const replacement1 = selectedItems[0].first_name;
//           const replacement2 = var2
//           replacedString = replaceVarPlaceholders(
//             inputValue,
//             replacement1,
//             replacement2,
//           );   
//           setstringPreview(replacedString);
//          }
//          else if ((var1 != "" || var1 == undefined) && (var2 != "" || var2 == undefined) )
//          {
//           const replacement1 = var1;
//           const replacement2 = var2
//           replacedString = replaceVarPlaceholders(
//             inputValue,
//             replacement1,
//             replacement2,
//           );   
//           setstringPreview(replacedString);
//          }

//          else {
//           const replacement1 = selectedItems[0].first_name;
//           const replacement2 = selectedItems[0].first_name;
//           replacedString = replaceVarPlaceholders(
//             inputValue,
//             replacement1,
//             replacement2,
//           );   
//           setstringPreview(replacedString);         }
  
//       }

//       else if(inputValue.match(/#var#/g).length  == 3)
//       {
        
//       }



 

//     //   if(var1 !=)

//     //   const replacement1 = selectedItems[0].first_name;
//     //   const replacement2 = var2;
//     //   replacedString = replaceVarPlaceholders(
//     //     inputValue,
//     //     replacement1,
//     //     replacement2
//     //   );
//     // } else {
//     //   replacedString = inputValue;
//     // }
//   };

// }

//   const closePopup = () => {
//     alert(
//       "This will close the popup and you will not be able to send the message to selected phone numbers"
//     );
//   };

//   const sendSMS = async () => {
//     let numberFlag = false;
//     let alphabetFlag = false;
//     var replacedString = "";
//     if (inputValue.includes("{#var#}")) {
//       console.log(inputValue.match(/#var#/g).length);
//     }

//     let value = "vikas chandra guru";
//     let i = 0;
//     var body = selectedItems.map((item) => {
//       if (inputValue.includes("{#var#}")) {
//         i = i + 1;
//         // var MessageData = inputValue.replace(
//         //   "{#var#}",
//         //   var1
//         // );
//         if(var1 == "" && var2 == "" && var3 == ""){
//           replacedString = inputValue;
//         } else if(var1 != "" && var2 =="" && var3 == "") {
//           const replacement1 = var1;
//           const replacement2 = var2;
//           replacedString = replaceVarPlaceholders(
//             inputValue,
//             replacement1,
//             replacement2
//           );
//         }
//         }


//       if (showForm) {
//         if (/^[a-zA-Z]+$/.test(headerTemplate)) {
//         } else if (/^[0-9]+$/.test(headerTemplate)) {
//           numberFlag = true;
//         } else {
//           alphabetFlag = true;
//         }
//         if (numberFlag && isEnglishString(inputValue)) {
//           var dateformat = formatDate(startDate);
//           var FinalDateandTime = `${dateformat} ${selectedTime}:00`;

//           return {
//             sms: replacedString,
//             mobiles: item.phone_no,
//             senderid: headerTemplate,
//             accountusagetypeid: "2",
//             time: FinalDateandTime,
//             tempid: templateId,
//             entityid: entityId,
//             clientsmsid: `${user_Id}-${randomTimestamp}-${i}`,
//             unicode: "1",
//           };
//         } else {
//           var dateformat = formatDate(startDate);
//           var FinalDateandTime = `${dateformat} ${selectedTime}:00`;
//           return {
//             sms: replacedString,
//             mobiles: item.phone_no,
//             senderid: headerTemplate,
//             accountusagetypeid: "1",
//             tempid: templateId,
//             entityid: entityId,
//             clientsmsid: `${user_Id}-${randomTimestamp}-${i}`,
//             time: FinalDateandTime,
//           };
//         }
//       } else {
//         if (/^[a-zA-Z]+$/.test(headerTemplate)) {
//           alphabetFlag = true;
//           console.log("It's a letter.");
//         } else if (/^[0-9]+$/.test(headerTemplate)) {
//           numberFlag = true;
//         } else {
//         }
//         if (numberFlag && isEnglishString(value.toString())) {
//           return {
//             sms: replacedString,
//             mobiles: item.phone_no,
//             senderid: headerTemplate,
//             accountusagetypeid: "2",
//             tempid: templateId,
//             entityid: entityId,
//             clientsmsid: `${user_Id}-${randomTimestamp}-${i}`,
//             unicode: "1",
//           };
//         } else {
//           return {
//             sms: replacedString,
//             mobiles: item.phone_no,
//             senderid: headerTemplate,
//             accountusagetypeid: "1",
//             tempid: templateId,
//             entityid: entityId,
//             clientsmsid: `${user_Id}-${randomTimestamp}-${i}`,
//           };
//         }
//         //   return  {
//         //     "sms": MessageData,
//         //     "mobiles": item.phone_no,
//         //     "senderid": "CSFTAI",
//         //     "accountusagetypeid": "1",
//         // }
//       }
//     });
//     var raw = {
//       campaign_id: randomTimestamp,
//       message: inputValue,
//       listsms: body,
//     };

//     sendSMSAPI(raw)
//       .then((result) => {
//         const statusresult = result.message.smslist.sms;
//         const data = statusresult.map((items) => {
//           return items;
//         });
//         if (data.some((item) => item.status === "success")) {
//           toast.success("SMS Scheduled Successfully!", {
//             position: "top-center",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: false,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//           });
//         } else {
//           toast.error(
//             `SMS Message Failed : ${result.message.smslist.sms.reason}`,
//             {
//               position: "top-center",
//               autoClose: 5000,
//               hideProgressBar: false,
//               closeOnClick: true,
//               pauseOnHover: false,
//               draggable: true,
//               progress: undefined,
//               theme: "light",
//             }
//           );
//         }
//       })
//       .catch((error) => {
//         console.log("error", error);
//         toast.error("SMS Message Failed!", {
//           position: "top-center",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: false,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//       });
//     // var myHeaders = new Headers();
//     // myHeaders.append("Content-Type", "application/json");
//   };

//   const formatDate = (dateString) => {
//     const dateObj = new Date(dateString);
//     const day = String(dateObj.getDate()).padStart(2, "0");
//     const month = String(dateObj.getMonth() + 1).padStart(2, "0");
//     const year = String(dateObj.getFullYear());
//     return `${year}-${month}-${day}`;
//   };
//   var AllNumberSMS;

//   const [prev, setPrev] = useState();
//   const [checkboxes, setCheckboxes] = useState([]);

//   const handleSelectAllChange = async (event) => {
//     const constituency = selectConstituencyArray;
//     const mandal = selectMandalArray;
//     const ward_village = selectVillageArray;
//     const polling_booth = selectPollingArray;
//     const isChecked = event ? event.target.checked : selectAll;
//     const check = [
//       { constituency: constituency },
//       { mandal: mandal },
//       { ward_village: ward_village },
//       { polling_booth: polling_booth },
//     ];
//     const test = () => {
//       let obj = {};

//       check.forEach((item) => {
//         const key = Object.keys(item)[0]; // Get the property key from the object
//         if (item[key].length !== 0) {
//           obj[key] = item[key];
//         }
//       });
//       if (casteSelected) {
//         obj.sub_caste = caste.split(",").map(Number);
//       }
//       if (genderSelected) {
//         obj.gender = gender.split(",");
//       }
//       if (ageSelected) {
//         obj.age = age;
//       }
//       if (religionSelected) {
//         obj.religion = religion.split(",").map(Number);
//       }

//       return obj;
//     };
//     setSelectAll(isChecked);
//     let NumberData;
//     if (isChecked) {
//       const allCheckboxIds = data.map((item) => item.voter_id);
//       setCheckboxes(allCheckboxIds);
//       let payload = test();
//       setLoadingScreen(true);
//       await getAllNumbersEcampaign(payload).then((result) => {
//         NumberData = result.filter(
//           (x) => x.phone_no != null && x.phone_no != 0
//         );
//         sessionStorage.setItem("phoneLength", NumberData.length);
//         const AllNumberData = result.filter(
//           (x) => x.phone_no != null && x.phone_no != 0
//         );
//         const getPhoneNumber = [];
//         const DataforAPI = [];
//         AllNumberData.forEach((element) => {
//           getPhoneNumber.push(element.phone_no);
//           DataforAPI.push({
//             phone_no: element.phone_no,
//             first_name: element.first_name,
//           });
//         });

//         if (selectedItems.length == undefined) {
//           setSelectedItems([selectedItems, DataforAPI]);
//           setSelectedCheckboxes(getPhoneNumber);
//           setLoadingScreen(false);
//         } else {
//           setSelectedItems(...selectedItems, DataforAPI);
//           setSelectedCheckboxes(getPhoneNumber);
//           setLoadingScreen(false);
//         }
//       });
//       toast.info(`${NumberData.length} Numbers selected`, {
//         position: "top-center",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: false,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       });
//     } else {
//       toast.info(
//         `${sessionStorage.getItem("phoneLength")} Numbers unselected`,
//         {
//           position: "top-center",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: false,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         }
//       );
//       Object.keys(selectedCheckboxes).forEach(
//         (key) => delete selectedCheckboxes[key]
//       );
//       setCheckboxes([]);
//       setSelectedCheckboxes([]);
//     }
//   };

//   const handleCheckboxChange = (event, id, item) => {
//     const isChecked = event.target.checked;
//     if (isChecked) {
//       setCheckboxes([...checkboxes, id]);
//       setSelectedCheckboxes([...selectedCheckboxes, item.phone_no]);
//       console.log("selected ITems", selectedItems.length);
//       if (selectedItems.length == undefined) {
//         setSelectedItems([selectedItems, item]);
//       } else {
//         setSelectedItems([...selectedItems, item]);
//       }
//     } else {
//       const updatedItems = checkboxes.filter((checkboxId) => checkboxId !== id);
//       setSelectedItems(() =>
//         selectedItems.filter((i) => i.voter_id !== item.voter_id)
//       );
//       const updatedPhone = selectedCheckboxes.filter((checkboxId) => {
//         return checkboxId !== item.phone_no;
//       });

//       setCheckboxes(updatedItems);
//       setSelectedCheckboxes(updatedPhone);
//       Object.keys(selectedCheckboxes).forEach(
//         (key) => delete selectedCheckboxes[key]
//       );
//       return;
//     }
//   };

//   const [loadingscreen, setLoadingScreen] = useState(true);

//   const handleDataFromChild = (
//     constituencyParamValue,
//     mandalParamValue,
//     villageParams,
//     pollingStationParams,
//     casteParams,
//     genderParams,
//     ageParams,
//     relegionParams
//   ) => {
//     setSelectedConstituencies(constituencyParamValue);
//     setMandals(mandalParamValue);
//     setVillages(villageParams);
//     setPollingstations(pollingStationParams);
//     setCaste(casteParams);
//     setGender(genderParams);
//     if (ageParams) setAge(ageParams);
//     setReligion(relegionParams);
//   };

//   const arrayDataFromChild = (
//     constArray,
//     mArray,
//     vArray,
//     pArray,
//     castArray,
//     genderArray,
//     ageSelection,
//     reigionArray
//   ) => {
//     setSelectConstituencyArray(constArray);
//     setSelectMandalArray(mArray);
//     setSelectVillageArray(vArray);
//     setSelectPollingArray(pArray);
//   };

//   const constituenciesParams = `${urlData.votersdataConstituencyapi}${selectedconstituencies}&`;
//   const mandalParams = `${urlData.votersdataMandalapi}${mandals}&`;
//   const villageParams = `${urlData.votersdataVillageapi}${villages}&`;
//   const pollingstationParams = `${urlData.votersdatapollingstationapi}${pollingstations}&`;
//   const casteParams = `${urlData.votersdataCasteapi}${caste}&`;
//   const genderParams = `${urlData.votersdataGenderapi}${gender}&`;
//   const ageParams = `${urlData.votersdataAgeapi}${age}&`;
//   const religionParams = `${urlData.votersdataReligionapi}${religion}&`;

//   const constituenciesSelected = selectedconstituencies
//     ? constituenciesParams
//     : "";
//   const mandalsSelected = mandals ? mandalParams : "";
//   const villagesSelected = villages ? villageParams : "";
//   const pollingstationsSelected = pollingstations ? pollingstationParams : "";
//   const casteSelected = caste ? casteParams : "";
//   const genderSelected = gender ? genderParams : "";
//   const ageSelected = age ? ageParams : "";
//   const religionSelected = religion ? religionParams : "";
//   useEffect(() => {
//     setLoadingScreen(false);
//     VotersdataEcampaings(
//       currentPage,
//       constituenciesSelected,
//       mandalsSelected,
//       villagesSelected,
//       pollingstationsSelected,
//       casteSelected,
//       genderSelected,
//       ageSelected,
//       religionSelected
//     ).then((result) => {
//       setVoterData(result.results);
//       setData(result.results);
//       setTotalpages(result.count);
//       if (result.results) {
//         setLoadingScreen(false);
//       }
//       if (result.next != null || result.next != undefined) {
//         setNext(result.next);
//       }
//       if (result.prev != null || result.next != undefined) {
//         setPrev(result.prev);
//       }
//       // if (selectAll) {
//       //   const allCheckboxIds = result.results.map((item) => item.voter_id);
//       //   setCheckboxes(allCheckboxIds);
//       // }
//     });

//     Object.keys(selectedCheckboxes).forEach(
//       (key) => delete selectedCheckboxes[key]
//     );
//     setCheckboxes([]);
//     setSelectedCheckboxes([]);
//   }, [
//     currentPage,
//     constituenciesParams,
//     mandalParams,
//     villageParams,
//     pollingstationParams,
//     casteParams,
//     genderParams,
//     ageParams,
//     religionParams,
//   ]);
//   // const handleClick = (e) => {
//   //   e.preventDefault();
//   //   var removeItem = selectedCheckboxes[0];
//   //   var finalItem = selectedCheckboxes.slice(1);
//   //   setSelectedCheckboxes([finalItem]);
//   // };


//   const handleClick = (e) => {
//     e.preventDefault();
//     var finalItem = selectedCheckboxes.slice(0);
//     const updatedCheckboxes = [...selectedCheckboxes];
//     const removedItem = updatedCheckboxes.shift();
//     setSelectedCheckboxes(updatedCheckboxes);
//     const removernumber = data.filter((item) => item.phone_no === removedItem);
//     const checkboxesvoterid = checkboxes;
//     const removedvoterid =
//       removernumber.length > 0
//         ? removernumber.map((items) => items.voter_id)
//         : null;
//     const filteredArray1 = checkboxesvoterid.filter(
//       (itemval) => !removedvoterid.includes(itemval)
//     );
//     setCheckboxes(filteredArray1);

//     setSelectedItems(() => {
//       return selectedItems.filter((i) => {
//         return i.phone_no !== finalItem[0];
//       });
//     });
//   };

//   const handleCount = (e) => {
//     e.preventDefault();
//     alert(`You have entered ${selectedCheckboxes.length} number(s)`);
//   };

//   const clearfilters = () => {
//     filterRef.current.clearFilter();
//     setSelectedConstituencies("");
//     setMandals("");
//     setVillages("");
//     setPollingstations("");
//     setCaste("");
//     setGender("");
//     setAge("");
//     setReligion("");
//     setSelectConstituencyArray([]);
//     setSelectMandalArray([]);
//     setSelectVillageArray([]);
//     setSelectPollingArray([]);
//     toast.info(`Selected Numbers are Unselected please select`, {
//       position: "top-center",
//       autoClose: 3000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: false,
//       draggable: true,
//       progress: undefined,
//       theme: "light",
//     });
//   };

//   const customStyles = {
//     control: (provided, state) => ({
//       ...provided,
//       backgroundColor: "#F3F7F9",
//       backgroundImage: "none",
//       height: "2.65625vw",
//       width: "45vw",
//       border: "none",
//       minHeight: "0px",
//       fontSize: "0.9375vw",
//       span: {
//         display: "none",
//       },
//       svg: {
//         display: "none",
//       },
//       "&:after": {
//         content: '""',
//         display: "inline-block",
//         width: "0",
//         height: "0",
//         marginLeft: "4px",
//         verticalAlign: "middle",
//         borderTop: "6px solid #000",
//         borderRight: "4px solid transparent",
//         borderBottom: "0",
//         borderLeft: "4px solid transparent",
//         position: "absolute",
//         right: "0.9375vw",
//       },
//     }),
//     placeholder: (provided, state) => ({
//       ...provided,
//       fontWeight: "400",
//       fontSize: "0.9375vw",
//       color: "#8B93A5",
//       position: "relative",
//       bottom: "3vw",
//     }),
//     option: (defaultStyles, state) => ({
//       ...defaultStyles,
//       borderBottom: "1px solid #D9D8D8",
//       color: "#263659",
//       fontSize: "0.9375vw",
//       "&:last-child": {
//         borderBottom: "none",
//       },
//     }),
//   };

//   const [searchValue, setSearchValue] = useState("");
//   const [searchData, setSearchData] = useState();
//   const filterRef = useRef();

  

//   const handleSerach = (e) => {
//     setSearchValue(e.target.value);
//   };

//   const hanclesearchClicked = () => {
//     setLoadingScreen(true);
//     searchvoterEcampaign(searchValue).then((resp) => {
//       if (resp.voters.length > 0) {
//         setSearchData(resp.voters);
//         setLoadingScreen(false);
//       }
//       else{
//         alert('Data not found');
//         setLoadingScreen(false);
//       }
//     });
//   };

//   const votersSearchdata = searchData ? searchData : VoterData;

//   useEffect(() => {
//     if (searchValue == "") {
//       setSearchData();
//     }
//   });
//   const nav = useNavigate();
//   const handleImageClick = () => {
//     nav("/ecampaign-status");
//   };

//   return (
//     <div className="e_campaigns_main_div d-flex">
//       <div className="top_nav_holder_main" id="top_nav_holder_main">
//         <div className="d-flex flex-column">
//           <div
//             className="e_campaigns_text_maindiv"
//             id="e_campaigns_text_maindiv"
//           >
//             <span className="e_campaigns_text_ems">E-Campaign</span>

//             <div className="e_campaigns_inputfield_main_div">
//             <div className="col-md-3">
//                     <img
//                       data-toggle="modal"
//                       data-target="#uploadexcel"
//                       style={{width:'25px',float:'right',bottom:'10px'}}
//                       src="https://png.pngtree.com/png-clipart/20190920/original/pngtree-file-upload-icon-png-image_4646955.jpg"
//                       alt="whatsapp"
//                     ></img>
//                   </div>
//               <input
//                 className="Searchbar_inputfield"
//                 onChange={handleSerach}
//                 placeholder="Enter Voter ID Number"
//               />
//               <button
//                 className="Searchclick_button"
//                 onClick={hanclesearchClicked}
//               >
//                 search
//               </button>


//             </div>
            
//             <div className={"socialImages"}>
//               <div className="row">
//                 <div className="col-md-3">
//                   <img
//                     className="refresh_img"
//                     src={refresh}
//                     alt="not-visible"
//                     onClick={clearfilters}
//                   />
//                 </div>
                

//                 {roleDataService.access.update ? (
//                   <div className="col-md-3">
//                     <img
//                       data-toggle="modal"
//                       data-target="#myWhatsapp"
//                       src={WhatsappIcon}
//                       alt="whatsapp"
//                       class="whatsapp"
//                     ></img>
//                   </div>
//                 ) : (
//                   <div className="col-md-3"></div>
//                 )}
//                 {/* <div className="col-md-3">
//                   <img data-toggle="modal" data-target="#phoneCall" phoneCall src={CallIcon} alt="call" class="call"></img>
//                 </div> */}
//                 {roleDataService.access.update ? (
//                   <div className="col-md-3">
//                     <img
//                       data-toggle="modal"
//                       data-target="#myModal"
//                       src={SMS}
//                       alt="sms"
//                       class="sms_modal"
//                     ></img>
//                   </div>
//                 ) : (
//                   <div className="col-md-3"></div>
//                 )}
  

//                 <div className="col-md-3">
//                   <img
//                     onClick={handleImageClick}
//                     mode
//                     src={EcampBtn}
//                     alt="e-campaign"
//                     class="e-campaign"
//                   ></img>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <Ecampaign_Filter
//           ref={filterRef}
//           selectedDropdownValues={handleDataFromChild}
//           seletedArayList={arrayDataFromChild}
//           setSelectAll={setSelectAll}
//         />
//         <div
//           class="scroll_ecampaigns"
//           id="scroll_ecampaigns_main_div"
//           style={{}}
//         >
//           <div class="tableFixHead" id="scrollbar_ecampaigns">
//             <table
//               id="e-campaigns_table"
//               class="table table-striped table-bordered"
//               style={{ width: "100%" }}
//             >
//               <thead
//                 className="table_header_ecampaigns"
//                 id="table_header_ecampaigns"
//               >
//                 <tr>
//                   <th className="thead_checkbox">
//                     <input
//                       checked={selectAll}
//                       onChange={handleSelectAllChange}
//                       type="checkbox"
//                       id="checkbox1"
//                     ></input>
//                   </th>
//                   <th className="thead_1" id="thead_1">
//                     Voter ID Number
//                   </th>
//                   <th className="thead_2" id="thead_1">
//                     First Name
//                   </th>
//                   <th className="thead_3" id="thead_1">
//                     Last Name
//                   </th>
//                   <th className="thead_4" id="thead_1">
//                     Gender
//                   </th>
//                   <th className="thead_5" id="thead_1">
//                     Caste
//                   </th>
//                   <th className="thead_6" id="thead_1">
//                     Mobile Number
//                   </th>
//                 </tr>
//               </thead>
//               <tbody classNam="scro" id="scroll_table_body">
//                 {votersSearchdata.map((item) => (
//                   <tr>
//                     <td className="thead_checkbox table_data_1">
//                       <input
//                         checked={checkboxes.includes(item.voter_id)}
//                         onChange={(event) =>
//                           handleCheckboxChange(event, item.voter_id, item)
//                         }
//                         type="checkbox"
//                         id="body_checkbox"
//                       ></input>
//                     </td>
//                     <td className="table_data_2">{item.voter_id}</td>
//                     <td className="table_data_3">{item.first_name}</td>
//                     <td className="table_data_4">{item.last_name}</td>
//                     <td className="table_data_5">{item.gender}</td>
//                     <td className="table_data_6">{item.caste}</td>
//                     <td className="table_data_7">{item.phone_no}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//         <div className="e_campaigns_hr_line_maindiv">
//           <hr />
//           <hr />
//         </div>
//         <div class="buttons_ecampaign_next_prev_main">
//           <div className="d-flex buttons_ecampaign_next_prev_main_div">
//             <div className="total_pages" style={{}}>
//               Pages : {pagesCount}
//             </div>
//             <button
//               className="prev_button_ecampaignsdata"
//               onClick={handlePreviousPage}
//               disabled={currentPage === 1}
//             >
//               Prev
//             </button>
//             <div
//               className="current_page_number_ecampaignsdata "
//               id="e-campaigns_currentPage"
//             >
//               <span> {currentPage}</span>
//               {/* {currentPage} */}
//             </div>
//             <button
//               className="next_button_ecampaignsdata"
//               onClick={handleNextPage}
//               disabled={data.length < objectsPerPage}
//             >
//               Next
//             </button>
//           </div>
//         </div>
//       </div>
//       <div class="modal fade" id="myModal" role="dialog">
//         <div class="modal-dialog">
//           <div class="modal-content">
//             <div class="ecampaign_modal-header">
//               <div></div>
//               <h4 class="modal-title">Compose SMS</h4>
//               <button
//                 type="button"
//                 className="close btn-close"
//                 data-dismiss="modal"
//               ></button>
//             </div>
//             <div class="ecampaign_modal-body">
//               <form onSubmit={handleSubmit}>
//                 <div className="row">
//                   <span id="label">Template</span>
//                 </div>
//                 <div className="row">
//                   <div class="select-toggle" id="select-toggle">
//                     <select
//                       className="select"
//                       value={selectedOption}
//                       onChange={(event) =>
//                         handleSelectChange(event, selectedOption, "world")
//                       }
//                     >
//                       <option value="">Select Template</option>
//                       {optionElements}
//                     </select>
//                   </div>
//                 </div>

//                 <div className="row mt-2">
//                   <span id="label">Sender ID</span>
//                 </div>
//                 <div className="row">
//                   <div class="select-toggle" id="select-toggle">
//                     <select
//                       className="select"
//                       value={selectedValue}
//                       onChange={(event) =>
//                         handleSelectChangeSenderID(
//                           event,
//                           selectedOption,
//                           "world"
//                         )
//                       }
//                     >
//                       <option value="">Select an option</option>
//                       <option value="CSFTAI">{headerTemplate}</option>
//                     </select>
//                   </div>
//                 </div>

//                 <div className="row mt-2">
//                   <span id="label">Mobile</span>
//                 </div>
//                 <div className="row" id="buttons_maindiv">
//                   <textarea
//                     className="textAreaMobile"
//                     type="mobile"
//                     id="mobile"
//                     name="mobile"
//                     placeholder="Enter Mobile numbers"
//                     value={selectedCheckboxes}
//                     min="0"
//                     max="100"
//                     step="5"
//                   />

//                   <div className="side_section">
//                     <a
//                       type=""
//                       className="close_side"
//                       onClick={handleClick}
//                       id="close_side"
//                     >
//                       <span id="close_icon">x</span>
//                     </a>
//                     <a class="btn" onClick={handleCount} id="iframe">
//                       <img src={iFrame} />
//                     </a>
//                   </div>
//                 </div>

//                 <div className="row mt-2">
//                   <span id="label" class="msg">
//                     Message
//                   </span>
//                 </div>
//                 <div className="row">
//                   <textarea
//                     type="text"
//                     name="inputValue"
//                     id="enter_message"
//                     placeholder="Enter Message"
//                     value={inputValue}
//                     onChange={handleMessageChange}
//                   />
//                   {inputValueError && (
//                     <div className="error error_msg">{inputValueError}</div>
//                   )}
//                 </div>
//               {varValue == 1 && (
//                           <div className="row mt-2">
//                           <div class="col-md-6">
//                             <span id="label" class="msg">
//                               Variable 1
//                             </span>
//                           </div>
//                           <div class="col-md-6">
//                             <div className="row">
//                               <input
//                                 className="enter_name"
//                                 type="text"
//                                 name="inputValue"
//                                 id="enter_name"
//                                 placeholder="Please Enter the Name"
//                                 value={var1}
//                                 onChange={handleNameChange}
//                               />
//                               {inputValueError && (
//                                 <div className="error error_msg">{inputValueError}</div>
//                               )}
//                             </div>
//                           </div>
//                         </div>
//               )

//               }


// {varValue == 2 && 
//                           <div className="row mt-2">
//                           <div class="col-md-6">
//                             <span id="label" class="msg">
//                               Variable 1
//                             </span>
//                           </div>
//                           <div class="col-md-6">
//                             <div className="row">
//                               <input
//                                 className="enter_name"
//                                 type="text"
//                                 name="inputValue"
//                                 id="enter_name"
//                                 placeholder="Please Enter the Name"
//                                 value={var1}
//                                 onChange={handleNameChange}
//                               />
//                               {inputValueError && (
//                                 <div className="error error_msg">{inputValueError}</div>
//                               )}
//                             </div>
//                           </div>

//                           <div class="col-md-6">
//                             <span id="label" class="msg">
//                               Variable 2
//                             </span>
//                           </div>
//                           <div class="col-md-6">
//                             <div className="row">
//                               <input
//                                 className="enter_name"
//                                 type="text"
//                                 name="inputValue"
//                                 id="enter_name"
//                                 placeholder="Please Enter the Name"
//                                 value={var2}
//                                 onChange={handleNameChangeVar2}
//                               />
//                               {inputValueError && (
//                                 <div className="error error_msg">{inputValueError}</div>
//                               )}
//                             </div>
//                           </div>
//                         </div>
  

//               }

// {varValue == 3 && 
//                           <div className="row mt-2">
//                           <div class="col-md-6">
//                             <span id="label" class="msg">
//                               Variable 1
//                             </span>
//                           </div>
//                           <div class="col-md-6">
//                             <div className="row">
//                               <input
//                                 className="enter_name"
//                                 type="text"
//                                 name="inputValue"
//                                 id="enter_name"
//                                 placeholder="Please Enter the Name"
//                                 value={var1}
//                                 onChange={handleNameChange}
//                               />
//                               {inputValueError && (
//                                 <div className="error error_msg">{inputValueError}</div>
//                               )}
//                             </div>
//                           </div>

//                           <div class="col-md-6">
//                             <span id="label" class="msg">
//                               Variable 2
//                             </span>
//                           </div>
//                           <div class="col-md-6">
//                             <div className="row">
//                               <input
//                                 className="enter_name"
//                                 type="text"
//                                 name="inputValue"
//                                 id="enter_name"
//                                 placeholder="Please Enter the Name"
//                                 value={var2}
//                                 onChange={handleNameChangeVar2}
//                               />
//                               {inputValueError && (
//                                 <div className="error error_msg">{inputValueError}</div>
//                               )}
//                             </div>
//                           </div>


//                           <div class="col-md-6">
//                             <span id="label" class="msg">
//                               Variable 3
//                             </span>
//                           </div>
//                           <div class="col-md-6">
//                             <div className="row">
//                               <input
//                                 className="enter_name"
//                                 type="text"
//                                 name="inputValue"
//                                 id="enter_name"
//                                 placeholder="Please Enter the Name"
//                                 value={var3}
//                                 onChange={handleNameChangeVar3}
//                               />
//                               {inputValueError && (
//                                 <div className="error error_msg">{inputValueError}</div>
//                               )}
//                             </div>
//                           </div>
//                         </div>



//               }

      

       
//                 <div className="schedule_division">
//                   <span>Schedule</span>
//                   <input onChange={handleCheckSchedule} type="checkbox" />
//                 </div>
//                 {showForm && (
//                   <form
//                     onSubmit={handleSubmit}
//                     className="formdivs_inputfields"
//                   >
//                     <div>
//                       <label>From</label>
//                       <DatePicker
//                         className="form_input"
//                         selected={startDate}
//                         onChange={handleStartDateChange}
//                         dateFormat="dd/MM/yyyy"
//                       />
//                     </div>
//                     <div>
//                       <label>To</label>
//                       <DatePicker
//                         className="form_input"
//                         selected={endDate}
//                         onChange={handleEndDateChange}
//                         dateFormat="dd/MM/yyyy"
//                       />
//                     </div>
//                     <div>
//                       <input
//                         className="form_timeinput"
//                         type="time"
//                         value={selectedTime}
//                         onChange={handleTimeChange}
//                       />
//                     </div>
//                   </form>
//                 )}
//                 <div className="submitdiv_main">
//                   <button
//                     onClick={previewData}
//                     data-toggle="modal"
//                     data-target="#messagePreview"
//                     messagePreview
//                     className="submit"
//                     type="submit"
//                   >
//                     Submit
//                   </button>
//                 </div>
//                 <br />
//                 <br />
//                 <br />
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div class="modal fade" id="myWhatsapp" role="dialog">
//         <div class="modal-dialog">
//           <div class="modal-content">
//             <div class="ecampaign_modal-header">
//               <div></div>
//               <h4 class="modal-title">Send Whatsapp</h4>
//               <button
//                 type="button"
//                 className="close btn-close"
//                 data-dismiss="modal"
//               ></button>
//             </div>
//             <div class="ecampaign_modal-body">
//               <form onSubmit={handleSubmit}>
//                 <div className="row">
//                   <span id="label">Template</span>
//                 </div>
//                 <div className="row">
//                   <div class="select-toggle" id="select-toggle">
//                     <select
//                       className="select"
//                       value={selectedOption}
//                       onChange={(event) =>
//                         handleSelectChange(event, selectedOption, "world")
//                       }
//                     >
//                       <option value="1272404983119428">Testing Template</option>
//                     </select>
//                   </div>
//                 </div>

//                 <div className="row mt-2">
//                   <span id="label">Sender ID</span>
//                 </div>
//                 <div className="row">
//                   <div class="select-toggle" id="select-toggle">
//                     <select
//                       className="select"
//                       value={selectedValue}
//                       onChange={(event) =>
//                         handleSelectChangeSenderID(
//                           event,
//                           selectedOption,
//                           "world"
//                         )
//                       }
//                     >
//                       <option value="CSFTAI">{headerTemplate}</option>
//                     </select>
//                   </div>
//                 </div>

//                 <div className="row mt-2">
//                   <span id="label">Mobile</span>
//                 </div>
//                 <div className="row" id="buttons_maindiv">
//                   <textarea
//                     className="textAreaMobile"
//                     type="mobile"
//                     id="mobile"
//                     name="mobile"
//                     placeholder="Enter Mobile numbers"
//                     value={selectedCheckboxes}
//                     min="0"
//                     max="100"
//                     step="5"
//                   />
//                   <div className="side_section">
//                     <a
//                       type=""
//                       className="close_side"
//                       onClick={handleClick}
//                       id="close_side"
//                     >
//                       <span id="close_icon">x</span>
//                     </a>
//                     <a class="btn" onClick={handleCount} id="iframe">
//                       <img src={iFrame} />
//                     </a>
//                   </div>
//                 </div>

//                 <div className="row mt-2">
//                   <span id="label" class="msg">
//                     Message
//                   </span>
//                 </div>
//                 <div className="row">
//                   <input
//                     type="text"
//                     name="inputValue"
//                     id="enter_message"
//                     placeholder="Enter Message"
//                     value="Hi $(variable1),  Thank you for registering with us. Your ref. no is $(variable2)"
//                     onChange={handleMessageChange}
//                   />
//                   {inputValueError && (
//                     <div className="error error_msg">{inputValueError}</div>
//                   )}
//                 </div>
//                 <div className="submitdiv_main">
//                   <button
//                     onClick={sendWhatsApp}
//                     className="submit"
//                     type="submit"
//                   >
//                     Submit
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div class="modal fade" id="uploadexcel" role="dialog">
//         <div class="modal-dialog">
//           <div class="modal-content">
//             <div class="ecampaign_modal-header">
//               <div></div>
//               <h4 class="modal-title">Upload Excel</h4>
//               <button
//                 type="button"
//                 className="close btn-close"
//                 data-dismiss="modal"
//               ></button>
//             </div>
//             <div class="ecampaign_modal-body">
//             <div className="App mt-2">
//       <h5>Upload Templates</h5>
//       <div className="mt-3 text-center">
//       <ExcelDataUploader />

//       </div>
//       </div>
//             </div>
//           </div>
//         </div>
//       </div>

      

//       <div class="modal fade" id="phoneCall" role="dialog">
//         <div class="modal-dialog">
//           <div class="modal-content">
//             <div class="ecampaign_modal-header">
//               <div></div>
//               <h4 class="modal-title">Make a Phone Call</h4>
//               <button
//                 type="button"
//                 className="close btn-close"
//                 data-dismiss="modal"
//               ></button>
//             </div>
//             <div class="ecampaign_modal-body">
//               <form onSubmit={handleSubmit}>
//                 <div className="row">
//                   <span id="label">Template</span>
//                 </div>
//                 <div className="row">
//                   <div class="select-toggle" id="select-toggle">
//                     <select
//                       className="select"
//                       value={selectedOption}
//                       onChange={(event) =>
//                         handleSelectChange(event, selectedOption, "world")
//                       }
//                     >
//                       <option value="1272404983119428">Testing Template</option>
//                     </select>
//                   </div>
//                 </div>

//                 <div className="row mt-2">
//                   <span id="label">Sender ID</span>
//                 </div>
//                 <div className="row">
//                   <div class="select-toggle" id="select-toggle">
//                     <select
//                       className="select"
//                       value={selectedValue}
//                       onChange={(event) =>
//                         handleSelectChangeSenderID(
//                           event,
//                           selectedOption,
//                           "world"
//                         )
//                       }
//                     >
//                       <option value="CSFTAI">{headerTemplate}</option>
//                     </select>
//                   </div>
//                 </div>

//                 <div className="row mt-2">
//                   <span id="label">Mobile</span>
//                 </div>
//                 <div className="row" id="buttons_maindiv">
//                   <textarea
//                     className="textAreaMobile"
//                     type="mobile"
//                     id="mobile"
//                     name="mobile"
//                     placeholder="Enter Mobile numbers"
//                     value={selectedCheckboxes}
//                     min="0"
//                     max="100"
//                     step="5"
//                   />
//                   <div className="side_section">
//                     <a
//                       type=""
//                       className="close_side"
//                       onClick={handleClick}
//                       id="close_side"
//                     >
//                       <span id="close_icon">x</span>
//                     </a>
//                     <a class="btn" onClick={handleCount} id="iframe">
//                       <img src={iFrame} />
//                     </a>
//                   </div>
//                 </div>

//                 <div className="row mt-2">
//                   <span id="label" class="msg">
//                     Voice Record
//                   </span>
//                 </div>
//                 <div className="row">
//                   <input
//                     type="file"
//                     name="inputValue"
//                     id="enter_message"
//                     placeholder="Enter Message"
//                     onChange={handleMessageChange}
//                   />
//                   {inputValueError && (
//                     <div className="error error_msg">{inputValueError}</div>
//                   )}
//                 </div>
//                 <div className="submitdiv_main">
//                   <button
//                     onClick={makeaPhoneCall}
//                     className="submit"
//                     type="submit"
//                   >
//                     Submit
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div
//         style={{ backgroundColor: "rgb(0 0 0)", opacity: "0.9" }}
//         class="modal fade"
//         id="messagePreview"
//         role="dialog"
//       >
//         <div class="modal-dialog">
//           <div class="modal-content">
//             <div class="ecampaign_modal-header">
//               <div></div>
//               <h4 class="modal-title">Message Preview</h4>
//               <button
//                 type="button"
//                 className="close btn-close"
//                 data-dismiss="modal"
//               ></button>
//             </div>
//             <div class="ecampaign_modal-body">
//               <div className="row mt-4">
//                 <h6>Message Preview :</h6>
//                 <div className="row mt-4">
//                   <h6>{replacedstringPreview}</h6>
//                 </div>
//                 {/* <textarea 
//                     style={{fontSize:'15px !important',overflow:'auto'}}
//                     type="text"
//                     name="inputValue"
//                     id="enter_message"
//                     placeholder="Enter Message"
//                     value={replacedstringPreview}
//              />             */}
//               </div>
//               <div className="row mt-3">
//                 <span>Message Length : {replacedstringPreview?.length}</span>
//               </div>
//               <div className="row mt-3">
//                 <span>
//                   This length is for first record only for other records message
//                   length depends on used columns of uploaded file.{" "}
//                 </span>
//               </div>
//               <div className="row mt-4">
//                 <span>
//                   Total Phone Number Selected : {selectedItems.length}
//                 </span>
//               </div>
//               <div className="col mt-5 text-center">
//                 <button onClick={sendSMS} className="submit" type="submit">
//                   Send SMS
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <ToastContainer
//         position="top-center"
//         autoClose={5000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="light"
//       />
//       <br />
//       <br />
//       <br />
//       {loadingscreen ? <LoaderWrapper /> : null}
//     </div>
//   );
// };

 export default Ecampaign;
