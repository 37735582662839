import React from "react";
import Modal from "react-bootstrap/Modal";
import callPendingActionIcon from "../Assets/callPendingActionIcon.svg";

import "./Birthday.css"
const BirthdayIndividualModal = (props) => {
    const viewBirthdayItem = props.viewBirthdayItem;
    return (
        <div >
            <Modal.Body className="modal-body-custom" >
                <div className={"birthday_view_main_div"}>
                    <div style={{width: "50%"}} id="first-name_div_">
                        <h6>First Name</h6>
                        <p>{viewBirthdayItem.first_name}</p>
                    </div>
                    <div style={{width: "50%"}} id="lasst-name_div_">
                        <h6>Last Name</h6>
                        <p>{viewBirthdayItem.last_name}</p>
                    </div>
                </div>
                <div className={"birthday_view_main_div"}>
                    <div style={{width: "50%"}} id="person-designation_div_">
                        <h6>Designation</h6>
                        <p>{viewBirthdayItem.designation}</p>
                    </div>
                    <div style={{width: "50%"}} id="person-designation_div_">
                        {/* <h6>Dob</h6>
                        <p>{viewBirthdayItem.dob}</p> */}
                        <h6>Notes</h6>
                        <p>{viewBirthdayItem.notes}</p>
                        {/* <p>He is very important</p> */}
                    </div>
                </div>
                <div className={"birthday_view_main_div"}>
                    <div style={{display:'flex',width: "50%"}} id="call_stauts_view_page">
                        {/* <h6>Email</h6>
                        <p>{viewBirthdayItem.email_id}</p> */}
                        <h6>Call Status</h6>
                        &nbsp; &nbsp;
                        <span>
                        <img
                        className="call_image_button"
                        src={callPendingActionIcon}
                        alt="not_visible"
                        // onClick={() => handleMakeCall(item.phone_no)}
                      />
                        </span>
                         </div>
                    {/* <div style={{width: "50%"}}>
                        <h6>Mobile Number</h6>
                        <p>{viewBirthdayItem.phone_no}</p>
                    </div> */}
                </div>
            </Modal.Body>

        </div>
    )
}

export default BirthdayIndividualModal;