import { urlData } from "../config/apiconfig";
import axiosInstance from "./AxiosInstance";

const url = process.env.REACT_APP_SERVER_CONFIG;

export async function newvotersDropdown() {
  try {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const response = await axiosInstance.get(`${urlData.newvotersdropdownendpoint}`);
    return response.data;
  } catch (error) {
    throw error
  }

}

export async function addvoter(
  selectedMandaldropdown,
  selectedgender,
  selectedRelationtype,
  selectedVillagedropdown,
  selectedpollingbootid,
  selectedSubcaste,
  selectedState,
  selectedreligion,
  selectedvotertype,
  constituencyvaluenewvoters,
  selectedVotfavour,
  voterid,
  title,
  firstName,
  middleName,
  lastName,
  age,
  mobile,
  email,
  designation,
  houseno,
  street,
  city,
  postalcode,
  sectionname,
  relationname,
  revenuedivision,
  companyname,
  selectedDateofbirth,
  paramxx,
  paramHH,
  paramLOC,
  paramipvid,
  paramipname,
  paramipph,
  paramrefname,
  paramrefvid,
  paramrefph,
  paramnotes,
  paramcount,
  paramsoccupation,
  paramseducation
) {
  try {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const addData = {
      voter_id: voterid === "" ? null : voterid,
      title: title === "" ? null : title,
      first_name: firstName === "" ? null : firstName,
      middle_name: middleName === "" ? null : middleName,
      last_name: lastName === "" ? null : lastName,
      gender: selectedgender === undefined ? null : `${selectedgender}`,
      dob: selectedDateofbirth === "" ? null : selectedDateofbirth,
      age: age,
      phone_no: mobile,
      email_id: email === "" ? null : email,
      sub_caste: selectedSubcaste === undefined ? null : selectedSubcaste,
      constituency: constituencyvaluenewvoters === "" ? null : constituencyvaluenewvoters,
      company_name: companyname === "" ? null : companyname,
      mandal: selectedMandaldropdown === undefined ? null : selectedMandaldropdown,
      ward_village: selectedVillagedropdown === undefined ? null : selectedVillagedropdown,
      voter_type: selectedvotertype === undefined ? null : `${selectedvotertype}`,
      voter_favour_to: selectedVotfavour === undefined ? null : selectedVotfavour,
      polling_booth: selectedpollingbootid === "" ? null : selectedpollingbootid,
      designation: designation === "" ? null : designation,
      relation_type: selectedRelationtype === undefined ? null : selectedMandaldropdown,
      relation_name: relationname === "" ? null : relationname,
      religion: selectedreligion === undefined ? null : selectedreligion,
      house_no_name: houseno === "" ? null : houseno,
      street: street === "" ? null : street,
      revenue_division: revenuedivision === "" ? null : revenuedivision,
      section_name: sectionname === "" ? null : sectionname,
      city: city === "" ? null : city,
      state: selectedState === undefined ? null : selectedState,
      postalcode: postalcode,
      country: 1,
      notes: paramnotes === "" ? null : paramnotes,
      house_head: paramHH === "" ? null : paramHH,
      ip_vid: paramipvid === "" ? null : paramipvid,
      ip_name: paramipname === "" ? null : paramipname,
      ip_ph: paramipph === "" ? null : paramipph,
      ref_vid: paramrefvid === "" ? null : paramrefvid,
      ref_name: paramrefname === "" ? null : paramrefname,
      ref_ph: paramrefph === "" ? null : paramrefph,
      xx: paramxx === "" ? null : paramxx,
      curr_loc: paramLOC === "" ? null : paramLOC,
      voters_count: paramcount === "" ? null : paramcount,
      occupation: paramsoccupation === "" ? null : paramsoccupation,
      education: paramseducation === "" ? null : paramseducation,
    }


    const response = await axiosInstance.post('/dashboard/addvoters/', addData);
    return { status: true, ...response.data };
    // return response.data;
  } catch (error) {
    throw error
  }


}

export async function constituencynewvoters() {
  try {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const response = await axiosInstance.get(`${urlData.constituencyDashboardAPI}`);
    return response.data;
  } catch (error) {
    throw error
  }
}


export async function newvotersMandals(constituencyvaluenewvoters) {
  try {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const constituencies = constituencyvaluenewvoters ? `?constituency_id=${constituencyvaluenewvoters}` : ''
    const response = await axiosInstance.get(`${urlData.constituencyDashboardAPI}${constituencies ? constituencies : ''}`);
    return response.data;
  } catch (error) {
    throw error
  }
}

export async function newvotersVillages(selectedMandaldropdown) {
  try {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const mandals = selectedMandaldropdown ? `?mandal_id=${selectedMandaldropdown}` : '';
    const response = await axiosInstance.get(`${urlData.constituencyDashboardAPI}${mandals ? mandals : ''}`);
    return response.data;
  } catch (error) {
    throw error
  }
}

export async function newvotersPollingstations(selectedVillagedropdown) {
  try {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const villages = selectedVillagedropdown ? `?ward_village_id=${selectedVillagedropdown}` : ''
    const response = await axiosInstance.get(`${urlData.constituencyDashboardAPI}${villages ? villages : ''}`);
    return response.data;
  } catch (error) {
    throw error
  }
}

export async function subCasteDropdown(selectedCaste) {
  try {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const response = await axiosInstance.get(`${urlData.newvotersdropdownendpoint}?caste_id=${selectedCaste}`);
    return response.data;
  } catch (error) {
    throw error
  }
}

export async function checkvoterId(voterid) {
  try{
    const response = await axiosInstance.get(`${urlData.checkvoters}${voterid}`);
    return response.data;
  }catch(error){
    throw error
  }
}

// patch call method

export async function updatevoterData(
  selectedMandaldropdown,
  selectedgender,
  selectedRelationtype,
  selectedVillagedropdown,
  selectedpollingbootid,
  selectedSubcaste,
  selectedState,
  selectedreligion,
  selectedvotertype,
  constituencyvaluenewvoters,
  selectedVotfavour,
  voterid,
  title,
  firstName,
  middleName,
  lastName,
  age,
  mobile,
  email,
  designation,
  houseno,
  street,
  city,
  postalcode,
  sectionname,
  relationname,
  revenuedivision,
  companyname,
  selectedDateofbirth,
  paramxx,
  paramHH,
  paramLOC,
  paramipvid,
  paramipname,
  paramipph,
  paramrefname,
  paramrefvid,
  paramrefph,
  paramnotes,
  paramcount,
  paramsoccupation,
  paramseducation
) {
  try{
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const updateData = {
      voter_id: `${voterid}`,
      title: title === "null" ? null : title,
      first_name: firstName == "null" ? null : firstName,
      middle_name: middleName === "null" ? null : middleName,
      last_name: lastName === "null" ? null : lastName,
      gender: selectedgender === "null" ? null : selectedgender,
      dob: selectedDateofbirth === "null" ? null : selectedDateofbirth,
      age: age === "null" ? null : age,
      phone_no: mobile === "null" ? null : mobile,
      email_id: email === "null" ? null : email,
      sub_caste: selectedSubcaste === "null" ? null : selectedSubcaste,
      constituency:
        constituencyvaluenewvoters === "null"
          ? null
          : constituencyvaluenewvoters,
      company_name: companyname === "null" ? null : companyname,
      mandal: selectedMandaldropdown === "null" ? null : selectedMandaldropdown,
      ward_village:
        selectedVillagedropdown === "null" ? null : selectedVillagedropdown,
      voter_type: selectedvotertype === "null" ? null : selectedvotertype,
      voter_favour_to: selectedVotfavour === "null" ? null : selectedVotfavour,
      polling_booth:
        selectedpollingbootid === "null" ? null : selectedpollingbootid,
      designation: designation === "null" ? null : designation,
      relation_type:
        selectedRelationtype === "null" ? null : selectedRelationtype,
      relation_name: relationname === "null" ? null : relationname,
      religion: selectedreligion === "null" ? null : selectedreligion,
      house_no_name: houseno === "null" ? null : houseno,
      street: street === "null" ? null : street,
      revenue_division: revenuedivision === "null" ? null : revenuedivision,
      section_name: sectionname === "null" ? null : sectionname,
      city: city === "null" ? null : city,
      state: selectedState === "null" ? null : selectedState,
      postalcode: postalcode === "null" ? null : postalcode,
      country: 1,
      notes: paramnotes === "null" ? null : paramnotes,
      house_head: paramHH === "null" ? null : paramHH,
      ip_vid: paramipvid === "null" ? null : paramipvid,
      ip_name: paramipname === "null" ? null : paramipname,
      ip_ph: paramipph === "null" ? null : paramipph,
      ref_vid: paramrefvid === "null" ? null : paramrefvid,
      ref_name: paramrefname === "null" ? null : paramrefname,
      ref_ph: paramrefph === "null" ? null : paramrefph,
      xx: paramxx === "null" ? null : paramxx,
      curr_loc: paramLOC === "null" ? null : paramLOC,
      voters_count: paramcount === "null" ? null : paramcount,
      occupation: paramsoccupation === "" ? null : paramsoccupation,
      education: paramseducation === "" ? null : paramseducation,
    }
  
    const response = await axiosInstance.patch('/dashboard/addvoters/', updateData);
    return response.data;
  }catch(error){
    throw error
  }

}

