import { urlData } from "../config/apiconfig";
import axios from "axios";
import axiosInstance from "./AxiosInstance";

// const url = "http://65.0.54.74:8000";
// const url = "https://api.itoconnect.online";
// const url = "https://test-api.itoconnect.online";

import React from "react";
import { encode, decode } from "base-64"; // Import the Base64 encode function

const url = process.env.REACT_APP_SERVER_CONFIG;
const cookieUrl = process.env.REACT_APP_COOKIE_CONFIG;
// const cookieUrl = "http://localhost:3000/"; // comment this before pushing to dev
const emailkey = "ufj2mjfrpz35rnz8i78f";
const passwordkey = "c84t7p4pjt4w5clenxiloc1mu";

export async function refreshToken() {
  let accessData = JSON.parse(localStorage.getItem("data"));
  if (accessData && accessData.refresh) {
    let data = {
      refresh: accessData.refresh,
    };
    try {
      let response = await axios.post(`${url}${urlData.refreshToken}`, data);
      // let response = await axiosInstance.post(`${urlData.refreshToken}`, data)

      if (response.data && response.data.access) {
        accessData["token"] = response.data.access;
        accessData["access"] = response.data.access;
        localStorage.setItem("data", JSON.stringify(accessData));
        return true;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = "/login";
      }
      return false;
    }
  } else {
    window.location.href = "/login";
  }
}

export async function loginUser(email, password, isRememberMeChecked) {
  const encodedEmail = encode(email);
  const encodedPassword = encode(password);
  const encodeemailkey = encode(emailkey);
  const encodepasswordkey = encode(passwordkey);
  const emailencrypted = `${encodedEmail}${encodeemailkey}`;
  const passwordencrypted = `${encodedPassword}${encodepasswordkey}`;


  const response = await fetch(`${url}${urlData.signIn}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: emailencrypted,
      password: passwordencrypted,
    }),
  });

  const data = await response.json();
  if (data.status) {
    if (data.access) {
      data["token"] = data.access;
    }

    if (isRememberMeChecked) {
      document.cookie = `emailid=${encodeURIComponent(
        encodedEmail
      )};path=${cookieUrl}`;
      document.cookie = `password=${encodeURIComponent(
        encodedPassword
      )};path=${cookieUrl}`;
    }
    localStorage.setItem("data", JSON.stringify(data));
    window.location.href = "/";
  }
  return data;
}

export async function templates() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get("/sms/dltdata/");
  return response.data;
}

export async function finaltemplates() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get("/sms/getcampaigns/");
  return response.data;
}

export async function getcampData(item) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `/sms/campaigndetails/?campaign_id=${item}`
  );
  return response.data;
}

export async function dashboardMasterData() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.constituencyDashboardAPI}`
  );
  return response.data;
}

export async function dashboardConstituencyVotersData() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.allConstituencyVoterDashboardAPI}`
  );
  return response.data;
}

export async function constituencyCasteData() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.casteAtConstituencyDashboardAPI}`
  );
  return response.data;
}

export async function favourToConstituencyData() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.favourConstituencyDashboardAPI}`
  );
  return response.data;

}

// venkat code

export async function DashboardApi() {
  try {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const response = await axiosInstance.get(
      `${urlData.constituencyDashboardAPI}`
    );
    return response.data;
  } catch (error) {
    throw error
  }

}


export async function constituencyDashboardApi(id) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const dashboard = await axiosInstance.get(
    `${urlData.mpConstituencyDashboardAPI}${id}`
  );
  return dashboard.data;

}

export async function mandalData(selectedConstituencyId) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const dashboard = await axiosInstance.get(
    `${urlData.mpConstituencyDashboardAPI}${selectedConstituencyId}`
  );
}

//filter village data dashboard//finished
export async function villageData(selectedmandal) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const dashboard = await axiosInstance.get(
    `${urlData.mandalDashboardAPI}${selectedmandal}`
  );
  return dashboard.data;
}

//finished
export async function pollingStation(selectedvillage) {

  const tokenData = JSON.parse(localStorage.getItem("data"));
  const polling = await axiosInstance.get(
    `${urlData.wardVillageDashboardAPI}${selectedvillage}`
  );
  return polling.data;
}
//male,female,total,others api
export async function constituencyVoterFilterData(selectedconstituencies, selectedmandals, selectedvillages, selectedpollingstations) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
   const role = tokenData.user_data.role;
  try {
    const constituencies = role == 'MP'?`constituency_id=${selectedconstituencies}`:''
    const mandal = selectedmandals.length > 0 ? `&mandal=${selectedmandals}` : ''
    const village = selectedvillages.length > 0 ? `&ward_village=${selectedvillages}` : ''
    const pollings = selectedpollingstations.length > 0 ? `&polling_booth=${selectedpollingstations}` : ''
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const response = await axiosInstance.get(
      `${urlData.constituencyMPVoterDashboardAPI}${constituencies}${mandal}${village}${pollings}`
    );
    return response.data;
  }
  catch (error) {
    throw error
  }
}

export async function mandalVoterFilterData(
  selectConstituencyId,
  selectedmandal
) {
  try {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const response = await axiosInstance.get(
      `${urlData.constituencyMPVoterDashboardAPI}${selectConstituencyId}&mandal=${selectedmandal}`
    );
    return response.data;
  }
  catch (error) {
    throw error
  }
}

export async function casteConstituencyVoterFilterData(
  constituencyId,
  selectedmandal
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.casteAtMPConstituencyDashboardAPI}${constituencyId}`
  );
  return response.data;

}

export async function casteMandalVoterFilterData(
  constituencyId,
  selectedmandal
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.casteAtMPConstituencyDashboardAPI}${constituencyId}&mandal=${selectedmandal}`
  );
  return response.data;
}

export async function favourConstituencyVoterFilterData(
  selectedConstituencyId
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.favourMPConstituencyDashboardAPI}${selectedConstituencyId}`
  );
  return response.data;
}

export async function favourMandalVoterFilterData(
  selectedConstituencyId,
  selectedmandal
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.favourMPConstituencyDashboardAPI}${selectedConstituencyId}&mandal=${selectedmandal}`
  );
  return response.data;
}

export async function villageVoterFilterData(
  selectConstituencyId,
  selectedmandal,
  selectedvillage
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.constituencyMPVoterDashboardAPI}${selectConstituencyId}&mandal=${selectedmandal}&ward_village=${selectedvillage}`
  );
  return response.data;
}

export async function casteVillageVoterFilterData(
  selectedconstituency,
  selectedmandal,
  selectedvillage
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.casteAtMPConstituencyDashboardAPI}${selectedconstituency}&mandal=${selectedmandal}&ward_village=${selectedvillage}`
  );
  return response.data;
}

export async function favourVillageVoterFilterData(
  selectedconstituencies, selectedmandals, selectedvillages,selectedpollingstations
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const role = tokenData.user_data.role;
  try {
    const constituencies = role == 'MP'?`constituency_id=${selectedconstituencies}`:'';
    const mandal = selectedmandals.length > 0 ? `&mandal=${selectedmandals}` : '';
    const village = selectedvillages.length > 0 ? `&ward_village=${selectedvillages}` : '';
    const pollings = selectedpollingstations.length > 0 ? `&polling_booth=${selectedpollingstations}` : '';
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const response = await axiosInstance.get(
      `${urlData.favourMPConstituencyDashboardAPI}${constituencies}${mandal}${village}${pollings}`
    );
    return response.data;
  } catch (error) {
    throw error
  }

}

export async function pollingVoterFilterData(
  selectConstituencyId,
  selectedmandal,
  selectedvillage,
  selectedPollingStation
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const url =
    selectedmandal.length > 0
      ? `${urlData.constituencyMPVoterDashboardAPI}${selectConstituencyId}&mandal=${selectedmandal}&ward_village=${selectedvillage}&polling_booth=${selectedPollingStation}`
      : `${urlData.constituencyMPVoterDashboardAPI}${selectConstituencyId}&polling_booth=${selectedPollingStation}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function castePollingVoterFilterData(
  selectedconstituencies, selectedmandals, selectedvillages, selectedpollingstations
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
   const role = tokenData.user_data.role;
  try {
    const constituencies = role == 'MP'?`constituency_id=${selectedconstituencies}`:''
    const mandal = selectedmandals.length > 0 ? `&mandal=${selectedmandals}` : ''
    const village = selectedvillages.length > 0 ? `&ward_village=${selectedvillages}` : ''
    const pollings = selectedpollingstations.length > 0 ? `&polling_booth=${selectedpollingstations}` : ''
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const url = `${urlData.casteAtMPConstituencyDashboardAPI}${constituencies}${mandal}${village}${pollings}`
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error
  }

}

export async function favourPollingVoterFilterData(
  selectConstituencyId,
  selectedmandal,
  selectedvillage,
  selectedPollingStation
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const url =
    selectedmandal.length > 0
      ? `${urlData.favourMPConstituencyDashboardAPI}${selectConstituencyId}&mandal=${selectedmandal}&ward_village=${selectedvillage}&polling_booth=${selectedPollingStation}`
      : `${urlData.favourMPConstituencyDashboardAPI}${selectConstituencyId}&polling_booth=${selectedPollingStation}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

//completed
export async function importantpeople() {
  try {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const polling = await axiosInstance.get(`${urlData.impPeopleDashboardAPI}`);
    return polling.data;
  } catch (error) {
    throw error;
  }

}
export async function getwardissues(selectedconstituencies, selectedmandals, selectedvillages) {
  try {
    const constituencies = selectedconstituencies.length > 0 ? `?constituency_id=${selectedconstituencies}` : ''
    const mandal = selectedmandals.length > 0 ? `&mandal=${selectedmandals}` : ''
    const village = selectedvillages.length > 0 ? `&ward_village=${selectedvillages}` : ''
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const resp = await axiosInstance.get(`${urlData.wradvillagegetissues}${constituencies}${mandal}${village}`)
    return resp.data;
  } catch (error) {
    throw error
  }

}

export async function todopostDashboard(eventtodo) {
  // const tokenData = JSON.parse(localStorage.getItem("data"));
  const tododata = { todo_title: `${eventtodo}` };
  const response = await axiosInstance.post(
    `${urlData.toDoDashboardAPI}`,
    tododata
  );
  return response.data;
}

export async function sendBulkSMSITO(smsDataMobile, smsMessage) {
  const tokenData = JSON.parse(localStorage.getItem("data"));

  const response = await fetch(
    `https://api.itoconnect.online/sms/sendbulksms`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        users: "C360soft",
        keys: "4566b49e8aXX",
        contacts: "+917411484910",
        content:
          "Hello Abhilash Welcome to iToconnect Setting your password is easy. Just press the button below and follow the instructions. We/'ll have you up and running in no time. 77 If you did not make this request then please ignore this sms. Thanks, CSFTAI",
        senderids: "CSFTAI",
        templates: "1707167844054150447",
      }),
    }
  );

  const responseData = response.json();
  return responseData;
}

export async function sendBulkSMS(smsDataMobile, smsMessage) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await fetch(
    `https://smslogin.co/v3/api.php?username=c360sft&apikey=8b64819ce753a1260163&senderid=CSFTAI&mobile=${smsDataMobile}&message=${smsMessage}`,
    {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${tokenData.token}`,
      },
    }
  );
  const postdata = response.json();
  return postdata;
}

export async function todogetDashboard() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(`${urlData.toDoDashboardAPI}`);
  return response.data;
}

export async function tododeleteDashboard(deletetodo) {
  // const tokenData = JSON.parse(localStorage.getItem("data"));

  const response = await axiosInstance.delete(
    `${urlData.toDoDashboardAPI}?id=${deletetodo}`
  );
  return response.data;
}

export async function logout() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(`${urlData.signout}`);
  return response.data;
}

// ********* signup ***************//

export async function signUp(formData) {
  const response = await fetch(`${url}${urlData.signUp}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(formData),
  });

  const responseData = response.json();
  return responseData;
}
// ********* verify otp ***************//

export async function verifyOtp(otpData) {
  const response = await axiosInstance.post(`${urlData.verifyOtp}`, otpData);
  return response.data;
}

//*********** Resend OTP  ************** */
export async function resendOtp(otpmail) {
  const response = await axiosInstance.get(`${urlData.resendOtp}${otpmail}`);
  return response.data;
}

//************ get State and role data for signup  ************* */

export async function getStateAndRoleData(otpmail) {
  const response = await fetch(`${url}${urlData.getStateAndRoleDataSignup}`);
  const responseData = response.json();
  return responseData;
}

//************ Get district data   ************* */

export async function getDistrictData(stateId) {
  const response = await fetch(
    `${url}${urlData.getDistrictDataSignup}${stateId}`
  );
  const responseData = response.json();
  return responseData;
}

//************ Get Constituency data for MLA/MP role  ************* */

export async function getConstituencyDataForMLAorMP(roleId, districtId) {
  const response = await fetch(
    `${url}/dropdown/?user_type_id=${roleId}&dist_id=${districtId}`
  );
  const responseData = response.json();
  return responseData;
}

//******************Create Password ************************* */
export async function createNewPassword(updatedbearertoken, createPassword) {
  const passwordencode = encode(passwordkey);
  const currentpassword = encode(createPassword.password);
  const confirmcurrentpassword = `${currentpassword}${passwordencode}`;
  const confirmpassword = encode(createPassword.ConfirmPassword);
  const confirmpassowrdencoded = `${confirmpassword}${passwordencode}`;

  const response = await fetch(`${url}${urlData.setPassword}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "origin-when-cross-origin", // Use a compatible policy
    body: JSON.stringify({
      password: confirmcurrentpassword,
      ConfirmPassword: confirmpassowrdencoded,
      Token: updatedbearertoken,
    }),
  });
  const responseData = response.json();
  return responseData;
}

// ****************Forgot Password *********************/

export async function forgotPassword(resetPassword) {
  try {
    const response = await axiosInstance.get(
      `${urlData.forgotPassword}${resetPassword}`
    );
    return response.data;
  } catch (error) {
  }
}

// ******************* Roles Page ***********************

export class RoleDataService {
  constructor() {
    if (this.tokenData) {
      this.headerConfig = {
        headers: {
          Authorization: "Bearer " + this.tokenData.token,
        },
      };
    }
  }

  tokenData = JSON.parse(localStorage.getItem("data"));
  headerConfig = {};
  serverURL = process.env.REACT_APP_SERVER_CONFIG;
  // serverURL = process.env.REACT_APP_TEST_SERVER_CONFIG;
  getRolesURL = this.serverURL + urlData.roles.getRoles;
  getAllRolesURL = this.serverURL + urlData.roles.getAllRoles;
  changeRoleStatusURL = this.serverURL + urlData.roles.changeRoleStatus;
  addOrUpdateRolesURL = this.serverURL + urlData.roles.addRoles;
  getPermissionsURL = this.serverURL + urlData.roles.getPermissions;
  getScreensURL = this.serverURL + urlData.roles.getScreens;

  // const response = await axiosInstance.get('/sms/dltdata');
  // return response.data;
  getRoles(pageNo) {
    return axios.get(this.getRolesURL + pageNo, this.headerConfig);
  }

  getAllRoles() {
    return axios.get(this.getAllRolesURL, this.headerConfig);
  }

  changeStatus(body) {
    return axios.post(this.changeRoleStatusURL, body, this.headerConfig);
  }

  getPermissions(role) {
    return axios.get(this.getPermissionsURL + role, this.headerConfig);
  }

  addRole(data) {
    return axios.post(this.addOrUpdateRolesURL, data, this.headerConfig);
  }

  editRole(data) {
    return axios.patch(this.addOrUpdateRolesURL, data, this.headerConfig);
  }

  getScreens() {
    return axios.get(this.getScreensURL, this.headerConfig);
  }
}

// ****************change password **************

export async function passwrodChange(oldpassword, newpassword) {
  // const tokenData = JSON.parse(localStorage.getItem("data"));
  const oldpasswordencode = encode(oldpassword);
  const newpasswordencode = encode(newpassword);
  const encodenewpasswordkey = encode(passwordkey);

  const changepasswordData = {
    old_password: `${oldpasswordencode}${encodenewpasswordkey}`,
    new_password: `${newpasswordencode}${encodenewpasswordkey}`,
  };

  const response = await axiosInstance.post(
    `${urlData.changepassword}`,
    changepasswordData
  );
  return response.data;
}

/*****Reports Page */
export class ReportsDataService {
  constructor() {
    if (this.tokenData) {
      this.headerConfig = {
        headers: {
          Authorization: "Bearer " + this.tokenData.token,
        },
      };
    }
  }

  tokenData = JSON.parse(localStorage.getItem("data"));
  headerConfig = {};
  serverURL = process.env.REACT_APP_SERVER_CONFIG;
  getS3ReportsURL = this.serverURL + urlData.reports.getS3Reports;
  getReportsURL = this.serverURL + urlData.reports.getReports;
  getReportsByKeyURL = this.serverURL + urlData.reports.getReportByKey;
  updateconstreportsURL = this.serverURL + urlData.reports.updateconstreports;
  updatepollreportsURL = this.serverURL + urlData.reports.updatepollreports;
  checkStatusURL = this.serverURL + urlData.dasboard.checkstatus;
  activeTasksURL = this.serverURL + urlData.dasboard.activeTasks;
  getS3Reports() {
    return axios.get(this.getS3ReportsURL, this.headerConfig);
  }
  getReports() {
    return axios.post(this.getReportsURL, {}, this.headerConfig);
    // return axiosInstance.get();
  }
  getReportsByKey(key) {
    return axios.get(this.getReportsByKeyURL + key, this.headerConfig);
  }
  updatereports(isPolling) {
    if (isPolling) {
      return this.updatepollreports();
    } else {
      return this.updateconstreports();
    }
  }
  updateconstreports() {
    return axios.get(this.updateconstreportsURL, this.headerConfig);
  }
  updatepollreports() {
    return axios.get(this.updatepollreportsURL, this.headerConfig);
  }
  checkstatus(key) {
    return axios.get(this.checkStatusURL + key, this.headerConfig);
  }
  getActiveTasks() {
    return axios.get(this.activeTasksURL, this.headerConfig);
  }
  getBlobFile(url) {
    let headersConfig = {
      headers: {
        // Authorization: "Bearer " + this.tokenData.token,
        responseType: "blob",
      },
    };
    return axios.get(url, headersConfig);
  }
}

export async function profilePic(croppedimageName) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await fetch(
    `${url}${urlData.profilePicendpoint}?file=${croppedimageName}`,
    {
      headers: {
        Authorization: `Bearer ${tokenData.token}`,
      },
    }
  );
  const responseData = response.json();
  return responseData;
}

export async function getProfilepic(selectedconstituencies, selectedmandals, selectedvillages, selectedpollingstations) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(`${urlData.getprofileendpoint}`);
  return response.data;
}
