import React, { useEffect, useState } from 'react'
import './Usermanagement.css'
import AddUser from '../../Assets/add user.png'
import { activeUser, deleteUser, userManagementdata } from '../../Service/Usermanagemen.service';
import { urlData } from '../../config/apiconfig';
import polygon from "../../Assets/Polygonvotersdata.png";
import Addusers from "./Addusers";
import Assignvoter from './Assignvoter';
import LoaderWrapper from '../../components/LoaderWrapper';

function Usermanagement() {
  const headings = [
    "Voter ID Number",
    "First Name",
    "Last Name",
    "Email",
    "Mobile number",
    "Role",
    "Assigned Voters",
    "Action",
  ];
  const [loadData, setLoadData] = useState([]);
  const [pageno, setPageno] = useState(1);
  const [pagecount, setpagecount] = useState()
  const [edit, setEdit] = useState()
  const [openpopup, setOpenpopup] = useState(false)
  const [openaspopup, setOpenaspopup] = useState(false)
  const [module, setModule] = useState(null);
  const [voteridvalue, setVoteridValue] = useState(null)
  const [activedelete, setActiveDelete] = useState(false)
  const [loadingscreen, setLoadingscreen] = useState(true)
  const [showAssignVoter, setShowAssignVoter] = useState(false);
  const [selectedVoterData, setSelectedVoterData] = useState(null); // Initialize to null or an appropriate initial value
  const [modulevalue, setmodulevalue] = useState(null)


  useEffect(() => {
    const pageurl = `${urlData.getsubusers}?page=${pageno}`;
    userManagementdata(pageurl).then((resp) => {
      if (resp.results) {
        setLoadData(resp.results);
        const recordcount = resp.count;
        setpagecount(Math.ceil(recordcount / 50))
        setLoadingscreen(false)
      }
    });
  }, [pageno, activedelete])


  const handleEditclicked = (voterId) => {
    setModule("Edit");
    setVoteridValue(voterId);
    setOpenpopup(!openpopup);
  };
  const handleDeleteclicked = (userId) => {
    deleteUser(userId).then((resp) => {
      setActiveDelete(!activedelete)
    })
  }
  const handleActiveclicked = (userId) => {
    activeUser(userId).then((resp) => {
      setActiveDelete(!activedelete)
    })
  }
  const handleViewclicked = (voterId) => {
    setModule("View");
    setVoteridValue(voterId);
    setOpenpopup(!openpopup);
  };
  const handleopenPopup = () => {
    setOpenpopup(!openpopup);
  };
  const handleopenasPopup = () => {
    setOpenaspopup(!openaspopup);
  };
  const handleCloseAddusers = () => {
    setOpenpopup(false);
    setOpenaspopup(false);
    setModule(null);
  };

  const handleAssignvoter = (item) => {
    setmodulevalue('Edit');
    setSelectedVoterData(item);
    setOpenaspopup(!openaspopup);
  }
  const handleAssignvoterClose = () => {
    setOpenaspopup(!openaspopup)
  };
  return (
    <div className="usermanagement_main_div">
      {
        loadingscreen ? <LoaderWrapper /> : null
      }

      <div className="user_management_dashboard-main_div d-flex align-items-center">
        <span id="user_managemnt_title"> User Managment</span>
      </div>
      <div
        className="user_management_left_buttons"
        id="usermanagements_buttons"
      >
        <div id="we">
          <button class="user_management_assingn_voter " onClick={handleopenasPopup}
          >Assign Voters</button>
        </div>
        <div id="ad">
          <button class="user_management_add_user" onClick={handleopenPopup}>
            Add User
          </button>
        </div>
        {/* <button class="user_management_add_user" onClick={handleopenPopup}>Add User</button> */}
      </div>
      <div>
        <div className="parent_horizontal_scroll_user_managementdata">
          <div className="horizontal_scroll_user_managementdata">
            <div className="data_main_div_usermanagement d-flex">
              {headings.map((item) => {
                return (
                  <div className="heading_div_userManagement_data">
                    <span id="usermanagement_title">{item}</span>
                  </div>
                );
              })}
            </div>
            {
              loadData.length > 0 ? (
                loadData.map((item) => {
                  return (
                    <div className="d-flex usermanagement_data_holder_div">
                      <div className="d-flex flex-column usermanagement_data_holder_sub_div">
                        <div className="heading_div_userManagement_data_holder"
                          onClick={() => handleAssignvoter(item)}>
                          <span
                            className="user_data_text_div"
                            id="usermanagement_title_bodydata"
                          >
                            {item.voter_id}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column usermanagement_data_holder_sub_div">
                        <div className="heading_div_userManagement_data_holder">
                          <span className="user_data_text_div">
                            {item.first_name}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column usermanagement_data_holder_sub_div">
                        <div className="heading_div_userManagement_data_holder">
                          <span className="user_data_text_div">
                            {item.last_name}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column usermanagement_data_holder_sub_div">
                        <div
                          className="heading_div_userManagement_data_holder"
                          id="item_userman_email"
                        >
                          <span className="user_data_text_div">
                            {item.email_id}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column usermanagement_data_holder_sub_div">
                        <div className="heading_div_userManagement_data_holder">
                          <span className="user_data_text_div">
                            {item.phone_no}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column usermanagement_data_holder_sub_div">
                        <div className="heading_div_userManagement_data_holder">
                          <span className="user_data_text_div">
                            {item.role__role_name}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column usermanagement_data_holder_sub_div">
                        <div className="heading_div_userManagement_data_holder">
                          <span className="user_data_text_div">
                            {item.assigned_voters}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column usermanagement_data_holder_sub_div">
                        <div className="heading_div_userManagement_data_holder ">
                          <span
                            className="usermanagement_edit"
                            onClick={() => handleEditclicked(item)}
                          >
                            Edit |
                          </span>
                          <span className='heading_div_userManagement_view' onClick={() => handleViewclicked(item)} >View</span>
                          {item.is_active == 1 ? <button className='heading_div_userManagement_dltbutton' onClick={() => handleDeleteclicked(item.user_id)}>
                            InActive
                          </button> : <button onClick={() => handleActiveclicked(item.user_id)} className='heading_div_userManagement_activebutton'>
                            Active
                          </button>}
                        </div>
                      </div>
                    </div>
                  );
                }
                )
              ) : <p className="nodata_filters_data">No Data Found</p>
            }

          </div>

        </div>
      </div>
      <div className='user_management_underlines_holder_main'>
        <div className="user_management_underlines_holder">
          <div className="user_management_underline_one"></div>
          <div className="user_management_underline_one"></div>
        </div>

        <div className="d-flex align-items-center buttons_usermanagement_next_prev_main_div ">
          <div id="usermanagement" className="total_pages_usermanagement">
            Pages : {pagecount}
          </div>
          <button className="prev_button_usermanagement" disabled={pageno == 1 ? true : false} onClick={() => setPageno(pageno - 1)}>Prev</button>
          <div className="current_page_number_usermanagement">{pageno}</div>
          <button className="next_button_usermanagement" disabled={pageno == pagecount ? true : false} onClick={() => setPageno(pageno + 1)}>Next</button>
        </div>
      </div>
      {
        openpopup ?
          <Addusers handleClosed={handleCloseAddusers} moduletype={module} voteridvar={voteridvalue} /> : null
      }
      {
        openaspopup ?
          <Assignvoter handleClosed={handleAssignvoterClose}
            selectedVoterData={selectedVoterData} modulevalue={modulevalue} voteridvar={voteridvalue} /> : null
      }
    </div>
  );
}

export default Usermanagement;
