import React, { createRef, useContext, Suspense, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Stack, Button } from "react-bootstrap";
import StepProgressComponent from "../StepProgressComponent/StepProgressComponent";
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import greenTick from '../../../Assets/GreenTick.svg';
import './ImportSummaryComponent.scss'
import { ImportDataContext } from "../../../Pages/ImportData/ImportDataContext";
import LoaderWrapper from "../../LoaderWrapper";

function ImportSummaryComponent() {
  const { importDataService } = useContext(ImportDataContext);
  const [enableNextButton, setEnableNextButton] = useState(true);
  const [showModel, setShowModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [summaryData, setSummaryData] = useState({
    fileName: '',
    mappedFields: '',
    unMappedFields: ''
  })
  // const fileName = importDataService
  const navigation = useNavigate();
  

  useEffect(() => {
    getSummaryData();
    // jsonPayload = constructRequestPayload();
  }, []);

  const constructRequestPayload = () => {
    let _data = { importSelection : {}, importData: {}, batchId: ''};
    _data.importSelection = importDataService.importDataSelected;
    _data.importData = importDataService.getVoterDataByImportType();
    _data.batchId = importDataService.jobID;
    return _data;
  }

  const jsonPayload = useMemo(() => constructRequestPayload(), []);
  const getSummaryData = () => {
    let _summaryData = {
      fileName: '',
      mappedFields: '',
      unMappedFields: ''
    };
    _summaryData.fileName = importDataService.fileUploaded;
    _summaryData.mappedFields = importDataService.importDataSelected.mappingData.filter( x => x.mappedField != "").length;
    _summaryData.unMappedFields = importDataService.importDataSelected.mappingData.filter( x => x.mappedField == "").length;
    setSummaryData(_summaryData);
  }

  const saveImportedData = () => {
    setIsLoading(true);
    setShowError(false);
    try {
      importDataService.saveImportedData(jsonPayload).then( result => {
        if( result.data ) {
          setIsLoading(false);
          setShowModel(true);
        }
      });
    } catch(error) {
      setIsLoading(false);
      setShowError(true);
    }

  }

  const downloadJsonFile = () => {
    const json = JSON.stringify(jsonPayload);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  const dataUploadSuccess = () => {
    // write code to erase data
    navigation('../importBatch');
  }
  const cancelUpload = () => {
    navigation('../')
  }

  const previousStep = () => {
    navigation('../viewData')
  }

  const nextStep = () => {
    saveImportedData();
  }
  return (
    <>
      <div className="import-upload-component ems-text">
        {
          isLoading ? (
            <LoaderWrapper />
          ) : null
        }
        <Row>
          <StepProgressComponent step={2} />
        </Row>
        <div className="title-section">
          <div className="title">Review & Start Import</div>
          <div className="sub-title">Review your <span onClick={downloadJsonFile}>import information</span> and click Start import</div>
        </div>
        {showError ? <div className="text-danger">
                  {`Data upload Failed, Please try again.`}
              </div> : ''}
        <Row className="mb-5">
          <Col sm xs="12" md lg xl="4">
            <div className="data-section">Your selections:</div>
            <div className="data-section">
              <Card body>
                <Stack direction="horizontal" gap={3}>
                  <div>Voter Data</div>
                  <div className="ms-auto">
                    <img className="success-icon" src={greenTick} alt="Success" />
                  </div>
                </Stack>
                <Stack direction="horizontal" gap={3}>
                  <div>Add new records</div>
                  <div className="ms-auto">
                    <img className="success-icon" src={greenTick} alt="Success" />
                  </div>
                </Stack>
                <Stack direction="horizontal" gap={3}>
                  <div>{summaryData.fileName}</div>
                  <div className="ms-auto">
                    <img className="success-icon" src={greenTick} alt="Success" />
                  </div>
                </Stack>
              </Card>
            </div>
          </Col>
          <Col sm xs="12" md lg xl="4">
            <div className="data-section">Your import will include:</div>
            <div className="data-section">
              <Card body>
                <div>Mapped Fields</div>
                <div>{summaryData.mappedFields}</div>
              </Card>
            </div>
          </Col>
          <Col sm xs="12" md lg xl="4">
            <div className="data-section">Your will not include:</div>
            <div className="data-section">
              <Card body>
                <div>Un-Mapped Fields</div>
                <div>{summaryData.unMappedFields}</div>
              </Card>
            </div>
          </Col>
        </Row>
        <Row className="navbar-fixed-bottom">
        <Card className="card-navbar-bottom" body>
            <Stack direction="horizontal" gap={3}>
              <div></div>
              <Button onClick={cancelUpload} size="sm" variant="outline-primary" className="ms-auto no-border-radius btn-outline-ems">Cancel</Button>
              <Button onClick={previousStep} size="sm" variant="info" style={{ background: '#C4D1E0' }} className="no-border-radius btn-outline-ems">Previous</Button>
              <Button onClick={nextStep} size="sm" variant="primary" className={`no-border-radius btn-primary-ems ${enableNextButton ? '' : 'disabled'}`}>Next</Button>
            </Stack>
          </Card>
          <Stack className="import-footer-main" direction="horizontal" gap={3}>
              <div className="import-footer">
                <span className="import-footer-endfooterimg">Copyright © 2023 iToConnect. All Rights Reserved.</span>
              </div>
          </Stack>
        </Row>
      </div>
      <Modal show={showModel} onHide={()=>{}}>
        <Modal.Header closeButton>
          <Modal.Title> Congratulations </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Your import has started! </div>
          <div>click OK to view your import Status on Bulk Data Load Job page </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={dataUploadSuccess}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ImportSummaryComponent;