export const urlData = {
  constituencyDashboardAPI: "/dashboard/dropdowndb/",
  mpConstituencyDashboardAPI: "/dashboard/dropdowndb/?constituency_id=",
  mandalDashboardAPI: "/dashboard/dropdowndb/?mandal_id=",
  wardVillageDashboardAPI: "/dashboard/dropdowndb/?ward_village_id=",
  //******* sample voters count************ */
  allConstituencyVoterDashboardAPI: "/dashboard/voter_count/",
  constituencyMPVoterDashboardAPI: "/dashboard/voter_count/?",

  mandalVoterFilterDashboardAPI:
    "/dashboard/voter_count/?constituency_id=107&mandal=1,2",
  villageVoterFilterDashboardAPI:
    "/dashboard/voter_count/?constituency_id=107&mandal=0&ward_village=",
  pollingStationVoterFilterDashboardAPI:
    "/dashboard/voter_count/?constituency_id=107&mandal=0&ward_village=1&polling_booth=2",
  casteVoterFilterDashboardAPI:
    "/dashboard/voter_count/constituency_id=107&?mandal=1&ward_village=1&polling_booth=4&sub_caste=42,33,34",
  //********voters count end */
  //****** caste voters count  */
  casteAtConstituencyDashboardAPI: "/dashboard/caste/",
  casteAtMPConstituencyDashboardAPI: "/dashboard/caste/?",
  // casteMandalConstituencyDashboardAPI:"/dashboard/caste/?constituency_id=107&mandal=",
  // casteVillageConstituencyDashboardAPI:"/dashboard/caste/?constituency_id=107&mandal=1&ward_village=1,2",
  // castePollingStationConstituencyDashboardAPI:"/dashboard/caste/?constituency_id=107&mandal=1&ward_village=1,2,3,4,5&polling_booth=1,2,3,4,5",
  //***** caste voters count end  */
  // **********favourto voters count */

  favourConstituencyDashboardAPI: "/dashboard/favour/",
  favourMPConstituencyDashboardAPI: "/dashboard/favour/?",
  //   favourMandalDashboardAPI: "/dashboard/favour/?constituency_id=107&mandal=",
  //   favourVillageDashboardAPI:
  //     "/dashboard/favour/?constituency_id=107&mandal=1&ward_village=1,2",
  //   favourPollingStationDashboardAPI:
  // "/dashboard/favour/?constituency_id=107&mandal=1&ward_village=1&polling_booth=1,2,3,4,5,6",
  // ********* favourto voters count end ****** **/
  impPeopleDashboardAPI: "/dashboard/imp/",
  toDoDashboardAPI: "/dashboard/todo/",
  resendOtp: "/resend/?email_id=",
  verifyOtp: "/verifyotp/",
  signUp: "/signup/",
  signIn: "/signin/",
  signout: "/signout/",
  refreshToken: "/jwt/refresh/",
  getStateAndRoleDataSignup: "/dropdown/",
  getDistrictDataSignup: "/dropdown/?state_id=",
  setPassword: "/SetPassword/",
  forgotPassword: "/forgotpassword/?data=",
  // **********************votersdata
  votersdataConstituencyapi: "constituency_id=",
  votersdataMandalapi: "mandal=",
  votersdataVillageapi: "ward_village=",
  votersdatapollingstationapi: "polling_booth=",
  votersdataCasteapi: "sub_caste=",
  votersdataGenderapi: "gender=",
  votersdataAgeapi: "age=",
  votersdataReligionapi: "religion=",
  votersdataPage: "page=",
  votersdataDashboard: "/dashboard/votersdata/?",
  //  votersdataDashboard: "/dashboard/app.c360soft.ai/?",
  //  votersdataDashboard: "/app.c360soft.ai",


  votersdataColumnselection: "/dashboard/columndd/",
  columnsstoring: "/dashboard/columns/",
  newvotersdropdownendpoint: "/dashboard/addvotersdb/",
  checkvoters: "/dashboard/checkvoter/?voter_id=",
  searchvoters : "dashboard/searchvoter/?search=",
  // searchvoters: "/dashboard/viewvoter/?voter_id=",
  profilePicendpoint: "/dashboard/uploadprofile/",
  getprofileendpoint: "/dashboard/profileimage/",
  // ********************change password***************
  changepassword: "/dashboard/changepassword/",
  getsubusers:'/roles/getsubusers/',
  addsubusers:'/roles/addsubuser/',
  assignvoter:'/roles/assignvoters/',
  deletesubusers:'/roles/deletesubuser/?user_id=',
  activeuser :`roles/activeuser/?user_id=`,
  wradvillagegetissues :`dashboard/getissues/`,
  wardvillagedelete : `/dashboard/getissues/?issue_id=`,
  BirthdayReports :`/dashboard/birthday_reports/`,
  //*********************import data api start  */
  importData: {
    downloadSampleFile: "/import/s3/download_sample_file/",
    uploadPDF: "/import/s3/upload_polling_booth_data/?file_name=",
    parsePDF: "/import/parsepdf/",
    getConstituencies: "/dashboard/constituency/",
    getPollingBooth: "/dashboard/polling_booth/",
    getPollingBoothByConstituency: "/dashboard/polling_booth/?constituency_id=",
    getvillagesForPollingBooth:
      "/dashboard/polling_booth/villages_for_booth/?polling_booth=",
    mappingFields: "/dashboard/mapfields/",
    saveImportData: "/import/readjson/",
    saveImportData_Excel: "/import/readjsonexcel/",
    getBatchData: "/import/jobs/",
  },
  roles: {
    getRoles: "/roles/readroles/?page=",
    getAllRoles: '/roles/read_all_roles/',
    changeRoleStatus: '/roles/edit_role_status/',
    addRoles: "/roles/addroles/",
    getPermissions: "/roles/readpermissions/?role=",
    getScreens: "/roles/getscreens/",
  },
  reports: {
    getS3Reports:"/reports/s3/list_reports/",
    getReports: "/reports/voterdatainexcel/",
    getReportByKey: "/reports/s3/download_report/?key=",
    updateconstreports: "/reports/updateconstreports/",
    updatepollreports: "/reports/updatepollreports/",
  },
  dasboard: {
    activeTasks: '/dashboard/tasks/',
    checkstatus: "/dashboard/checkstatus/?task_id=",
  }
};
