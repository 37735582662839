import React from "react";
import "../components/Forgotpassword.css";
// import Emslogo from "../Assets/emslogo.png";
import Emslogo from "../../src/Assets/itoclogo.png";
import { useState, useEffect } from "react";
import { forgotPassword } from "../Service/service";
import LoaderWrapper from "../components/LoaderWrapper";
import { encode } from "base-64";
import LeftArrow from "../Assets/leftarrrowmobile.png";
import { useNavigate } from "react-router-dom";

function Forgotpassword() {
  const [resetPassword, setresetPassword] = useState("");
  const [status, setStatus] = useState("");
  const [statusColorClass, setStatusColorClass] = useState("");
  const [rstPwd, setRstpwd] = useState('');
  const [email, setEmail] = useState('');
  const [loadingscreen, setLoadingscreen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (email === '' && rstPwd === '') {
      setStatusColorClass("")
    }
  }, [rstPwd, email])

  const updatePassword = () => {
    setLoadingscreen(true);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^(?!([0-9])\1{9}$)[6-9][0-9]{9}$/;

    if (!(emailRegex.test(rstPwd) || mobileRegex.test(rstPwd))) {
      setStatus("Invalid email or mobile number");
      setStatusColorClass("error"); 
      setLoadingscreen(false);
      return;
    }

    forgotPassword(rstPwd)
      .then((result) => {
        setStatus(result);
        if (result.status === false) {
          setStatusColorClass("error"); 
          setLoadingscreen(false);
        }
        if (result.status === true) {
          setLoadingscreen(false);
          alert(`${result.message}`);
          navigate('/login');   
         }
      })
      .catch((error) => {
        console.log(error);
        setStatus(error);
      });

    setresetPassword(rstPwd);
  };




  

  const redirectLogin = () => {
    window.location.href = "/";
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  
  return (
    <div className="forgot-password_main_div_mobile">
      {loadingscreen ? <LoaderWrapper /> : null}

      <div onClick={handleGoBack} className="leftarrow_block">
        <img
          src={LeftArrow}
          alt="back_arrow"
          className="leftarrow_img_signupimg"
        />
      </div>
      <div className="fp_emslogo">
        <img src={Emslogo} alt="Waiting..." />
      </div>
      <div className="fp_emslogo">
        <span
          className="fp_emslogo_title_text"
          style={{
            color: "green",
            fontSize: "30px",
            fontWeight: "800",
            textAlign: "center",
          }}
        >
          ItoConnect
        </span>
      </div>
      <div className="fpword__main">
        <p className="fpword__text">
          Enter your email address or phone number and we'll send you a <br />
          link to reset your password.
        </p>
        <div className="fpword__sub">
          <div className="fpword__heading">
            <span className="fpword__hrline" />
            <h3>Forgot Password</h3>
            <span className="fpword__hrline" />
          </div>
          <input
            className="forgot_password_place"
            placeholder="Enter Email / Phone Number"
            id="resetPassword"
            value={rstPwd}
            onChange={(e) => {
              const enteredValue = e.target.value.toLowerCase();
              setRstpwd(enteredValue);
              setEmail(enteredValue);
            }}
          />

          {/* <span className={`fpword__sub_statusMsg ${statusColorClass}`}>
            {statusColorClass}
          </span> */}
          <span className={`fpword__sub_statusMsg ${statusColorClass}`}>
            {statusColorClass === "error"
              ? "Invalid email or mobile number"
              : statusColorClass}
          </span>
          <button
            className="resetpword__button"
            style={{ opacity: email === '' && rstPwd === '' ? '0.5' : '1' }}
            disabled={email === '' && rstPwd === '' ? true : false}
            onClick={updatePassword}
          >
            Reset Password
            {/* Get OTP */}
          </button>
        </div>
        <p
          style={{ color: "black", marginTop: "1rem" }}
          className="fpword_bottom_text"
          onClick={redirectLogin}
        >
          Log in to Application
        </p>
      </div>
      {/* <div className="fp_emptyspace"></div>
      <div className="copyright">© 2023 EMS ----- All Right Reserved</div> */}
    </div>
  );
}

export default Forgotpassword;
