import { urlData } from "../config/apiconfig";
import axiosInstance from "./AxiosInstance";


export async function getissues(dropdownvalue) {
    try {
        const resp = await axiosInstance.get(`${urlData.wradvillagegetissues}?${dropdownvalue == '' ? '' : dropdownvalue}`)
        return resp.data;
    } catch (error) {
        throw error
    }
}

export async function importantpeopleApi() {
    try {
        const resp = await axiosInstance.get(`${urlData.impPeopleDashboardAPI}`)
        return resp.data;
    } catch (error) {
        throw error
    }
}

export async function wardvillagedelete(issueid) {
    try {
        const resp = await axiosInstance.delete(`${urlData.wardvillagedelete}${issueid}`)
        return resp.data;
    } catch (error) {
        console.log('error', error)
        throw error
    }
}

export async function wardvillageissuesdropdown() {
    try {
        const resp = await axiosInstance.get('/dashboard/dropdowndb/')
        return resp.data;
    } catch (error) {
        throw error
    }
}

export async function wardvillageissuesMandal(selectedValues) {
    try {
        const constituencies = selectedValues ? `?constituency_id=${selectedValues}` : ''
        const dashboard = await axiosInstance.get(`/dashboard/dropdowndb/${constituencies ? constituencies : ''}`)
        return dashboard.data;
    } catch (error) {
        throw error
    }
}

export async function wardvillageissuesVillage(selectedmandalvalue) {
    try {
        const mandals = selectedmandalvalue ? `?mandal_id=${selectedmandalvalue}` : '';
        const response = await axiosInstance.get(`/dashboard/dropdowndb/${mandals}`);
        return response.data;
    } catch (error) {
        throw error
    }
}

export async function wardvillageissuesPollingStation(selectedvillagevalue) {
    try {
        const villages = selectedvillagevalue ? `?ward_village_id=${selectedvillagevalue}` : ''
        const response = await axiosInstance.get(`/dashboard/dropdowndb/${villages}`);
        return response.data;
    }
    catch (error) {
        throw error
    }
}

export async function AddissuesApicall(datapayload) {
    try {
        const response = await axiosInstance.post(`${urlData.wradvillagegetissues}`, datapayload);
        return response.data;
    } catch (error) {
        throw error
    }
}

export async function AddissuesApicallPatch(datapayload) {
    try {
        const response = await axiosInstance.patch(`${urlData.wradvillagegetissues}`, datapayload);
        return response.data;
    } catch (error) {
        console.log(error)
        throw error
    }
}

