import React, { useEffect, useState } from 'react'
import './Addusers.css'
import { useSearchParams } from 'react-router-dom'
import { Savesubuser, addrolesdata, updatecheckvoter } from '../../Service/Usermanagemen.service'
import { Dropdown } from 'react-bootstrap'

function Addusers(props) {

  const [dataPost, setDataPost] = useState({
    voter_id: "",
    first_name: "",
    last_name: "",
    email_id: "",
    phone_no: null,
    role: null,
    user_id: null
  })
  const [roledata, setRoledata] = useState(null);
  const [role, setRole] = useState(null);
  const modulevar = props.moduletype;


  const saveuser = () => {
    if (dataPost.voter_id !== ""
      && dataPost.first_name !== ""
      && dataPost.last_name !== ""
      && dataPost.email_id !== ""
      && dataPost.phone_no !== null
      && dataPost.role !== null) {
      Savesubuser(dataPost, modulevar).then((resp) => {
        if (resp.status == true) {
          alert('User Added Successfully');
          window.location.href = '/usermanagement'
        }
        if (resp.status == false && resp.message) {
          alert(`User Added Faild ${resp.message}`)
        }
      })
    }
  }


  useEffect(() => {
    addrolesdata()
      .then((resp) => {
        setRoledata(resp.Roles)
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, []);
  
  const handlecheckvoter = () => {
    const voterId = dataPost.voter_id
    updatecheckvoter(voterId).then((resp) => {
      if (resp.status === false) {
        const data = resp.message;
        let populateddata
        data.map((items) => {
          populateddata = items;
        })
        setDataPost({
          ...dataPost,
          email_id: populateddata.email_id,
          voter_id: populateddata.voter_id,
          phone_no: populateddata.phone_no,
          first_name: populateddata.first_name,
          last_name: populateddata.last_name,
        })
      }
    })
  }

  useEffect(() => {
    const voterId = props.voteridvar;
    if (modulevar == 'Edit' || modulevar == 'View') {
      setDataPost({
        ...dataPost,
        email_id: voterId.email_id,
        voter_id: voterId.voter_id,
        phone_no: voterId.phone_no,
        first_name: voterId.first_name,
        last_name: voterId.last_name,
        user_id: voterId.user_id,
        role: voterId.role
      })
      setRole(voterId.role__role_name)
    }
  }, [modulevar])

  const handleadduserClose = () => {
    setDataPost({
      voter_id: "",
      first_name: "",
      last_name: "",
      email_id: "",
      phone_no: null,
      role: null,
      user_id: null
    });
    setRole(null)
    props.handleClosed()
  }

  return (
    <div className='adduser_background_main_div'>
      <div className="add_user_pop_up_maindiv">
        <div className="add_user_modal_head">
          <span>Add User</span>
        </div>
        <div className='add-user_popup_modal_body'>
          <span id="add">Information</span>
          <div className="row  voter_label_div">
            <div class="col-md-6 add_user_child_div" >
              <label>Voter ID Number</label>
              <input type="text" onChange={(e) =>
                setDataPost({
                  ...dataPost,
                  voter_id: e.target.value,
                })
              } disabled={modulevar ? true : false}
               value={dataPost.voter_id}
                onBlur={handlecheckvoter}
                className="voter_id_number_div_input_filed"
                placeholder='Enter Voter ID Number' />
            </div>
            <div class="col-md-6 add_user_childs_div">
              <label>Mobile</label>

              <input type="text" onChange={(e) =>
                setDataPost({
                  ...dataPost,
                  phone_no: parseInt(e.target.value),
                })
              } disabled={modulevar ? true : false} value={dataPost.phone_no} className="voter_id_number_div_input_filed" placeholder='Enter Mobile Number' />
            </div>
          </div>
          <div className="row voter_label_div">
            <div class="col-md-6 add_user_childs_div">
              <label>First Name </label>
              <input type="text" onChange={(e) =>
                setDataPost({
                  ...dataPost,
                  first_name: e.target.value,
                })
              } disabled={modulevar ? true : false} value={dataPost.first_name} className="voter_id_number_div_input_filed" placeholder='Enter your First Name' />
            </div>
            <div class="col-md-6 add_user_childs_div">
              <label>Last Name</label>
              <input type="text" onChange={(e) =>
                setDataPost({
                  ...dataPost,
                  last_name: e.target.value,
                })
              } disabled={modulevar ? true : false} value={dataPost.last_name} className="voter_id_number_div_input_filed" placeholder='Enter your last name' />
            </div>
          </div>
          <div className="row voter_label_div">
            <div class="col-md-6 add_user_childs_div">
              <label>Email</label><br />
              <input type="email" onChange={(e) =>
                setDataPost({
                  ...dataPost,
                  email_id: e.target.value,
                })
              } disabled={modulevar ? true : false} value={dataPost.email_id} className="voter_id_number_div_input_filed" placeholder='Enter Email' />
            </div>
            <div class="col-md-6 add_user_childs_div" >
              <label>Role</label>
              <div class="">
                <Dropdown class="new_voter_dropdown" >
                  <Dropdown.Toggle
                    id="dropdown-basic"
                  >
                    {role ? role : 'None'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu >
                    {
                      roledata && Array.isArray(roledata) && roledata.map((items) => {
                        return (
                          <Dropdown.Item
                            className="dropdown_text_newVoters"
                            href="#/action-1"
                            onClick={() => {
                              setDataPost({
                                ...dataPost,
                                role: parseInt(items.role_id),
                              });
                              setRole(items.role_name)
                            }}
                          >
                            {items.role_name}
                          </Dropdown.Item>
                        )
                      })
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="add_user_underline_block">
            <div className="add_user_underline"></div>
          </div>
          <div className='add_user_buttons'>
            <button type="button" className="add_user_cancel_button" onClick={handleadduserClose}>Cancel</button>
            <button type="button" className="add_user_save_button" onClick={saveuser}>Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Addusers