import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.heat'; // Import leaflet.heat library for heatmap
import './Map.css';
import axios from 'axios';

// Import marker icon images
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

// Ensure Leaflet can find the default icon images by explicitly specifying the path
let DefaultIcon = L.icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const Map = () => {
  const [userData, setUserData] = useState([]);
  const [userDataFull, setUserDataFull] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [heatmapLayer, setHeatmapLayer] = useState(null);
  const mapRef = useRef();

  useEffect(() => {
    // Fetch user data from API
    const fetchData = async () => {
      try {
        const response = await fetch('https://sxqm562sjc.execute-api.us-east-1.amazonaws.com/dev/getlocationdetails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setUserDataFull(data);

        // Filter unique users based on user_id
        const uniqueUsers = Array.from(new Set(data.map((user) => user.user_id))).map(
          (uniqueUserId) => data.find((user) => user.user_id === uniqueUserId)
        );
        setUserData(uniqueUsers);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);

  const handleUserSelect = async (event) => {
    const selectedUserId = event.target.value;
    console.log("selected User", selectedUserId);
    const userLocations = userDataFull.filter(x => x.user_id === selectedUserId);
    userLocations.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    console.log("selected userLocations", userLocations);

    if (!userLocations.length) {
      return; // Return early if selected user is not found
    }

    // Update the selected user state
    setSelectedUser(userLocations);

    let place = '';

    // Generate heatmap data for all the selected user's locations
    const heatmapData = userLocations.map((location) => [
      parseFloat(location.latitude),
      parseFloat(location.longitude),
    ]);

    // Clear existing heatmap layer
    if (heatmapLayer) {
      mapRef.current.removeLayer(heatmapLayer);
    }

    // Create new heatmap layer
    const newHeatmapLayer = L.heatLayer(heatmapData).addTo(mapRef.current);
    setHeatmapLayer(newHeatmapLayer);

    // Center map on the selected user's last location and increase zoom
    const lastLocation = userLocations[0]; // Get the last location
    console.log("last Location", lastLocation);
    if (lastLocation) {
      mapRef.current.setView([(lastLocation.latitude), (lastLocation.longitude)], 12);

      // Retrieve place information using reverse geocoding
      try {
        const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lastLocation.latitude}&lon=${lastLocation.longitude}`);
        place = response.data.display_name;
        console.log('Place:', response);

  // Add a marker directly to the map with a popup containing place information
const marker = L.marker([(lastLocation.latitude), (lastLocation.longitude)])
.addTo(mapRef.current)
.bindPopup(`<b>User Name:</b> ${lastLocation.user_name}<br/><b>Last Tracked Location:</b> ${place || 'Unknown'}<br/><b>Last Tracked Time:</b> ${lastLocation.timestamp}`)
.openPopup();

        marker.options.user_id = selectedUserId; // Set a custom property to identify this marker
        return;
      } catch (error) {
        console.error('Error fetching place information:', error);
      }
    }

    // Remove previously selected user markers
    mapRef.current.eachLayer((layer) => {
      if (layer.options && layer.options.user_id) {
        mapRef.current.removeLayer(layer);
      }
    });
  };

  return (
    <div className="map-container">
      <div className="leaflet-top leaflet-center">
        {/* <div className="leaflet-control leaflet-bar">
          <h2 className="map-title">User Location Map</h2>
        </div> */}
        <div className="leaflet-control leaflet-bar user-dropdown" style={{marginLeft:'100px'}}>
          <select  style={{width:'200px',height:'34px',fontSize:'16px'}} onChange={handleUserSelect}>
            <option value="">Select User</option>
            {userData.map((user) => (
              <option key={user.user_id} value={user.user_id}>{user.user_name}</option>
            ))}
          </select>
        </div>
      </div>
      <MapContainer center={[17.4456832, 78.3843328]} zoom={5} style={{ height: '100%', width: '100%' }} ref={mapRef}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      </MapContainer>
    </div>
  );
};

export default Map;
