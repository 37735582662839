import React, { useEffect, useState, useContext } from "react";
import { useNavigate, redirect, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { Row , Button, Stack, Card } from "react-bootstrap";
import { ImportDataContext } from "../../../Pages/ImportData/ImportDataContext";
import Groupicon from "../../../Assets/Group_icon.png"
// import LoaderWrapper from '../../LoaderWrapper';

function ImportOptions() {
 
  const { importDataService } = useContext(ImportDataContext);
  const [isLoading, setIsLoading] = useState(true);

  const navigation = useNavigate()

  useEffect(() => {
   
  }, []);

  const downloadSampleData = ()=> {
    importDataService.downloadSampleFiles().then(result => {
      if( result.data) {
        window.open(result.data,'_blank')
      }
      /* importDataService.getPDFBLOB(result.data).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'SamplePDF.pdf';
            alink.click();
        })
    }) */
    })
  }

  const navigateToUploadComponent = () => {
    importDataService.exportedJsonData = {polling_booth_data: {}, voterData: []};
    importDataService.importDataSelected = importDataService.newImportUploadData();
    navigation('./uploadData');
  }

  return (
      <>
        <div className="page">
          <div className="label">What data would you like to import?</div>
          <Row className="session">
            <Stack direction="horizontal" gap={3}> 
              {/* <FontAwesomeIcon icon={solid('users')} size="xl"/> */}
              <img src={Groupicon} className="group_logo_importdata" alt="Waiting ...." />
              <Button className="import-button" onClick={()=> navigateToUploadComponent()} > Import Voters Data</Button>
              <a href="#" className="download_sample_impdata" onClick={downloadSampleData}>Download Sample file</a>
            </Stack>
          </Row>
          <p className="import_data_line">Import data</p>
        </div>
      </>
    );
}

export default ImportOptions;