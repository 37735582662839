import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'font-awesome/css/font-awesome.min.css';
import { register } from './service-worker'; // Import the register function from service-worker.js
export function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('./service-worker.js')
        .then((registration) => {
          console.log('Service worker registered:', registration);
        })
        .catch((error) => {
          console.error('Service worker registration failed:', error);
        });
    });
  } else {
    console.log('Service workers are not supported.');
  }
}

// Call the registerServiceWorker function
registerServiceWorker();
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
// Call the register function to register the service worker
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
