import React, { useEffect, useRef, useState } from "react";
import {
    wardvillageissuesMandal,
    wardvillageissuesPollingStation,
    wardvillageissuesVillage,
    wardvillageissuesdropdown,
} from "../../Service/Wardvillageissues.service";
import "../../Pages/WardvillageIssues/Wardvillageissues.css";
import arrow from '../../Assets/Polygonvotersdata.png'
import { faL } from "@fortawesome/free-solid-svg-icons";
import "./Filter.css"


function Filter(props) {
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedmandalvalue, setselectedmandalvalue] = useState([]);
    const [selectedvillagevalue, setselectedvillagevalue] = useState([]);
    const [constituencies, setConstituencies] = useState([]);
    const [mandals, setMandals] = useState([]);
    const [villages, setVillages] = useState([]);
    const [constituencyisopen, setConstituencyisopen] = useState(false);
    const [mandalisopen, setmandalisopen] = useState(false);
    const [villageisopen, setvillageisopen] = useState(false);
    const [pollingisopen, setPollingisopen] = useState(false);
    const [castevotefavour,setCastevotefavour] = useState(false);
    const [pollings, setpollings] = useState();
    const [selectedpollings, setSelectedpollings] = useState([]);
    const previousselectedvallues = useRef([]);
    const previousselectedmandals = useRef([]);
    const previousselectedvillages = useRef([]);
    const [response, setResponse] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [mandalSearchQuery, setMandalSearchQuery] = useState('');
    const [villageSearchQuery, setVillageSearchQuery] = useState('');
    const [pollingSearchQuery, setPollingSearchQuery] = useState('');
    const [showcaste,setshowcast]=useState(true)
    const [showvotefavour,setshowvotefavour]=useState(true)
    const clearFilter = props.clearfilter



    const tokenData = JSON.parse(localStorage.getItem("data"));
    const roledata = tokenData.user_data.role;

    useEffect(() => {
        wardvillageissuesdropdown().then((resp) => {
            setResponse(resp);
            setSelectedValues(resp.constituencies.map(
                (item) => item.constituency_id
            ));
        });
    }, []);

    useEffect(() => {
        setConstituencies(response.constituencies);
        if (response && selectedValues.length == 0) {
            setMandals(response.mandal);
        }
        if (response && selectedmandalvalue.length == 0) {
            setVillages(response.villages);
        }
        if (response && selectedvillagevalue.length == 0) {
            setpollings(response.polling);
        }
    }, [response, selectedmandalvalue, selectedvillagevalue, selectedpollings]);

    useEffect(() => {
        if (selectedValues.length > 0) {
            wardvillageissuesMandal(selectedValues).then((resp) => {
                setMandals(resp);
            });
        }
        previousselectedvallues.current = selectedValues;
    }, [selectedValues]);

    useEffect(() => {
        if (selectedmandalvalue.length > 0) {
            wardvillageissuesVillage(selectedmandalvalue).then((resp) => {
                setVillages(resp);
            });
        }
        previousselectedmandals.current = selectedmandalvalue;
    }, [selectedmandalvalue]);

    useEffect(() => {
        if (selectedvillagevalue.length > 0) {
            wardvillageissuesPollingStation(selectedvillagevalue).then((resp) => {
                setpollings(resp);
            });
        }
        previousselectedvillages.current = selectedvillagevalue;
    }, [selectedvillagevalue]);

    const handleCheckboxChange = (constituencyid) => {
        if (selectedValues !== previousselectedvallues.current.value) {
            setselectedmandalvalue([]);
            setselectedvillagevalue([]);
            setSelectedpollings([]);
        }
        if (selectedValues.includes(constituencyid)) {
            setSelectedValues(
                selectedValues.filter((item) => item !== constituencyid)
            );
        } else {
            setSelectedValues([...selectedValues, constituencyid]);
        }
    };
    const handleCheckboxChangemandal = (mandalid) => {
        if (selectedmandalvalue !== previousselectedmandals.current.value) {
            setselectedvillagevalue([]);
            setSelectedpollings([]);
        }

        if (selectedmandalvalue.includes(mandalid)) {
            setselectedmandalvalue(
                selectedmandalvalue.filter((item) => item !== mandalid)
            );
        } else {
            setselectedmandalvalue([...selectedmandalvalue, mandalid]);
        }
    };

    const handleCheckboxChangevillage = (villageid) => {
        if (selectedvillagevalue !== previousselectedvillages.current.value) {
            setSelectedpollings([]);
        }

        if (selectedvillagevalue.includes(villageid)) {
            setselectedvillagevalue(
                selectedvillagevalue.filter((item) => item !== villageid)
            );
        } else {
            setselectedvillagevalue([...selectedvillagevalue, villageid]);
        }
    };
    const handleopencastevotefavor =()=>{
        setCastevotefavour(!castevotefavour)
    }

    const handleCheckboxChangepollings = (pollingid) => {
        if (selectedpollings.includes(pollingid)) {
            setSelectedpollings(
                selectedpollings.filter((item) => item !== pollingid)
            );
        } else {
            setSelectedpollings([...selectedpollings, pollingid]);
        }
    };

    const clickedConstituencies = () => {
        setConstituencyisopen(!constituencyisopen);
        setmandalisopen(false);
        setvillageisopen(false);
        setPollingisopen(false);
    };
    const clickedmandal = () => {
        setmandalisopen(!mandalisopen);
        setConstituencyisopen(false);
        setvillageisopen(false);
        setPollingisopen(false);
    };
    const clickvillage = () => {
        setvillageisopen(!villageisopen);
        setConstituencyisopen(false);
        setmandalisopen(false);
        setPollingisopen(false);
    };
    const clickpollingstations = () => {
        setPollingisopen(!pollingisopen);
        setConstituencyisopen(false);
        setmandalisopen(false);
        setvillageisopen(false);
    };
    const handlecasteClicked =()=>{
        setshowcast(!showcaste)
    }
    const handlechangeshowvotefavour =()=>{
        setshowvotefavour(!showvotefavour)
    }

    const firstSelectedConstituency =
        selectedValues.length > 0
            ? constituencies.find(
                (option) => option.constituency_id === selectedValues[0]
            ).mla_constituency_name
            : "Constituency";

    const firstSelectedmandal =
        selectedmandalvalue.length > 0
            ? mandals.find(
                (option) => option.mandal_id === selectedmandalvalue[0]
            ).mandal_name
            : "Town/Mandal";

    const firstSelectevillages =
        selectedvillagevalue.length > 0
            ? villages.find(
                (option) => option.ward_village_id === selectedvillagevalue[0]
            ).ward_village_name
            : "Ward/Village";

    const firstSelectepollings =
        selectedpollings.length > 0
            ? (
                pollings.find(option => option.polling_booth_id === selectedpollings[0]) || {}
            ).polling_booth_no + "-" + (
                pollings.find(option => option.polling_booth_id === selectedpollings[0]) || {}
            ).polling_booth_name || "Polling stations"
            : "Polling Station";

    useEffect(() => {
        props.updateconstituencydropdownvalues(selectedValues);
        props.updatemandalsdropdown(selectedmandalvalue);
        props.updatevillagedropdowns(selectedvillagevalue);
        props.updatepollingstations(selectedpollings);
        props.casteudateshow(showcaste);
        props.votefavourupdate(showvotefavour)
    })
    useEffect(() => {
        setselectedmandalvalue([])
        setselectedvillagevalue([])
        setSelectedpollings([])
    }, [clearFilter])

    const constituencyref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                constituencyref.current &&
                !constituencyref.current.contains(event.target)
            ) {
                setConstituencyisopen(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const mandalref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                mandalref.current &&
                !mandalref.current.contains(event.target)
            ) {
                setmandalisopen(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const villageref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                villageref.current &&
                !villageref.current.contains(event.target)
            ) {
                setvillageisopen(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const pollingsref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                pollingsref.current &&
                !pollingsref.current.contains(event.target)
            ) {
                setPollingisopen(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const castevofavourref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                castevofavourref.current &&
                !castevofavourref.current.contains(event.target)
            ) {
                setCastevotefavour(false)
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);



    return (
        <>
        {/* // <div className="wardvillage_issues_filters_main_div d-flex"> */}

        <div className="wardvillage_issues_filters_main_div d-flex">
            <div className="dashboard_constituency_to_topset constituency_wardvillageissues_constituency">
                <div ref={constituencyref} className="constituency_wardvillageissues1">
                    <button
                        className="constituency_wardvillageissues " id="constituency_wardvillageissues_active_login"
                        onClick={() => (roledata === 'MLA' ? null : clickedConstituencies)}
                        style={{ borderColor: roledata == 'MLA' ? 'green' : '#D8D8D8' }}
                    >
                        <span className="ward_village_filter_contstiency_text" id="ward_village_filter_contstiency_text_constituncy_name">
                            {firstSelectedConstituency}
                        </span>
                        <span className="ward_village_filter_contstiency_text_more">
                            {
                                selectedValues.length > 1 ? `+${selectedValues.length - 1} More` : null
                            }
                        </span>
                        {
                            roledata === 'MLA' ? null : <img className="rotete_pollygon_dashboard" src={arrow} style={{ rotate: constituencyisopen ? '180deg' : '0deg' }} />
                        }
                    </button>
                    {constituencyisopen ? (
                        <div className="dropdown_wardvillages_data_div">
                            <input
                                type="text"
                                className="searchbar_wardvillagesissue_filters"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search Constituencies"
                            />
                            <div className="wardvillages_dropdowns_data_scroller_div">
                                {constituencies && constituencies.length > 0 ? (
                                    constituencies.some((item) =>
                                        item.mla_constituency_name.toLowerCase().includes(searchQuery.toLowerCase())
                                    ) ? (
                                        constituencies
                                            .filter((item) =>
                                                item.mla_constituency_name.toLowerCase().includes(searchQuery.toLowerCase())
                                            )
                                            .map((item) => (
                                                <button
                                                    className="lablesof-wardvillage_dropdown_data d-flex"
                                                    key={item.constituency_id}
                                                    onClick={() =>
                                                        handleCheckboxChange(item.constituency_id)
                                                    }
                                                >
                                                    <input
                                                        className="ward_village_filter_constiency_checkbox"
                                                        type="checkbox"
                                                        value={item.constituency_id}
                                                        checked={selectedValues.includes(item.constituency_id)}
                                                        onChange={() =>
                                                            handleCheckboxChange(item.constituency_id)
                                                        }
                                                    />
                                                    <span className="ward_village_filter_constiency_api_data">
                                                        {item.mla_constituency_name}
                                                    </span>
                                                </button>
                                            ))
                                    ) : (
                                        <p className="nodata_filters_data">No Data Found</p>
                                    )
                                ) : <p className="nodata_filters_data">No Data Found</p>}
                            </div>

                        </div>
                    ) : null}

                </div>
            </div>
            <div ref={mandalref}>
                <button className="constituency_wardvillageissues" onClick={clickedmandal}>
                    <span className="ward_village_filter_contstiency_text">
                        {firstSelectedmandal}
                    </span>
                    <span className="ward_village_filter_contstiency_text_more">
                        {
                            selectedmandalvalue.length > 1 ? `+${selectedmandalvalue.length - 1} More` : null
                        }
                    </span>
                    <img className="rotete_pollygon_dashboard" src={arrow} style={{ rotate: mandalisopen ? '180deg' : '0deg' }} />

                </button>
                {mandalisopen ? (
                    // <div className="dropdown_wardvillages_data_div"  id="dropdown_wardvillages_data_div_drop">
                    <div className={`dropdown_wardvillages_data_div ${mandals && mandals.length > 0 ? 'with-data' : ''}`} id="dropdown_wardvillages_data_div_drop">

                        <input
                            type="text"
                            className="searchbar_wardvillagesissue_filters"
                            value={mandalSearchQuery}
                            onChange={(e) => setMandalSearchQuery(e.target.value)}
                            placeholder="Search Town/Mandal"
                        />
                        <div className="wardvillages_dropdowns_data_scroller_div">

                            {mandals && mandals.length > 0 ? (
                                mandals.some((item) =>
                                    item.mandal_name.toLowerCase().includes(mandalSearchQuery.toLowerCase())
                                ) ? (
                                    mandals
                                        .filter((item) =>
                                            item.mandal_name.toLowerCase().includes(mandalSearchQuery.toLowerCase())
                                        )
                                        .map((item) => (
                                            <button
                                                className="lablesof-wardvillage_dropdown_data d-flex" id="lablesof-wardvillage_dropdown_data_"
                                                key={item.mandal_id}
                                                onClick={() => handleCheckboxChangemandal(item.mandal_id)}
                                            >
                                                   <span className="ward_village_filter_constiency_api_data">
                                                    {item.mandal_name}
                                                </span>
                                                <input
                                                    className="ward_village_filter_constiency_checkbox" id="ward_village_filter_constiency_checkbox"
                                                    type="checkbox"
                                                    value={item.mandal_id}
                                                    checked={selectedmandalvalue.includes(item.mandal_id)}
                                                    onChange={() => handleCheckboxChangemandal(item.mandal_id)}
                                                />
                                                {/* <span className="ward_village_filter_constiency_api_data">
                                                    {item.mandal_name}
                                                </span> */}
                                            </button>
                                        ))
                                ) : (
                                    <p className="nodata_filters_data">No Data Found</p>
                                )
                            ) : <p className="nodata_filters_data">No Data Found</p>}
                        </div>


                    </div>
                ) : null}
            </div>
            <div ref={villageref}>
                <button className="constituency_wardvillageissues" onClick={clickvillage}>
                    <span className="ward_village_filter_contstiency_text">
                        {firstSelectevillages}
                    </span>
                    <span className="ward_village_filter_contstiency_text_more">
                        {
                            selectedvillagevalue.length > 1 ? `+${selectedvillagevalue.length - 1} More` : null
                        }
                    </span>
                    <img className="rotete_pollygon_dashboard" src={arrow} style={{ rotate: villageisopen ? '180deg' : '0deg' }} />
                </button>
                {villageisopen ? (
                    // <div className="dropdown_wardvillages_data_div">
                     <div className={`dropdown_wardvillages_data_div ${villages && villages.length > 0 ? 'with-data' : ''}`} >
                        <input
                            type="text"
                            className="searchbar_wardvillagesissue_filters"
                            value={villageSearchQuery}
                            onChange={(e) => setVillageSearchQuery(e.target.value)}
                            placeholder="Search Ward/Village"
                        />
                        <div className="wardvillages_dropdowns_data_scroller_div">
                            {villages && villages.length > 0 ? (
                                villages.some((item) =>
                                    item.ward_village_name.toLowerCase().includes(villageSearchQuery.toLowerCase())
                                ) ? (
                                    villages
                                        .filter((item) =>
                                            item.ward_village_name.toLowerCase().includes(villageSearchQuery.toLowerCase())
                                        )
                                        .map((item) => (
                                            <button
                                                className="lablesof-wardvillage_dropdown_data d-flex" id="lablesof-wardvillage_dropdown_data_"
                                                key={item.ward_village_id}
                                                onClick={() => handleCheckboxChangevillage(item.ward_village_id)}
                                            >
                                                <span className="ward_village_filter_constiency_api_data">
                                                    {item.ward_village_name}
                                                </span>
                                                <input
                                                    className="ward_village_filter_constiency_checkbox" id="ward_village_filter_constiency_checkbox"
                                                    type="checkbox"
                                                    value={item.ward_village_id}
                                                    checked={selectedvillagevalue.includes(item.ward_village_id)}
                                                    onChange={() =>
                                                        handleCheckboxChangevillage(item.ward_village_id)
                                                    }
                                                />
                                                {/* <span className="ward_village_filter_constiency_api_data">
                                                    {item.ward_village_name}
                                                </span> */}
                                            </button>
                                        ))
                                ) : (
                                    <p className="nodata_filters_data">No Data Found</p>
                                )
                            ) : <p className="nodata_filters_data">No Data Found</p>}
                        </div>

                    </div>
                ) : null}
            </div>
        </div>


        <div className="wardvillage_issues_filters_main_div d-flex" id="war_villages_polling_caste_favour">
          
            <div ref={pollingsref} >
                <button
                    className="constituency_wardvillageissues"
                    onClick={clickpollingstations}
                >
                    <span className="ward_village_filter_contstiency_text">
                        {firstSelectepollings}
                    </span>
                    <span className="ward_village_filter_contstiency_text_more">
                        {
                            selectedpollings.length > 1 ? `+${selectedpollings.length - 1} More` : null
                        }
                    </span>
                    <img className="rotete_pollygon_dashboard" src={arrow} style={{ rotate: pollingisopen ? '180deg' : '0deg' }} />

                </button>
                {pollingisopen ? (
                    // <div className="dropdown_wardvillages_data_div"  id="dropdown_wardvillages_data_div_vill">
                    <div className={`dropdown_wardvillages_data_div ${pollings && pollings.length > 0 ? 'with-data' : ''}`} id="dropdown_wardvillages_data_div_vill">
                        <input
                            type="text"
                            className="searchbar_wardvillagesissue_filters"
                            value={pollingSearchQuery}
                            onChange={(e) => setPollingSearchQuery(e.target.value)}
                            placeholder="Search Polling Station"
                        />
                        <div className="wardvillages_dropdowns_data_scroller_div">
                            {pollings && pollings.length > 0 ? (
                                pollings.some((item) =>
                                    (item.polling_booth_no + '-' + item.polling_booth_name)
                                        .toLowerCase()
                                        .includes(pollingSearchQuery.toLowerCase())
                                ) ? (
                                    pollings
                                        .filter((item) =>
                                            (item.polling_booth_no + '-' + item.polling_booth_name)
                                                .toLowerCase()
                                                .includes(pollingSearchQuery.toLowerCase())
                                        )
                                        .map((item) => (
                                            <button
                                                className="lablesof-wardvillage_dropdown_data d-flex" id="labels_border" 
                                                key={item.polling_booth_id}
                                                onClick={() => handleCheckboxChangepollings(item.polling_booth_id)}
                                            >
                                                 <span className="ward_village_filter_constiency_api_data">
                                                    {item.polling_booth_no}-{item.polling_booth_name}
                                                </span>
                                                <input
                                                    className="ward_village_filter_constiency_checkbox"  id="ward_village_filter_constiency_checkbox"
                                                    type="checkbox"
                                                    value={item.polling_booth_id}
                                                    checked={selectedpollings.includes(item.polling_booth_id)}
                                                    onChange={() =>
                                                        handleCheckboxChangepollings(item.polling_booth_id)
                                                    }
                                                />
                                                
                                            </button>
                                        ))
                                ) : (
                                    <p className="nodata_filters_data">No Data Found</p>
                                )
                            ) : <p className="nodata_filters_data">No Data Found</p>}
                        </div>

                    </div>
                ) : null}
            </div>
            <div ref={castevofavourref}  className="constituency_wardvillageissues_caste_voter_favour">
                <button
                    className="constituency_wardvillageissues  "
                    onClick={handleopencastevotefavor}
                >
                    <span className="ward_village_filter_contstiency_text">
                        Caste/Vote Favour
                    </span>
                    <span className="ward_village_filter_contstiency_text_more">

                    </span>
                    <img className="rotete_pollygon_dashboard" src={arrow} style={{ rotate: castevotefavour ? '180deg' : '0deg' }} />

                </button>
                {
                    castevotefavour ? <div className="dropdown_wardvillages_data_div" id="constituency_wardvillageissues_caste_voter_favour_">
                    <div className="wardvillages_dropdowns_data_scroller_div" id="wardvillages_dropdowns_data_scroller_div">
                        <button
                            className="lablesof-wardvillage_dropdown_data d-flex" id="labels_border"
                            onClick={handlecasteClicked}
                        >
                             <span className="ward_village_filter_constiency_api_data" 
                             id="ward_village_filter_constiency_api_data_"
                             >
                                Caste
                            </span>
                            <input
                                className="ward_village_filter_constiency_checkbox" id="ward_village_filter_constiency_checkbox"
                                type="checkbox"
                                checked={showcaste}
                            />
                            {/* <span className="ward_village_filter_constiency_api_data">
                                Caste
                            </span> */}
                        </button>
                        <button
                            className="lablesof-wardvillage_dropdown_data d-flex" id="labels_border"
                            onClick={handlechangeshowvotefavour}
                        >
                             <span className="ward_village_filter_constiency_api_data"
                              id="ward_village_filter_constiency_api_data_">
                                Vote Favour
                            </span>
                            <input
                                className="ward_village_filter_constiency_checkbox" 
                                id="ward_village_filter_constiency_checkbox"
                                // id="ward_village_filter_constiency_checkbox_"
                                type="checkbox"
                                checked={showvotefavour}
                            />
                            {/* <span className="ward_village_filter_constiency_api_data">
                                Vote Favour
                            </span> */}
                        </button>

                    </div>

                </div> : null
                }
                

            </div>
        </div>
        </>

    );
}

export default Filter;

