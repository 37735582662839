import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "./Generaldropdown.scss";
// import Checkbox from 'react-select-checkbox';

const Generaldropdown = ({
  options,
  handler,
  nextFilterRef,
  placeholder,
  selectedValues,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (selectedValues && selectedValues.length) {
      if (selectedValues.length === options.length) {
        setSelectedOptions([{ label: "All", value: "*" }, ...selectedValues]);
      } else {
        setSelectedOptions([...selectedValues]);
      }
    } else if (selectedValues && selectedValues.length === 0) {
      setSelectedOptions([]);
    }
  }, [selectedValues]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value.length === 0) {
      return `${placeholderButtonLabel}`;
    } else if (value && value.some((o) => o.value === "*")) {
      return `${value.filter((x) => x.value !== "*")[0].label} ${
        value.length - 2 !== 0 ? "+" : ""
      } ${value.length - 2 !== 0 ? value.length - 2 + "More" : ""} `;
    } else {
      return `${value[0].label}${
        value.length > 1 ? " + " + (value.length - 1) + " More" : ""
      }`;
    }
  }

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
      handler(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
      handler([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
      handler(value.filter((x) => x.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
      handler(value.filter((x) => x.value !== "*"));
    } else {
      this.setState(value);
      handler(value.filter((x) => x.value !== "*"));
    }
  }

  return (
    <div className="ems-dropdown">
      <ReactMultiSelectCheckboxes
        className="drop_down_list"
        options={[{ label: "All", value: "*" }, ...options]}
        placeholderButtonLabel={placeholder}
        getDropdownButtonLabel={getDropdownButtonLabel}
        value={selectedOptions}
        onChange={onChange}
        setState={setSelectedOptions}
      />
    </div>
  );
};

export default Generaldropdown;
