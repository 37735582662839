import axios from 'axios';
import { refreshToken } from './service';

const serverURL = process.env.REACT_APP_SERVER_CONFIG;

const axiosInstance = axios.create({
    baseURL: serverURL,
  });

axiosInstance.interceptors.request.use(
  (config) => {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    if (tokenData) {
      config.headers['Authorization'] = `Bearer ${tokenData.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        let response = await refreshToken();
        const tokenData = JSON.parse(localStorage.getItem("data"));
        if (tokenData) {
            originalRequest.headers.Authorization = `Bearer ${tokenData.token}`;
        }
      originalRequest._retry = true;
      return axiosInstance(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;