import axiosInstance from './AxiosInstance';

const url = process.env.REACT_APP_SERVER_CONFIG;
export const eventsData = async () => {
  try {
    const dashboard = await axiosInstance.get('/event/')
    return dashboard.data;
  } catch (error) {
    throw error
  }
};


export const postEvents = async (
  title,
  meetDate,
  meetFrom,
  meetTo,
  meetDesc
) => {
  try {
    const postData = {
      event_title: title,
      event_start: `${meetDate}T${meetFrom}`,
      event_end: `${meetDate}T${meetTo}`,
      event_desc: meetDesc,
      created_id: "1",
      updated_id: "1",
    }
    console.log('post', postData);
    const response = await axiosInstance.post('/event/', postData);
    return response.data;
  } catch (error) {
    throw error
  }

};

export const deleteEvent = async (eventID) => {
  try {
    const response = await axiosInstance.delete(`/event/${eventID}/`)
    const postdata = response;
    return postdata;
  }
  catch (error) {
    throw error
  }
};


export const EditEvents = async (
  id,
  title,
  meetDate,
  meetFrom,
  meetTo,
  meetDesc
) => {
  try {
    const updateData = JSON.stringify({
      event_title: title,
      event_start: `${meetDate}T${meetFrom}`,
      event_end: `${meetDate}T${meetTo}`,
      event_desc: meetDesc,
      created_id: "1",
      updated_id: "1",
    })
    const response = await axiosInstance.patch(`/event/${id}/`, updateData);
    return response.data;
  } catch (error) {
    throw error
  }

};