import React, { useEffect, useRef, useState } from "react";
import "./Addissues.css";
import { AddissuesApicall, AddissuesApicallPatch } from "../../Service/Wardvillageissues.service";
import {
    constituencynewvoters,
    newvotersMandals,
    newvotersPollingstations,
    newvotersVillages,
} from "../../Service/Addvoters.service";
import arrow from "../../Assets/dropdownarrow.png";

function AddIssues(props) {
    const statusdata = [{ status: 'completed' }, { status: 'In Progress' }]
    const [filterdata, setfilterdata] = useState(null);
    const [dropdownConstituency, setDropdownConstituency] = useState(null);
    const [constituency_id, setConstituency_id] = useState(null);
    const [constituencyname, setconstituencyname] = useState(null);
    const [mandalname, setmandalname] = useState(null);
    const [villagename, setVillagename] = useState(null);
    const [pollingname, setPollingname] = useState(null);
    const [dropdownMandal, setDropdownMandal] = useState(null);
    const [mandal_id, setMandal_id] = useState(null);
    const [dropdownvillage, setDropdownvillage] = useState();
    const [village_id, setVillage_id] = useState(null);
    const [dropdownPollings, setDropdownPollings] = useState();
    const [polling_id, setpolling_id] = useState(null);
    const [constituencyisopen, setConstituencyisopen] = useState(false);
    const [mandalopen, setMandalopen] = useState(false);
    const [villageisopen, setVillageisopen] = useState(false);
    const [pollingopen, setpollingopen] = useState(false);
    const initialconstituency = useRef();
    const initialmandal = useRef();
    const initialvillage = useRef();
    const [villageError, setVillageError] = useState(null);
    const [issueDescError, setIssueDescError] = useState(null);
    const [issueNameError, setIssueNameError] = useState(null);
    const [datapayload, setDatapayload] = useState({
        issue_name: "",
        issue_desc: "",
        ward_village_id: "",
        mandal_id: "",
        constituency_id: "",
    });
    const [editdata, setEditdata] = useState(null);
    const [modulevalue, setModulevalue] = useState(null);
    const [statusOpen, setStatusopen] = useState(false)


    const saveissues = () => {
        if (modulevalue !== 'Edit' && village_id && datapayload.issue_desc && datapayload.issue_name) {

            console.log('post');

            AddissuesApicall(datapayload).then((resp) => {
                if (resp.status == true) {
                    alert(" Issue added successfully");
                    handleCancleClicked();
                }
                else{
                    alert("Faild to add Issue ");
                    handleCancleClicked();
                }
            });
        }
        if (modulevalue == 'Edit' && village_id && datapayload.issue_desc && datapayload.issue_name) {
            console.log('patch');

            AddissuesApicallPatch(datapayload).then((resp) => {
                if (resp.status == true) {
                    alert("succes");
                    handleCancleClicked();
                }
            });
        }

        if (village_id === null) {
            setVillageError("Please Select Village");
        }
        else {
            setVillageError(null);
        }
        if (datapayload.issue_desc === '') {
            setIssueDescError("Issue Description is required");
        }
        else {
            setIssueDescError(null);
        }
        if (datapayload.issue_name === '') {
            setIssueNameError("Issue Name is required");
        }
        else {
            setIssueNameError(null);
        }
    };

    useEffect(() => {
        constituencynewvoters().then((resp) => {
            setfilterdata(resp);
        });
    }, []);

    useEffect(() => {
        if (filterdata) {
            if (constituency_id == null) {
                setDropdownConstituency(filterdata.constituencies);
            }
            if (mandal_id == null) {
                setDropdownMandal(filterdata.mandal);
            }
            if (village_id == null) {
                setDropdownvillage(filterdata.villages);
            }
            if (polling_id == null) {
                setDropdownPollings(filterdata.polling);
            }
        }
    }, [filterdata, constituency_id, mandal_id, village_id, polling_id]);

    const handleconstituencyClicked = () => {
        setConstituencyisopen(!constituencyisopen);
    };
    const handlemandalopen = () => {
        setMandalopen(!mandalopen);
    };
    const handlevillageopen = () => {
        setVillageisopen(!villageisopen);
    };
    const handlepollingopen = () => {
        setpollingopen(!pollingopen);
    };


    useEffect(() => {
        if (constituency_id) {
            setDatapayload({
                ...datapayload,
                constituency_id: constituency_id ? constituency_id : "",
            });
            newvotersMandals(constituency_id).then((resp) => {
                setDropdownMandal(resp);
            });
            initialconstituency.current = constituency_id;
        }
    }, [constituency_id]);

    useEffect(() => {
        if (mandal_id) {
            setDatapayload({
                ...datapayload,
                mandal_id: mandal_id ? mandal_id : "",
            });

            newvotersVillages(mandal_id).then((resp) => {
                setDropdownvillage(resp);
            });
            initialmandal.current = mandal_id;
        }
    }, [mandal_id]);

    useEffect(() => {
        if (village_id) {
            setDatapayload({
                ...datapayload,
                ward_village_id: village_id ? village_id : "",
            });
            newvotersPollingstations(village_id).then((resp) => {
                setDropdownPollings(resp);
            });
            initialvillage.current = village_id;
        }
    }, [village_id]);

    useEffect(() => {
        if (polling_id) {
            setDatapayload({
                ...datapayload,
                polling_booth_id: polling_id ? polling_id : "",
            });
        }
    }, [polling_id]);

    const handleselectConstituency = (constituencyid, constituencyname) => {
        setConstituency_id(constituencyid);
        setconstituencyname(constituencyname);
        setmandalname(null);
        setVillagename(null);
        setPollingname(null);
        setMandal_id(null);
        setVillage_id(null);
        delete datapayload.mandal_id;
        delete datapayload.ward_village_id
    };
    const handleselectMandal = (mandalid, mandal_name) => {
        setMandal_id(mandalid);
        setmandalname(mandal_name);
        setVillagename(null);
        setPollingname(null);
        setVillage_id(null);
        delete datapayload.ward_village_id;
    };
    const handleselectVillage = (villageid, villag_ename) => {
        setVillage_id(villageid);
        setVillagename(villag_ename);
        setPollingname(null);
        setpolling_id(null);
    };
    const handleselectpollings = (pollingid, polling_name) => {
        setpolling_id(pollingid);
        setPollingname(polling_name);
    };
    const handleCancleClicked = () => {
        setfilterdata(null);
        setEditdata(null);
        setModulevalue(null);
        setConstituency_id(null)
        setconstituencyname(null)
        setMandal_id(null);
        setmandalname(null);
        setVillage_id(null);
        setVillagename(null)
        setDatapayload({
            ...datapayload,
            issue_name: "",
            issue_desc: "",
            ward_village_id: "",
            mandal_id: "",
            constituency_id: "",
        })
        props.oncancle();
    };
    const handlechangeTitle = (e) => {
        setDatapayload({
            ...datapayload,
            issue_name: e.target.value,
        });
    };

    const handleDescchange = (e) => {
        setDatapayload({
            ...datapayload,
            issue_desc: e.target.value,
        });
    };

    const handleStatusClicked = () => {
        setStatusopen(!statusOpen)
    }

    const handleStatusSelected = (statusitem) => {
        if (modulevalue == 'Edit') {
            setDatapayload({
                ...datapayload,
                issue_status: statusitem.status ? statusitem.status : ''
            })
        }
    }

    useEffect(() => {
        if (editdata == null && modulevalue == null) {
            const editdataval = props.eddata.updatedata;
            const modulevalueval = props.modulevar;
            setEditdata(editdataval)
            setModulevalue(modulevalueval)
        }
        if (modulevalue === 'Edit' && editdata) {
            setDatapayload({
                ...datapayload,
                issue_name: editdata.issue_name ? editdata.issue_name : '',
                issue_desc: editdata.issue_desc ? editdata.issue_desc : '',
                ward_village_id: editdata.ward_village,
                mandal_id: editdata.mandal,
                constituency_id: editdata.constituency,
                issue_id: editdata.issue_id,
                issue_status: editdata.issue_status,
            })
        }
        if (filterdata && modulevalue === 'Edit') {
            const constituencydata = filterdata.constituencies;
            const mandaldata = filterdata.mandal;
            const villagedata = filterdata.villages;
            console.log('vilgdata', villagedata)

            const editconstituencyname = constituencydata && constituencydata.length > 0 ? (constituencydata.find((item) => {
                return item.constituency_id == editdata.constituency;
            })) : null;

            setconstituencyname(editconstituencyname ? editconstituencyname.mla_constituency_name : null);
            setConstituency_id(editconstituencyname ? editconstituencyname.constituency_id : null)

            const editmandal = mandaldata && mandaldata.length > 0 ? (mandaldata.find((item) => {
                return item.mandal_id == editdata.mandal;
            })) : null;

            setmandalname(editmandal ? editmandal.mandal_name : null)
            setMandal_id(editmandal ? editmandal.mandal_id : null)

            const editvillage = villagedata && villagedata.length > 0 ? (villagedata.find((item) => {
                return item.ward_village_id == editdata.ward_village
            })) : null;

            setVillagename(editvillage.ward_village_name);
            setVillage_id(editvillage.ward_village_id)
        }

    }, [filterdata])


    const dropdownconstituencyRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownconstituencyRef.current &&
                !dropdownconstituencyRef.current.contains(event.target)
            ) {
                setConstituencyisopen(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);


    const mandalref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                mandalref.current &&
                !mandalref.current.contains(event.target)
            ) {
                setMandalopen(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const villageref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                villageref.current &&
                !villageref.current.contains(event.target)
            ) {
                setVillageisopen(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const statusref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                statusref.current &&
                !statusref.current.contains(event.target)
            ) {
                setStatusopen(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div className="addwardvillageissues_main_div d-flex">
            <div className="addissues_mainbox_div">
                <div className="add_ward_village_issues_add_pop_up_title">
                    {modulevalue == 'Add' ? 'Add':'Edit'} Ward/Village Issues
                </div>
                <div className="d-flex add_issues_column">
                    <div className="d-flex flex-column dropdownflex_div" ref={dropdownconstituencyRef}>
                            <span className="add_isues_popup_label_names">Select Constituency</span>
                        <div
                            className="dropdown_top_Div_addissues d-flex align-items-center"
                            onClick={handleconstituencyClicked}
                        >
                            <span className="ward_villages_text_pattern">
                                {constituencyname ? constituencyname : "Select Constituency"}
                            </span>

                            <img
                                className="ward_village_issues_dropdown_arrow"
                                src={arrow}
                                alt="not_visible"
                            />
                        </div>

                        {constituencyisopen ? (
                            <div className="dropdown_bottom_Div_addissues">
                                {dropdownConstituency
                                    ? dropdownConstituency.map((item) => {
                                        return (
                                            <div
                                                className="ward_village_add_dropdonw_addbutton d-flex align-items-center"
                                                key={item.constituency_id}
                                                onClick={() => {
                                                    handleselectConstituency(
                                                        item.constituency_id,
                                                        item.mla_constituency_name
                                                    );
                                                }}
                                            >
                                                <span className="ward_village_add_filter_constiency_api_data">
                                                    {item.mla_constituency_name}
                                                </span>
                                            </div>
                                        );
                                    })
                                    : "nodata"}
                            </div>
                        ) : null}
                    </div>
                    <div className="d-flex flex-column dropdownflex_div" ref={mandalref}>
                    <span className="add_isues_popup_label_names">Select Mandal</span>

                        <div
                            className="dropdown_top_Div_addissues d-flex align-items-center"
                            onClick={handlemandalopen}
                        >
                            <span className="ward_villages_text_pattern">
                                {mandalname ? mandalname : "Select Mandal"}
                            </span>
                            <img
                                className="ward_village_issues_dropdown_arrow"
                                src={arrow}
                                alt="not_visible"
                            />
                        </div>
                        {mandalopen ? (
                            <div className="dropdown_bottom_Div_addissues">
                                {dropdownMandal
                                    ? dropdownMandal.map((item) => {
                                        return (
                                            <div
                                                key={item.mandal_id}
                                                className="ward_village_add_dropdonw_addbutton d-flex align-items-center"
                                                onClick={() => {
                                                    handleselectMandal(
                                                        item.mandal_id,
                                                        item.mandal_name
                                                    );
                                                }}
                                            >
                                                <span>{item.mandal_name}</span>
                                            </div>
                                        );
                                    })
                                    : "nodata"}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="d-flex">
                    <div className="d-flex flex-column dropdownflex_div" ref={villageref}>
                    <span className="add_isues_popup_label_names">Select Village <span className="add_issues_important_symbol">*</span></span>

                        <div
                            className="dropdown_top_Div_addissues d-flex align-items-center"
                            onClick={handlevillageopen}
                        >
                            <span className="ward_villages_text_pattern">
                                {villagename ? villagename : "Select Village"}
                            </span>
                            <img
                                className="ward_village_issues_dropdown_arrow"
                                src={arrow}
                                alt="not_visible"
                            />
                        </div>
                        <div className="wards_village_pop_up_error_msg">
                            {villageError ? villageError : null}
                        </div>
                        {/* {
                            villageError ? villageError : null
                        } */}
                        {villageisopen ? (
                            <div className="dropdown_bottom_Div_addissues">
                                {dropdownvillage
                                    ? dropdownvillage.map((item) => {
                                        return (
                                            <div
                                                key={item.ward_village_id}
                                                className="ward_village_add_dropdonw_addbutton d-flex align-items-center"
                                                onClick={() => {
                                                    handleselectVillage(
                                                        item.ward_village_id,
                                                        item.ward_village_name
                                                    );
                                                }}
                                            >
                                                <span>{item.ward_village_name}</span>
                                            </div>
                                        );
                                    })
                                    : "nodata"}
                            </div>
                        ) : null}
                    </div>
                    <div className="d-flex flex-column dropdownflex_div">
                        <div
                            className="dropdown_top_Div_addissues"
                            onClick={handlepollingopen}
                            style={{ display: "none" }}
                        >
                            {pollingname}.
                        </div>
                        {pollingopen ? (
                            <div className="dropdown_bottom_Div_addissues">
                                {dropdownPollings
                                    ? dropdownPollings.map((item) => {
                                        return (
                                            <div
                                                key={item.polling_booth_id}
                                                className="ward_village_add_dropdonw_addbutton d-flex align-items-center"
                                                onClick={() => {
                                                    handleselectpollings(
                                                        item.polling_booth_id,
                                                        item.polling_booth_name
                                                    );
                                                }}
                                            >
                                                <span>{item.polling_booth_name}</span>
                                            </div>
                                        );
                                    })
                                    : "nodata"}
                            </div>
                        ) : null}
                    </div>
                    {
                        modulevalue == 'Edit' ?
                            <div className="d-flex flex-column dropdownflex_div" ref={statusref}>
                             <span className="add_isues_popup_label_names">Issue Status</span>

                                <div
                                    className="dropdown_top_Div_addissues d-flex align-items-center" onClick={handleStatusClicked}
                                >
                                    <span className="ward_villages_text_pattern">{datapayload.issue_status ? datapayload.issue_status : ' Select status'}</span>
                                </div>
                                {
                                    statusOpen ? <div className="dropdown_bottom_Div_addissues">
                                        {
                                            statusdata.map((item) => {
                                                return (
                                                    <div
                                                        className="ward_village_add_dropdonw_addbutton d-flex align-items-center"
                                                        onClick={() => handleStatusSelected(item)}
                                                    >
                                                        <span>{item.status}</span>
                                                    </div>
                                                )
                                            })
                                        }


                                    </div> : null
                                }


                            </div> : null
                    }

                </div>
                <div className="d-flex flex-column wards_village_popup_tiledescri">
                    <div className="ward_villages_text_title_pattern">Title <span className="add_issues_important_symbol">*</span></div>
                    <input
                        className="ward_village_add_input_filed_dropdown"
                        type="text"
                        value={datapayload.issue_name}
                        placeholder="Call to Counsellor"
                        onChange={handlechangeTitle}
                    />
                    <div className="wards_village_pop_up_error_msg_title">
                        {issueNameError ? issueNameError : null}
                    </div>

                    <div className="ward_villages_text_title_pattern">Description<span className="add_issues_important_symbol">*</span></div>
                    <textarea
                        className="ward_village_add_input_filed_description_dropdown"
                        value={datapayload.issue_desc}
                        onChange={handleDescchange}
                        placeholder="Call to Counsellor"
                        style={{ resize: "none" }}
                        maxLength={100}
                    />
                    <div className="wards_village_pop_up_error_msg_description">
                        {issueDescError ? issueDescError : null}
                    </div>
                </div>
                <div className="ward_village_issues_footer">
                    <button
                        className="ward_village_issue_cancel_button"
                        onClick={() => handleCancleClicked()}
                    >
                        Cancel
                    </button>
                    <button
                        className="ward_village_issue_save_button"
                        onClick={() => saveissues()}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddIssues;
