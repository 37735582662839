import React, { useEffect, useRef, useState } from "react";
import {
    wardvillageissuesMandal,
    wardvillageissuesPollingStation,
    wardvillageissuesVillage,
    wardvillageissuesdropdown,
} from "../../Service/Wardvillageissues.service";
import '../Usermanagement/AssignvotersFilter.scss';
import { Savesubuser, addrolesdata, updatecheckvoter } from '../../Service/Usermanagemen.service'
import arrow from "../../Assets/dropdownarrow.png";

function AssignvotersFilters(props) {
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedmandalvalue, setselectedmandalvalue] = useState([]);
    const [selectedvillagevalue, setselectedvillagevalue] = useState([]);
    const [constituencies, setConstituencies] = useState([]);
    const [mandals, setMandals] = useState([]);
    const [villages, setVillages] = useState([]);
    const [constituencyisopen, setConstituencyisopen] = useState(false);
    const [mandalisopen, setmandalisopen] = useState(false);
    const [villageisopen, setvillageisopen] = useState(false);
    const [pollingisopen, setPollingisopen] = useState(false);
    const [pollings, setpollings] = useState();
    const [selectedpollings, setSelectedpollings] = useState([]);
    const previousselectedvallues = useRef([]);
    const previousselectedmandals = useRef([]);
    const previousselectedvillages = useRef([]);
    const [response, setResponse] = useState({});

    const tokenData = JSON.parse(localStorage.getItem("data"));
    const roledata = tokenData.user_data.role;

    useEffect(() => {
        wardvillageissuesdropdown().then((resp) => {
            setResponse(resp);
            const allConstituencyIds = resp.constituencies.map(
                (item) => item.constituency_id
            );
            setSelectedValues(allConstituencyIds);
        });
    }, []);

    useEffect(() => {
        setConstituencies(response.constituencies);
        if (response && selectedValues.length == 0) {
            setMandals(response.mandal);
        }
        if (response && selectedmandalvalue.length == 0) {
            setVillages(response.villages);
        }
        if (response && selectedvillagevalue.length == 0) {
            setpollings(response.polling);
        }
    }, [response, selectedmandalvalue, selectedvillagevalue, selectedpollings]);

    useEffect(() => {
        if (selectedValues.length > 0) {
            wardvillageissuesMandal(selectedValues).then((resp) => {
                setMandals(resp);
            });
        }
        previousselectedvallues.current = selectedValues;
    }, [selectedValues]);



    useEffect(() => {
        if (selectedmandalvalue.length > 0) {
            wardvillageissuesVillage(selectedmandalvalue).then((resp) => {
                setVillages(resp);
            });
        }
        previousselectedmandals.current = selectedmandalvalue;
    }, [selectedmandalvalue]);

    useEffect(() => {
        if (selectedvillagevalue.length > 0) {
            wardvillageissuesPollingStation(selectedvillagevalue).then((resp) => {
                setpollings(resp);
            });
        }
        previousselectedvillages.current = selectedvillagevalue;
    }, [selectedvillagevalue]);

    const handleCheckboxChange = (constituencyid) => {
        if (selectedValues !== previousselectedvallues.current.value) {
            setselectedmandalvalue([]);
            setselectedvillagevalue([]);
            setSelectedpollings([]);
        }

        if (selectedValues.includes(constituencyid)) {
            setSelectedValues(
                selectedValues.filter((item) => item !== constituencyid)
            );
        } else {
            setSelectedValues([...selectedValues, constituencyid]);
        }
    };
    const handleCheckboxChangemandal = (mandalid) => {
        if (selectedmandalvalue !== previousselectedmandals.current.value) {
            setselectedvillagevalue([]);
            setSelectedpollings([]);
        }

        if (selectedmandalvalue.includes(mandalid)) {
            setselectedmandalvalue(
                selectedmandalvalue.filter((item) => item !== mandalid)
            );
        } else {
            setselectedmandalvalue([...selectedmandalvalue, mandalid]);
        }
    };

    const handleCheckboxChangevillage = (villageid) => {
        if (selectedvillagevalue !== previousselectedvillages.current.value) {
            setSelectedpollings([]);
        }

        if (selectedvillagevalue.includes(villageid)) {
            setselectedvillagevalue(
                selectedvillagevalue.filter((item) => item !== villageid)
            );
        } else {
            setselectedvillagevalue([...selectedvillagevalue, villageid]);
        }
    };

    const handleCheckboxChangepollings = (pollingid) => {
        if (selectedpollings.includes(pollingid)) {
            setSelectedpollings(
                selectedpollings.filter((item) => item !== pollingid)
            );
        } else {
            setSelectedpollings([...selectedpollings, pollingid]);
        }
    };

    const clickedConstituencies = () => {
        setConstituencyisopen(!constituencyisopen);
    };
    const clickedmandal = () => {
        setmandalisopen(!mandalisopen);
    };
    const clickvillage = () => {
        setvillageisopen(!villageisopen);
    };
    const clickpollingstations = () => {
        setPollingisopen(!pollingisopen);
    };
    const [dataPost, setDataPost] = useState({
        voter_id: '',
        wardVillage: '',
        mandalTown: '',
        pollingStation: '',
    });


    const handlecheckvoter = () => {
        const voterId = dataPost.voter_id
        updatecheckvoter(voterId).then((resp) => {
            if (resp.status === false) {
                const data = resp.message;
                let populateddata
                data.map((items) => {
                    populateddata = items;
                })
                setDataPost({
                    ...dataPost,
                    voter_id: populateddata.voter_id,
                })
            }
        })
    }

    useEffect(() => {
        props.updateconstituencies(selectedValues);
        props.updatemandals(selectedmandalvalue);
        props.updatevillages(selectedvillagevalue);
        props.updatepollings(selectedpollings);
    }, [selectedValues, selectedmandalvalue, selectedvillagevalue, selectedpollings])

    const firstSelectedConstituency =
        selectedValues.length > 0
            ? constituencies.find(
                (option) => option.constituency_id === selectedValues[0]
            ).mla_constituency_name
            : "Select Constituency";

    const firstSelectedmandal =
        selectedmandalvalue.length > 0
            ? mandals.find(
                (option) => option.mandal_id === selectedmandalvalue[0]
            ).mandal_name
            : "Select Town/Mandal";

    const firstSelectevillages =
        selectedvillagevalue.length > 0
            ? villages.find(
                (option) => option.ward_village_id === selectedvillagevalue[0]
            ).ward_village_name
            : "Select Ward/Village";

    const firstSelectepollings =
        selectedpollings.length > 0
            ? (
                pollings.find(option => option.polling_booth_id === selectedpollings[0]) || {}
            ).polling_booth_no + "-" + (
                pollings.find(option => option.polling_booth_id === selectedpollings[0]) || {}
            ).polling_booth_name || "Polling stations"
            : "Select Polling Station";




    return (
        <div className="row">
            <div className="col col-sm-12 col-md-6">

            </div>
            {roledata === "MLA" ? null : (
                <div className="col col-sm-12 col-md-6 lables_assignvoter">
                    Constituency
                    <div
                        className="constituency_assignvoters"
                        onClick={clickedConstituencies}
                    >
                        <span className="assignvoters_filter_contstiency_text">
                            {firstSelectedConstituency}
                            {
                                selectedValues.length > 1 ? `+${selectedValues.length - 1} More` : null
                            }
                        </span>
                        <img
                            className="assignvoter_dropdown_arrow"
                            src={arrow}
                            alt="not_visible"
                        />
                    </div>
                    {constituencyisopen ? (
                        <div className="dropdown_assignvoter_data_div">
                            {constituencies && constituencies.length > 0
                                ? constituencies.map((item) => (
                                    <div
                                        className="lablesof-wardvillage_dropdown_data d-flex"
                                        key={item.constituency_id}
                                    >
                                        <input
                                            className="ward_village_filter_constiency_checkbox"
                                            type="checkbox"
                                            value={item.constituency_id}
                                            checked={selectedValues.includes(item.constituency_id)}
                                            onChange={() =>
                                                handleCheckboxChange(item.constituency_id)
                                            }
                                        />
                                        <span className="ward_village_filter_constiency_api_data">
                                            {item.mla_constituency_name}
                                        </span>
                                        {/* {item.mla_constituency_name} */}
                                    </div>
                                ))
                                : null}
                        </div>
                    ) : null}
                </div>
            )}
            <div className=" col col-sm-12 col-md-6 lables_assignvoter">
                Mandals

                <div className="constituency_assignvoters" onClick={clickedmandal}>
                    <span className="assignvoters_filter_contstiency_text">{
                        firstSelectedmandal}
                        {
                            selectedmandalvalue.length > 1 ? `+${selectedmandalvalue.length - 1} More` : null
                        }</span>
                    <img
                        className="assignvoter_dropdown_arrow"
                        src={arrow}
                        alt="not_visible"
                    />
                </div>
                {mandalisopen ? (
                    <div className="dropdown_assignvoter_data_div">
                        {mandals && mandals.length > 0
                            ? mandals.map((item) => (
                                <div
                                    className="lablesof-wardvillage_dropdown_data d-flex"
                                    key={item.mandal_id}
                                >
                                    <input
                                        className="ward_village_filter_constiency_checkbox"
                                        type="checkbox"
                                        value={item.mandal_id}
                                        checked={selectedmandalvalue.includes(item.mandal_id)}
                                        onChange={() =>
                                            handleCheckboxChangemandal(item.mandal_id)
                                        }
                                    />
                                    <span className="ward_village_filter_constiency_api_data">
                                        {item.mandal_name}
                                    </span>
                                </div>
                            ))
                            : null}
                    </div>
                ) : null}
            </div>
            <div className=" col col-sm-12 col-md-6 lables_assignvoter">
                Ward/Village
                <div className="constituency_assignvoters" onClick={clickvillage}>
                    <span className="assignvoters_filter_contstiency_text">
                        {firstSelectevillages}
                        {
                            selectedvillagevalue.length > 1 ? `+${selectedvillagevalue.length - 1} More` : null
                        }
                    </span>
                    <img
                        className="assignvoter_dropdown_arrow"
                        src={arrow}
                        alt="not_visible"
                    />
                </div>
                {villageisopen ? (
                    <div className="dropdown_assignvoter_data_div">
                        {villages && villages.length > 0
                            ? villages.map((item) => (
                                <div
                                    className="lablesof-wardvillage_dropdown_data d-flex"
                                    key={item.ward_village_id}
                                >
                                    <input
                                        className="ward_village_filter_constiency_checkbox"
                                        type="checkbox"
                                        value={item.ward_village_id}
                                        checked={selectedvillagevalue.includes(
                                            item.ward_village_id
                                        )}
                                        onChange={() =>
                                            handleCheckboxChangevillage(item.ward_village_id)
                                        }
                                    />
                                    <span className="ward_village_filter_constiency_api_data">
                                        {item.ward_village_name}
                                    </span>
                                </div>
                            ))
                            : null}
                    </div>
                ) : null}
            </div>

            <div className="col col-sm-12 col-md-6 lables_assignvoter">
                Polling Station
                <div
                    className="constituency_assignvoters"
                    onClick={clickpollingstations}
                >
                    <span className="assignvoters_filter_contstiency_text">
                        {firstSelectepollings}
                        {
                            selectedpollings.length > 1 ? `+${selectedpollings.length - 1} More` : null
                        }
                    </span>
                    <img
                        className="assignvoter_dropdown_arrow"
                        src={arrow}
                        alt="not_visible"
                    />
                </div>
                {pollingisopen ? (
                    <div className="dropdown_assignvoter_data_div">
                        {pollings && pollings.length > 0
                            ? pollings.map((item) => (
                                <div
                                    className="lablesof-wardvillage_dropdown_data d-flex"
                                    key={item.polling_booth_id}
                                >
                                    <input
                                        className="ward_village_filter_constiency_checkbox"
                                        type="checkbox"
                                        value={item.polling_booth_id}
                                        checked={selectedpollings.includes(item.polling_booth_id)}
                                        onChange={() =>
                                            handleCheckboxChangepollings(item.polling_booth_id)
                                        }
                                    />
                                    <span className="ward_village_filter_constiency_api_data">
                                        {item.polling_booth_name}
                                    </span>
                                </div>
                            ))
                            : null}
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default AssignvotersFilters;
