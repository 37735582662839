import React, { useEffect, useState } from 'react'
import './Addusers.css'
import '../Usermanagement/Assignvoter.scss'
import { useSearchParams } from 'react-router-dom'
import AssignvotersFilters from '../Usermanagement/AssignvotersFilter'
import { Savesubuser, addrolesdata, assignvotersdata, updatecheckvoter } from '../../Service/Usermanagemen.service'
import { Value } from 'devextreme-react/range-selector'


function Assignvoter(props) {
  const [dataPost, setDataPost] = useState({
    voter_id: "",
    constituency_id: "",
    mandal_id: "",
    ward_village_id: "",
    polling_booth_id: ""
  })
  const [module, setModule] = useState(null);
  const [initialrender, setInitialrender] = useState(false)
  const editdata = props.selectedVoterData;

  const [assigndata, setAssigndata] = useState();
  const [constituencies, setconstituencies] = useState();
  const [mandals, setmandals] = useState();
  const [villages, setvillages] = useState();
  const [pollings, setpollings] = useState();
  const [assignedvoters,setAsignedvoters]=useState()

  const [error, setError] = useState('');

  const [selectedValue, setSelectedValue] = useState()
  const modulevar = props.moduletype;

  const handleadduserClose = () => {
    props.handleClosed()
  }
  const updateconstituencies = (value) => {
    setconstituencies(value)
  }
  const updatemandals = (value) => {
    setmandals(value)
  }
  const updatevillages = (value) => {
    setvillages(value)
  }
  const updatepollings = (value) => {
    setpollings(value)
  }

  const saveuser = () => {
    if (!dataPost.voter_id) {
      setError('Voter ID is mandatory.');
      return;
    }
    setError('');
    assignvotersdata(dataPost)
      .then((resp) => {
        setSelectedValue(resp.data.selectedValue);
        console.log("Response from assignvotersdata API:", resp);
      })
    // .catch((error) => {
    //   console.error("An error occurred while calling assignvotersdata:", error);
    //   setError('Failed to save data. Please try again.'); 
    // });
  }

  useEffect(() => {
    const updatedDataPost = {};
    if (constituencies && constituencies.length > 0) {
      updatedDataPost.constituency_id = constituencies.join(',');
    }
    if (mandals && mandals.length > 0) {
      updatedDataPost.mandal_id = mandals.join(',');
    }
    if (villages && villages.length > 0) {
      updatedDataPost.ward_village_id = villages.join(',');
    }
    if (pollings && pollings.length > 0) {
      updatedDataPost.polling_booth_id = pollings.join(',');
    }
    setDataPost(updatedDataPost);
  }, [constituencies, mandals, villages, pollings]);



  const handlevoteridChange = (e) => {
    setDataPost({
      ...dataPost,
      voter_id: e.target.value
    });
  }

  useEffect(() => {
    if (initialrender == false) {
      setModule(props.modulevalue);
    }
    if (module == 'Edit') {
      setDataPost({
        ...dataPost,
        voter_id: editdata.voter_id,
        constituency_id: editdata.constituency_id,
        // mandal_id: "",
        // ward_village_id: "",
        // polling_booth_id: ""
      })
      setAsignedvoters(editdata.assigned_voters)
    }
    setInitialrender(true);
  }, [module])

  return (
    <div className='adduser_background_main_div'>
      <div className="assignvoter_pop_up_maindiv">
        <div className="add_user_modal_head">
          <span>Assigning Voters</span>
        </div>
        <div >
          <div className='information_bar'>
            <span id="add">Information</span>
          </div>
          <div className="assignvoter_label_div">
            <AssignvotersFilters
              updateconstituencies={updateconstituencies}
              updatemandals={updatemandals}
              updatevillages={updatevillages}
              updatepollings={updatepollings} />

            <p className='assignvoter_totalvoter'>Total Voters - {assignedvoters}</p>
          </div>
          <div className="add_user_underline_block">
            <div className="add_user_underline"></div>
          </div>
          <div className="col-md-6 voterid_field">
            <label>Voter ID Number</label>
            <input type="text"
              onChange={handlevoteridChange}
              value={dataPost.voter_id}
              className="constituency_assignvoters"
              placeholder='Enter Voter ID Number' />
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </div>
          <div className='assign_voter_buttons'>
            <button type="button" className="add_user_cancel_button" onClick={handleadduserClose}>Cancel</button>
            <button type="button" className="add_user_save_button"
              onClick={saveuser}
            >Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Assignvoter