
import { urlData } from "../config/apiconfig";
import axiosInstance from "./AxiosInstance";


const url = process.env.REACT_APP_SERVER_CONFIG;

export async function userManagementdata(pageurl) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const resp = await axiosInstance.get(`${pageurl}`)
  return resp.data;
}

export async function Savesubuser(dataPost,modulevar) {
  if(modulevar=='Edit'){
    console.log('edit')
    const response = await axiosInstance.patch(`${urlData.addsubusers}`,dataPost);
    return response.data;
  }
  else{
    const response = await axiosInstance.post(`${urlData.addsubusers}`,dataPost);
    return response.data;
  } 
}
export async function assignvotersdata(dataPost,modulevar) {
  if(modulevar=='assign'){
    console.log('assign')
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const resp = await axiosInstance.patch(urlData.assignvoter, dataPost);
    return resp.data;
  }
  else{
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const resp = await axiosInstance.post(urlData.assignvoter, dataPost)
    return resp.data;
  }
  
 
}
// export async function assignvotersdata(dataPost, isPatch = false) {
//   const tokenData = JSON.parse(localStorage.getItem("data"));
//   if (isPatch) {
//     const resp = await axiosInstance.patch(urlData.assignvoter, dataPost);
//     return resp.data;
//   } else {
//     const resp = await axiosInstance.post(urlData.assignvoter, dataPost);
//     return resp.data;
//   }
// }

export async function addrolesdata() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const resp = await axiosInstance.get(urlData.addrole)
  return resp.data;
}

export async function updatecheckvoter(voterId) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const resp = await axiosInstance.get(`${urlData.checkvoters}${voterId}`)
  return resp.data;
}

export async function deleteUser(userId) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.delete(`${urlData.deletesubusers}${userId}`);
  return response.data;
}

export async function activeUser(userId) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const resp = await axiosInstance.get(`${urlData.activeuser}${userId}`)
  return resp.data;
}