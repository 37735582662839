import React, { useState, useEffect, useRef } from "react";
import "./SettingsInfovotersdata.css";
import {
  getupdateCheckedvalues,
  updateCheckedvalues,
  votersDataColumnselection,
} from "../Service/voterdataservice";

function SettingsInfovotersdata(props) {
  const [posts, setPosts] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [checkedValues, setCheckedValues] = useState([]);
  const [getcheckedvalues, setGetschekedvalues] = useState({});
  const [SearchtermvoterID, setSearchtermvoterID] = useState("");

  const [storedPosts, setStoredPosts] = useState([]);

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      votersDataColumnselection().then((resp) =>
        setPosts(resp.map((post) => ({ post, selected: true })))
      );
      initialRender.current = false; 
    }
    
    
  }, []);
  
  useEffect(() => {
    // Read from local storage and set initial posts state
    const storedPosts = JSON.parse(localStorage.getItem('postsData')) || [];
    setPosts(storedPosts.map((post) => ({ post, selected: true })));
  }, []);

  useEffect(() => {
    setCheckedValues(
      posts.filter((post) => post.selected).map((post) => post.post)
    );
    const allSelected = posts.every((post) => post.selected);
    setSelectAll(allSelected);
       const lc = localStorage.setItem('postsData', JSON.stringify(posts));
  }, [posts]);

 

  const handleSelectAll = (event) => {
    const allSelected = event.target.checked;
    setSelectAll(allSelected);
    setPosts((posts) =>
      posts.map((post, index) => {
        if (index === 0) {
          return { ...post, selected: true };
        } else {
          return { ...post, selected: allSelected };
        }
      })
    );
  };



  const handleSelect = (event, id) => {
    if (id === posts[0].post && !event.target.checked) {
      event.preventDefault();
      return;
    }
    setPosts((posts) =>
      posts.map((post) =>
        post.post === id ? { ...post, selected: event.target.checked } : post
      )
    );
  };

  const checkeddata = {
    fields: checkedValues,
  };

  const handleClickedApplycolumnsection = () => {
    updateCheckedvalues(checkeddata).then((resp) => {
    });
    props.onhandleApplysettingclose();
  };

  const initialcolumnRender = useRef(true);
  useEffect(() => {
    if (initialcolumnRender.current) {
      getupdateCheckedvalues().then((resp) => {
        if (resp) {
          setGetschekedvalues(resp.columns);
        }
      });
      initialcolumnRender.current = false;
    }
  }, [posts]);

  useEffect(() => {
    props.selectedData(checkedValues);
  }, [checkedValues]);

  useEffect(() => {
    const updatedPosts = posts.map((post) => {
      if (getcheckedvalues[post.post]) {
        return { ...post, selected: true };
      } else {
        return { ...post, selected: false };
      }
    });
    setPosts(updatedPosts);
  }, [getcheckedvalues]);

  const cancleColumnselections = () => {
    props.oncancelcolumnsection();
  };

  const voteridSearch = (voter) =>
    SearchtermvoterID === "" ||
    voter.post.toLowerCase().includes(SearchtermvoterID.toLowerCase());
    
  const getDisplayName = (post) => {
    // console.log("post:", post);

    if (post === "LOC") {
      return "Location";
    }
    if (post === "HH") {
      return "House Head";
    }
    if (post === "XX") {
      return "Status";
    }
    return post;

  };


  return (
    <div className="settingsinfo_mainDiv d-flex flex-column align-items-center">
      <div className="column_section_main_div d-flex align-items-center">
        <span>Column Section</span>
      </div>
      <div className="settings_serach_div d-flex justify-content-center align-items-center">
        <input
          className="settings_search_columns"
          id="settings_search_columns"
          type={"text"}
          onChange={(e) => setSearchtermvoterID(e.target.value)}
          placeholder="🔍︎ Search"
        />
      </div>
      <div className="select_all_div d-flex align-items-center">
        <input
          type="checkbox"
          id="selectAll"
          checked={selectAll}
          onChange={handleSelectAll}
          className="selectall_checkbox"
        />
        <label htmlFor="selectAll" className="select_all_text">
          Select All
        </label>
      </div>
      <div className="scroll_main-div">
          {posts
          .filter((voter) => voteridSearch(voter))
          .map((post) => (
            <div
              className="select_all_div d-flex align-items-center"
              htmlFor={post.post}
              key={post.post}
            >
              <input
                className="selectall_checkbox"
                type="checkbox"
                checked={post.selected}
                onChange={(event) => handleSelect(event, post.post)}
                id={post.post}
              />
              <span>{getDisplayName(post.post)}</span>
            
            </div>
          ))}  

       
      </div>

      <div class="column_section_footer_" style={{ backgroundColor: "red" }}>
        <button
          className="cancel_column_section"
          onClick={cancleColumnselections}
        >
          Cancel
        </button>
        <button
          className="applybutton_column_section"
          onClick={handleClickedApplycolumnsection}
        >
          Apply
        </button>
      </div>
    </div>
  );
}

export default SettingsInfovotersdata;
