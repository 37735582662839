import React, { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import requireAuth from "./components/requireAuth";
import { user } from "./config/roleconfig";
import Dashboard from "./Pages/Dashboard";
import Otp from "./Pages/Otp.js";
import CreatePassword from "./Pages/CreatePassword.js";
import Forgotpassword from "./components/Forgotpassword.js";
import Loginpage from "./Pages/Loginpage";
import Signup from "./Pages/Signup";
import Votersdata from "./Pages/Votersdata";
import ImportDataPage from "./Pages/ImportData/ImportDataPage";
import AddCalender from "./Pages/AddCalender";
import Sidenav from "./components/Navbar/Sidenav";
import Topnav from "./components/Navbar/Topnav";
import RolePage from "./Pages/Role/RolePage";
import ReportPage from "./Pages/Reports/Reports";
import Birthday from "./Pages/Birthday";
import Footer from "./components/Footer";
import Mandal from "./Pages/Mandala";
import Villages from "./Pages/Villages";
import Ecampaign from "./Pages/Ecampaign";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import EcampaignViewStatus from "./Pages/EcampaignViewStatus";
import { RoleDataContext, RoleDataProvider } from "./AppContextComponent";
import { useContext } from "react";
import Usermanagement from "./Pages/Usermanagement/Usermanagement";
import Wardvillageissues from "./Pages/WardvillageIssues/Wardvillageissues";
import LocationPage from "./Pages/locationaccess.js";
import Map from "./Pages/map.js";
import Profile from "./Pages/Profile.js";

function App() {
  const [closenav, setClosenav] = useState(true);
  const [respclosenav, setRespClosenav] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isMobileDevice = /Mobi|Android/i.test(userAgent);

    setIsMobile(isMobileDevice);
  }, []);

  const handleTopNav = (val) => {
    const topNavEl = document.getElementsByClassName("topnav_main_div")[0];
    const constituencyFilterEl = document.getElementById("constituency_posn");
    const viewPortWidth = window.innerWidth;
    if (val === "show") {
      // reduce topnav width by 13.35% and apply transition values
      topNavEl.style.transition = "all 0.3s ease 0s";
      topNavEl.style.width = "86.65%"; // also change the width of element in topnav.css
      topNavEl.style.left = "13.35%";
      if (constituencyFilterEl && viewPortWidth > 600) {
        constituencyFilterEl.style.transition = "all 0.3s ease 0s";
        constituencyFilterEl.style.width = "19.8%";
        constituencyFilterEl.style.right = "3.3vw";
      }
    } else {
      // increase topnav to full length and apply transition values
      topNavEl.style.transition = "all 0.3s ease 0s";
      topNavEl.style.width = "100%";
      topNavEl.style.left = "0%";
      if (constituencyFilterEl && viewPortWidth > 600) {
        constituencyFilterEl.style.transition = "all 0.3s ease 0s";
        constituencyFilterEl.style.width = "23.1%";
        constituencyFilterEl.style.right = "3.8vw";
      }
    }
  };

  const showSideNav = () => {
    handleTopNav("show");
    setClosenav(true);
    setRespClosenav(true);
  };
  const hideSideNav = () => {
    handleTopNav("hide");
    setClosenav(false);
    setRespClosenav(false);
  };

  const renderComponent = (WrappedComponent, showFooter, props) => {
    // return (
    //   <div className="d-flex navholder_main_div">
    //     <div
    //       className="d-flex justify-content-center navholder_main_select_div "
    //       style={{
    //         height: "auto",
    //         width: "15.3vw",
    //         marginLeft: closenav ? "0vw" : "-15.29vw",
    //         transition: "0.3s",
    //         left: respclosenav ? "0vw" : "-100vw",
    //       }}
    //     >
    //       <Sidenav
    //         class="sidenavbar"
    //         toggleopend={hideSideNav}
    //         showSideNav={showSideNav}
    //       />
    //     </div>
    //     <div className="topnav_holder_div">
    //       <Topnav
    //         showSideNav={showSideNav}
    //         hideSideNav={hideSideNav}
    //         closenav={closenav}
    //       />
    //       <WrappedComponent {...props} closenav={closenav} />
    //       {showFooter ? <Footer /> : ""}
    //     </div>
    //   </div>
    // );
    return (
      <div className="App">
        {isMobile ? (
          // Render your application content for mobile and tablet devices
          // <div>
          //   <h1>Mobile and Tablet View</h1>
          //   {/* Render your mobile and tablet content here */}
          // </div>
        <div className="d-flex navholder_main_div">

        <div className="topnav_holder_div">
          {/* <Topnav
            showSideNav={showSideNav}
            hideSideNav={hideSideNav}
            closenav={closenav}
          /> */}
          <WrappedComponent {...props} closenav={closenav} />
          {showFooter ? <Footer /> : ""}
        </div>
      </div>
        ) : (
          // Render an error message for desktop and laptop devices
          <div>
            {/* <h1>Error: This application is only accessible on mobile and tablet devices</h1> */}
            {/* You can provide more details in the error message */}
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <RoleDataProvider>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route exact path="/signup" element={<Signup />} />
              <Route exact path="/map" element={<Map />} />

              <Route exact path="/login" element={<Loginpage />} />
              <Route exact path="/otp" element={<Otp />} />
              <Route
                exact
                path="/createpassword"
                element={<CreatePassword />}
              />
              <Route
                exact
                path="/forgotpassword"
                element={<Forgotpassword />}
              />
              <Route
                exact
                path="/importData/*"
                element={renderComponent(ImportDataPage, false)}
              />
              <Route
                exact
                path="/roles/*"
                element={renderComponent(RolePage, true)}
              />
              <Route
                exact
                path="/reports/*"
                element={renderComponent(ReportPage, true)}
              />
              <Route
                exact
                path=""
                element={requireAuth(renderComponent(Dashboard, true), [])}
              />
              <Route
                exact
                path="/votersdata"
                element={requireAuth(renderComponent(Votersdata, true), [
                  user.superadmin,
                  user.mp,
                  user.mla,
                  user.pa,
                  user.admin_staff,
                ])}
              />
              

              <Route
                exact
                path="/ecampaign-status"
                element={requireAuth(
                  renderComponent(EcampaignViewStatus, true),
                  []
                )}
              />
              <Route
                exact
                path="/add-calender"
                element={requireAuth(renderComponent(AddCalender, true), [])}
              />
 
              <Route
                exact
                path="/profile"
                element={requireAuth(renderComponent(Profile, true), [])}
              />
              <Route
                exact
                path="/birthdays"
                element={requireAuth(renderComponent(Birthday, true), [])}
              />
              <Route
                exact
                path="/e-campaign"
                element={requireAuth(renderComponent(Ecampaign, true), [])}
              />
               <Route
                exact
                path="/wardvillageissues"
                element={requireAuth(renderComponent(Wardvillageissues, true), [])}
              />
              <Route
                exact
                path="/usermanagement"
                element={requireAuth(renderComponent(Usermanagement, true), [])}
              />
              <Route
                exact
                path="/map-view"
                element={requireAuth(renderComponent(Villages, true), [])}
              />
            </Routes>
          </Suspense>
        </Router>
      </RoleDataProvider>
    </>
  );
}
export default App;
