import React, { useEffect, useState } from "react";
// import "./Login.css";
import "./LoginPage.css";
import logo from "../../src/Assets/itoclogo.png";
import Eye from "../Assets/Eye.png";
import EyeOpen from "../Assets/EyeOpen.png";

import { loginUser } from "../Service/service";
import { useNavigate, redirect, useLocation } from "react-router-dom";
import { decode } from "base-64";

function Loginpage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState(decode(getCookie("emailid")));
  const [password, setPassword] = useState(decode(getCookie("password")));
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [pwd, setPwd] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [errorresp, setErrorresp] = useState();
  const nav = useNavigate();
  const [locationTracker, setLocationTracker] = useState();
  const [isLocationPermissionGranted, setLocationPermissionGranted] =
    useState(false);

  useEffect(() => {
    console.log("Fetching location...");
    const watchId = navigator.geolocation.getCurrentPosition(
      async (position) => {
        console.log('Location fetched successfully:', position);
        // const { latitude, longitude } = position.coords;
        console.log('position.coords', position.coords);
        console.log('latitude', position.coords.latitude);
        console.log('longitude', position.coords.longitude);
      },
      (error) => {
      }
    );
    return () => navigator.geolocation.clearWatch(watchId);
  }, []);
  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const signupPage = () => {
    nav("/signup");
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!email) {
      setEmailError("Email required.");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email is invalid.");
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password required.");
    } else {
      setPasswordError("");
    }

    if (email && password) {
      loginUser(email, password, rememberme).then((data) => {
        const errMessageElement =
          document.getElementsByClassName("errMessage")[0];
        errMessageElement.style.display = "inline";
        errMessageElement.style.color = "red";
        errMessageElement.innerText = data.message ? data.message : "";
      });
    }
  };

  const [rememberme, setRememberme] = useState(false);
  const handleRememberme = () => {
    setRememberme(!rememberme);
  };
  // const [isSmallFont, setSmallFont] = useState(false);
  // const handleInputChange = (e) => {
  //   const inputValue = e.target.value;
  //   const shouldUseSmallFont = inputValue.length > 10;
  //   setSmallFont(shouldUseSmallFont);
  // };
  return (
    <>
      <div className="ems_login_page_main_div" 
      >
        <div className="ems_login_logo_div" 
        >
          <img className="login_emslogo" src={logo} alt="not visible" />
        </div>
        <div className="d-flex justify-content-center align-items-center itoconnect_logoname">
        <span 
        >iToconnect</span>
        </div>

        <div className="login_page_body_main_div d-flex justify-content-center align-items-center">
          <div className="login_page_body_sub_div d-flex flex-column justify-content-start">
            <div className="d-flex horizontal_login_line">
              <span className="ems_login_text">Login</span>
            </div>
            <div className="login_page_inputfields_">
            <form onSubmit={handleSubmit}>
              <div>
                <div className="login_email_input_div d-flex justify-content-center">
                  <input
                    type="email"
                    style={{border:'1px solid #0047B2'}}

                    placeholder="Enter Email"
                    className="login_page_input_fields"

                    value={email}
                    onChange={(event) =>
                      setEmail(event.target.value.toLowerCase()
                      )
                    }
                    required
                  />
                </div>
                {emailError && <div
                 className="error_msg_login"
                 id="error_msg_login"

                 >{emailError}</div>}
              </div>
              <div className="login_inputfileds_pwd">
                <div className="login_email_input_div d-flex justify-content-center">
                  <input
                    style={{border:'1px solid #0047B2'}}

                    className="login_page_input_fields"

                    placeholder="Enter Password"
                    
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    required
                    type={isRevealPwd ? "text" : "password"}
                  />
                  <img
                    className={`eye ${isRevealPwd ? 'eye-open' : 'eye-closed'}`}

                    alt=""
                    title={isRevealPwd ? "Hide password" : "Show password"}
                    src={isRevealPwd ? EyeOpen : Eye}
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                </div>

                {passwordError && <div 
                 id="error_msg_login"
                >{passwordError}</div>}
              </div>

              <span className="errMessage"></span>
              <div className="remeber_me_main_div">
              <input type="checkbox" className="remember_me_checkbox" onClick={handleRememberme} checked={rememberme} />
              <span className="login_page_rememberme">Remember me</span>
              </div>
            <a className="forgetpassword_anchortag_element" href="/forgotpassword">
  <u>Forgot Password?</u>
</a>
            <br></br>
              <button
                className="loginpage_login_button"
                type="submit"
                onClick={handleSubmit}
              >
                Login
              </button>
            </form>
            </div>
        
            <div className="privacy_policy_div">
              <span className="Donthaveanaccount_loginpage">
                Don’t have an account?{" "}
              </span>
            </div>
            <div className="singup_redirect_loginPage">
            <a href="/signup" id="href" className="privacy_policy_text">
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loginpage;
