import React, { Suspense, useContext, useEffect, useState } from "react";
import { useNavigate, redirect, useLocation } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import './RolePage.scss';
import { Button, Form, Stack, Modal, Col, CloseButton } from "react-bootstrap";
import { AiOutlineArrowRight } from "@react-icons/all-files/ai/AiOutlineArrowRight"
import {AiOutlinePlus} from "@react-icons/all-files/ai/AiOutlinePlus"
import { RoleDataService } from "../../Service/service";
import Toast from 'react-bootstrap/Toast';
import { RoleDataContext } from "../../AppContextComponent";
import { ROLES_PAGE_NAME_CONSTANTS } from "../../Utils/utils";

function RolePage() {

  const [roleData, setRoleData] = useState([]);
  const [showcreateModel, setShowcreateModel] = useState(false);
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedRoleRenderer, setSelectedRoleRenderer] = useState({Screens:[]});
  const [showSuccessTost, setShowSuccessTost] = useState({status: false});

  const statusDropDownOptions = [ {label:'Active', value:true}, {label:'Inactive',value: false}];
  const [showErrorRoleName,setShowErrorRoleName] = useState(false);
  const [showErrorRoleNameDuplicate,setShowErrorRoleNameDuplicate] = useState(false);
  const rolesService = new RoleDataService();
  const { roleDataService } = useContext(RoleDataContext);
  useEffect( ()=> {
    roleDataService.getPageRole(ROLES_PAGE_NAME_CONSTANTS.SettingsRole);
    rolesService.getRoles().then(result => {
      if( result.data) {
        setRoleData(result.data.Roles);
      }
    })
  },[])

  const renderDropdownOptions = () => {
    return statusDropDownOptions.map( x => {
      return <option value={x.value}>{x.label}</option>
    })
  }
  const renderRoleMain = () => {
    return roleData.map( (row, i) => {
      return <tr>
        <td>{row.role_name}</td>
        <td>
          <Stack direction="horizontal" gap={3}>
            <Form.Select value={row.is_active}  
              aria-label="mapping field"
              disabled= {!roleDataService.access.update }>
              {renderDropdownOptions()}
            </Form.Select>
            {roleDataService.access.update ? 
            <Button variant="secondary" onClick={()=>{showCreateRoleModel(row)}}>{<AiOutlineArrowRight />}</Button> : ''}
          </Stack>
        </td>
      </tr>
    })
  }

  const renderUpdateRoles = (data) => {
    let _selectedData;
    if ( data.role_name) {
      _selectedData = {...data};
      setSelectedRoleRenderer(_selectedData);
    } else {
      rolesService.getScreens().then(result => {
        if( result.data && result.data.Screens) {
          _selectedData = {role_name:"", is_active:true}; 
          _selectedData['newRole'] = true;
          _selectedData['Screens'] = [];
          result.data.Screens.forEach( x => {
            let _screenData = {};
            _screenData['page_name'] = x;
            _screenData['create_role'] = false;
            _screenData['read_role'] = false;
            _screenData['update_role'] = false;
            _screenData['delete_role'] = false;
            _selectedData.Screens.push(_screenData);
          })
          setSelectedRoleRenderer(_selectedData);
          setSelectedRole(_selectedData);
        }
      })
      
    }
  }

  const showCreateRoleModel = (_selectedRole) => {
    if(_selectedRole) {
    rolesService.getPermissions(_selectedRole.role_id).then(result => {
      if( result.data) {
        _selectedRole['Screens'] = result.data;
        setSelectedRole(_selectedRole);
        renderUpdateRoles(_selectedRole);
      }
    })
      
    } else {
      renderUpdateRoles({});
      setSelectedRole({});
    }
    setShowcreateModel(true);
  }

  

  const changePermissionCheck = (evt, row, permissionName) => {
    row[permissionName] = evt.target.checked;
    let index = selectedRoleRenderer.Screens.findIndex(x => x.page_name === row.page_name);
    let _data = {...selectedRoleRenderer};
    _data.Screens[index] = row;
    setSelectedRoleRenderer(_data);
  }
  const validateRoleName = () => {
    if ( !selectedRole.role_name ) {
      setShowErrorRoleNameDuplicate(false);
      setShowErrorRoleName(true);
      return false;
    } else if ( roleData.some(x => x.role_name.toLowerCase() === selectedRole.role_name.toLowerCase())) {
      setShowErrorRoleName(false);
      setShowErrorRoleNameDuplicate(true);
      return false;
    } else {
      setShowErrorRoleName(false);
      setShowErrorRoleNameDuplicate(false);
      return true;
    }
  }
  const createModelClose = (saveFlag) => {
    if(saveFlag) {
      let _data = [...roleData];
      let _roleData = {...selectedRole}
      _roleData.Screens = selectedRoleRenderer.Screens;
      if (validateRoleName()) {
        if(selectedRole.newRole) {
          delete _roleData.newRole;
          rolesService.addRole(_roleData).then(result => {
            if (result.data) {
              if(result.data.status) {
                setShowSuccessTost({status: true, type:'success', message: 'Data Saved Successfully'});
                rolesService.getRoles().then(result => {
                  if( result.data) {
                    setRoleData(result.data.Roles);
                    setSelectedRole({});
                    setShowcreateModel(false);
                  }
                })
              }
            } else {
              setSelectedRole({});
              setShowcreateModel(false);
              setShowSuccessTost({status: true,type:'error', message: 'Failed to save Data'});
            }
          }).catch( error => {
            setSelectedRole({});
            setShowcreateModel(false);
            setShowSuccessTost({status: true,type:'error', message: 'Failed to save Data'});
          });
        } else {
          rolesService.editRole(_roleData).then(result => {
            if (result.data) {
              if(result.data.status) {
                rolesService.getRoles().then(result => {
                  setShowSuccessTost({status: true, type:'success', message: 'Data Saved Successfully'});
                  if( result.data) {
                    setRoleData(result.data.Roles);
                    setSelectedRole({});
                    setShowcreateModel(false);
                  }
                })
              } else {
                setSelectedRole({});
                setShowcreateModel(false);
                setShowSuccessTost({status: true,type:'error', message: 'Failed to save Data'});
              }
            }
          }).catch( error => {
            setSelectedRole({});
            setShowcreateModel(false);
            setShowSuccessTost({status: true,type:'error', message: 'Failed to save Data'});
          });
        }
      }
      
    } else {
      setSelectedRole({});
      setShowcreateModel(false);
    }
    

  }

  const onChangeRoleName = (evt) => {
    if ( evt.target) {
      let _data = {...selectedRole};
      _data.role_name = evt.target.value;
      
      setSelectedRole(_data);
    }
  }
  const statusChangeforRole = (evt, row) => {
    if(evt.target) {
      let flag;
      if (typeof(evt.target.value) == 'string') {
        if ( evt.target.value === 'true') {
          flag = true;
        } else {
          flag = false;
        }
      } else {
        flag = evt.target.value;
      }
      let _data = {...row};
      _data.is_active = flag;
      
      setSelectedRole(_data);
    }
  }
  return (
      <div className="role-view container-fluid">
        <Row className="title">
        <Stack direction="horizontal" gap={3}>
          <div>
            <div>Roles</div>
          </div>
          {roleDataService.access.create ? <div className="ms-auto create-button">
            <Button onClick={()=>{showCreateRoleModel(null)}}><AiOutlinePlus/> Create Role</Button>
          </div> : '' }
        </Stack>
        <hr />
        </Row>
        <Row>
          {showSuccessTost.message ? <Toast show={showSuccessTost.status} className="d-inline-block m-1" bg={showSuccessTost.type === 'success'? 'success' : 'danger'}>
              <Toast.Body>
                <Stack className="text-white" direction="horizontal" gap={3}>
                  <div>{showSuccessTost.message}</div>
                  <CloseButton className="btn-close ms-auto text-white" onClick={()=> setShowSuccessTost({status: false})} />
                </Stack>
                </Toast.Body>
            </Toast> : '' }
        </Row>
        <Row className="m-3 table-main">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {renderRoleMain()}
            </tbody>
          </Table>
        </Row>
        {showcreateModel? <Modal show={showcreateModel} onHide={()=>{createModelClose(false)} } size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title> {selectedRole.newRole ? 'Create' : 'Update'} a Role </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm xs md="12" lg xl="6">
                <div>Name</div>
                <Form.Control 
                  required 
                  type="text" 
                  name="roleName"
                  value={selectedRole.role_name} 
                  onChange={(evt)=>{onChangeRoleName(evt)}} 
                  placeholder="Role Name"
                  />
                <Form.Control.Feedback type="invalid" style={showErrorRoleName ? {display:'block'} : {}} >
                  Please enter Role Name.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid" style={showErrorRoleNameDuplicate ? {display:'block'} : {}}  >
                  Role Name can not be same as Existing Roles.
                </Form.Control.Feedback>
              </Col>
              <Col sm xs md="12" lg xl="6">
                <div>Status</div>
                <Form.Select value={selectedRole.is_active} 
                  onChange={(evt) => {statusChangeforRole(evt,selectedRole)}}
                  aria-label="mapping field">
                  {renderDropdownOptions()}
                </Form.Select>
              </Col>
            </Row>
            <Row className="mt-2">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Menu</th>
                    <th>Create</th>
                    <th>Read</th>
                    <th>Update</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedRoleRenderer.Screens.map( row => {
                    return <tr>
                      <td>{row.page_name}</td>
                      <td>
                        <Form.Check  checked={row.create_role} onChange={(evt)=>{changePermissionCheck(evt,row,'create_role')} } />
                      </td>
                      <td>
                        <Form.Check  checked={row.read_role} onChange={(evt)=>{changePermissionCheck(evt,row,'read_role')} }  />
                      </td>
                      <td>
                        <Form.Check  checked={row.update_role} onChange={(evt)=>{changePermissionCheck(evt,row,'update_role')}} />
                      </td>
                      <td>
                        <Form.Check  checked={row.delete_role} onChange={(evt)=>{changePermissionCheck(evt,row,'delete_role')}} />
                      </td>
                    </tr>
                  })}
                </tbody>
              </Table>
            </Row>
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{createModelClose(false)}}>
              Close
            </Button>
            <Button variant="primary" onClick={()=>{createModelClose(true)}}>
              Save
            </Button>
          </Modal.Footer>
        </Modal> : '' }
      </div>
  );
}

export default RolePage;