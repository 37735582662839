import moment from "moment";

let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

export const INITIAL_EVENTS22 = [
  {
    id: createEventId(),
    title: "All-day event",
    start: "2023-03-27T10:30:00+05:30",
    end: "2023-03-27T11:30:00+05:30",
    description: "This is event 1 description",
  },
  {
    id: createEventId(),
    title: "Timed event",
    start: "2023-03-27T12:30:00+05:30",
    end: "2023-03-27T01:30:00+05:30",
    description: "This is event 2 description",
  },
];

export function createEventId() {
  return String(eventGuid++);
}

export const calenderFromToHrs = [
  {
    key: "12:00am",
    value: "00:00",
  },
  {
    key: "12:30am",
    value: "00:30",
  },
  {
    key: "01:00am",
    value: "01:00",
  },
  {
    key: "01:30am",
    value: "01:30",
  },
  {
    key: "02:00am",
    value: "02:00",
  },
  {
    key: "02:30am",
    value: "02:30",
  },
  {
    key: "03:00am",
    value: "03:00",
  },
  {
    key: "03:30am",
    value: "03:30",
  },
  {
    key: "04:00am",
    value: "04:00",
  },
  {
    key: "04:30am",
    value: "04:30",
  },
  {
    key: "05:00am",
    value: "05:00",
  },
  {
    key: "05:30am",
    value: "05:30",
  },
  {
    key: "06:00am",
    value: "06:00",
  },
  {
    key: "06:30am",
    value: "06:30",
  },
  {
    key: "07:00am",
    value: "07:00",
  },
  {
    key: "07:30am",
    value: "07:30",
  },
  {
    key: "08:00am",
    value: "08:00",
  },
  {
    key: "08:30am",
    value: "08:30",
  },
  {
    key: "09:00am",
    value: "09:00",
  },
  {
    key: "09:30am",
    value: "09:30",
  },
  {
    key: "10:00am",
    value: "10:00",
  },
  {
    key: "10:30am",
    value: "10:30",
  },
  {
    key: "11:00am",
    value: "11:00",
  },
  {
    key: "11:30am",
    value: "11:30",
  },
  {
    key: "12:00pm",
    value: "12:00",
  },
  {
    key: "12:30pm",
    value: "12:30",
  },
  {
    key: "01:00pm",
    value: "13:00",
  },
  {
    key: "01:30pm",
    value: "13:30",
  },
  {
    key: "02:00pm",
    value: "14:00",
  },
  {
    key: "02:30pm",
    value: "14:30",
  },
  {
    key: "03:00pm",
    value: "15:00",
  },
  {
    key: "03:30pm",
    value: "15:30",
  },
  {
    key: "04:00pm",
    value: "16:00",
  },
  {
    key: "04:30pm",
    value: "16:30",
  },
  {
    key: "05:00pm",
    value: "17:00",
  },
  {
    key: "05:30pm",
    value: "17:30",
  },
  {
    key: "06:00pm",
    value: "18:00",
  },
  {
    key: "06:30pm",
    value: "18:30",
  },
  {
    key: "07:00pm",
    value: "19:00",
  },
  {
    key: "07:30pm",
    value: "19:30",
  },
  {
    key: "08:00pm",
    value: "20:00",
  },
  {
    key: "08:30pm",
    value: "20:30",
  },
  {
    key: "09:00pm",
    value: "21:00",
  },
  {
    key: "09:30pm",
    value: "21:30",
  },
  {
    key: "10:00pm",
    value: "22:00",
  },
  {
    key: "10:30pm",
    value: "22:30",
  },
  {
    key: "11:00pm",
    value: "23:00",
  },
  {
    key: "11:30pm",
    value: "23:30",
  },
];

let currentTime = moment().format("h:mm A");
const time = moment(currentTime, "h:mm A");

export let defTimeFrom = roundTime(time).format("hh:mm A");

function roundTime(time) {
  let roundedTime = moment(time);
  let minutes = roundedTime.minutes();

  if (minutes > 30) {
    roundedTime.add(1, "hour").minute(0);
  } else if (minutes > 0) {
    roundedTime.minute(30);
  } else {
    roundedTime.minute(0);
  }
  return roundedTime;
}

const endTime = addMinutesToTime(defTimeFrom, 30);
export let defTimeTo = endTime;

function addMinutesToTime(time, minutesToAdd) {
  if (!time) {
    return null;
  }

  const timeMoment = moment(time, "hh:mm A");

  if (!timeMoment.isValid()) {
    return null;
  }

  const newTimeMoment = timeMoment.clone().add(minutesToAdd, "minutes");
  return newTimeMoment.format("hh:mm A");
}

export const REPORTS_CONSTANTS = [
  {label: 'Pooling Booth Details',
  fileName:''},
  {label: 'Booth History',
  fileName:''},
  {label: 'Projections',
  fileName:''},
  {label: 'Voter Data',
  fileName:''},
  {label: 'Summary',
  fileName:''},
  {label: 'Pooling Booth Details',
  fileName:''}
];

export const ROLES_PAGE_NAME_CONSTANTS = {
  Dashboard: 'Dashboard',
  VoterData: 'Voters Data',
  Calendar: 'Calendar',
  UserManagement: 'User Management',
  ECampaigns: 'E-Campaigns',
  Birthdays: 'Birthdays',
  ImportData: 'Import Data',
  Reports: 'Reports',
  SettingsRole: 'Settings-Roles',
  SettingsVoterType: 'Settings-Voter Type',
  SettingsConfiguration: 'Settings-Configuration'
};