import React from "react";
import "./CreatePassword.css";
// import emslogo from "../Assets/emslogo.png";
import emslogo from "../../src/Assets/itoclogo.png";
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { createNewPassword } from "../Service/service";
import LoadingScreen from "../components/LoaderWrapper";
import LeftArrow from "../Assets/leftarrrowmobile.png";
import { useNavigate } from "react-router-dom";

const CreatePassword = () => {
  const navigate = useNavigate();

  const [passwordVal, setPasswordVal] = useState("");
  const [strength, setStrength] = useState("");
  const [passwordused, setPasswordused] = useState();
  const [disablebutton, setDisablebutton] = useState(false);

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPasswordVal(passwordValue);
    setStrength(checkPasswordStrength(passwordValue));
  };
  const confirmPasswordListener = (value) => {
    // debugger
    // trigger("ConfirmPassword");
    var pwd = document.getElementById("password").value;
    return value === pwd ? true : "The passwords do not match";
    // var pwd = document.getElementById("password").value;
    // var cpwd = document.getElementById("ConfirmPassword").value;
    // if (pwd != cpwd) {
    //   document.getElementById("passwordmessage").style.display =
    //     "block";
    //   // document.getElementById("passwordmessage").innerHTML =
    //   //   "password mismatch";
    //   document.getElementById("passwordmessage").style.color =
    //     "red";
    // } else {
    //   document.getElementById("passwordmessage").style.display =
    //     "none";
    //   // return false;
    // }
  };
  const checkPasswordStrength = (password) => {
    let strength = 0;

    // Check for uppercase letters
    // if (password.match(/[A-Z]/)) {
    //   strength++;
    // }

    // Check for lowercase letters
    if (password.match(/[a-z]/)) {
      strength++;
    }

    // Check for numbers
    if (password.match(/[0-9]/)) {
      strength++;
    }

    // Check for symbols
    if (password.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/)) {
      strength++;
    }

    // Check for length
    if (password.length >= 8) {
      strength++;
    }

    switch (strength) {
      case 1:
        return "very weak";
      case 2:
        return "weak";
      case 3:
        return "medium";
      case 4:
        return "strong";
      default:
        return "";
    }
  };
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors },
  } = useForm();
  const [createPassword, setcreatePassword] = useState();
  let password = useRef();
  let ConfirmPassword = useRef();
  let passwordData = {};

  let token = window.location.href;
  console.log("token:", token);
  let bearertoken = token.split("?");

  let updatedbearertoken = bearertoken[1];
  var newtoken = "5lCBvDUQzaiwhu6We0FORtnXLmgx8d";
  const onSubmit = (data) => {
    passwordData = getValues();
    setcreatePassword(passwordData);
    setDisablebutton(true);
    createNewPassword(updatedbearertoken, passwordData)
      .then((apiData) => {
        console.log("apiData:", apiData);
        if (apiData === "password set successfull...") {
          window.location.href = "/login";
          setDisablebutton(false);
        } else if (apiData.message) {
          //This password is already in use
          setPasswordused(apiData.message);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const val =
      passwordused === "This password is already in use"
        ? setDisablebutton(false)
        : "";
  }, [passwordused]);

  useEffect(() => {}, [createPassword]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="cp_emslogo">
        <img src={emslogo} alt="Waiting..." />
      </div>

      <div onClick={handleGoBack} className="bac_arrow_main_div">
        <img
          src={LeftArrow}
          alt="back_arrow"
          className="leftarrow_img_createpasswordimg"
        />
      </div>
      
      <form className="cpword__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="cpword__main">
          <div className="cpword__sub">
            <div className="cpword__heading">
              <span className="cpword__hrline" />
              <h3>Create Password</h3>
              <span className="cpword__hrline" />
            </div>
            <p className="cpword__text">New Password</p>
            <input
              className="forgot_password_input_value"
              placeholder="New Password"
              type="password"
              id="password"
              name="password"
              ref={password}
              {...register("password", {
                required: "Password is required",
                pattern: {
                  //   value: /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{7,}$/,
                  value:
                    /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z!#$@^%&? "])[a-zA-Z0-9!#$@^%&?]{8,}$/,
                  message:
                    "Please choose a stronger password. Try a mix of letters, numbers and symbols.",
                },
              })}
              onBlur={(e) => {
                trigger("password");
              }}
              onKeyUp={(e) => {
                handlePasswordChange(e);
                trigger("password");
              }}
            />
            {errors.password && (
              <p id="nameAlert" class="cpword__validation_text">
                {" "}
                {errors.password.message}
              </p>
            )}
            {/* {strength && <div>Password Strength: {strength}</div>} */}
            {strength && (
              <div class="indicator">
                <div
                  className={
                    strength === "very weak" ? "strength" : "strengthNoshow"
                  }
                >
                  <span
                    className={strength === "very weak" ? "veryweak" : ""}
                  ></span>
                  {/* <span className={strength === "weak"? "weak":""}></span>
                <span className={strength === "medium"? "medium":""}></span>
                <span className={strength=== "strong"? "strong":""}></span> */}
                </div>
                <div
                  className={
                    strength === "weak" ? "strength" : "strengthNoshow"
                  }
                >
                  <span className="veryweak"></span>
                  <span className={strength === "weak" ? "weak" : ""}></span>
                  {/*<span className={strength === "medium"? "medium":""}></span>
                <span className={strength=== "strong"? "strong":""}></span> */}
                </div>
                <div
                  className={
                    strength === "medium" ? "strength" : "strengthNoshow"
                  }
                >
                  <span className="veryweak"></span>
                  <span className="weak"></span>
                  <span
                    className={strength === "medium" ? "medium" : ""}
                  ></span>
                  {/* <span className={strength=== "strong"? "strong":""}></span> */}
                </div>
                <div
                  className={
                    strength === "strong" ? "strength" : "strengthNoshow"
                  }
                >
                  <span className="veryweak"></span>
                  <span className="weak"></span>
                  <span className="medium"></span>
                  <span
                    className={strength === "strong" ? "strong" : ""}
                  ></span>
                </div>
              </div>
            )}
            <p className="cpword__text">Confirm Password</p>
            <input
              className="forgot_password_input_value"

              placeholder="Confirm Password"
              type="password"
              id="ConfirmPassword"
              name="ConfirmPassword"
              ref={ConfirmPassword}
              {...register("ConfirmPassword", {
                required: "Confirm Password is required",
                validate: confirmPasswordListener,
              })}
              // onBlur={confirmPasswordListener}
            />
            {errors.ConfirmPassword && (
              <p id="nameAlert" className="cpword__validation_text">
                {" "}
                {errors.ConfirmPassword.message}
              </p>
            )}
            {passwordused ? (
              <div className="password_already_used_parent d-flex align-items-center justify-content-center">
                <span className="password_already_used">{passwordused}</span>
              </div>
            ) : null}
            <p
              style={{ display: "none", marginTop: "0px" }}
              class="cpword__validation_text"
              id="passwordmessage"
            >
              Those passwords didn’t match. Try again.
            </p>
            <button disabled={disablebutton} className="createpword__button">
              Confirm
            </button>
          </div>
        </div>
      </form>
      <div className="cp_emptyspace"></div>
      <div className="copyright">© 2023 EMS ----- All Right Reserved</div>
      {disablebutton ? <LoadingScreen /> : null}
    </div>
  );
};

export default CreatePassword;
