// eslint-disable-next-line no-restricted-globals
const ignored = self.__WB_MANIFEST;


export const register = () => {


async function fetchLocationAndAPICall() {
  try {
    // Fetch the user's location
    const position = await getCurrentLocation();
    console.log('position',position);

    // Make API call to update location on the server
    await updateLocationOnServer(position.coords.latitude, position.coords.longitude);
    console.log('Location updated successfully');
  } catch (error) {
    console.error('Error updating location:', error.message);
  }
}

// Function to get current location using Geolocation API
async function getCurrentLocation() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => resolve(position),
      (error) => reject(error)
    );
  });
}

// Function to make API call to update user's location on the server
async function updateLocationOnServer(latitude, longitude) {
  const userData = JSON.parse(localStorage.getItem('data'));
  const locationData = {
    user_id: userData.user_data.user_id,
    latitude,
    longitude,
    user_name: userData.user_data.first_name
  };
  const response = await fetch('https://aum2086ycf.execute-api.us-east-1.amazonaws.com/dev/updatelocation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(locationData)
  });
  if (!response.ok) {
    throw new Error('Failed to update location');
  }
}

// Schedule API call every 5 seconds
setInterval(fetchLocationAndAPICall, 180000);
// eslint-disable-next-line no-restricted-globals
}