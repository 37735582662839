import React, {
  createRef,
  useContext,
  Suspense,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Stack,
  Button,
  InputGroup,
  Spinner,
  ProgressBar,
} from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import Tab from "react-bootstrap/Tab";
import StepProgressComponent from "../StepProgressComponent/StepProgressComponent";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import greenTick from "../../../Assets/GreenTick.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  icon,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import "./ImportDataUploadComponent.scss";
import { ImportDataContext } from "../../../Pages/ImportData/ImportDataContext";
import { ImportConstants } from "../../../Pages/ImportData/ImportConstants";
import Select from "react-select";
import * as XLSX from "xlsx";
import { RoleDataContext } from "../../../AppContextComponent";

function ImportDataUploadComponent() {
  const { importDataService } = useContext(ImportDataContext);
  const [showVotersUpload, setShowVotersUpload] = useState(false);
  const [enableNextButton, setEnableNextButton] = useState(true);
  const [enablePreviousButton, setEnablePreviousButton] = useState(true);
  const [disableCancel, setDisableCancel] = useState(false);
  const [importTypeSelection, setImportTypeSelection] = useState(
    ImportConstants.IMPORT_TYPE_POLLING_STATION
  );
  const [selectedFile, setSelectedFile] = useState();
  const [progresBar, setProgresBar] = useState(0);
  const [showPollingStation, setshowPollingStation] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [enableUpload, setEnableUpload] = useState(true);
  const [showVillage, setShowVillage] = useState(false);
  const [showPDFIcon, setShowPDFIcon] = useState(true);
  const [showExelIcon, setShowExelIcon] = useState(true);
  const [showFileUploadSession, setShowFileUploadSession] = useState(false);
  const [pollingStations, setPollingStations] = useState([]);
  const [constituencies, setConstituencies] = useState([]);
  const [villages, setVillages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  const { roleDataService } = useContext(RoleDataContext);
  const [progress1, setProgress1] = useState(0);
  const isButtonDisabled = progress1 === 100;

  const addVotersMenu = [
    "Add new voters",
    "Update existing voters",
    "Add new and update existing voters",
  ];
  let fileRef = new createRef();
  const navigation = useNavigate();
  const progressTime = 180;
  let startTime = 0;
  const timeIntervelScale = 3;
  let progressIntervel = null;
  let progressbarFlag = true;

  useEffect(() => {
    if (importDataService.exportedJsonData.voterData.length) {
      setEnableNextButton(true);
    } else {
      setEnableNextButton(false);
    }
    initiateDataUpload();
  }, []);

  useEffect(() => {
    initiateDataUpload();
  }, [importTypeSelection]);

  const initiateDataUpload = () => {
    if (importDataService.tokenData.user_data.role === "MP") {
      setshowPollingStation(false);
      getConstituencies(false);
    } else {
      if (importTypeSelection === ImportConstants.IMPORT_TYPE_CONSTITUENCY) {
        setShowExelIcon(true);
        setShowPDFIcon(false);
        setConstituencyDataUpload();
      } else {
        setShowExelIcon(false);
        setShowPDFIcon(true);
        setshowPollingStation(true);
        getConstituencies(true);
        getPollingStation();
      }
    }
  };
  const getConstituencies = (MLARole) => {
    importDataService.getConstituencies().then((result) => {
      if (result.data) {
        if (MLARole) {
          importDataService.importDataSelected.constituency = result.data[0];
        } else {
          setConstituencies(result.data);
        }
      }
    });
  };
  const getPollingStation = () => {
    importDataService.getPollingBooth().then((result) => {
      if (result.data) {
        setPollingStations(
          result.data.sort((a, b) => {
            if (
              a.polling_booth__polling_booth_no <
              b.polling_booth__polling_booth_no
            ) {
              return -1;
            }
            if (
              a.polling_booth__polling_booth_no >
              b.polling_booth__polling_booth_no
            ) {
              return 1;
            }
            return 0;
          })
        );
      }
    });
  };
  const getPollingStationByConstitution = (constitutionID) => {
    importDataService
      .getPollingBoothByConstituency(constitutionID)
      .then((result) => {
        if (result.data) {
          setPollingStations(
            result.data.sort((a, b) => {
              if (
                a.polling_booth__polling_booth_no <
                b.polling_booth__polling_booth_no
              ) {
                return -1;
              }
              if (
                a.polling_booth__polling_booth_no >
                b.polling_booth__polling_booth_no
              ) {
                return 1;
              }
              return 0;
            })
          );
        }
      });
  };
  const getVillages = (pollingStation) => {
    importDataService
      .getvillagesForPollingBooth(pollingStation)
      .then((result) => {
        if (result.data) {
          setVillages(result.data);
          if (result.data.length > 1) {
            setShowVillage(true);
            importDataService.importDataSelected.hasMultipleVillages = true;
          } else {
            setShowVillage(false);
            importDataService.importDataSelected.hasMultipleVillages = false;
          }
        }
      });
  };

  const onChangePollingBooth = (evt) => {
    if (evt.target.value) {
      let _data = JSON.parse(evt.target.value);
      getVillages(_data.polling_booth__polling_booth_id);
      setShowFileUploadSession(true);
      importDataService.importDataSelected.polingStation = _data;
    }
  };
  const onChangeConstituency = (evt) => {
    if (evt.target.value) {
      let _data = JSON.parse(evt.target.value);
      if (importTypeSelection === ImportConstants.IMPORT_TYPE_CONSTITUENCY) {
        setConstituencyDataUpload();
      } else {
        getPollingStationByConstitution(_data.constituency_id);
        setshowPollingStation(true);
      }
      importDataService.importDataSelected.constituency = _data;
    }
  };
  const onChangeVillages = (evt, village) => {
    if (evt.target.value) {
      let _data = JSON.parse(evt.target.value);
      if (village === "village1") {
        importDataService.importDataSelected.village1.village = _data;
      } else {
        importDataService.importDataSelected.village2.village = _data;
      }
    }
  };

  const onChangeVillageSequance = (evt, village, fromToFlag) => {
    if (evt.target.value) {
      if (village === "village1") {
        if (fromToFlag === "from") {
          importDataService.importDataSelected.village1.from = evt.target.value;
        } else {
          importDataService.importDataSelected.village1.to = evt.target.value;
        }
      } else {
        if (fromToFlag === "from") {
          importDataService.importDataSelected.village2.from = evt.target.value;
        } else {
          importDataService.importDataSelected.village2.to = evt.target.value;
        }
      }
    }
  };

  const renderConstituencies = (evt) => {
    return constituencies.map((x) => {
      return (
        <option value={JSON.stringify(x)}>{x.mla_constituency_name}</option>
      );
    });
  };
  const renderPollingStation = (evt) => {
    return pollingStations.map((x) => {
      return (
        <option
          value={JSON.stringify(x)}
        >{`${x.polling_booth_no}-${x.polling_booth_name}`}</option>
      );
    });
  };
  const renderVillages = () => {
    return villages.map((x) => {
      return (
        <option value={JSON.stringify(x)}>
          {x.ward_village__ward_village_name}
        </option>
      );
    });
  };
  const renderAddVotersMenu = () => {
    return addVotersMenu.map((x, i) => {
      return (
        <ListGroup.Item action href={"#" + i} onClick={showPDFUpload}>
          <Stack direction="horizontal" gap={3}>
            <div>{x}</div>
            <div className="ms-auto">
              <img className="success-icon" src={greenTick} alt="Success" />
              <FontAwesomeIcon
                className="arrow-icon"
                icon={solid("chevron-right")}
              />
            </div>
          </Stack>
        </ListGroup.Item>
      );
    });
  };

  const renderErrorMessages = () => {
    return errors.map((x, i) => {
      return <li>{x}</li>;
    });
  };

  const renderSuccessMessages = () => {
    return successMessage.map((x, i) => {
      return <li>{x}</li>;
    });
  };

  const showPDFUpload = () => {
    setShowVotersUpload(true);
  };
  const [selectedfile, setSelectedfile] = useState(null);
  const uploadPDF = (evt) => {
    const file = evt.target.files[0];
    setSelectedfile(file);
    if (evt.target.files.length) {
      setSelectedFile(evt.target.files[0]);
    }
  };

  const processPDFUpload = () => {
    setProgress1(100);
    let validate = importDataService.validateImportData("pdfUpload");
    if (validate.isValid) {
      setErrors([]);
      setEnablePreviousButton(false);
      setDisableCancel(true);
      setEnableUpload(false);
      importDataService.fileUploaded = selectedFile.name;
      importDataService.getPDFPreSignedURL(selectedFile.name).then((result) => {
        if (result.data) {
          let fileName = result.data.fields.key;
          setShowProgressBar(true);
          if (result.data.job_id) {
            importDataService.jobID = result.data.job_id;
          }
          if (ImportConstants.excelFileType.includes(selectedFile.type)) {
            convertExcelToJSON(selectedFile);
          } else {
            setProgress();
            uploadtoS3(result.data, selectedFile).then((status) => {
              parsePDF(fileName).then(
                (result) => {
                  if (result.data) {
                    importDataService.exportedJsonData = result.data;
                    setFileUploadSuccessFlags();
                  }
                },
                (error) => {
                  progressbarFlag = false;
                  setErrors(["File Upload Failed, Please try again"]);
                  setEnableNextButton(false);
                  setEnablePreviousButton(true);
                  setDisableCancel(false);
                  setEnableUpload(true);
                }
              );
            });
          }
        }
      });
    } else {
      setErrors(validate.errorMessages);
    }
  };

  const uploadtoS3 = (data, acceptedFile) => {
    let file = new FormData();
    let fileData = data.fields;
    for (let [key, value] of Object.entries(fileData)) {
      file.append(key, value);
    }
    file.append("file", acceptedFile);
    return importDataService.uploadPresignedFile(data.url, file);
  };

  const setFileUploadSuccessFlags = () => {
    setProgresBar(100);
    progressbarFlag = false;
    setEnableNextButton(true);
    setEnablePreviousButton(true);
    setDisableCancel(false);
    setEnableUpload(true);
    setErrors([]);
    setSuccessMessage(["File Converted Successfully."]);
  };

  const parsePDF = (fileName) => {
    // let fileName = selectedFile.name;
    // let fileName = '3_KANTEPUDI.pdf';
    let data = {
      file_name: `s3://ems-backend-files/${fileName}`,
    };

    return importDataService.parsePDF(data);
  };

  const setProgress = () => {
    clearInterval(progressIntervel);
    startTime = 0;
    if (progressIntervel) {
      clearInterval(progressIntervel);
    }
    progressIntervel = setInterval((x) => {
      if (!progressbarFlag) {
        clearInterval(progressIntervel);
      } else {
        startTime = startTime + timeIntervelScale;
        let percentage = Math.floor((startTime / progressTime) * 100);
        if (percentage > 97) {
          percentage = 97;
          clearInterval(progressIntervel);
        }
        setProgresBar(percentage);
      }
    }, timeIntervelScale * 1000);
  };

  const onChangeImportType = (evt) => {
    if (evt.target.value) {
      importDataService.resetImportData();
      resetData();
      setImportTypeSelection(evt.target.value);
      importDataService.importDataSelected.importTypeSelection =
        evt.target.value;
      // initiateDataUpload();
    }
  };

  const resetData = (evt) => {
    setEnableNextButton(false);
    setDisableCancel(false);
    setSelectedFile();
    setProgresBar(0);
    setshowPollingStation(false);
    setShowProgressBar(false);
    setEnableUpload(true);
    setShowVillage(false);
    setShowFileUploadSession(false);
    setErrors([]);
    setSuccessMessage([]);
  };

  const convertExcelToJSON = (excel) => {
    const reader = new FileReader();
    setProgresBar(10);
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const header = jsonData[0];
      const jsonDataWithHeaderAsKeys = jsonData.slice(1).map((row) => {
        const rowData = {};
        header.forEach((cell, index) => {
          const headerKey = cell;
          rowData[headerKey] = row[index] ? row[index] : "";
        });
        return rowData;
      });
      importDataService.exportedJsonData.voterData = jsonDataWithHeaderAsKeys;
      setFileUploadSuccessFlags();
    };

    reader.readAsArrayBuffer(excel);
  };
  const setConstituencyDataUpload = () => {
    // set upload for constituency
    const mlaRole =
      importDataService.tokenData.user_data.role === "MP" ? false : true;
    getConstituencies(mlaRole);
    setShowFileUploadSession(true);
  };
  const removeSelectedPDF = () => {
    setProgress1(100);
    setSelectedFile();
    fileRef.current.value = "";
  };

  const cancelUpload = () => {
    navigation("../");
  };

  const previousStep = () => {
    navigation("../");
  };

  const nextStep = () => {
    navigation("../mapFields");
  };
  return (
    <>
      <div className="import-upload-component ems-text">
        <Row>
          <StepProgressComponent step={0} />
        </Row>
        <Stack direction="horizontal" gap={3}>
          <div className="title-section">
            <div className="title">Import Data</div>
            <div className="sub-title">
              You can only upload one polling station data at a time
            </div>
          </div>
          <div className={`ms-auto col-4`}>
            <div className="text-danger">{renderErrorMessages()}</div>
            <div className="text-success">{renderSuccessMessages()}</div>
          </div>
        </Stack>

        <Row>
          <Tab.Container>
            <Col sm xs="12" md lg xl="4">
              <div className="data-section">
                What kind of data are you importing ?
              </div>
              <ListGroup>
                <ListGroup.Item variant="secondary">
                  Standard Menu
                </ListGroup.Item>
                <ListGroup.Item action href="#voterData">
                  <Stack direction="horizontal" gap={3}>
                    <div>Voters Data</div>
                    <div className="ms-auto">
                      <img
                        className="success-icon"
                        src={greenTick}
                        alt="Success"
                      />
                    </div>
                  </Stack>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm xs="12" md lg xl="4">
              <Tab.Content>
                <Tab.Pane eventKey="#voterData">
                  <div className="data-section">What do you want to do ?</div>
                  <ListGroup>{renderAddVotersMenu()}</ListGroup>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Tab.Container>
          <Col sm xs="12" md lg xl="4" className="pdf-upload-main">
            {showVotersUpload ? (
              <div>
                <div className="data-section">Where is your data located?</div>
                <div className="data-section">
                  <Form.Check
                    inline
                    label="Constituency"
                    name="importType"
                    type="radio"
                    id={`inline-radio-1`}
                    onChange={(evt) => onChangeImportType(evt)}
                    value={ImportConstants.IMPORT_TYPE_CONSTITUENCY}
                    checked={
                      importTypeSelection ===
                      ImportConstants.IMPORT_TYPE_CONSTITUENCY
                    }
                  />
                  <Form.Check
                    inline
                    label="Polling Station"
                    name="importType"
                    type="radio"
                    id={`inline-radio-2`}
                    onChange={(evt) => onChangeImportType(evt)}
                    value={ImportConstants.IMPORT_TYPE_POLLING_STATION}
                    checked={
                      importTypeSelection ===
                      ImportConstants.IMPORT_TYPE_POLLING_STATION
                    }
                  />
                </div>
                <div className="data-section">
                  {importDataService.tokenData.user_data.role === "MP" ? (
                    <Form.Select
                      onChange={onChangeConstituency}
                      aria-label="Constituencies"
                    >
                      <option value={null}>Select Constituencies</option>
                      {renderConstituencies()}
                    </Form.Select>
                  ) : (
                    ""
                  )}
                </div>
                <div className="data-section">
                  {showPollingStation ? (
                    <Form.Select
                      className="formselect_upload"
                      onChange={onChangePollingBooth}
                      aria-label="Polling Station"
                    >
                      <option value={null}>Select Polling Station</option>
                      {renderPollingStation()}
                    </Form.Select>
                  ) : (
                    ""
                  )}
                </div>
                <div className="data-section">
                  {showFileUploadSession ? (
                    <>
                      <Card body>
                        <Stack direction="horizontal" gap={3}>
                          {showPDFIcon ? (
                            <FontAwesomeIcon
                              className="pdf-icon"
                              icon={regular("file-pdf")}
                              size="2xl"
                            />
                          ) : (
                            ""
                          )}
                          {showExelIcon ? (
                            <FontAwesomeIcon
                              className="pdf-icon"
                              icon={regular("file-excel")}
                              size="2xl"
                            />
                          ) : (
                            ""
                          )}
                          {selectedFile ? (
                            <>
                              <Button
                                onClick={removeSelectedPDF}
                                size="sm "
                                variant="outline-primary"
                                className={`ms-auto btn-outline-ems no-border-radius ${
                                  disableCancel ? "disabled" : ""
                                }`}
                                disabled={isButtonDisabled}
                              >
                                Cancel
                              </Button>

                              <Button
                                onClick={processPDFUpload}
                                size="sm "
                                variant="outline-primary"
                                className={`btn-outline-ems no-border-radius ${
                                  enableUpload ? "" : "disabled"
                                }`}
                                disabled={isButtonDisabled}
                              >
                                Convert
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                          {selectedFile ? (
                            <div>
                              <img
                                className="success-icon"
                                src={greenTick}
                                alt="Success"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </Stack>
                      </Card>
                      <Card body>
                        <Stack direction="horizontal" gap={3}>
                          <div>File</div>
                          <Form.Group controlId="formFile">
                            <Form.Control
                              ref={fileRef}
                              accept={`${
                                showPDFIcon ? "application/pdf" : ""
                              } ${
                                showExelIcon
                                  ? ImportConstants.excelFileType.join(",")
                                  : ""
                              }`}
                              onChange={uploadPDF}
                              type="file"
                              disabled={selectedfile !== null || !roleDataService.access.update  }
                            />
                          </Form.Group>
                        </Stack>
                      </Card>
                    </>
                  ) : (
                    ""
                  )}
                  {showVillage ? (
                    <Card body>
                      <Form.Label>Combination two Villages/Wards</Form.Label>
                      <Stack direction="horizontal" gap={3}>
                        <div>
                          <InputGroup className="mb-3">
                            <div className="d-flex">
                              <InputGroup.Text>From S.NO</InputGroup.Text>
                              <Form.Control
                                onChange={(evt) => {
                                  onChangeVillageSequance(
                                    evt,
                                    "village1",
                                    "from"
                                  );
                                }}
                                aria-label="From"
                              />
                            </div>
                            <div className="d-flex">
                              <InputGroup.Text>To S.NO</InputGroup.Text>
                              <Form.Control
                                onChange={(evt) => {
                                  onChangeVillageSequance(
                                    evt,
                                    "village1",
                                    "to"
                                  );
                                }}
                                aria-label="To"
                              />
                            </div>
                          </InputGroup>
                          <Form.Select
                            onChange={(evt) => {
                              onChangeVillages(evt, "village1");
                            }}
                            aria-label="Polling Station"
                          >
                            <option value={null}>Select Village/Ward</option>
                            {renderVillages()}
                          </Form.Select>
                        </div>
                        <div>
                          <InputGroup className="mb-3">
                            <div className="d-flex">
                              <InputGroup.Text>From S.NO</InputGroup.Text>
                              <Form.Control
                                onChange={(evt) => {
                                  onChangeVillageSequance(
                                    evt,
                                    "village2",
                                    "from"
                                  );
                                }}
                                aria-label="From"
                              />
                            </div>
                            <div className="d-flex">
                              <InputGroup.Text>To S.NO</InputGroup.Text>
                              <Form.Control
                                onChange={(evt) => {
                                  onChangeVillageSequance(
                                    evt,
                                    "village2",
                                    "to"
                                  );
                                }}
                                aria-label="To"
                              />
                            </div>
                          </InputGroup>
                          <Form.Select
                            onChange={(evt) => {
                              onChangeVillages(evt, "village2");
                            }}
                            aria-label="Polling Station"
                          >
                            <option value={null}>Select Village/Ward</option>
                            {renderVillages()}
                          </Form.Select>
                        </div>
                      </Stack>
                    </Card>
                  ) : (
                    ""
                  )}
                </div>
                {showProgressBar ? (
                  <div className="progress-session">
                    <Stack direction="horizontal" gap={3}>
                      <div>
                        {progresBar === 100 ? "Completed" : "Processing"}
                      </div>
                      {/* <ProgressBar className="flex-fill" animated now={progresBar} label={`${progresBar}%`} /> */}
                      <ProgressBar
                        className={`flex-fill ${
                          progresBar === 100 ? "hide" : ""
                        }`}
                        animated
                        now={progresBar}
                        label={`${progresBar}%`}
                      />
                    </Stack>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row className="navbar-fixed-bottom">
          <Card className="card-navbar-bottom" body>
            <Stack direction="horizontal" gap={3}>
              <Button
                onClick={cancelUpload}
                size="sm"
                variant="outline-primary"
                className="ms-auto no-border-radius btn-outline-ems"
              >
                Cancel
              </Button>
              <Button
                onClick={previousStep}
                size="sm"
                variant="info"
                style={{ background: "#C4D1E0" }}
                className={`no-border-radius btn-outline-ems ${
                  enablePreviousButton ? "" : "disabled"
                }`}
              >
                Previous
              </Button>
              <Button
                onClick={nextStep}
                size="sm"
                variant="primary"
                className={`no-border-radius btn-primary-ems ${
                  enableNextButton ? "" : "disabled"
                }`}
              >
                Next
              </Button>
            </Stack>
          </Card>
          <Stack className="import-footer-main" direction="horizontal" gap={3}>
            <div className="import-footer">
              <span className="import-footer-endfooterimg">
                Copyright © 2023 iToConnect. All Rights Reserved.
              </span>
            </div>
          </Stack>
        </Row>
      </div>
    </>
  );
}

export default ImportDataUploadComponent;
