// Profile.js

import React, { useState, useRef, useEffect, useCallback } from "react";
import Card from "../components/Card"; // Assuming you have a Card component for styling
import "./Profile.css"; // Import the CSS file
import BackArrow from "../Assets/Backarrow.png";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { loginUser, logout } from "../Service/service";
import axios from 'axios';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const divRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();


  useEffect(() => {
    console.log('Fetching location...');
    let userData = JSON.parse(localStorage.getItem('data'));
    
    let isApiCallInProgress = false; // Flag to track API call status
    let intervalId; // Store the interval ID
  
    const getLocation = async () => {
      // Check if an API call is already in progress or if the component is unmounted
      if (isApiCallInProgress || !userData) {
        console.log('API call already in progress or component is unmounted. Skipping location update.');
        return;
      }
  
      isApiCallInProgress = true; // Set flag to indicate API call start
  
      const watchId = navigator.geolocation.watchPosition(
        async (position) => {
          console.log('Location fetched successfully:', position);
          console.log('position.coords', position.coords);
          console.log('latitude', position.coords.latitude);
          console.log('longitude', position.coords.longitude);
          const locationData = {
            user_id: userData.user_data.user_id,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            user_name: userData.user_data.first_name
          };
          // Make a POST request to the API endpoint
          try {
            await axios.post(
              'https://sxqm562sjc.execute-api.us-east-1.amazonaws.com/dev/updatelocation',
              locationData
            );
            console.log('Location updated successfully.');
          } catch (error) {
            console.error('Error updating location:', error);
          } finally {
            isApiCallInProgress = false; // Reset flag after API call completion
          }
        },
        (error) => {
          console.error('Error getting location:', error.message);
        }
      );
  
      return () => navigator.geolocation.clearWatch(watchId);
    };
  
    // Call getLocation immediately
    getLocation();
  
    // Set interval to call getLocation every 15 seconds
    intervalId = setInterval(getLocation, 15000);
  
    // Clear interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  
  const logOut = () => {
    logout().then((res) => {
      localStorage.removeItem("data");
      navigate("/login");
      // setTimeout(() => {
      //   navigate("/login");
      // }, 500); 
    });
  };
  useEffect(() => {
    // Fetch user data from local storage or an API
    const storedData = JSON.parse(localStorage.getItem("data"));
    setUserData(storedData.user_data);
  }, []);

  const handleDelete = async () => {
    // Show a confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to delete your Account?"
    );

    if (isConfirmed) {
      try {
        let tokenData = JSON.parse(localStorage.getItem("data"));

        // Make an API call to delete the user
        const response = await fetch(
          "https://test-api.itoconnect.online/deleteuser/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Add your JWT token to the Authorization header
              Authorization: `Bearer ${tokenData.token}`,
            },
            // If you need to send data in the request body, add it here
            // body: JSON.stringify({ key: 'value' }),
          }
        );

        if (response.ok) {
          // Update local storage or handle success as needed
          localStorage.removeItem("data");
          logout().then((res) => {
            localStorage.removeItem("data");
            navigate("/login");
          });
          console.log("User deleted successfully");
          // Redirect to some page after deletion
        } else {
          // Handle API error
          console.error("Error deleting user:", response.statusText);
        }
      } catch (error) {
        // Handle network error
        console.error("Network error:", error.message);
      }
    } else {
      console.log("Deletion canceled");
    }
  };

  const handleInputChange = (field, value) => {
    setUserData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleSave = () => {
    // Update local storage or send a save request to your API
    // For now, let's just update local storage
    localStorage.setItem("data", JSON.stringify({ user_data: userData }));
    console.log("User data saved successfully");
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  // const handleClickOutside = useCallback((event) => {
  //   if (divRef.current && !divRef.current.contains(event.target)) {
  //     console.log('Clicked outside the div');
  //   }
  // }, []);

  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, [handleClickOutside]);

  return (
    <div
    //  ref={divRef}
    >
      {/* <div className="row" >
        <span className="Admin_dashboard_text"> User Profile </span>
        </div>
     */}
      {userData ? (
        <Card>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div onClick={handleGoBack}>
              <img
                src={BackArrow}
                alt="back_arrow"
                className="backarrow_img_profileimg"
              />
            </div>

            <span className="ems_title_dashbaord"> User Profile </span>
            <span onClick={handleShow}  className="log_out_icons"
            // style={{ fontSize: "4.8vw", top: "2px" }}
            >
              <i class="fa fa-sign-out" aria-hidden="true"></i>
            </span>
            <Modal show={show} onHide={handleClose} className="mobile_view_pop" >
              <Modal.Body className="modal-body-title_profile">Are you sure, you want to sign out?</Modal.Body>
              <Modal.Footer
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <Button variant="secondary"
                 onClick={handleClose}
                 >
                  No
                </Button> */}
                <button className="logout_from-application"
                 onClick={handleClose}
                 >
                  No
                </button>
                {/* <Button className="logout_from-application"
                 variant="primary" 
                onClick={logOut}
                >
                  Yes
                </Button> */}
                <button className="logout_from-application"
                // variant="primary" 
                onClick={logOut}
                >
                  Yes
                </button>
              </Modal.Footer>
            </Modal>
          </div>

          <div className="profile-container">
            <div className="pc_tab">
              <label className="itoconnect_profile_module_android">
                User ID
                <input
                  className="itoconnect_profile_module_android_input-field"
                  id="itoconnect_profile_module_android_input-field"
                  type="text"
                  value={userData.user_id}
                  readOnly
                />
              </label>

              <label className="itoconnect_profile_module_android">
                First Name
                <input
                  className="itoconnect_profile_module_android_input-field"
                  id="itoconnect_profile_module_android_input-field"

                  type="text"
                  value={userData.first_name}
                  onChange={(e) =>
                    handleInputChange("first_name", e.target.value)
                  }
                  readOnly
                />
              </label>
            </div>

            <div className="pc_tab">
              <label className="itoconnect_profile_module_android" id="itoconnect_profile_module_android_lastname">
                Last Name
                <input
                  className="itoconnect_profile_module_android_input-field"
                  id="itoconnect_profile_module_android_input-field_last"

                  type="text"
                  value={userData.last_name}
                  onChange={(e) =>
                    handleInputChange("last_name", e.target.value)
                  }
                  readOnly
                />
              </label>

              <label className="itoconnect_profile_module_android">
                Email
                <input
                  className="itoconnect_profile_module_android_input-field"
                  type="text"
                  value={userData.email_id}
                  onChange={(e) =>
                    handleInputChange("email_id", e.target.value)
                  }
                  readOnly
                />
              </label>
            </div>

            <div className="pc_tab">
              <label className="itoconnect_profile_module_android">
                Phone Number
                <input
                  className="itoconnect_profile_module_android_input-field"
                  type="text"
                  value={userData.phone_no}
                  onChange={(e) =>
                    handleInputChange("phone_no", e.target.value)
                  }
                  readOnly
                />
              </label>

              <label className="itoconnect_profile_module_android">
                Country
                <input
                  className="itoconnect_profile_module_android_input-field"
                  type="text"
                  value={userData.country}
                  onChange={(e) => handleInputChange("country", e.target.value)}
                  readOnly
                />
              </label>
            </div>

            <div className="pc_tab">
              <label className="itoconnect_profile_module_android">
                State
                <input
                  className="itoconnect_profile_module_android_input-field"
                  type="text"
                  value={userData.state}
                  onChange={(e) => handleInputChange("state", e.target.value)}
                  readOnly
                />
              </label>
              <label className="itoconnect_profile_module_android">
                District
                <input
                  className="itoconnect_profile_module_android_input-field"
                  type="text"
                  value={userData.dist}
                  onChange={(e) => handleInputChange("dist", e.target.value)}
                  readOnly
                />
              </label>
            </div>
            <div className="pc_tab_role">
            <label className="itoconnect_profile_module_android">
                Role
                <input
                  className="itoconnect_profile_module_android_input-field"
                  type="text"
                  value={userData.role}
                  onChange={(e) => handleInputChange("role", e.target.value)}
                  readOnly
                />
              </label>
            
            </div>
            

            <div className="button-container">
              {/* <button className="save-button" onClick={handleSave}>
                Save
              </button> */}

              <button className="delete-button" onClick={handleDelete}>
                Delete Account
                <span><i className="fas fa-trash"></i></span>
                
              </button>
            </div>
          </div>
        </Card>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Profile;
