import "./Signup.scss";
import Emslogo from "../../src/Assets/itoclogo.png";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, withRouter } from "react-router-dom";
import {
  getConstituencyDataForMLAorMP,
  getDistrictData,
  getStateAndRoleData,
  signUp,
} from "../Service/service";
import Select from "react-select";
import LeftArrow from "../Assets/leftarrrowmobile.png";
import { useNavigate } from "react-router-dom";

function Signup({ history }) {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors },
    setValue,
  } = useForm();

  const [selectedState, setSelectedState] = useState("");
  const [selectedUsertype, setSelectedUsertype] = useState("");
  const [selectedDist, setSelectedDist] = useState("");
  const [selectedConstituency, setSelectedConstituency] = useState("");
  const [email, setEmail] = useState("");

  const [dropdownData, setdropdownData] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [stateId, setStateId] = useState("");
  const [districtData, setdistrictData] = useState([]);
  const [constituencyId, setconstituencyId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [constituencyData, getconstituencyData] = useState([]);
  const [apiResponce, setapiResponce] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [roleOptions, setRoleOptions] = useState([]);
  const [isEmailAlreadyRegistered, setIsEmailAlreadyRegistered] =
    useState(false);
  const [phoneErrMessage, setPhoneErrMessage] = useState("");
  const [isPhoneAlreadyRegistered, setIsPhoneAlreadyRegistered] =
    useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email_id: "",
    password: "user",
    phone_no: "",
    role: "",
    dist: "",
    state: "",
    constituency: "",
    country: "1",
  });

  localStorage.setItem("local_email_id", apiResponce.email);

  const otpdata = localStorage.getItem("local_email_id");

  let first_name = useRef();
  let last_name = useRef();
  let email_id = useRef();
  let state = useRef();
  let dist = useRef();
  let phone_no = useRef();
  let constituency = useRef();
  let role = useRef();

  var enterData = {};
  const firstNameListener = () => {
    trigger("first_name");
    var firstname = document.getElementById("first_name");
    if (!!firstname.value) {
      firstname.value =
        firstname.value[0]?.toUpperCase() + firstname.value.slice(1);
    }
  };
  const lastNameListener = () => {
    trigger("last_name");
    var lastname = document.getElementById("last_name");
    if (!!lastname.value) {
      lastname.value =
        lastname.value[0]?.toUpperCase() + lastname.value.slice(1);
    }
  };
  const emailListener = (e) => {
    setIsEmailAlreadyRegistered(false);
    trigger("email_id");
    var emailId = document.getElementById("email_id");

    emailId.value = emailId.value.toLowerCase();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  const getUserData = async () => {
    getStateAndRoleData().then((json) => {
      const roleForFilterdOptions = json.user_type.filter((item) => {
        if (item.user_type === "MLA" || item.user_type === "MP") {
          return item;
        }
      });
      const roleForOptions = roleForFilterdOptions.map((item) => {
        return { value: item.user_type_id, label: item.user_type };
      });
      const stateOptions = json.states.map((item) => {
        return { value: item.state_id, label: item.state_name };
      });
      setdropdownData(stateOptions);
      setRoleOptions(roleForOptions);
    });
  };

  const onClickErrorMessage = (id, elementId) => {
    const getState = id;
    const stateErrorEl = document.getElementById(elementId);
    if (getState !== "") {
      if (stateErrorEl) {
        stateErrorEl.style.display = "none";
      }
    } else {
      stateErrorEl.style.display = "block";
    }
  };
  const handleRole = (event, item) => {
    const getRole = event.value;
    setRoleId(getRole);
    setValue("user_type_id", event.value);
    onClickErrorMessage(event, "roleError");
  };


  const handleState = (event, item) => {
    const getState = event.value;
    setStateId(getState);
    setValue("state", event.value);
    onClickErrorMessage(event, "stateError");
  };

  const handledistrict = (event, item) => {
    const getdistrictId = event.value;
    setDistrictId(getdistrictId);
    setValue("dist", event.value);
    onClickErrorMessage(event, "districtError");
  };
  const handleconstituency = (event, item) => {
    const getconstituencyId = event.value;
    setconstituencyId(getconstituencyId);
    setValue("constituency", event.value);
    onClickErrorMessage(event, "constituencyError");
  };

  const getdistrictData = async () => {
    if (!!stateId) {
      getDistrictData(stateId).then((dist) => {
        const distOptions = dist.map((item) => {
          return { value: item.dist_id, label: item.dist_name };
        });
        setdistrictData(distOptions);
      });
    }
  };

  const getConstituencyData = async () => {
    getConstituencyDataForMLAorMP(roleId, districtId).then((cons) => {
      const distOptions = cons.map((item) => {
        const constituency_name =
          roleId === 1 ? item.mla_constituency_name : item.mp_constituency_name;
        return { value: item.constituency_id, label: constituency_name };
      });
      getconstituencyData(distOptions);
    });
  };

  const stateSelectListener = (e, item) => {
    setSelectedState(e.target.innerText);
  };
  const userTypeSelectListener = (e, value) => {
    setSelectedUsertype(e.target.innerText);
  };
  const districtSelectListener = (e, value) => {
    setSelectedDist(e.target.innerText);
  };
  const constituencySelectListener = (e, value) => {
    setSelectedConstituency(e.target.innerText);
  };

  const onSubmit = (data) => {
    enterData = getValues();
    enterData.country = "1";

    enterData.email_id = enterData.email_id.toLowerCase();

    setFormData(enterData);

    signUp(enterData)
      .then((apiData) => {
        setapiResponce(apiData);
        if (
          apiData.message ===
            "Account already exist, if forgot password please click on Forgot password" ||
          apiData.message ===
            "Phone validation completed, set your password from the link send to your Email."
        ) {
          setErrMessage(apiData.message);
          setIsEmailAlreadyRegistered(true);
          setIsPhoneAlreadyRegistered(false);
        } else if (
          apiData.message ===
            "Phone number exists, please use some other number" ||
          apiData.message ===
            "Phone number exists with other user, please use other number or already given number" ||
          apiData.message ===
            "Phone number used is linked with an existing account. Use a different phone number to create a new account."
        ) {
          setPhoneErrMessage(apiData.message);
          setIsPhoneAlreadyRegistered(true);
          setIsEmailAlreadyRegistered(false);
        } else {
          window.location.href = `/otp/?${enterData.email_id}`;
        }
      })
      .catch((error) => {
        console.error("Error in signup API call:", error);
      });
  };
  useEffect(() => {}, [phoneErrMessage]);

  useEffect(() => {
    getdistrictData();
  }, [stateId]);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (roleId && !!districtId !== false) {
      getConstituencyData();
    }
  }, [roleId, districtId]);

  useEffect(() => {
    document.getElementById("first_name").value = "";
    document.getElementById("last_name").value = "";
    document.getElementById("email_id").value = "";
    document.getElementById("phone_no").value = "";
  }, [history]);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#F3F7F9",
      backgroundImage: "none",
      height: "2.65625vw",
      border: "none",
      minHeight: "0px",
      fontSize: "0.9375vw",
      span: {
        display: "none",
      },
      svg: {
        color: "#AEAEAE",
        verticalAlign: "middle",
        marginRight: "1.5vw",
      },
    }),

    menu: (provided) => ({
      ...provided,
      width: "35.011vw",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontWeight: "500",
      fontSize: "0.9375vw",
      color: "#8b93a5",
    }),
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      borderBottom: "1px solid #D9D8D8",
      color: "#263659",
      fontSize: "0.9375vw",
      "&:last-child": {
        borderBottom: "none",
      },
    }),

    "option:last-child": () => ({}),
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const [isEditingFirstName, setIsEditingFirstName] = useState(false);
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleFirstNameBlur = () => {
    if (isEditingFirstName) {
      trigger('first_name');
    }
    setIsEditingFirstName(false);
  };

  const handleFirstNameFocus = () => {
    setIsEditingFirstName(true);
  };
  return (
    <div
      className="signupcontainer"
      id="signup_"
    >
      <div onClick={handleGoBack}>
        <img
          src={LeftArrow}
          alt="back_arrow"
          className="leftarrow_img_signupimg"
        />
      </div>
      <div className="signup__main">
        <form className="signup__sub" onSubmit={handleSubmit(onSubmit)}>
          <div className="heading">
            <h3>Sign Up</h3>
          </div>
          <p>First Name</p>

          <div className="signup__sub_inputBlock" id="singup_block_firstname">
            <label>
              <input
                className="signup__sub_input signup_phonenumberBlock_input"
                // className="signup_phonenumberBlock_input"
                placeholder="First Name"
                // type={"text"}
                type="text"
                id="first_name"
                tabIndex="1"
                name="first_name"
                ref={first_name}
                autofocus="autofocus"
                {...register("first_name", {
                  // required: true,
                  required: 'First Name required',
                  pattern: {
                    value: /^[a-zA-Z]+(([a-zA-Z ])?[a-zA-Z]*)*$/,
                    message: "Allows alphabets only",
                  },
                })}
                // onBlur={firstNameListener}
                onBlur={handleFirstNameBlur} // Trigger validation on blur only if the field was being edited
                // onFocus={handleFirstNameFocus} // Set flag when field is being edited
                onChange={(e) => {
                  e.target.value = capitalizeFirstLetter(e.target.value);
                }}
              />
            </label>
            {/* {errors.first_name && (
              <span id="errormessage_firstName_">First Name required</span>
            )} */}
             {errors.first_name && (
              <span id="errormessage_firstName_">{errors.first_name.message}</span>
            )}
          </div>

          <p>Last Name</p>
          <div className="signup__sub_inputBlock" id="singup_block_lastname">
            <label>
              <input
                placeholder="Last Name"
                type="text"
                tabIndex="2"
                id="last_name"
                name="last_name"
                ref={last_name}
                className="singup_last_name_input signup_phonenumberBlock_input"
                {...register("last_name", {
                  required: "Last Name required",
                  pattern: {
                    value: /^[a-zA-Z]+(([a-zA-Z ])?[a-zA-Z]*)*$/,
                    message: "Allows alphabets only",
                  },
                })}
                onBlur={lastNameListener}
                onChange={(e) => {
                  e.target.value = capitalizeFirstLetter(e.target.value);
                }}
              />
            </label>
          
            {errors.last_name && (
              <span id="errormessage_firstName_">
                {errors.last_name.message}
              </span>
            )}
          </div>
          <p>Email ID</p>
          <div className="signup__sub_inputBlock" id="signup__sub_inputBloc__">
            <label>
              <input
                placeholder="Email"
                className="singup_email_input signup_phonenumberBlock_input"
                type="text"
                tabIndex="3"
                id="email_id"
                name="email_id"
                {...register("email_id", {
                  required: "Email required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid mail Id",
                  },
                })}
                onBlur={emailListener}
              />
            </label>

            {errors.email_id && (
              <>
                <span id="errormessage_firstName_">
                  {errors.email_id.message}
                </span>
                <br />
              </>
            )}
            {isEmailAlreadyRegistered && (
              <span id="errormessage_firstName_"> {errMessage}</span>
            )}
            <span id="errormessage_firstName_"></span>
          </div>

          <div
            className="signup__sub_inputBlock_"
            id="itoconnect-phoneblock_main_"
          >
            <label>
              <input
                className="signup_phonenumberBlock_input"
                type="text"
                tabIndex="4"
                id="phone_no"
                placeholder="Phone Number"
                name="phone_no"
                ref={phone_no}
                {...register("phone_no", {
                  required: "Phone Number required",
                  pattern: {
                    value: /^(?!([0-9])\1{9}$)[6-9][0-9]{9}$/,
                    message:
                      "Phone number should start with a digit between 6 and 9 and have a total of 10 unique digits.",
                  },
                })}
                onBlur={() => {
                  trigger("phone_no");
                }}
              />
            </label>

            {errors.phone_no && (
              <>
                <span id="errormessage_phonenumber">
                  {errors.phone_no.message}
                </span>
              </>
            )}

            {isPhoneAlreadyRegistered && (
              <p className="errormessage"> {phoneErrMessage}</p>
            )}
          </div>

          <div className="grid-container">
            

            <div className="grid-item" id="user_type_signup_bloack">
              <p className="extra__text">User Type</p>
              <div className="signup_usertypeBlock">
                <label>
                  <Select
                    tabIndex="5"
                    isSearchable={true}
                    escapeClearsValue={true}
                    className="react_select_role"
                    styles={customStyles}
                    options={roleOptions}
                    classNamePrefix="select_role"
                    {...register("user_type_id", {
                      required: true,
                    })}
                    onChange={(e) => {
                      handleRole(e);
                    }}
                    placeholder="User Type"
                  />
                </label>
                {errors.role && (
                  <span id="errormessage_firstName">Role required</span>
                )}
              </div>
            </div>
            <div className="grid-item sigup_select_state">
              <p>Select State</p>
              <div className="signup_stateBlock">
                <label>
                  <Select
                    tabIndex="6"
                    className="signup_stateBlock_dropdown"
                    styles={customStyles}
                    options={dropdownData}
                    classNamePrefix="select_role"
                    {...register("state", {
                      required: true,
                    })}
                    onChange={(e) => {
                      handleState(e);
                    }}
                    placeholder="State"
                  />
                </label>

                {errors.state && !stateId && (
                  <span className="errormessage_state" id="errormessage_state">
                    State required
                  </span>
                )}
                <span id="stateError"></span>
              </div>
            </div>

            <div className="grid-item sigup_select_ditrict">
              <p className="extra__text">Select District</p>
              <div className="signup_DistrictBlock">
                <label>
                  <Select
                    tabIndex="7"
                    styles={customStyles}
                    options={districtData}
                    classNamePrefix="select_role"
                    {...register("dist", {
                      required: true,
                    })}
                    onChange={(e) => {
                      handledistrict(e);
                    }}
                    placeholder="District"
                  />
                </label>

                {errors.dist && !districtId && (
                  <span id="errormessage_district">District required</span>
                )}
                <span id="stateError"></span>
              </div>
            </div>

            <div className="grid-item sigup_select_constituency">
              <p>Select Constituency</p>
              <div className="signup_ConstituencyBlock">
                <label>
                  <Select
                    tabIndex="8"
                    styles={customStyles}
                    options={constituencyData}
                    classNamePrefix="select_role"
                    {...register("constituency", {
                      required: true,
                    })}
                    onChange={(e) => {
                      handleconstituency(e);
                    }}
                    placeholder="Constituency"
                  />
                </label>
                {errors.constituency && !constituencyId && (
                  <span id="errormessage_constituency">
                    Constituency required
                  </span>
                )}
                <span id="constituencyError"></span>
              </div>
            </div>

            <div className="grid-item"></div>
          </div>

          <div className="signupbutton__main">
            <button
              className="signup__button"
              type="submit"
              id="signup"
              tabIndex="9"
            >
              Sign Up
            </button>

            <div className="signin__text">
              <span className="sing_text_title">
                Already have an account? <br />
                <span className="sign_text_loginlink">
                  <Link to="/login">
                    <b>Log In </b>
                  </Link>
                </span>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signup;
