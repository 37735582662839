import { json } from "sjcl";
import { urlData } from "../config/apiconfig";
import axiosInstance from "./AxiosInstance";
import axios from 'axios';


const url = process.env.REACT_APP_SERVER_CONFIG;


export async function votersData(currentPage, constituenciesvotersdata, mandalsvotersdata, villagesvotersdata, pollingstationsvotersdata, casteData, religionData, ageData) {
  try {
    const resp = await axiosInstance.get(`${urlData.votersdataDashboard}${urlData.votersdataPage}${currentPage}&${constituenciesvotersdata ? constituenciesvotersdata : ''}${mandalsvotersdata ? mandalsvotersdata : ''}${villagesvotersdata ? villagesvotersdata : ''}${pollingstationsvotersdata ? pollingstationsvotersdata : ''}${casteData ? casteData : ''}${religionData ? religionData : ''}${ageData ? ageData : ''}`)
    return resp.data;
  } catch (error) {
    throw error
  }
}

export async function votersDataConstituency() {
  try {
    const resp = await axiosInstance.get('/dashboard/dropdowndb/')
    return resp.data;
  }
  catch (error) {
    throw error
  }


}

export async function votersDataMandal(selectedConstituencies) {
  try {
    const constituencies = selectedConstituencies ? `?constituency_id=${selectedConstituencies}` : ''
    const dashboard = await axiosInstance.get(`/dashboard/dropdowndb/${constituencies ? constituencies : ''}`)
    return dashboard.data;
  } catch (error) {
    throw error
  }
}

export async function votersDataVillage(selectedmandals) {
  try {
    const mandals = selectedmandals ? `?mandal_id=${selectedmandals}` : '';
    const response = await axiosInstance.get(`/dashboard/dropdowndb/${mandals}`);
    return response.data;
  } catch (error) {
    throw error
  }
}

export async function votersDatapollingStation(selectedvillages) {
  try {
    const villages = selectedvillages ? `?ward_village_id=${selectedvillages}` : ''
    const response = await axiosInstance.get(`/dashboard/dropdowndb/${villages}`);
    return response.data;
  } catch (error) {
    throw error
  }
}


export async function csvconverter(csvbody) {
  try {
    const resp = await axiosInstance.post(`/dashboard/generatecsv/`, csvbody, {
      responseType: 'blob',
    });
    return resp.data;
  } catch (error) {
    throw error;
  }
}

export async function generateExcel(csvbody) {
  try {
    const resp = await axiosInstance.post(`/dashboard/generat_excel/`, csvbody);
    return resp.data;
  } catch (error) {
    throw error;
  }
}


export async function downLoadExcelreport(taskId) {
  try {
    const resp = await axiosInstance.get(`/dashboard/list_excel/?task_id=${taskId}`);
    return resp.data;
  } catch (error) {
    throw error;
  }
}

export async function downloadExcelFile(preSignedUrl) {
  try {
    const resp = await axios.get(preSignedUrl, {responseType: 'blob'});
    const blob = resp.data;
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "VotersData.xlsx");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    throw error;
  }
}

export async function pdfconverter(pdfBody) {
  try {
    const resp = await axiosInstance.post('/dashboard/generatepdf/', pdfBody, {
      responseType: 'blob',
    });
    return resp.data;
  } catch (error) {
    throw error;
  }
}


export async function deleteVoter(selectedvoter) {
  try {
    const response = await axiosInstance.get(`/dashboard/deletevoter/?voter_id=${selectedvoter}`);
    return response.data;
  }
  catch (error) {
    throw error
  }
}


export async function votersDataColumnselection() {
  try {
    const response = await axiosInstance.get(`${urlData.votersdataColumnselection}`);
    return response.data;
  }
  catch (error) {
    throw error
  }
}

export async function updateCheckedvalues(selectedFields) {
  try {
    const response = await axiosInstance.post(`${urlData.columnsstoring}`, selectedFields);
    return response.data;
  } catch (error) {
    throw error
  }
}

export async function getupdateCheckedvalues() {
  try {
    const response = await axiosInstance.get(`${urlData.columnsstoring}`);
    return response.data;
  } catch (error) {
    throw error
  }

}

export async function searchvoter(search) {
  try {
    const response = await axiosInstance.get(`${urlData.checkvoters}${search}`);
    return response.data;
  }
  catch (error) {
    throw error
  }
}

export async function searchvoterId(SearchtermvoterID,currentPage) {
  try {
    const response = await axiosInstance.get(`${urlData.searchvoters}${SearchtermvoterID}&page=${currentPage}`);
    return response.data;
  } catch (error) {
    throw error
  }
}



export async function getvoterPrintList() {
  const tokenData = JSON.parse(localStorage.getItem("data"));

  const response = await axiosInstance.get(`https://test-api.itoconnect.online/sms/voterslip/`);
  // const response = await fetch(Voterjson); // Update the path accordingly

  return response.data;

  // const response = await fetch(`${url}${urlData.checkvoters}${SearchtermvoterID}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  const data = response.json();
  return data;
}