import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { finaltemplates, getcampData } from "../Service/service";
import iFrame from "../Assets/iframe.png";
import {
  ecampaignGetStatus,
  searchvoterEcampaign,
} from "../Service/Ecampaignservice";
import filter from "../Assets/filterbtn.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./EcampaignViewStatus.css";
import LoaderWrapper from "../components/LoaderWrapper";
import General_Filter from "../components/Dropdowns/General Filter/General_Filter";
import { urlData } from "../config/apiconfig";
import audioPng from "../Assets/audio.png";
import imagePng from "../Assets/imagePng.png";
import sampleAudio from "../Assets/sample-3s.mp3";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, fas } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useNavigate } from "react-router-dom";

const EcampaignViewStatus = () => {
  const StatusFlag = ({ flag }) => {
    const iconStyle = {
      marginRight: "5px",
      fontSize: "25px",
      position: "relative",
      bottom: "5px",
      // padding:'11px 0px 0px 0px' // Adjust this to add spacing between the icon and the text (if required)
    };

    if (flag) {
      return (
        <div>
          <FontAwesomeIcon
            icon={faCheck}
            style={{ ...iconStyle, color: "green" }}
          />
          {/* <span>Right</span> */}
        </div>
      );
    } else {
      return (
        <div>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ ...iconStyle, color: "red" }}
          />
          {/* <span>Wrong</span> */}
        </div>
      );
    }
  };

  const [data, setData] = useState([]);
  const [TemplateData, setTemplateData] = useState([]);
  const [VoterData, setVoterData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpages, setTotalpages] = useState();
  const pagesCount = Math.ceil(totalpages / 50);
  const [objectsPerPage, setObjectsPerPage] = useState(50);
  const itemsPerPage = 50;
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [selectedOptionSenderID, setSelectedOptionSenderID] = useState("none");
  const [next, setNext] = useState();

  const [inputValueError, setInputValueError] = useState("");

  const [selectedconstituencies, setSelectedConstituencies] = useState();
  const [mandals, setMandals] = useState();
  const [villages, setVillages] = useState();
  const [pollingstations, setPollingstations] = useState();

  const ref = useRef();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleCheckSchedule = (event) => {
    setShowForm(event.target.checked);
  };

  useEffect(() => {
    finaltemplates().then((result) => {
      setTemplateData(result.message);
      setLoadingScreen(false);
    });
  }, []);
  const optionElements = TemplateData.map((data) => {
    return (
      <option key={data.template_id} value={data.template_id}>
        {data.template_name}
      </option>
    );
  });

  const optionElementsSenderID = data.map((data) => {
    return (
      <option key={data.template_header} value={data.template_header}>
        {data.template_header}
      </option>
    );
  });
  const handleSelectChange = (event, param1, param2) => {
    var filteredData = TemplateData.filter(
      (v) => v.template_id == event.target.value
    );
    setSelectedOption(event.target.value);
    setInputValue(filteredData[0].content_registered);
  };

  function handleNextPage() {
    setCurrentPage((prevPage) => prevPage + 1);
  }
  function handlePreviousPage() {
    setCurrentPage((prevPage) => prevPage - 1);
  }

  const [prev, setPrev] = useState();

  const [loadingscreen, setLoadingScreen] = useState(true);

  const handleDataFromChild = (
    constituencyParamValue,
    mandalParamValue,
    villageParams,
    pollingStationParams
  ) => {
    setSelectedConstituencies(constituencyParamValue);
    setMandals(mandalParamValue);
    setVillages(villageParams);
    setPollingstations(pollingStationParams);
  };

  const constituenciesParams = `${urlData.votersdataConstituencyapi}${selectedconstituencies}&`;
  const mandalParams = `${urlData.votersdataMandalapi}${mandals}&`;
  const villageParams = `${urlData.votersdataVillageapi}${villages}&`;
  const pollingstationParams = `${urlData.votersdatapollingstationapi}${pollingstations}&`;

  const constituenciesSelected = selectedconstituencies
    ? constituenciesParams
    : "";
  const mandalsSelected = mandals ? mandalParams : "";
  const villagesSelected = villages ? villageParams : "";
  const pollingstationsSelected = pollingstations ? pollingstationParams : "";
  const [ShowData, setShowData] = useState([]);

  //   useEffect(() => {
  //     const today = new Date();

  // // Calculate the date 7 days ago
  // const sevenDaysAgo = new Date();
  // sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  // setSelectedDate(sevenDaysAgo.toISOString().slice(0, 10));
  // setSelectedToDate(today.toISOString().slice(0, 10))
  // console.log('Today:', today.toISOString().slice(0, 10)); // Format: YYYY-MM-DD

  // console.log('Seven days ago:', sevenDaysAgo.toISOString().slice(0, 10)); // Format: YYYY-MM-DD
  //     setLoadingScreen(true);
  //     let payload = {
  //       "fromdate":sevenDaysAgo.toISOString().slice(0, 10),
  //       "todate": today.toISOString().slice(0, 10)
  //   }
  //   console.log("payload",payload);
  //    ecampaignGetStatus(payload).then((result) => {
  //     // setLoadingScreen(true);
  //     console.log("result", result);
  //       if(!!result){
  //         console.log("result data", result.data);
  //         let data = result.data.filter(x=>x.campaign_id != -1);
  //         console.log("Filtered data",data);
  //         console.log("Filtered data",data.length);
  //         setVoterData(data);
  //         if(data.length == 0){
  //           setShowData(false);
  //         }
  //         else
  //         {
  //           setShowData(true);
  //         }
  //       }
  //       console.log("ID ",ShowData);

  //     });
  //     setLoadingScreen(false);
  //   }, []);

  const handleClick = (e) => {
    e.preventDefault();
    var removeItem = selectedCheckboxes[0];
    var finalItem = removeItem.slice(1);
    setSelectedCheckboxes([finalItem]);
  };

  const handleCount = (e) => {
    e.preventDefault();
    alert(`You have entered ${selectedCheckboxes.length} number(s)`);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#F3F7F9",
      backgroundImage: "none",
      height: "2.65625vw",
      width: "45vw",
      border: "none",
      minHeight: "0px",
      fontSize: "0.9375vw",
      span: {
        display: "none",
      },
      svg: {
        display: "none",
      },
      "&:after": {
        content: '""',
        display: "inline-block",
        width: "0",
        height: "0",
        marginLeft: "4px",
        verticalAlign: "middle",
        borderTop: "6px solid #000",
        borderRight: "4px solid transparent",
        borderBottom: "0",
        borderLeft: "4px solid transparent",
        position: "absolute",
        right: "0.9375vw",
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontWeight: "400",
      fontSize: "0.9375vw",
      color: "#8B93A5",
      position: "relative",
      bottom: "3vw",
    }),
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      borderBottom: "1px solid #D9D8D8",
      color: "#263659",
      fontSize: "0.9375vw",
      "&:last-child": {
        borderBottom: "none",
      },
    }),
  };

  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState();

  const handleSerach = (e) => {
    setSearchValue(e.target.value);
  };

  const hanclesearchClicked = () => {
    searchvoterEcampaign(searchValue).then((resp) => {
      if (resp.status == false) {
        setSearchData([resp.message]);
      }
    });
  };

  useEffect(() => {
    if (searchValue == "") {
      setSearchData();
    }
  });

  const [popupData, setPopupData] = useState([]);

  const [ChannelData, setChannelData] = useState("");
  const PersonStatusData = (item, channel) => {
    setPopupData(item);
    setChannelData(channel);
  };

  const [campPreview, setcampPreview] = useState();

  const getDataEcampaign = (item) => {
    setLoadingScreen(true);
    setPopupData(item);
    getcampData(item.campaign_id).then(async (result) => {
      await result.message;
      setLoadingScreen(false);
      setcampPreview(result.message);
    });
  };

  const [filterbutton, setFilterbutton] = useState(false);
  const handleclickedFilter = () => {
    setFilterbutton(!filterbutton);
    setSettingbutton(false);
  };

  const [settingbutton, setSettingbutton] = useState(false);

  const handleclickedSettingsbutton = () => {
    setSettingbutton(!settingbutton);
    setFilterbutton(false);
  };

  const [selectedDate, setSelectedDate] = useState();
  const [selectedToDate, setSelectedToDate] = useState();

  const fromDateObj = new Date(selectedDate);
  const toDateObj = new Date(selectedToDate);
  const filteredData = null;
  const handleApplyClicked = () => {
    // const filteredData = votersSearchdata.filter(item => {
    //   const itemDateObj = new Date(item.date);
    //   return itemDateObj >= fromDateObj && itemDateObj <= toDateObj;
    // });
    let filteredData = VoterData.filter(
      (item) =>
        new Date(item.date) >= fromDateObj && new Date(item.date) <= toDateObj
    );

    setVoterData(filteredData);
  };

  const handleDateChange = (event) => {
    // The selected date is available in the event.target.value
    const selectedDate = event.target.value;
    setSelectedDate(selectedDate);
  };

  const handleDateToChange = (event) => {
    // The selected date is available in the event.target.value
    const selectedToDate = event.target.value;
    setSelectedToDate(selectedToDate);
  };
  const [isPlaying, setIsPlaying] = useState(false);

  const handleAudioPlay = () => {
    setIsPlaying(true);
  };

  const handleAudioPause = () => {
    setIsPlaying(false);
  };
  const navigate = useNavigate();

  return (
    <div className="e_campaigns_main_div e_campaigns_main_main_div d-flex">
      <div className="top_nav_holder_main">
        <div className="d-flex flex-column">
          <div
            className="e_campaigns_text_maindiv"
            id="e_campaigns_text_maindiv"
          >
            <span
              className="e_campaigns_text_ems"
              onClick={() => navigate("/e-campaign")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                id="estatus_leftarrow"
              >
                <path
                  d="M18.7495 8.75032H4.78704L10.887 2.65032C11.3745 2.16282 11.3745 1.36282 10.887 0.87532C10.7714 0.759441 10.634 0.667506 10.4828 0.60478C10.3316 0.542053 10.1695 0.509766 10.0058 0.509766C9.84208 0.509766 9.67998 0.542053 9.52876 0.60478C9.37754 0.667506 9.24018 0.759441 9.12454 0.87532L0.88704 9.11282C0.77116 9.22846 0.679226 9.36582 0.616499 9.51704C0.553772 9.66826 0.521484 9.83036 0.521484 9.99407C0.521484 10.1578 0.553772 10.3199 0.616499 10.4711C0.679226 10.6223 0.77116 10.7597 0.88704 10.8753L9.12454 19.1128C9.24027 19.2285 9.37766 19.3203 9.52886 19.383C9.68007 19.4456 9.84213 19.4778 10.0058 19.4778C10.1695 19.4778 10.3315 19.4456 10.4827 19.383C10.6339 19.3203 10.7713 19.2285 10.887 19.1128C11.0028 18.9971 11.0946 18.8597 11.1572 18.7085C11.2198 18.5573 11.2521 18.3952 11.2521 18.2316C11.2521 18.0679 11.2198 17.9058 11.1572 17.7546C11.0946 17.6034 11.0028 17.466 10.887 17.3503L4.78704 11.2503H18.7495C19.437 11.2503 19.9995 10.6878 19.9995 10.0003C19.9995 9.31282 19.437 8.75032 18.7495 8.75032Z"
                  fill="black"
                />
              </svg>
              E-Campaign
            </span>
            <button
              className="filterES d-flex align-items-center"
              onClick={handleclickedFilter}
            >
              <img
                src={filter}
                className="
               e_campaigns_filter_img"
                alt="not-visible"
              />
            </button>
            <div
              style={{
                display: filterbutton ? "block" : "none",
                height: "-8vw !important",
              }}
              className="voters_data_filter_dropdownE"
              id="voters_data_filter_dropdownE"
            >
              <div className="dropdown_constituency_Main_div d-flex flex-column "></div>
              <div className="col-md-12 selection_criteria_main_div">
                <div
                  className="col-md-12  selection_criteria_title_div"
                  //  style={{ backgroundColor: '#f7f7f7', color: 'black', padding: '12px', border: '1px solid grey',fontWeight:'800' }}
                >
                  Selection Criteria
                </div>
                <div className="row date_pciker_block">
                  <div className="col-m-12">
                    <div
                      className="ecampaigns_data  ecampiangsdate_ "
                      id="e_campaings_data"
                      // style={{
                      //    display: 'flex',
                      //  justifyContent: 'space-between',
                      //   margin:'7px'
                      //    }}
                    >
                      <span>
                        <label className="e-campaigns_filter_fromdate">
                          From Date
                        </label>
                      </span>
                      <span>
                        <label className="e-campaigns_filter_todate">
                          To Date
                        </label>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 e-campaignsdate_picker_div">
                    <div
                      className="date_picker_topmanin_div"
                      // style={{ display: 'flex', justifyContent: 'space-around',padding:'5px' }}
                    >
                      <span
                      // style={{ padding: '0px 10px 0px 10px' }}
                      >
                        <input
                          type="date"
                          id="datePicker"
                          name="datePicker"
                          value={selectedDate}
                          onChange={handleDateChange}
                        ></input>
                      </span>
                      <span
                        style={
                          {
                            //  padding: '0px 10px 0px 10px'
                          }
                        }
                      >
                        <input
                          type="date"
                          id="datetoPicker"
                          name="datetoPicker"
                          value={selectedToDate}
                          onChange={handleDateToChange}
                        ></input>
                      </span>
                    </div>
                  </div>
                </div>

                <hr className="e-campaigns_filter_hrtag" />
              </div>
              <div className="e-campaigns_filter_foot_buttons">
                <button
                  className="canclebt_in_voters_data_dropdowns canclebt_in_ecampaings_data_dropdowns"
                  onClick={() => setFilterbutton(false)}
                >
                  Cancel
                </button>
                <button
                  className="applybtn_in_e-campaigns_data_dropdowns"
                  onClick={() => {
                    handleApplyClicked();
                    setFilterbutton(false);
                  }}
                >
                  Apply
                </button>
              </div>
              {/* <button
                className="canclebt_in_voters_data_dropdowns"
                onClick={() => setFilterbutton(false)}
              >
                Cancel
              </button>
              <button
                className="applybtn_in_e-campaigns_data_dropdowns"
                onClick={() => {
                  handleApplyClicked();
                  setFilterbutton(false);
                }}
              >
                Apply
              </button> */}
            </div>
            {/* <div className={"socialImages"}>
              <div className="row">
                <div className="col-md-3">
                  <img
                    data-toggle="modal"
                    data-target="#myWhatsapp"
                    src={WhatsappIcon}
                    alt="whatsapp"
                    class="whatsapp"
                  ></img>
                </div>
                <div className="col-md-3">
                  <img src={CallIcon} alt="call" class="call"></img>
                </div>
                <div className="col-md-3">
                  <img
                    data-toggle="modal"
                    data-target="#myModal"
                    src={SMS}
                    alt="sms"
                    class="sms_modal"
                  ></img>
                </div>
                <div className="col-md-3">
                  <img
                    mode
                    src={EcampBtn}
                    alt="e-campaign"
                    class="e-campaign"
                  ></img>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div
          className="scroll_ecampaigns"
          id="scroll_ecampaigns_main_div"
          style={{}}
        >
          <div
            className="tableFixHead_ecampaigns_status  table_fixed_status"
            id="scrollbar_ecampaigns"
          >
            <table
              id="e-campaigns_table"
              className="ecampaings_status_table table table-striped table-bordered"
              style={{ width: "100%" }}
            >
              <thead
                className="table_header_ecampaigns"
                id="table_header_ecampaigns"
              >
                <tr>
                  {/* <th className="thead_checkbox">
                    <input
                      checked={
                        checkboxes.length > 0 &&
                        checkboxes.length === data.length
                      }
                      onChange={handleSelectAllChange}
                      type="checkbox"
                      id="checkbox1"
                    ></input>
                  </th> */}

                  <th className="thead_1_" id="thead_1_">
                    Campaign ID
                  </th>

                  <th className="thead_2_" id="thead_1_">
                    Message/Voice
                  </th>

                  {/* <th className="thead_3" id="thead_1">
                    Photo/file
                  </th>
                  <th className="thead_4" id="thead_1">
                    creditUsed
                  </th> */}

                  <th className="thead_5_" id="thead_1_">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="scro" id="scroll_table_body">
                {TemplateData.map((item) => (
                  <tr>
                    {/* <td className="thead_checkbox table_data_1">
                      <input
                        checked={checkboxes.includes(item.voter_id)}
                        onChange={(event) =>
                          handleCheckboxChange(event, item.voter_id, item)
                        }
                        type="checkbox"
                        id="body_checkbox"
                      ></input>
                    </td> */}

                    <td
                      data-toggle="modal"
                      data-target="#myModal"
                      onClick={() => getDataEcampaign(item)}
                      className="table_data_2"
                    >
                      {item.campaign_id}
                    </td>
                    {/* <td data-toggle="modal" data-target="#myModal" onClick={() => PersonStatusData(item, 'Message/Voice')} className="table_data_3">
                      {
                    item.messageVoice.includes('mp3') && item.messageVoice.substring(0,27)
                    }
                    {
                    !item.messageVoice.includes('mp3') && `${item.messageVoice.substring(0,27)}....`
                    }
                      {item.messageVoice.includes('mp3') &&
                        <span style={{ color: "#528BB8", float: 'right' }}>
                          <img src={audioPng}></img>
                         <span style={{paddingLeft:'8px'}}>
                         Audio File
                          </span>
                        </span>
                      }
                      {!item.messageVoice.includes('mp3') &&
                        <span style={{ color: "#528BB8", float: 'right' }}>
                         See More
                        </span>
                      }
                    </td>
                    <td data-toggle="modal" data-target="#myModal" onClick={() => PersonStatusData(item, 'Photo/File')} className="table_data_4">{item.photofile}
                        <span style={{ color: "#528BB8", float: 'right' }}>
                          <img src={imagePng}></img>
                          Image File
                        </span>
                    </td> */}
                    {/* <td className="table_data_5">{item.creditUsed}</td> */}
                    {/* <div data-toggle="modal" data-target="#myModal" onClick={() => PersonStatusData(item,'phonecall')} className="row" style={{padding:'5px 0px 5px 5px',fontSize:'15px'}}>
                 <div className="col-md-8" >
                  <span>PhoneCall :</span>
                 </div>
                 <div className="col-md-4">
                 <StatusFlag flag={true} />
                 </div>
                 <Popup isOpen={isPopupOpen} />       
               </div>    */}
                    <td>{item.message}</td>
                    {item.status == "Processing" && (
                      <td
                        style={{ color: "#e9d73a", fontSize: "12px" }}
                        className="table_data_5"
                      >
                        {item.status}
                      </td>
                    )}
                    {item.status == "blocked" && (
                      <td
                        style={{ color: "red", fontSize: "12px" }}
                        className="table_data_5"
                      >
                        {item.status}
                      </td>
                    )}
                    {item.status == "Delivered" && (
                      <td
                        style={{ color: "green", fontSize: "12px" }}
                        className="table_data_5"
                      >
                        {item.status}
                      </td>
                    )}
                  </tr>
                ))}
                {TemplateData.length == 0 && (
                  <span className="noDataContainer">No Data available</span>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {popupData.length != 0 && ChannelData != "" && (
        <div class="modal fade" id="myModal" role="dialog">
          <div class="viewstatus_modal-dialog">
            <div class="viewstatus_modal-content">
              <div class="viewstatus_modal-header">
                <div></div>
                <h4 class="modal-title">{ChannelData.toUpperCase()} </h4>
                <button
                  type="button"
                  className="close btn-close"
                  data-dismiss="modal"
                ></button>
              </div>
              <div class="modal-body">
                <div className="row">
                  <div
                    className="col-md-12"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <b>{popupData.campaignId}</b>
                    {ChannelData == "Photo/File" && (
                      <span>{popupData.photofile}</span>
                    )}
                  </div>
                  {popupData.messageVoice.includes("mp3") &&
                    ChannelData != "Photo/File" && (
                      <div
                        className="col-md-12 mt-4"
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <audio
                          style={{ width: "220px" }}
                          controls
                          autoPlay={isPlaying}
                          src={sampleAudio}
                          onPlay={handleAudioPlay}
                          onPause={handleAudioPause}
                        />
                        <span className="mt-3">Audio File MP3 Format</span>
                      </div>
                    )}
                  {!popupData.messageVoice.includes("mp3") &&
                    ChannelData != "Photo/File" && (
                      <div className="col-md-12  mt-4">
                        <span>{popupData.messageVoice}</span>
                      </div>
                    )}
                  {ChannelData == "Photo/File" &&
                    ChannelData == "Photo/File" && (
                      <div
                        className="col-md-12  mt-4"
                        style={{ textAlign: "center" }}
                      >
                        <span>
                          <img
                            className="img-fluid"
                            style={{ width: "100px" }}
                            src="https://www.itoconnect.online/static/media/itoclogo.177bcd8fbbfae7581125.png"
                          ></img>
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div class="modal fade" id="myModal" role="dialog">
        <div class="viewstatus_modal-dialog">
          <div class="viewstatus_modal-content">
            <div class="viewstatus_modal-header">
              <div></div>
              <h4 class="modal-title">Campaign Preview</h4>
              <button
                type="button"
                className="close btn-close"
                data-dismiss="modal"
              ></button>
            </div>
            <div class="modal-body">
              <div style={{ height: "400px", overflowY: "scroll" }}>
                <table
                  id="e-campaigns_table"
                  className="ecampaings_status_table table table-striped table-bordered"
                >
                  <thead
                    className="table_header_ecampaigns"
                    id="table_header_ecampaigns"
                  >
                    <tr>
                      <th className="thead_1_" id="thead_1_">
                        Campaign ID
                      </th>
                      <th className="thead_1_" id="thead_1_">
                        Client Campaign ID
                      </th>
                      <th className="thead_1_" id="thead_1_">
                        Serial No
                      </th>
                      <th className="thead_2_" id="thead_1_">
                        Message/Voice
                      </th>
                      <th className="thead_5_" id="thead_1_">
                        Phone Number
                      </th>
                      <th className="thead_5_" id="thead_1_">
                        Sent Date
                      </th>
                      <th className="thead_5_" id="thead_1_">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="scro" id="scroll_table_body">
                    {campPreview?.map((item) => (
                      <tr>
                        <td className="table_data_2">{item.campaign_id}</td>
                        <td className="table_data_2">{item.client_sms_id}</td>
                        <td className="table_data_2">{item.serial_no}</td>
                        <td className="table_data_2">{item.message_id}</td>
                        <td className="table_data_2">{item.phone_no}</td>
                        <td className="table_data_2">{item.sent_on}</td>
                        {item.delivery_status == "Processing" && (
                          <td
                            style={{ color: "#e9d73a", fontSize: "12px" }}
                            className="table_data_5"
                          >
                            {item.delivery_status}
                          </td>
                        )}
                        {item.status == "blocked" && (
                          <td
                            style={{ color: "red", fontSize: "12px" }}
                            className="table_data_5"
                          >
                            {item.delivery_status}
                          </td>
                        )}
                        {item.status == "DELIVERD" && (
                          <td
                            style={{ color: "green", fontSize: "12px" }}
                            className="table_data_5"
                          >
                            {item.delivery_status}
                          </td>
                        )}
                        {item.status == "" && (
                          <td
                            style={{ color: "green", fontSize: "12px" }}
                            className="table_data_5"
                          >
                            No Status available
                          </td>
                        )}
                      </tr>
                    ))}
                    {TemplateData.length == 0 && (
                      <span className="noDataContainer">No Data available</span>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />

      {loadingscreen ? <LoaderWrapper /> : null}
    </div>
  );
};
export default EcampaignViewStatus;
