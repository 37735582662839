import React, { createRef, useContext, Suspense, useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import { useTable, usePagination,useBlockLayout, useResizeColumns } from 'react-table'
import { Row, Col, Stack, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Toast from 'react-bootstrap/Toast';
import CloseButton from 'react-bootstrap/CloseButton';
import { ImportDataContext } from "../../../Pages/ImportData/ImportDataContext";
import './ViewDataComponent.scss';
import * as XLSX from 'xlsx';

import { GiFastBackwardButton} from "@react-icons/all-files/gi/GiFastBackwardButton";
import { GiFastForwardButton } from "@react-icons/all-files/gi/GiFastForwardButton";
import { GiNextButton } from "@react-icons/all-files/gi/GiNextButton";
import { GiPreviousButton } from "@react-icons/all-files/gi/GiPreviousButton";

  // Create an editable cell renderer

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return <input value={value} onChange={onChange} onBlur={onBlur} />
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
}

// Be sure to pass our updateMyData and the skipPageReset option
function Table({ columns, data, updateMyData, skipPageReset }) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    usePagination,
    useBlockLayout,
    useResizeColumns
  )

  // Render the UI for your table
  return (
    <>
    <div className="tablemain_div">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()} className={(cell.value == null || cell.value == '') ? 'error-cell': ''} >{cell.value == null ? '' : cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      </div>
      <div className="pagination">

        <div className="pagination_element">
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
        </div>
        <div className="pagination_element">
          <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                if (page < 0){
                  e.target.value = 1;
                  gotoPage(0);
                } else {
                  gotoPage(page)
                }
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
        </div>
        <div className="pagination_element">
          <select
            value={pageSize}
            className="select_pages"
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 50, 100, 200].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>

        <div className="pagination_element">
          <button onClick={() => gotoPage(0)} class="pagination_buttons" disabled={!canPreviousPage}>
            {<GiFastBackwardButton />}
          </button>{' '}
          <button onClick={() => previousPage()} class="pagination_buttons" disabled={!canPreviousPage}>
            {<GiPreviousButton />}
          </button>{' '}
          <button onClick={() => nextPage()} class="pagination_buttons" disabled={!canNextPage}>
            {<GiNextButton />}
          </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} class="pagination_buttons" disabled={!canNextPage}>
            {<GiFastForwardButton />}
          </button>{' '}
        </div>
      </div>
    </>
  )
}


function ViewDataComponent({closenav}) {
  
  const { importDataService } = useContext(ImportDataContext);
  const [enableNextButton, setEnableNextButton] = useState(true);
  const navigation = useNavigate();
  const [processingData, setProcessingData] = React.useState(importDataService.exportedJsonData.voterData);
  const [data, setData] = React.useState(processingData);
  const [missingData, setMissingData] = React.useState([]);
  const [originalData] = React.useState(importDataService.exportedJsonData.voterData);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [saveDataButton, setSaveDataButton] = React.useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessTost, setShowSuccessTost] = useState(false);
  const [selectedOption, setselectedOption] = useState('All');
  let _mappingCheckFields = { emptyCheck : [], matchCheck: [] };
  useEffect(() => {
    validateMatchCheck();
    processMissingData();
  }, [])

  useEffect(() => {
    processMissingData();
  }, [processingData])
  

  const getColumnsFromJson = (data) => {
    return Object.keys(data);
  }
  const columns = React.useMemo(
    () => {
      if (importDataService.exportedJsonData.voterData.length){
        let colFromJson = getColumnsFromJson(importDataService.exportedJsonData.voterData[0]);
        let _Cols =  colFromJson.map(x => {
          return {
            Header: x,
            accessor: x,
          }
        })
        return _Cols;
      } else {
        return [];
      }
      
    },
    []
  );

  const processMissingData = () => {
    let _missingData = [];
    _mappingCheckFields.emptyCheck = importDataService.getMappingCheckFields(importDataService.viewDataValidationConfig.emptyCheck);
    processingData.forEach((x,i) => {
      let _fieldInfo = _mappingCheckFields.emptyCheck.filter( field => {
        if(x[field] === null || x[field] === '') {
          return true;          
        }
      })
      if (_fieldInfo.length) {
        x['index'] = i;
        _missingData.push(x);
      }
    });
    setMissingData(_missingData);
  }

  const validateMatchCheck = () => {
    _mappingCheckFields.matchCheck = importDataService.getMappingCheckFields(importDataService.viewDataValidationConfig.matchCheck);
    console.log(importDataService.exportedJsonData);
    let constituencySelected = importDataService.importDataSelected.constituency;
    let pollingStationSelected = importDataService.importDataSelected.polingStation;
    if (importDataService.importDataSelected.importTypeSelection === 'constituency') {
      let constituencyFromPDF = parseInt(importDataService.exportedJsonData.voterData[0][_mappingCheckFields.matchCheck[0]]);
      if ( !(constituencySelected.mla_constituency_no == constituencyFromPDF)) {
        setErrorMessage('Mismatch in constituency selected with data uploaded. Please upload correct Data');
        setShowError(true);
        setEnableNextButton(false);
      }
    } else {
      if (importDataService.exportedJsonData.polling_booth_data) {
        let constituencyFromPDF = importDataService.exportedJsonData.polling_booth_data[_mappingCheckFields.matchCheck[0]];
        let pollingStationFromPDF = importDataService.exportedJsonData.polling_booth_data[_mappingCheckFields.matchCheck[1]];
        if (pollingStationSelected && !((`${pollingStationSelected.polling_booth__polling_booth_id}-${pollingStationSelected.polling_booth__polling_booth_name}`).toLowerCase() == pollingStationFromPDF.toLowerCase())) {
          setErrorMessage('Mismatch in polling station selected with pdf uploaded. Please upload correct PDF');
          setShowError(true);
          setEnableNextButton(false)
        }else if ( !(constituencySelected.mla_constituency_no == constituencyFromPDF)) {
          setErrorMessage('Mismatch in constituency selected with pdf uploaded. Please upload correct PDF');
          setShowError(true);
          setEnableNextButton(false);
        }
      }
    }
    
  }
  

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  React.useEffect(() => {
    setSkipPageReset(false)
  }, [data])

  const resetData = () => setData(originalData)
  
  const saveMissingData = () => {
    let _data = [...processingData];
    data.forEach( x => {
      _data[x.index] = x;
    })
    setProcessingData(_data);
    importDataService.exportedJsonData.voterData = _data;
    setShowSuccessTost(true);
  }

  const showAllData = () => {
    setselectedOption('All');
    setSaveDataButton(false);
    setData(processingData);
  }

  const showMissingData = () => {
    setselectedOption('Missing');
    setSaveDataButton(true);
    setData(missingData);
  }

  const exportData = () => {
    csvmaker(processingData);
  }

  const convertJsonToWorkbook = (jsonData) => {
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    return workbook;
  }

  const download = function (data) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', 'download.csv');
    a.click()
}

  const csvmaker = function (data) {
    const workbook = convertJsonToWorkbook(data);
    XLSX.writeFile(workbook, 'data.xlsx');
    /* let csvRows = [];
    const headers = Object.keys(data[0]);

    csvRows.push(headers.join(','));
    data.forEach(x => {
      const values = Object.values(x).join(',');
      csvRows.push(values.toString())
    })
    download(csvRows.join('\n')); */
}
 
const get = async function () {
 
    // JavaScript object
    const data = {
        id: 1,
        name: "Geeks",
        profession: "developer"
    }
 
    const csvdata = csvmaker(data);
    download(csvdata);
}

  const cancelUpload = () => {
    navigation('../')
  }

  const previousStep = () => {
    navigation('../mapFields')
  }

  const nextStep = () => {
    if(missingData.length) {
      setShowError(true);
      setErrorMessage("It seems like First Name, Voter Id , Serial Number are missing for few records.");
    } else {
      navigation('../importSummary')
    }
  }
  
  return (
    <>
      <div className="view-data-component">
        <Row>
          <div className={`${closenav ?'table-main' : 'table-main-full'}`}> 
            <Stack className="mb-1" direction="horizontal" gap={3}>
            
            <Toast show={showSuccessTost} className="d-inline-block m-1" bg='success'>
              <Toast.Body>
                <Stack className="text-white" style={{width:'100%'}} direction="horizontal" gap={3}>
                  <div>Data saved Successfully</div>
                  <CloseButton className="btn-close ms-auto text-white" onClick={()=> setShowSuccessTost(false)} />
                </Stack>
                </Toast.Body>
            </Toast>
              {showError ? <div className="text-danger">
                  {errorMessage}
              </div> : ''}
              
              <Button  
                size="sm" 
                variant="primary" 
                aria-pressed="true"
                className={`ms-auto btn-primary-ems no-border-radius ${selectedOption === 'All' ? 'active-button' : ''}`}
                onClick={showAllData}>
                  Show All Data
              </Button>
              <Button  
                size="sm" 
                variant="primary" 
                aria-pressed="true"
                className={`btn-primary-ems no-border-radius ${selectedOption === 'Missing' ? 'active-button' : ''}`}
                onClick={showMissingData}>
                  Show Missing Data
              </Button>
              <Button 
                size="sm"
                variant="primary"
                aria-pressed="true"
                className={`btn-primary-ems no-border-radius `}
                onClick={exportData}>
                Export
              </Button>
              {saveDataButton ? 
                <Button  
                  size="sm" 
                  variant="primary" 
                  aria-pressed="true"
                  className="btn-primary-ems no-border-radius"
                  onClick={saveMissingData}>
                    Save
                </Button> : ''}
            </Stack>
            <Table
              columns={columns}
              data={data}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
            />
          </div>
        </Row>
        <Row className="navbar-fixed-bottom">
        <Card className="card-navbar-bottom" body>
            <Stack direction="horizontal" gap={3}>
              <div></div>
              <Button onClick={cancelUpload} size="sm" variant="outline-primary" className="ms-auto no-border-radius btn-outline-ems">Cancel</Button>
              <Button onClick={previousStep} size="sm" variant="info" className={`no-border-radius btn-outline-ems `}>Previous</Button>
              <Button onClick={nextStep} size="sm" variant="primary" className={`no-border-radius btn-primary-ems ${enableNextButton ? '' : 'disabled'}`}>Next</Button>
            </Stack>
          </Card>
          <Stack className="import-footer-main" direction="horizontal" gap={3}>
              <div className="import-footer">
                <span className="import-footer-endfooterimg">Copyright © 2023 iToConnect. All Rights Reserved.</span>
              </div>
          </Stack>
        </Row>
      </div>
    </>
  );
}

export default ViewDataComponent;