import { createContext, useState } from 'react';
import RoleDataService from './Service/RoleDataService';



let roleDataService = new RoleDataService();
const RoleDataContext = createContext();
const { Provider } = RoleDataContext;
const RoleDataProvider = ({ children }) => {
  return <RoleDataContext.Provider value={{roleDataService}}>{children}</RoleDataContext.Provider>;
};
export { RoleDataContext, RoleDataProvider }